import { AxiosError } from 'axios';
import { dateAndTime } from 'common/timeStampToDate';
import Button from 'components/base/Button';
import { KanbanBoardItem, KanbanBoardTask } from 'data/kanban';
import { useAuth } from 'providers/AuthContext';
import { useKanbanContext } from 'providers/KanbanProvider';
import { useToast } from 'providers/ToastProvider';
import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import apiCall from 'services/api';
import { handleCutomError } from 'services/handleCutomError';
import { CardDateTime } from '../KanbanTaskDetailsModal';
import Unicon from 'components/base/Unicon';
import { UilCheck, UilMultiply, UilTrash } from '@iconscout/react-unicons';

interface EditableTitleProps {
    task: KanbanBoardTask;
    list: KanbanBoardItem;
    setCardDateTime: React.Dispatch<React.SetStateAction<CardDateTime>>;
    setCardDetails: React.Dispatch<React.SetStateAction<KanbanBoardTask | undefined>>;
    onHistoryUpdate?: () => void;
}

export interface Loading {
    statusChange: boolean;
    deleteCard: boolean;

}

const CardStatusChange: React.FC<EditableTitleProps> = ({ task, list, setCardDateTime, setCardDetails, onHistoryUpdate }) => {
    const { alias, id } = useParams();
    const { boardLists, boardLoading, filterValue, kanbanDispatch } = useKanbanContext();

    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const { showErrorToast, showSuccessToast } = useToast();
    const navigation = useNavigate();
    const [taskData, setTaskData] = useState<KanbanBoardTask | undefined>(undefined);
    const [loading, setLoading] = useState<Loading>({
        statusChange: false,
        deleteCard: false
    });

    useEffect(() => {

        if (task) {
            setTaskData({
                ...task,
                id: task.id
            });
        }
    }, [task]);

    const statusChange = async (cardId: string) => {
        const isConfirmed = window.confirm("Are you sure you want change card status?");

        if (!isConfirmed) {
          // If the user clicks "No", simply return without doing anything
          return;
        }

        if (!id && !cardId) {

            return
        }
        try {
            setLoading((prev) => ({
                ...prev,
                statusChange: true
            }));
            // Update card Status
            const response = await apiCall({
                url: `project/${alias}/boards/${id}/card/${cardId}/status-change`,
                method: 'POST',
                headers: {
                    'x-access-token': userTkn,
                    workspace: workSpaceTkn
                }
            });

            if (response?.status === 200) {

                const cardResponse = response?.data?.data?.boardListCard
                const updatedBoardLists = boardLists.map(list => {
                    if (list.id === cardResponse.list_id) {
                        // Find the card to update
                        const updatedCards = list.boardListCards.map(card => {
                            if (card.id === cardResponse.id) {
                                // Update the card with new data
                                return { ...card, ...cardResponse };
                            }
                            return card;
                        });

                        // Return the list with the updated cards
                        return { ...list, boardListCards: updatedCards };
                    }
                    return list;
                });

                // updated boardLists
                kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists }); // set main state

                const { formattedDate, formattedTime } = dateAndTime(cardResponse.end_datetime)
                //set local state
                setCardDateTime({
                    cardDate: formattedDate,
                    cardTime: formattedTime
                })
                setCardDetails(cardResponse);
                if(filterValue === '1'){
                    updateBoardData();
                }
                if(filterValue === '0'){
                    updateBoardDataAfterDelete()
                }
                if (onHistoryUpdate) onHistoryUpdate();
            } else {
                console.error('error messag');
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An unexpected error occurred:', error);
            }
        } finally {
            setLoading((prev) => ({
                ...prev,
                statusChange: false
            }));
        }
    }
    const updateBoardData = async () => {

        try {
            kanbanDispatch({ type: 'BOARD_LOADING', payload: true }); // Set loading to true
            const [boardResponse, cardPriorityResponse, cardCategoryResponse] = await Promise.all([
                apiCall({
                    url: `project/${alias}/boards/${id}/view`,
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                        workspace: workSpaceTkn
                    }
                }),
                apiCall({
                    url: `project/${alias}/masters/priority`,  // updated
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                        workspace: workSpaceTkn
                    }
                }),
                apiCall({
                    url: `project/${alias}/masters/category`,  // updated
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                        workspace: workSpaceTkn
                    }
                })
            ])

            if (boardResponse?.status === 200) {
                // Calculate serial numbers for the projects on the current page
                const boardData = boardResponse?.data?.data?.board;
                const boardListData = boardData?.boardLists;
                const boardMembersData = boardData?.users;
                // setData(boardListData);
                kanbanDispatch({ type: 'SET_DATA', payload: boardListData });
                kanbanDispatch({ type: 'UPDATE_BOARD_USERS', payload: boardMembersData });
                kanbanDispatch({ type: 'BOARD_DATA', payload: boardData })

                kanbanDispatch({ type: 'BOARD_LOADING', payload: false });
            } else {
                console.error('error messag');
            }
            if (cardPriorityResponse?.status === 200) {

                const priorityData = cardPriorityResponse?.data?.data?.ProjectsPriority;
                const options = priorityData.map((item: any) => ({
                    value: item.id,
                    label: item.title,
                    color: item?.color
                }));
                kanbanDispatch({ type: 'CARD_PRIORITY', payload: options })
            }
            if (cardCategoryResponse?.status === 200) {

                const priorityData = cardCategoryResponse?.data?.data?.ProjectsCategory;
                const options = priorityData.map((item: any) => ({
                    value: item.id,
                    label: item.title,
                    color: item?.color
                }));
                kanbanDispatch({ type: 'CARD_CATEGORY', payload: options })
            }
        } catch (error: any) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                showErrorToast(error);
            }
        } finally {
            kanbanDispatch({ type: 'BOARD_LOADING', payload: false }); // Set loading to false after the API call is completed
        }

    }

    const updateBoardDataAfterDelete = async () => {

        try {
            kanbanDispatch({ type: 'BOARD_LOADING', payload: true }); // Set loading to true
            const [boardResponse, cardPriorityResponse, cardCategoryResponse] = await Promise.all([
                apiCall({
                    url: `project/${alias}/boards/${id}/view?card_status=0`,
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                        workspace: workSpaceTkn
                    }
                }),
                apiCall({
                    url: `project/${alias}/masters/priority`,  // updated
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                        workspace: workSpaceTkn
                    }
                }),
                apiCall({
                    url: `project/${alias}/masters/category`,  // updated
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                        workspace: workSpaceTkn
                    }
                })
            ])

            if (boardResponse?.status === 200) {
                // Calculate serial numbers for the projects on the current page
                const boardData = boardResponse?.data?.data?.board;
                const boardListData = boardData?.boardLists;
                const boardMembersData = boardData?.users;
                // setData(boardListData);
                kanbanDispatch({ type: 'SET_DATA', payload: boardListData });
                kanbanDispatch({ type: 'UPDATE_BOARD_USERS', payload: boardMembersData });
                kanbanDispatch({ type: 'BOARD_DATA', payload: boardData })

                kanbanDispatch({ type: 'BOARD_LOADING', payload: false });
            } else {
                console.error('error messag');
            }
            if (cardPriorityResponse?.status === 200) {

                const priorityData = cardPriorityResponse?.data?.data?.ProjectsPriority;
                const options = priorityData.map((item: any) => ({
                    value: item.id,
                    label: item.title,
                    color: item?.color
                }));
                kanbanDispatch({ type: 'CARD_PRIORITY', payload: options })
            }
            if (cardCategoryResponse?.status === 200) {

                const priorityData = cardCategoryResponse?.data?.data?.ProjectsCategory;
                const options = priorityData.map((item: any) => ({
                    value: item.id,
                    label: item.title,
                    color: item?.color
                }));
                kanbanDispatch({ type: 'CARD_CATEGORY', payload: options })
            }
        } catch (error: any) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                showErrorToast(error);
            }
        } finally {
            kanbanDispatch({ type: 'BOARD_LOADING', payload: false }); // Set loading to false after the API call is completed
        }

    }


    const deleteCard = async (cardId: string) => {
        const isConfirmed = window.confirm("Are you sure you want to delete card?");

        if (!isConfirmed) {
          // If the user clicks "No", simply return without doing anything
          return;
        }
    
        if (!id && !cardId) {

            return
        }
        try {
            setLoading((prev) => ({
                ...prev,
                deleteCard: true
            }));
            // Update card Status
            const response = await apiCall({
                url: `project/${alias}/boards/${id}/card/${cardId}/delete`,
                method: 'POST',
                headers: {
                    'x-access-token': userTkn,
                    workspace: workSpaceTkn
                }
            });

            if (response?.status === 200) {

                const cardResponse = response?.data?.data?.boardListCard
                const updatedBoardLists = boardLists.map(list => {
                    if (list.id === cardResponse.list_id) {
                        // Find the card to update
                        const updatedCards = list.boardListCards.map(card => {
                            if (card.id === cardResponse.id) {
                                // Update the card with new data
                                return { ...card, ...cardResponse };
                            }
                            return card;
                        });

                        // Return the list with the updated cards
                        return { ...list, boardListCards: updatedCards };
                    }
                    return list;
                });

                // updated boardLists
                kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists }); // set main state

                const { formattedDate, formattedTime } = dateAndTime(cardResponse.end_datetime)
                //set local state
                setCardDateTime({
                    cardDate: formattedDate,
                    cardTime: formattedTime
                })
                setCardDetails(cardResponse);
                if(filterValue === '0'){
                    updateBoardDataAfterDelete();
                }
                if (onHistoryUpdate) onHistoryUpdate();
            } else {
                console.error('error messag');
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An unexpected error occurred:', error);
            }
        } finally {
            setLoading((prev) => ({
                ...prev,
                deleteCard: false
            }));
        }
    }
    return (
        <div className='d-flex gap-1 align-items-center'>
            <div className='fs-8 fw-medium'>
                {taskData?.status === 1 ? "Active" : "Archive"}
            </div>
            <Button
                variant="phoenix-primary"
                className="btn-icon me-2 rounded-1 bg-transparent"
                onClick={() => {
                    statusChange(taskData?.id || '')
                }}
                disabled={loading?.statusChange}
            >
                {loading?.statusChange ? <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                /> : <Unicon icon={taskData?.status === 1 ? UilCheck : UilMultiply} size={20} />}
            </Button>
            <div>
                {taskData?.status === 0 && <Button
                    variant="phoenix-primary"
                    className="btn-icon me-2 rounded-1 bg-transparent ms-5"
                    onClick={() => {
                        deleteCard(taskData?.id || '')
                    }}
                    disabled={loading?.deleteCard}
                >
                    {loading?.deleteCard ? <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> : <Unicon icon={UilTrash} size={20} color='red' />}
                </Button>
                }
            </div>
        </div>

    );
};

export default CardStatusChange;
