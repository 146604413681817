import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "common/Constants";
import PageBreadcrumb, { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { useEffect, useState, useRef } from "react";
import { Link, NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { projectsTablecolumns } from "components/tables/customTables/ProjectsTablesList";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import Unicon from 'components/base/Unicon';
import { UilToggleOn, UilToggleOff } from '@iconscout/react-unicons';
import { ChangeEvent } from "react";
import { Col, FloatingLabel, Form, Card, Placeholder, Row, Table } from "react-bootstrap";
import { useAppContext } from "providers/AppProvider";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import { useAuth } from "providers/AuthContext";
import Avatar from "components/base/Avatar";
import apiCall from "../../services/api"; // Adjust the path based on your project structure
import calculateSerialNumber from "common/calculateSerialNumber";
import CustomPagination from "components/sp-common/CustomPagination";
import { getAdminPageBreadCrumbs } from "services/getAdminPageBreadCrumbs";
import { ToastContainer, toast } from "react-toastify";
import { handleCutomError } from "services/handleCutomError";



// Assuming you have a type for your data objects
type Projects = {
  id: number;
  title: string;
  short_name: string | null;
  project_status: string | null;
  alias: string;
  status: number;
  created_by: number;
  updated_by: number;
  created_at: number;
  updated_at: number;
  projectlogo: string;
  projects: any[]; // You might want to define a type for projects as well
  users: any[]; // You might want to define a type for users as well
  serialNumber: number;
};

// Define the type for the page filters state
type PageFiltersState = {
  status: string;
  titleSearch: string;
};


const AdminProjectsList = () => {
  const initialBreadcrumb: PageBreadcrumbItem[] = [];
  const [pageName, setPageName] = useState("AdminProjectList")
  const [breadCrumb, setBreadCrumb] = useState<PageBreadcrumbItem[]>(initialBreadcrumb);
  const { workspace } = useParams();
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const [data, setData] = useState<Projects[] | null>(null);
  const [summary, setSummary] = useState<any | null>({
    "total": 0,
    "page": 1,
    "pageSize": 0,
    "total_page": 1,
  });
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [category, setCategory] = useState<string | null>("");
  const [status, setStatus] = useState<string | null>("1");
  const tableRef = useRef<HTMLTableElement | null>(null);
  const [indexValueFirst, setValueFirst] = useState<number | null>(1);
  const [indexValueLast, setValueLast] = useState<number | null>(1);
  const navigation = useNavigate() as NavigateFunction; // Explicitly define the type

  //for filters
  const [pageFilters, setPageFilters] = useState<PageFiltersState>({
    status: "1",
    titleSearch: ""
  })


  const { config: { theme, navbarPosition }, setConfig, } = useAppContext();
  //update Breadcrumb
  useEffect(() => {
    getAdminPageBreadCrumbs({ pageName, workspace, setBreadCrumb });
  }, [workspace, pageName, setBreadCrumb]);
  //set top navigation
  useEffect(() => {
    const value = "horizontal";
    const value2 = "slim";
    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant,
    });
  }, []);
  
  //check workspace 
  useEffect(() => {
    if (!workSpaceTkn) {
        navigation("/admin/all-workspace/list")
    }
},[])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const response = await apiCall({
          url: `admin/project/list?page=${pageIndex}&category=${category}&status=${status}&projectCardOrder=2`,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });

        if (response.status === 200) {
          const updatedprojects = response?.data?.projects?.data?.map((projects: any, index: any) => ({
            ...projects,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, 10) // Assuming 10 items per page
          }));
          setData(updatedprojects);
          let summary = response?.data?.projects?.summary
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
          setLoading(false)
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false)
      }
    };
    fetchData();
  }, [userTkn, pageIndex, category, status]);

  useSettingsMountEffect({
    showSettingPanelButton: false,
  });

  const table = useAdvanceTable({
    data: data || [],
    columns: projectsTablecolumns,
    pageSize: 2,
    pagination: true,
    sortable: true,
    // selection: true
  });




  useEffect(() => {
    // Now 'tableRef.current' contains the reference to the table element
    const table = tableRef.current;

    if (table) {
      const firstRow = table?.rows[1];
      const lastRowIndex = table?.rows.length - 1;
      const lastRow = table?.rows[lastRowIndex];

      if (firstRow) {
        const firstCell = firstRow.cells[0];
        if (firstCell) {
          const cellValue = firstCell.textContent || firstCell.innerText;
          let value = Number(cellValue)
          setValueFirst(value)
        }
      }

      if (lastRow) {
        const firstCell = lastRow.cells[0];
        if (firstCell) {
          const cellValue = firstCell.textContent || firstCell.innerText;
          let value = Number(cellValue)
          setValueLast(value)
        }
      }
    }
  }, [data]);

  const getStatusIcon = (status: number, alias: string) => {
    if (status === 1) return <Unicon icon={UilToggleOn} size={23} color="" fill="#8FC644" />
    else if (status === 0) return <Unicon icon={UilToggleOff} size={23} color="" fill="#6E7891" />
    else return <div></div>
  }
  const searchInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPageFilters((prevData) => ({
      ...prevData,
      titleSearch: e.target.value
    }))
  };
  const handlePageFilters = (value: string) => {
    setPageFilters((prevData) => ({
      ...prevData,
      titleSearch: "",
    }));
  }
  const handleSearchInputReq = (value: string) => {

    const fetchData = async () => {
      let searchUrl = ""
      if (value === "search") {
        searchUrl = `admin/project/list?page=${pageIndex}&status=${status}&title=${pageFilters?.titleSearch}`
      } else {
        searchUrl = `admin/project/list?page=${pageIndex}&status=${status}&title=`
      }
      try {
        setLoading(true);
        const response = await apiCall({
          url: searchUrl,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });


        if (response?.status === 200) {
          const updatedprojects = response?.data?.projects?.data?.map((projects: any, index: any) => ({
            ...projects,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, 10) // Assuming 10 items per page
          }));
          setData(updatedprojects);
          let summary = response?.data?.projects?.summary
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false)
      }
    };
    fetchData();
  };

  const handleOnBlur = async (value: any) => {
    if (value !== "") {
      let searchUrl = ""

      searchUrl = `admin/project/list?page=${pageIndex}&category=${category}&status=${status}`;

      try {
        setLoading(true);
        const response = await apiCall({
          url: searchUrl,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });
        if (response?.status === 200) {
          const updatedprojects = response?.data?.projects?.data?.map((projects: any, index: any) => ({
            ...projects,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, 10) // Assuming 10 items per page
          }));
          setData(updatedprojects);
          let summary = response?.data?.projects?.summary
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
          setLoading(false)
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }
    }
  }
  return (
    <div>
      <PageBreadcrumb items={breadCrumb} navigation={navigation} />
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4  justify-content-between align-items-center">
          <h2 className="mb-0">
            <span className="me-3">{Constants.projects}</span>{" "}
          </h2>
          <Link
            className="btn btn-outline-primary px-5"
            onClick={() => setOpenFilterModal(true)}
            to={`/${workspace}/admin/create-project`}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {Constants.new_project}
          </Link>
        </div>
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <Col xs={12} sm="auto">
            <div className="d-flex flex-wrap gap-3 ">
              {/* <SearchInput
                pageFilters={pageFilters}
                handleSearchInputChange={searchInputOnChange}
                handleSearchInputBlur={handleSearchInputReq}
                handlePageFilters={handlePageFilters}
                handleOnBlur={handleOnBlur}
                title="Title"   // by anurag there is no filter.noted..i'll ask vikas sir... for now you disabled it
              /> */}
              <div className="scrollbar overflow-hidden-y d-flex gap-3">
                <FloatingLabel controlId="lead-woner" label="Status">
                  <Form.Select onChange={(e) => { setStatus(e.target.value); }}>
                    <option value="1">Ongoing</option>
                    <option value="2">Archive</option>
                    {/* <option value="0">Deleted</option> */}
                  </Form.Select>
                </FloatingLabel>
              </div>
            </div>
          </Col>
        </div>
        <div className="d-md-block">

          <PhoenixDocCard className="p-4 mt-3">
            <>
              <Table
                className="phoenix-table fs-9 project-table border rounded"
                size="sm"
                striped
                hover
                responsive
                ref={tableRef}
              >
                <thead>
                  <tr id={"a1"}>
                    <th style={{ width: "2%", maxWidth: "20px", padding: "16px 0px" }}>#</th>
                    <th
                      className="px-1"
                      style={{ width: "55%", minWidth: "200px" }}
                    >
                      {Constants.title}
                    </th>
                    <th
                      className="px-1"
                      style={{ width: "15%", minWidth: "200px" }}
                    >
                      {Constants.status}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((item: Projects, index: number) => {

                      return (
                        <tr key={item?.id || index}>
                          <td>{item?.serialNumber}</td>
                          <td>
                            <div className="d-flex gap-2 align-items-center">
                              <div className="avatar_width">
                                {/* <Avatar size="m" variant="name" className="me-1">{item?.title.slice(0, 2)}</Avatar> */}
                                {
                                  item?.projectlogo ?
                                    <Avatar src={item?.projectlogo} size="l" />
                                    :
                                    <Avatar size="l" variant="name" className="me-1">{item?.short_name?.slice(0, 2)}</Avatar>
                                }
                              </div>

                              <Link
                                to={{
                                  pathname: `/${workspace}/admin/project/${item.alias}/view`,
                                }}
                                state={{ user: item }}
                                className="text-decoration-none fw-semi-bold fs-8"
                              >
                                {item?.title}
                              </Link>
                            </div>
                          </td>

                          <td >
                            {loading ? (
                              <Placeholder xs={7} />
                            ) : (
                              getStatusIcon(item.status, item.alias)
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {summary?.total !== 0 ? <Row className="align-items-center pt-3 row">
                <Col className="d-flex fs-9">
                  <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900">
                    {indexValueFirst} to {indexValueLast}
                    <span className="text-600"> items of </span>
                    {summary?.total}
                  </p>
                </Col>
                <Col xs="auto">
                  <CustomPagination
                    pageIndex={pageIndex}
                    totalPage={summary?.total_page}
                    activePage={Number(summary?.page)}
                    setPageIndex={setPageIndex}
                  />
                </Col>
              </Row>
                :
                <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
                  <span className="text-600"> No record found !!</span>
                </p>
              }
            </>
          </PhoenixDocCard>

        </div>

      </AdvanceTableProvider>
      <ToastContainer />
    </div>
  );
};

export default AdminProjectsList;
