import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { KanbanBoardItem, KanbanBoardTask } from 'data/kanban';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'providers/AuthContext';
import apiCall from 'services/api';
import { useToast } from 'providers/ToastProvider';
import { AxiosError } from 'axios';
import { handleCutomError } from 'services/handleCutomError';
import { useKanbanContext } from 'providers/KanbanProvider';
import { getPriorityColor } from 'helpers/utils';
import Avatar from 'components/base/Avatar';
import classNames from 'classnames';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { dateAndTime, dbDateWithTimeStamp, formatDueDate } from 'common/timeStampToDate';
import ReactSelect from 'components/base/ReactSelect';
import DatePicker2 from 'components/base/DatePicker2';
import TinymceEditor from 'components/base/TinymceEditor';

interface KanbanTaskDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  task: KanbanBoardTask;
  list: KanbanBoardItem;
}
interface User {
  label: string;
  value: string;
}


// Custom single option with color
const CustomSingleValue = ({ data }: any) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span
      style={{
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: data.color,
        marginRight: 8,
        
      }}
    ></span>
    {data.label}
  </div>
);

// Custom option rendering
const CustomOption = (props: any) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        cursor: 'pointer',
        backgroundColor: props.isFocused ? '#f0f0f0' : '#fff',
      }}
    >
      <span
        style={{
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: data.color,
          marginRight: 8,
        }}
      ></span>
      {data.label}
    </div>
  );
};

const KanbanEditTaskModal = ({
  show,
  handleClose,
  task,
  list
}: KanbanTaskDetailsModalProps) => {
  const { showErrorToast, showSuccessToast } = useToast()
  const navigation = useNavigate();
  const { alias, id } = useParams();
  const { boardLists, cardPriority, boardUsersList, kanbanDispatch } = useKanbanContext();
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const [validated, setValidated] = useState<boolean>(false);
  const [taskData, setTaskData] = useState<KanbanBoardTask | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [userOptions, setUserOptions] = useState<User[]>([]);
  const [error, setError] = useState({
    title: '',
    description: '',
    category: '',
    color: '',
    links: '',
    priority: '',
    end_datetime: '',
    assignTo: ''
  })

  useEffect(() => {
    // loadUsers();
    const options = boardUsersList.map((user: any) => ({
      value: user.id,
      label: `${user.first_name} ${user.last_name}`,
    }));

    setUserOptions(options);
  }, [boardUsersList, task]);

  const loadUsers = async () => {
    try {

      const response = await apiCall({
        // url: `project/${projAlias}/users`,
        url: `project/${alias}/users?pagination=0&status=${10}`,   //using this admin api, because if loggedin user is admin
        method: 'GET',
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });

      let userData = response?.data?.users
      const options = userData.map((user: any) => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      }));
    

      setUserOptions(options);

    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        showErrorToast('An unexpected error occurred user list');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (task) {
      setTaskData({
        ...task,
        id: task.id
      });
    }
  }, [task]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | { target: { name: string; value: string } }) => {
    const { name, value } = e.target;

    setTaskData((prevData) => {
      if (prevData) {
        return {
          ...prevData,
          [name]: name === "end_datetime" ? dbDateWithTimeStamp(value) : value,
        };
      }
      return prevData;
    });
  };

  const handleUserChange = (selectedOption: any) => {

    const user = boardUsersList?.find((option: any) => option.id === (selectedOption?.value));


    setTaskData((prevData) => {
      if (prevData) {
        return {
          ...prevData,
          assignTo: user,
        };
      }
      return prevData;
    });


    setError((prevError) => ({
      ...prevError,
      assignTo: '',
    }));
  };

  const handlePriorityChange = (selectedOption: any) => {
    setTaskData((prevData) => {
      if (prevData) {
        return {
          ...prevData,
          priority: selectedOption?.value,
        };
      }
      return prevData;
    });


    setError((prevError) => ({
      ...prevError,
      priority: '',
    }));
  };

  const handleEditorChange = (content: string) => {
    setTaskData((prevData) => {
      if (prevData) {
        return {
          ...prevData,
          description: content
        };
      }
      return prevData;
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!taskData?.title || taskData.title.trim() === '') {
      setError((prevError) => ({
        ...prevError,
        title: 'Title is required',
      }));
      return; 
    } else {
      setError((prevError) => ({
        ...prevError,
        title: '',
      }));
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('title', taskData?.title || '');
      formData.append('description', taskData?.description || '');
      // formData.append('category', taskData?.category || '');
      formData.append('color', taskData?.color || '#cccccc');
      // formData.append('links', taskData?.links || '');
      // formData.append('priority', taskData?.priority || '');
      formData.append('end_datetime', taskData?.end_datetime || '');
      if (taskData?.assignTo) {
        formData.append('assign_to', String(taskData?.assignTo?.id) || '');
      }

      const response = await apiCall({
        url: `project/${alias}/boards/${id}/card/${taskData?.id}/update`,
        method: 'POST',
        data: formData,
        headers: {
          'x-access-token': userTkn,
          workspace: workSpaceTkn
        }
      });
      if (response.status === 200) {
        showSuccessToast('Card updated successfully!');
        const updatedItem = response?.data?.data?.boardListCard;
        const updatedBoardLists = boardLists.map((item) => {
          if (Number(item.id) === Number(list.id)) {
            // Update the board list by replacing the card within boardListCards
            return {
              ...item,
              boardListCards: item.boardListCards.map(card =>
                // If the card matches, replace it, otherwise keep it as is
                card.id === updatedItem.id ? updatedItem : card
              )
            };
          }
          return item;
        });
        kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });
      } else {
        throw new Error('Failed to update card');
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        handleCutomError(error, signOut, navigation);
      } else {
        console.error('An unexpected error occurred:', error);
        showErrorToast('An unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }

    // You can set validation state for form submission
    setValidated(true);

    handleClose(); // Close the modal after submission
  };



  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        onHide={handleClose}
        fullscreen="xl-down"
        centered
        className="modal-xl p-0"
        contentClassName="rounded-top-md-4 rounded-bottom-md-4 overflow-hidden h-75"
        scrollable
      >
        {/* <Modal.Header className="position-relative p-0" style={{ height: 200 }}>
        {task.coverImage ? (
          <img
            src={task.coverImage}
            alt=""
            className="w-100 h-100 fit-cover"
          />
        ) : (
          <CoverUpload />
        )}
      </Modal.Header> */}
        <Form noValidate validated={validated} onSubmit={handleSubmit} className='h-100 scrollbar'>
          <Modal.Body className="p-0 h-100 scrollbar">
            <Row className="gy-4 py-0 gx-0 h-100">
              <Col xs={12} lg={7} className="d-flex flex-column  h-100 scrollbar">
                <Row className="mt-0 top-0 gy-4 pb-3 gx-0 px-3">
                  <Col xs={4} sm={3}>
                    <h6 className="text-body-tertiary fw-bolder lh-sm mt-1">
                      TITLE
                    </h6>
                  </Col>
                  <Col xs={8} sm={9}>
                    <Form.Control type="text" placeholder="title" name="title" value={taskData?.title || ''}
                      onChange={handleInputChange} isInvalid={!!error?.title} />
                    <Form.Control.Feedback type="invalid">
                      {error?.title}
                    </Form.Control.Feedback>
                  </Col>

                  <Col xs={4} sm={3}>
                    <h6 className="text-body-tertiary fw-bolder lh-sm mt-1">
                      DESCRIPTION
                    </h6>
                  </Col>
                  <Col xs={8} sm={9}>
                    {/* <Form.Control
                      as="textarea"
                      placeholder="Leave a comment here"
                      style={{ height: '128px' }}
                      name="description"
                      value={taskData?.description || ''}
                      onChange={handleInputChange}
                    /> */}
                    <TinymceEditor
                      value={taskData?.description || ''}
                      onChange={handleEditorChange} // Ensure this is the correct prop name
                      options={{
                        height: '20rem',
                        plugins: 'image',
                        toolbar: 'undo redo | image',
                        image_title: true,
                        file_picker_types: 'image',
                        file_picker_callback: (callback: any, value: string, meta: any) => {
                          if (meta.filetype === 'image') {
                            // Open image upload dialog
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');
                            input.click();

                            input.onchange = function () {
                              const file = input.files && input.files[0];
                              if (file) {
                                const reader = new FileReader();
                                reader.onload = function (e) {
                                  if (e.target) {
                                    // Create an image element from the uploaded file
                                    const img = new Image();
                                    img.onload = function () {
                                      // Create a canvas to resize the image
                                      const canvas = document.createElement('canvas');
                                      const ctx = canvas.getContext('2d');
                                      if (ctx) {
                                        const maxWidth = 300; // Set maximum width (adjust as needed)
                                        const maxHeight = 200; // Set maximum height (adjust as needed)
                                        let width = img.width;
                                        let height = img.height;

                                        // Calculate new dimensions while maintaining aspect ratio
                                        if (width > height) {
                                          if (width > maxWidth) {
                                            height = Math.round(height * (maxWidth / width));
                                            width = maxWidth;
                                          }
                                        } else {
                                          if (height > maxHeight) {
                                            width = Math.round(width * (maxHeight / height));
                                            height = maxHeight;
                                          }
                                        }

                                        // Set canvas size and draw the resized image
                                        canvas.width = width;
                                        canvas.height = height;
                                        ctx.drawImage(img, 0, 0, width, height);

                                        // Convert the resized image to a data URL and pass it to the callback
                                        const resizedImage = canvas.toDataURL('image/jpeg');
                                        callback(resizedImage, { alt: file.name });
                                      }
                                    };
                                    img.src = e.target.result as string;
                                  }
                                };
                                reader.readAsDataURL(file);
                              }
                            };
                          }
                        },
                        content_style: `
                        img {
                          max-width: 300px;
                          max-height: 200px;
                          width: auto;
                          height: auto;
                        }`
                      }}
                    />

                  </Col>
                  <Col xs={4} sm={3}>User {taskData?.assignTo?.id}</Col>
                  <Col xs={8} sm={9}>
                    <ReactSelect
                      name="assignTo"
                      options={userOptions}
                      onChange={handleUserChange}
                      value={userOptions?.filter((option: any) => option.value === (taskData?.assignTo?.id))}
                      // isMulti
                      closeMenuOnSelect={true}
                      placeholder="Select User"
                      required
                      classNames={{
                        control: () => 'py-2',
                        valueContainer: () => 'lh-1'
                      }}
                    />
                    <span className="text-danger fs-9">{error?.assignTo}</span>
                  </Col>
                  <Col xs={4} sm={3}>
                    <h6 className="text-body-tertiary fw-bolder lh-sm mt-1">
                      Color
                    </h6>
                  </Col>
                  <Col xs={8} sm={9}>
                    <div className="d-flex align-items-center gap-1">
                      <input
                        type="color"
                        className="form-control w-25"
                        name="color"
                        value={taskData?.color || '#ffffff'}
                        onChange={handleInputChange}
                      />
                    </div>
                  </Col>

                  <Col xs={4} sm={3}>
                    <h6 className="text-body-tertiary fw-bolder lh-sm mt-1">
                      End Date
                    </h6>
                  </Col>
                  <Col xs={8} sm={9}>
                    <Form.Group className=" text-start">
                      <DatePicker2
                        // value={formData.due_date ? new Date(formData.due_date).toLocaleDateString('en-GB') : ''}
                        value={taskData?.end_datetime ? formatDueDate(new Date(taskData?.end_datetime)) : ''}
                        onChange={(date: Date[]) => {
                          handleInputChange({
                            target: {
                              name: 'end_datetime',
                              value: date[0].toString()
                            }
                          });
                        }}
                        options={{
                          enableTime: true,
                          dateFormat: "d/m/Y h:i K",
                          minDate: new Date().setHours(0, 0, 0, 0)
                        }}
                        isError={error?.end_datetime}
                        id="end_datetime"
                        name="end_datetime"
                        render={(_, ref) => {
                          const isInvalid = !!error.end_datetime;
                          return (
                            <Form.Floating>
                              <Form.Control
                                type="text"
                                ref={ref}
                                placeholder="d/m/Y  h:i"
                                readOnly
                                isInvalid={isInvalid}

                              />
                              <label htmlFor="startDate" className="ps-6">
                                {`Due Date *`}
                              </label>
                              <Form.Control.Feedback type="invalid">
                                {error?.end_datetime}
                              </Form.Control.Feedback>
                            </Form.Floating>
                          );
                        }}
                      />

                    </Form.Group>
                  </Col>
                  <Col xs={4} sm={3}>
                    <h6 className="text-body-tertiary fw-bolder lh-sm mt-1">
                      PRIORITY
                    </h6>
                  </Col>
                  <Col xs={8} sm={9}>
                    <ReactSelect
                      name="priority"
                      options={cardPriority}
                      onChange={handlePriorityChange}
                      value={cardPriority?.filter((option: any) => option.value === taskData?.priority)}
                      // isMulti
                      closeMenuOnSelect={true}
                      placeholder="Select Priority"
                      required
                      classNames={{
                        control: () => 'py-2',
                        valueContainer: () => 'lh-1'
                      }}
                      components={{
                        SingleValue: CustomSingleValue,
                        Option: CustomOption,
                      }}
                    />
                  </Col>

                  <Col xs={4} sm={3}>
                    <h6 className="text-body-tertiary fw-bolder lh-sm mt-1">
                      CATEGORY
                    </h6>
                  </Col>
                  <Col xs={8} sm={9}>
                    {/* <Form.Control type="text" placeholder="category" name="category" value={taskData?.category || ''}
                      onChange={handleInputChange} /> */}
                  </Col>
                  <Col xs={4} sm={3}>
                    <h6 className="text-body-tertiary fw-bolder lh-sm mt-1">
                      Links
                    </h6>
                  </Col>
                  <Col xs={8} sm={9}>
                    {/* <Form.Control type="text" placeholder="links" name="links" value={taskData?.links || ''}
                      onChange={handleInputChange} /> */}
                  </Col>
                </Row>
              </Col>
              <Col
                xs={12}
                lg={5}
                className="d-flex flex-column border-start-lg border-translucent h-100 scrollbar"
                style={{ height: '100%' }}
              >
                <div>
                  <div className="px-3">
                    <div>
                      <h5 className="mb-3 mt-4">Activities</h5>
                      <div className="d-flex flex-column gap-3">
                        {task?.history?.map((activity, index) => {
                          const { formattedDate, formattedTime } = dateAndTime(activity.updatedAt)
                          return (
                            <div
                              className={classNames(
                                'd-flex gap-2 pb-3 border-translucent',
                                {
                                  'border-bottom':
                                    index !== task?.history?.length - 1
                                }
                              )}
                              key={activity?.updatedAt}
                            >
                              <Avatar size="m" variant="name" key={activity?.updator?.short_name}>{activity?.updator?.short_name}</Avatar>
                              <div className="activity-item">
                                <p
                                  className="mb-1 fs-9"
                                  dangerouslySetInnerHTML={{
                                    __html: activity?.action_type
                                  }}
                                />
                                <div className="d-flex gap-2 justify-content-between fs-9">
                                  <p className="mb-0">
                                    <FontAwesomeIcon
                                      icon={faClock}
                                      className="me-1"
                                    />
                                    {formattedTime}
                                  </p>
                                  <p className="mb-0">{formattedDate}</p>
                                </div>
                              </div>
                            </div>
                          )
                        }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <Button
              startIcon={<FontAwesomeIcon icon={faTimes} />}
              onClick={handleClose}
              className="p-0"
              disabled={loading}
            >
              Close
            </Button>
            <Button
              variant="outline-primary"
              className="px-6"
              type='submit'
              disabled={loading}
            >
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default KanbanEditTaskModal;
