import classNames from 'classnames';
import Footer from 'components/footers/Footer';
import NavbarDual from 'components/navbars/navbar-dual/NavbarDual';
import NavbarTopHorizontal from 'components/navbars/navbar-horizontal/NavbarTopHorizontal';
import NavbarTopDefault from 'components/navbars/navbar-top/NavbarTopDefault';
import NavbarVerical from 'components/navbars/navbar-vertical/NavbarVertical';
import NavbarVertical from 'components/navbars/navbar-vertical/NavbarVertical';
import NavbarVerticalCustom from 'components/navbars/navbar-vertical/custom-menu/NavbarVerticalCustom';

import { useAppContext } from 'providers/AppProvider';
import { useAuth } from 'providers/AuthContext';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import { Container } from 'react-bootstrap';
import { Outlet, useLocation, useParams } from 'react-router-dom';

const MainLayout = () => {
  const {
    config: { navbarPosition }
  } = useAppContext();
  const { contentClass, footerClass } = useMainLayoutContext();
  const { pathname } = useLocation();
  const { alias, id } = useParams();
  const { workSpace } = useAuth();

  const validPaths = [
    `/${workSpace?.alias}/project/${alias}/overview`,
    `/${workSpace?.alias}/project/${alias}/setting`,
    `/${workSpace?.alias}/project/${alias}/project-update`,
    `/${workSpace?.alias}/project/${alias}/project-info`,
    `/${workSpace?.alias}/project/${alias}/calender`,
    `/${workSpace?.alias}/project/${alias}/boards`,
    `/${workSpace?.alias}/project/${alias}/board/${id}`,
    `/${workSpace?.alias}/project/${alias}/project-info/project-info-admin`,
    `/${workSpace?.alias}/project/${alias}/action-point`,
    `/${workSpace?.alias}/project/${alias}/project-info/project-info-admin/projectinfo-section`,
    `/${workSpace?.alias}/project/${alias}/notes`,
    `/${workSpace?.alias}/project/${alias}/project-story`
  ];

  const isNavbarVisible = alias && validPaths.includes(pathname);

  return (
    <Container fluid className="px-0">
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && isNavbarVisible ? (
        <NavbarVerical alias={alias} workspace={workSpace?.alias || ''} />
      ) : (
        <div></div>
      )}
      {navbarPosition === 'vertical' && <NavbarTopDefault />}
      {(navbarPosition === 'horizontal' || navbarPosition === 'combo') && (
        <NavbarTopHorizontal />
      )}
      {navbarPosition === 'dual' && <NavbarDual />}

      <div className={classNames(contentClass, `content px-3`)}>
        <Outlet />
        {/* <Footer className={classNames(footerClass, 'position-absolute')} /> */}
      </div>
    </Container>
  );
};

export default MainLayout;
