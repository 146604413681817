import { initialSummary, SummaryProps } from 'data/kanban';
import React, {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useReducer
} from 'react';
import { ACTIONTYPE, projectReducer } from 'reducers/ProjectReducer';
import { initialCategoryData, initialPriorityData, initialProjectState, initialProjectUser, Project, ProjectCategoryType, ProjectPriorityType, UserPermission } from 'types/project';

export type ConversationFilterType = 'all' | 'read' | 'unread';
interface ProjectProviderInterface { }

export interface ProjectState {
  projectData: Project;
  projectAllUsers: number[];
  presentUserPermission: UserPermission[];
  pastUserPermission: UserPermission[];
  addUserModal: boolean;
  summaryPresentUser: SummaryProps;
  indexValueFirstPresentUser: number;
  indexValueLastPresentUser: number;
  pageIndexPresentUser: number;
  summaryPastUser: SummaryProps;
  indexValueFirstPastUser: number;
  indexValueLastPastUser: number;
  pageIndexPastUser: number;
  updateUserPermission: UserPermission;
  toggleUpdateModal: boolean;
  toggleDeleteModal: boolean;
  activeTabSetting: string;
  toggleAddPriority: boolean;  //Project Priority
  priorityData: ProjectPriorityType[];
  summaryPriorityProject: SummaryProps;
  indexValueFirstPriorityProject: number;
  indexValueLastPriorityProject: number;
  pageIndexPriorityProject: number;
  isEditModePriority: boolean;
  updatePriorityData: ProjectPriorityType;
  toggleDeletePriorityModal: boolean;//Project Priority
  toggleAddCategory: boolean;  //Project Category
  categoryData: ProjectCategoryType[];
  summaryCategoryProject: SummaryProps;
  indexValueFirstCategoryProject: number;
  indexValueLastCategoryProject: number;
  pageIndexCategoryProject: number;
  isEditModeCategory: boolean;
  updateCategoryData: ProjectCategoryType;
  toggleDeleteCategoryModal: boolean;
  toggleCategorySequenceModal: boolean;
  togglePrioritySequenceModal: boolean;
}

interface ProjectContextInterface extends ProjectState {
  projectDispatch: Dispatch<ACTIONTYPE>;
}

export const ProjectContext = createContext({} as ProjectContextInterface);

const useProjectReducer = () => {
  const initState: ProjectState = {
    projectData: initialProjectState,
    projectAllUsers: [],
    presentUserPermission: [],
    pastUserPermission: [],
    addUserModal: false,
    summaryPresentUser: initialSummary,
    indexValueFirstPresentUser: 1,
    indexValueLastPresentUser: 1,
    pageIndexPresentUser: 1,
    summaryPastUser: initialSummary,
    indexValueFirstPastUser: 1,
    indexValueLastPastUser: 1,
    pageIndexPastUser: 1,
    updateUserPermission: initialProjectUser,
    toggleUpdateModal: false,
    toggleDeleteModal: false,
    activeTabSetting: 'presentuser',
    toggleAddPriority: false,
    priorityData: [],
    summaryPriorityProject: initialSummary,
    indexValueFirstPriorityProject: 1,
    indexValueLastPriorityProject: 1,
    pageIndexPriorityProject: 1,
    isEditModePriority: false,
    updatePriorityData: initialPriorityData,
    toggleDeletePriorityModal: false,
    toggleAddCategory: false,  //Project Category
    categoryData: [],
    summaryCategoryProject: initialSummary,
    indexValueFirstCategoryProject: 1,
    indexValueLastCategoryProject: 1,
    pageIndexCategoryProject: 1,
    isEditModeCategory: false,
    updateCategoryData: initialCategoryData,
    toggleDeleteCategoryModal: false,
    toggleCategorySequenceModal: false,
    togglePrioritySequenceModal: false
  };

  return useReducer(projectReducer, initState);
};

const ProjectProvider = ({
  children
}: PropsWithChildren<ProjectProviderInterface>) => {
  const [projectState, projectDispatch] = useProjectReducer();

  return (
    <ProjectContext.Provider
      value={{
        ...projectState,
        projectDispatch
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => useContext(ProjectContext);

export default ProjectProvider;
