import { BoardFormData, boardInitialData, BoardLabels, CardCategory, CardPriority, CreateNewLabel, initialCreateNewLabelData, initialDataFormToggle, initialSummary, KanbanBoard, KanbanBoardItem, KanbanBoardMembers, KanbanTaskHistroy, LabelsFormToggle, ListFormData, listInitialData, SummaryProps } from 'data/kanban';
import React, {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useReducer
} from 'react';
import { ACTIONTYPE, kanbanReducer } from 'reducers/KanbanReducer';

export type ConversationFilterType = 'all' | 'read' | 'unread';
interface KanbanProviderInterface { }

export interface KanbanState {
  openBoardDetailsOffcanvas: boolean;
  openAddListModal: boolean;
  openAddBoardModal: boolean;
  isEditMode: boolean;
  isEditModeList: boolean;
  boardLists: KanbanBoardItem[];
  boardUsersList: KanbanBoardMembers[];
  boardData: KanbanBoard;
  yourBoardData: KanbanBoard[];
  doardUpdateData: BoardFormData;
  listUpdateData: ListFormData;
  cardPriority: CardPriority[];
  cardCategory: CardCategory[];
  boardLabelsList: BoardLabels[];
  filterValue: string;
  userPermissionOnBoard: boolean;
  boardLoading: boolean;
  toggleLabelForms: LabelsFormToggle;
  createNewLabelFormData: CreateNewLabel;
  createUpdateLabel: boolean;
  toggleTaskDetailModal: boolean;
  taskHistory: KanbanTaskHistroy[];
  histotySummary: SummaryProps;
  pageIndexHistory: number;
  historyLoad: boolean;
}

interface KanbanContextInterface extends KanbanState {
  kanbanDispatch: Dispatch<ACTIONTYPE>;
}

export const KanbanContext = createContext({} as KanbanContextInterface);

const useKanbanReducer = () => {
  const initState: KanbanState = {
    openBoardDetailsOffcanvas: false,
    openAddListModal: false,
    openAddBoardModal: false,
    isEditMode: false,
    isEditModeList: false,
    boardLists: [],
    boardUsersList: [],
    boardData: boardInitialData,
    yourBoardData: [],
    doardUpdateData: boardInitialData,
    listUpdateData: listInitialData,
    cardPriority: [],
    cardCategory: [],
    boardLabelsList: [],
    filterValue: '1',
    userPermissionOnBoard: false,
    boardLoading: false,
    toggleLabelForms: initialDataFormToggle,
    createNewLabelFormData: initialCreateNewLabelData,
    createUpdateLabel: false,
    toggleTaskDetailModal: false,
    taskHistory:[],
    histotySummary:initialSummary,
    pageIndexHistory: 1,
    historyLoad: false
  };

  return useReducer(kanbanReducer, initState);
};

const KanbanProvider = ({
  children
}: PropsWithChildren<KanbanProviderInterface>) => {
  const [kanbanState, kanbanDispatch] = useKanbanReducer();

  return (
    <KanbanContext.Provider
      value={{
        ...kanbanState,
        kanbanDispatch
      }}
    >
      {children}
    </KanbanContext.Provider>
  );
};

export const useKanbanContext = () => useContext(KanbanContext);

export default KanbanProvider;
