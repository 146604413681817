import PageBreadcrumb, { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
import { useEffect, useState, Suspense } from "react";
import { Link, NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import Unicon from 'components/base/Unicon';
import { UilPen, UilEye, UilSetting, UilArchive, UilTrash, UilUnlock, UilLock, UilEyeSlash, UilRefresh } from '@iconscout/react-unicons';
import { Button, Col, Nav, Tab, Row, Table, Spinner, } from "react-bootstrap";
import { useAppContext } from "providers/AppProvider";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import { useAuth } from "providers/AuthContext";
import Avatar from "components/base/Avatar";
import parse from 'html-react-parser';
import apiCall from "services/api";
import { useMaster } from "providers/MasterContext";
import { geCategoryNameById, getPhaseNameById } from "utils/getNames";
import { toast } from 'react-toastify';
import UpdateUserPermissionModal from "./UpdateUserPermissionModal";
import { useProjectAdminContext } from "providers/ProjectAdminProvider";
import { getPageBreadCrumbs } from "services/getPageBreadCrumbs";
import { Tooltip } from "react-tooltip";
import ConfirmationModal from "common/ConfirmModal";
import { handleCutomError } from "services/handleCutomError";
import ProjectProvider, { useProjectContext } from "providers/ProjectProvider";
import { PROJECT_ALL_USERS, PROJECT_CORE_DATA, PROJECT_PAST_USER_PERMISSION_DATA, PROJECT_PRESENT_USER_PERMISSION_DATA, TOGGLE_ACTIVE_TAB } from "reducers/ProjectReducer";
import ProjectPresentUser from "./settings/ProjectPresentUser";
import ProjectPastUser from "./settings/ProjectPastUser";
import ProjectPriority from "./settings/ProjectPriority";
import ProjectCategory from "./settings/ProjectCategory";


type UserDel = {
  id: number;
  name: string;
}

const ProjectSettings = () => {
  return (
    <ProjectProvider>
      <ProjectContent />
    </ProjectProvider>
  );
}

const ProjectContent = () => {
  const { projectData, projectDispatch, activeTabSetting } = useProjectContext();
  const initialBreadcrumb: PageBreadcrumbItem[] = [];
  const [pageName, setPageName] = useState("ProjectSettings")
  const [breadCrumb, setBreadCrumb] = useState<PageBreadcrumbItem[]>(initialBreadcrumb);
  const [projectTitle, setProjectTitle] = useState<string>("")
  const { alias, id, workspace } = useParams();
  let { state } = useLocation();

  const [projectStatus, setProjectStatus] = useState<any>()

  const { projCategory, phases } = useMaster();

  const { userTkn, workSpaceTkn, isUserAdmin, signOut } = useAuth();
  const { adminValues, setAdminValues } = useProjectAdminContext();

  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const [allUsers, setAllUsers] = useState([])
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [addStatus, setAddStatus] = useState<boolean>(false);
  const navigation = useNavigate() as NavigateFunction; // Explicitly define the type
  const [userPermission, setUserPermission] = useState({
    id: "",
    user_id: "",
    role_in_project: "",
    is_project_admin: "",
    is_story_admin: "",
    is_meeting_admin: "",
    projectlogo: "",
    is_diary_admin: "",
    user_designation: "",
  });

  const [showIcons, setShowIcons] = useState({
    watching: 0,
    archive: 0,
    editable: 0
  })
  const [markWatching, setMarkWatching] = useState(0)
  const { setConfig } = useAppContext();

  //update Breadcrumb
  useEffect(() => {
    getPageBreadCrumbs({ pageName, workspace, setBreadCrumb, projectTitle });
  }, [workspace, projectTitle, pageName]);

  //set top navigation
  useEffect(() => {
    const value = "combo";
    const value2 = 'slim';

    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant
    });

  }, [])

  useEffect(() => {

    const fetchData = async () => {
      try {
        setLoading(true)

        const [response, response3] = await Promise.all([
          apiCall({
            url: `project/${alias}/view`,
            method: 'GET',
            headers: {
              'x-access-token': userTkn,
              'workspace': workSpaceTkn
            },
          }),

          apiCall({
            url: `admin/project/${alias}/permissions/list?expand=user&pagination=0`,
            method: 'GET',
            headers: {
              'x-access-token': userTkn,
              'workspace': workSpaceTkn
            },
          })
        ])

        if (response.status === 200) {

          let data = response.data;
          projectDispatch({ type: PROJECT_CORE_DATA, payload: data });
          setProjectStatus(data?.status)
          setShowIcons({
            watching: data?.userpermission?.is_user_watching,
            archive: data?.status,
            editable: data?.editable
          })
          setMarkWatching(data?.userpermission?.is_user_watching)
          setProjectTitle(response?.data.title)
          setAdminValues({
            is_diary_admin: data?.userpermission?.is_diary_admin,
            is_meeting_admin: data?.userpermission?.is_meeting_admin,
            is_project_admin: data?.userpermission?.is_project_admin,
            is_story_admin: data?.userpermission?.is_story_admin,
          });
        }
        if (response3.status === 200) {
          let permissionData = response3?.data?.data?.permission;
          const userIds: any = [];
          permissionData.forEach((item: any) => {
            userIds.push(item.user_id);
          });
          projectDispatch({ type: PROJECT_ALL_USERS, payload: userIds });
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false)
      }
    };
    fetchData();
  }, [userTkn, addStatus]);



  useSettingsMountEffect({
    showSettingPanelButton: false,
  });



  const projectActions = async (alias: any, action: string) => {
    let url = '';
    if (action === 'watching') {
      url = `project/${alias}/user-watch`;
    } else if (action === 'ongoing') {
      url = `admin/project/${alias}/ongoing`;
    }


    try {
      const response = await apiCall({
        url: url,
        method: 'POST',
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });
      if (response.status === 200) {

        toast.success(response?.data?.message, {
          position: "top-center"
        });

        let projectData = response.data.ProjectsUsers;
        setMarkWatching(projectData?.is_user_watching)

        setShowIcons(prev => ({
          ...prev,
          watching: projectData?.is_user_watching
        }));
        if (action === 'ongoing') {
          setProjectStatus(response?.data?.project?.status)
        }
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        toast.error('An unexpected error occurred', {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false)
    }

  }



  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="light" />
        </div>
      ) : <div >
        <div className="d-flex gap-2">
          <PageBreadcrumb items={breadCrumb} navigation={navigation} />
          {Number(showIcons.watching) === 1 ? <Unicon icon={UilEye} size={19} /> : ""}
          {Number(showIcons.archive) === 2 ? <Unicon icon={UilArchive} size={19} color="#6E7891" /> : ""}
          {Number(showIcons.archive) === 0 ? <Unicon icon={UilTrash} size={21} color="" fill="#6E7891" /> : ""}
        </div>


        <div className="d-flex flex-wrap mb-4  justify-content-between">
          <h2 className="mb-0 d-flex align-items-center gap-1">
            <Unicon icon={UilSetting} size={28} /><span className="me-3">{" Setting"}</span>
            {Number(showIcons.editable) === 1 ? <Unicon icon={UilUnlock} size={21} color="" fill="#8FC644" /> : ""}
            {Number(showIcons.editable) === 0 ? <Unicon icon={UilLock} size={21} color="" fill="#8FC644" /> : ""}
          </h2>
        </div>

        <div className="d-md-block d-block">
          <PhoenixDocCard className="p-4 mt-4 ">
            <Row className="d-flex">
              <Col xs={10}>
                <div className="d-flex gap-2 align-items-center">
                  <div className="avatar_width">
                    {
                      projectData?.projectlogo ?
                        <Avatar src={projectData?.projectlogo} size="l" />
                        :
                        <Avatar size="l" variant="name" className="me-1">{projectData?.short_name?.slice(0, 2)}</Avatar>
                    }
                  </div>

                  <Link to="#" className="text-decoration-none fw-bold text-black pe-none fs-7 font_sizeset">
                    {projectData?.title}
                  </Link>

                </div>
              </Col>

              <Col xs={2}>
                {adminValues.is_project_admin === 1 && <div className="d-flex justify-content-end gap-2">
                  {Number(projectStatus) === 0 ?
                    <>
                      <Button
                        variant="phoenix-primary"
                        className="btn-icon rounded-1"
                        onClick={() => { projectActions(alias, 'ongoing') }}
                      >
                        <Unicon icon={UilRefresh} size={20} id="ongoing" />
                        <Tooltip anchorSelect="#ongoing" clickable>On-going</Tooltip>
                      </Button>
                    </>
                    :
                    <>
                      <Button
                        variant="phoenix-primary"
                        className="btn-icon rounded-1"
                        onClick={() => { projectActions(alias, 'watching') }}
                      >
                        <Unicon icon={markWatching === 0 ? UilEye : UilEyeSlash} size={20} id="watching" />
                        <Tooltip anchorSelect="#watching" clickable>{markWatching === 0 ? "Watching" : "Un-Watching"}</Tooltip>
                      </Button>
                      <Link
                        state={{ user: state?.user }}
                        to={{
                          pathname: `/${workspace}/project/${alias}/project-update`,
                        }}>
                        <Button
                          variant="phoenix-primary"
                          className="btn-icon me-2 rounded-1 bg-transparent"
                          onClick={() => {

                          }}
                        >
                          <Unicon icon={UilPen} size={20} />
                        </Button>
                      </Link>
                    </>}

                </div>
                }
              </Col>
            </Row>
            <Row className="d-flex mt-1">
              <div className="d-flex  text-700 fw-semi-bold mt-2 gap-15">
                <div className="d-flex align-items-center">
                  <div className="fs-8 mt-2">
                    {"Category: "}
                    <span className="text-1100">
                      {geCategoryNameById(projectData?.category, projCategory)}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="fs-8 mt-2">
                    {"Phase: "}
                    <span className="text-1100">
                      {getPhaseNameById(projectData?.phase, phases)}
                    </span>
                  </div>
                </div>
              </div>
            </Row>


            <Row className="d-flex mt-1">
              <div className="d-flex  text-700 fw-semi-bold  gap-15">
                <div className="d-flex align-items-center">
                  <div className="fs-8 mt-2 ">
                    {"Description: "}
                    <span className="text-100">
                      {projectData?.description && parse(projectData?.description)}
                    </span>
                  </div>
                </div>
              </div>
            </Row>
          </PhoenixDocCard>

          <Row>
            <Col xl={12} xxl={12} className="mb-1 mt-4">
              <Tab.Container activeKey={activeTabSetting}
                onSelect={(key) => { projectDispatch({ type: TOGGLE_ACTIVE_TAB, payload: key || 'presentuser' }); }}
              >
                <Nav variant="underline" className="">
                  <Nav.Item className="under_line">
                    <Nav.Link eventKey="presentuser">Users</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="under_line">
                    <Nav.Link eventKey="pastuser">Past User</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="under_line">
                    <Nav.Link eventKey="priority">Priority</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="under_line">
                    <Nav.Link eventKey="category">Category</Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content style={{ marginTop: "-2px" }}>
                  <Tab.Pane eventKey="presentuser">
                    {activeTabSetting === "presentuser" &&
                      <ProjectPresentUser />
                    }
                  </Tab.Pane>
                  <Tab.Pane eventKey="pastuser">
                  {activeTabSetting == "pastuser" &&
                    <PhoenixDocCard className='pt-3 px-4 pb-4 mt-1' >
                 
                      <ProjectPastUser />
                   
                    </PhoenixDocCard>
                     }
                  </Tab.Pane>
                  <Tab.Pane eventKey="priority">
                  {activeTabSetting == "priority" &&
                    <PhoenixDocCard className='pt-3 px-4 pb-4 mt-1' >
                   
                      <ProjectPriority />
                   
                    </PhoenixDocCard>
                     }
                  </Tab.Pane>
                  <Tab.Pane eventKey="category">
                  {activeTabSetting == "category" &&
                    <PhoenixDocCard className='pt-3 px-4 pb-4 mt-1' >
                   
                      <ProjectCategory />
                   
                    </PhoenixDocCard>
                     }
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </div>
      </div>
      }
    </>

  );
};

export default ProjectSettings;
