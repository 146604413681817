import calculateSerialNumber from "common/calculateSerialNumber";
import Button from "components/base/Button";
import { useAuth } from "providers/AuthContext";
import { useProjectContext } from "providers/ProjectProvider";
import { useToast } from "providers/ToastProvider";
import { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { CATEGORY_SUMMARY, PROJECT_CATEGORY_DATA, TOGGLE_ADD_CATEGORY_MODAL } from "reducers/ProjectReducer";
import apiCall from "services/api";
import { handleCutomError } from "services/handleCutomError";
import { DragDropContext, DropResult, Draggable } from "react-beautiful-dnd";
import PhoenixDroppable from "components/base/PhoenixDroppable";

const UpdateCategorySequenceForm = () => {
    const { showSuccessToast, showErrorToast } = useToast();
    const navigation = useNavigate();
    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const { alias } = useParams();
    const { categoryData, projectDispatch } = useProjectContext();
    const [loading, setLoading] = useState(false)

    // Handle drag-and-drop event
    const handleDragEnd = async (result: DropResult) => {
        const { source, destination, draggableId } = result;
        

        if (!destination) return;
        if (source.index === destination.index) return;

        try {
            // Create a copy of the current categoryData
            const updatedCategories = Array.from(categoryData);

            // Reorder the items
            const [movedItem] = updatedCategories.splice(source.index, 1);
            projectDispatch({ type: PROJECT_CATEGORY_DATA, payload: updatedCategories });
            updatedCategories.splice(destination.index, 0, movedItem);
            const previousElementId =
                destination.index > 0 ? updatedCategories[destination.index - 1].id : null;

     
            const formData = new FormData();
            if(previousElementId === null){
                formData.append('referenceId', '');
            }else{
                formData.append('referenceId', `${previousElementId}`);
            }
            
            //update sequence
            const response = await apiCall({
                url: `project/${alias}/masters/category/${draggableId}/set-sequence`,
                method: 'POST',
                data: formData,
                headers: {
                  'x-access-token': userTkn,
                  workspace: workSpaceTkn
                }
              });
      
             if(response?.status === 200){
                if (response?.data?.errors) {

                }else{
                    fetchCategoryData()
                }
             }

        } catch (error) {
            // handleCutomError(error, showErrorToast, signOut);
        }
    };

    const fetchCategoryData = async () => {
        try {
            setLoading(true)

            const [response] = await Promise.all([

                apiCall({
                    url: `project/${alias}/masters/category`,
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                        'workspace': workSpaceTkn
                    },
                }),
            ])

            if (response.status === 200) {
                const categoryData = response?.data?.data?.ProjectsCategory;
                const updatedData = categoryData?.map((users: any, index: any) => ({
                    ...users,
                    serialNumber: calculateSerialNumber(1, index, response?.data?.data?.summary?.pageSize)
                }));

                projectDispatch({ type: PROJECT_CATEGORY_DATA, payload: updatedData });
                const summary = response?.data?.data?.summary;
                projectDispatch({
                    type: CATEGORY_SUMMARY, payload: {
                        "total": summary.total,
                        "page": summary.page,
                        "pageSize": summary.pageSize,
                        "total_page": summary.total_page,
                    }
                });

                projectDispatch({
                    type: TOGGLE_ADD_CATEGORY_MODAL,
                    payload: false
                });

            } else {
                console.error("error message:");
            }
        } catch (error: any) {
            if (error?.isAxiosError) {
                // Access the error message
                handleCutomError(error, signOut, navigation);
            } else {
                // Handle other types of errors
                showErrorToast('An error occurred:', error);

            }
        } finally {
            setLoading(false)
        }
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <PhoenixDroppable droppableId="category-lists" direction="vertical" type="CATEGORY">
                {(provided) => (
                    <div
                        className="category-container"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {categoryData?.map((item, index) => (
                            <Draggable key={`${item.id}`} draggableId={`${item.id}`} index={index}>
                                {(provided) => (
                                    <div
                                        className="hover-actions-trigger  border border-gray-200 border hover-border-navy-blue"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        {item?.title}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </PhoenixDroppable>
        </DragDropContext>
    );
};

export default UpdateCategorySequenceForm;
