import React, { useEffect, useRef, useState } from 'react';
import EditorJS from '@editorjs/editorjs';
import Paragraph from '@editorjs/paragraph';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Delimiter from '@editorjs/delimiter';
import Checklist from '@editorjs/checklist';
import LinkTool from '@editorjs/link';
import Quote from '@editorjs/quote';
import Warning from '@editorjs/warning';
import Marker from '@editorjs/marker';
import CodeTool from '@editorjs/code';
import InlineCode from '@editorjs/inline-code';
import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import RawTool from '@editorjs/raw';
import Alert from 'editorjs-alert';
import Underline from '@editorjs/underline';
import ImageTool from '@editorjs/image';
import { Col, Container, Row, Button, Form } from 'react-bootstrap';

import {
  useLocation,
  Link,
  useNavigate,
  NavigateFunction,
  useParams
} from 'react-router-dom';
import { handleCutomError } from 'services/handleCutomError';
// import { toast, ToastContainer } from "react-toastify";
import apiCall from 'services/api';
import { useToast } from 'providers/ToastProvider';
import { useAuth } from 'providers/AuthContext';
import NestedList from '@editorjs/nested-list';

const BlockEditor = () => {
  const location = useLocation();
  const { showErrorToast, showSuccessToast } = useToast();
  const { title, body } = location.state || {};

  const navigation = useNavigate();
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const { alias, workspace, id } = useParams();

  const [loading, setLoading] = useState(false);

  const editorRef = useRef(null);
  const [documentTitle, setDocumentTitle] = useState('');
  const [editorData, setEditorData] = useState({
    blocks: []
  });
  const [documentId, setDocumentId] = useState('');
  const [isPreview, setIsPreview] = useState(!!id); // Preview mode state
  const [previewContent, setPreviewContent] = useState(''); // HTML preview content
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return; // If no document ID, skip fetching

      try {
        setLoading(true); // Start loading
        const response = await apiCall({
          url: `project/${alias}/documents/${id}/view`,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            workspace: workSpaceTkn
          }
        });

        if (response?.status === 200 && response?.data?.data?.document) {
          const documentData = response.data.data.document;
          try {
            const parsedContent = JSON.parse(documentData.body || '{}');
            const newEditorData = { blocks: parsedContent.blocks || [] };
            await generatePreviewJson(newEditorData);
            setEditorData(newEditorData); // Set editor data with fetched content
            setDocumentId(id);
            setDocumentTitle(title);
          } catch (parseError) {
            console.error('Failed to parse document body:', parseError);
            showErrorToast('Error parsing document content.');
          }
        }
      } catch (error) {
        if (error?.isAxiosError) {
          handleCutomError(error, signOut, navigation);
        } else {
          console.error('An error occurred:', error);
          showErrorToast('An unexpected error occurred.');
        }
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchData();
  }, [alias, id, userTkn, workSpaceTkn, navigation, signOut]);

  useEffect(() => {
    if (isPreview) {
      return;
    }
    if (!editorRef.current && editorData.blocks) {
      const editor = new EditorJS({
        holder: 'editorjs',
        inlineToolbar: true,
        autofocus: true,
        data: editorData,
        tools: {
          header: { class: Header, inlineToolbar: ["link", "marker"] },
          paragraph: Paragraph,
          quote: Quote,
          warning: {
            class: Warning,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+W',
            config: {
              titlePlaceholder: 'Title',
              messagePlaceholder: 'Message'
            }
          },
          delimiter: Delimiter,
          linkTool: {
            class: LinkTool,
            config: {
              endpoint: 'http://localhost:8008/fetchUrl'
            }
          },
          checklist: {
            class: Checklist,
            inlineToolbar: true
          },
          list: {
            class: NestedList,
            inlineToolbar: true,
            config: {
              defaultStyle: 'unordered'
            }
          },
          Marker: {
            class: Marker,
            shortcut: 'CMD+SHIFT+M'
          },
          code: CodeTool,
          inlineCode: {
            class: InlineCode,
            shortcut: 'CMD+SHIFT+M'
          },
          embed: {
            class: Embed,
            config: {
              services: {
                youtube: true,
                coub: true
              }
            }
          },
          raw: RawTool,
          table: Table,
          alert: Alert,
          underline: Underline,
          // image: {
          //   class: ImageTool,
          //   inlineToolbar: true,
          //   config: {
          //     uploader: {
          //       async uploadByFile(file) {
          //         const formData = new FormData();
          //         formData.append('file', file);

          //         const response = await axios.post(
          //           `http://localhost:4001/api/uploadImage/create`,
          //           formData,
          //           {
          //             headers: {
          //               'Content-Type': 'multipart/form-data'
          //             },
          //             withCredentials: false
          //           }
          //         );

          //         if (response.data.success === 1) {
          //           return response.data;
          //         }
          //       },
          //       async uploadByUrl(url) {
          //         const response = await axios.post(
          //           `http://localhost:4001/api/uploadImage/createByUrl`,
          //           { url }
          //         );

          //         if (response.data.success === 1) {
          //           return response.data;
          //         }
          //       }
          //     },
          //     inlineToolbar: true
          //   }
          // }
        }
      });

      editorRef.current = editor;
    }

    return () => {
      if (editorRef.current?.destroy) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, [editorData, isPreview]);

  const saveContent = async () => {
    if (!documentTitle) {
      alert('Please enter a title for this content');
      return;
    }
    try {
      setLoading(true); // Set loading to true
      const content = await editorRef.current.save(); // Save content from the editor
      const contentJson = JSON.stringify(content); // Convert content to JSON

      const formData = new FormData();
      formData.append('title', documentTitle);
      formData.append('body', contentJson);
      let url = '';
 
      if (documentId) {
        url = `project/${alias}/documents/${documentId}/update`;
      } else {
        url = `project/${alias}/documents/create`;
      }
      const response = await apiCall({
        url: url,
        method: 'POST',
        data: formData,
        headers: {
          'x-access-token': userTkn,
          workspace: workSpaceTkn
        }
      });

      if (response?.status === 200 && response?.data?.data?.document) {
        showSuccessToast('Document save sucessfully');
        const documentData = response.data.data.document;
        try {
          const parsedContent = JSON.parse(documentData.body || '{}');
         
          const newEditorData = { blocks: parsedContent.blocks || [] };
          setEditorData(newEditorData); // Set editor data with fetched content
          setDocumentId(documentData?.id);
          setDocumentTitle(documentData?.title);
        } catch (parseError) {
          console.error('Failed to parse document body:', parseError);
          showErrorToast('Error parsing document content.');
        }
      }
    } catch (error) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        showErrorToast('An unexpected error occurred');
      }
    } finally {
      setLoading(false); // Set loading to false after the API call is completed
    }
    // const newItem = { title, content, time: new Date().getTime() };
    // const updatedItems = [...savedItems, newItem];

    // localStorage.setItem('editorItems', JSON.stringify(updatedItems));
    // setSavedItems(updatedItems);
    // setDocumentTitle('');
  };

  const generatePreviewJson = async editorData => {
    try {
      if (!editorData || !editorData.blocks) {
        throw new Error('No blocks found in editor data.');
      }

      const htmlContent = editorData.blocks
        .map(block => {
          switch (block.type) {
            case 'header':
              return `<h${block.data.level} id="header-${block.id}">${block.data.text}</h${block.data.level}>`;
            case 'paragraph':
              return `<p>${block.data.text}</p>`;
            case 'list':
              const tag = block.data.style === 'ordered' ? 'ol' : 'ul';
              return `<${tag}>${block.data.items
                .map(item => `<li>${item}</li>`)
                .join('')}</${tag}>`;
            case 'quote':
              return `<blockquote>${block.data.text}</blockquote>`;
            case 'delimiter':
              return `<hr />`;
            case 'code':
              return `<pre><code>${block.data.code}</code></pre>`;
            case 'table':
              const rows = block.data.content
                .map(
                  row =>
                    `<tr>${row.map(cell => `<td>${cell}</td>`).join('')}</tr>`
                )
                .join('');
              return `<table>${rows}</table>`;
            default:
              return '';
          }
        })
        .join('');

      // Extract navigation items from headers
      const headers = editorData.blocks
        .filter(block => block.type === 'header')
        .map(block => ({ id: `header-${block.id}`, text: block.data.text }));

      // Update preview content and navigation items
      setNavItems(headers);
      setPreviewContent(htmlContent);
      setIsPreview(true); // Switch to preview mode
    } catch (error) {
      console.error('Error generating preview:', error);
      showErrorToast('Failed to generate preview.');
    }
  };

  // Generate preview from editor
  const generatePreview = async () => {
    if (!editorRef.current) return;

    try {
      const content = await editorRef.current.save();
      const htmlContent = content.blocks
        .map(block => {
          switch (block.type) {
            case 'header':
              return `<h${block.data.level} id="header-${block.id}">${block.data.text}</h${block.data.level}>`;
            case 'paragraph':
              return `<p>${block.data.text}</p>`;
            case 'list':
              const tag = block.data.style === 'ordered' ? 'ol' : 'ul';
              return `<${tag}>${block.data.items
                .map(item => `<li>${item}</li>`)
                .join('')}</${tag}>`;
            case 'quote':
              return `<blockquote>${block.data.text}</blockquote>`;
            case 'delimiter':
              return `<hr />`;
            case 'code':
              return `<pre><code>${block.data.code}</code></pre>`;
            case 'table':
              const rows = block.data.content
                .map(
                  row =>
                    `<tr>${row.map(cell => `<td>${cell}</td>`).join('')}</tr>`
                )
                .join('');
              return `<table>${rows}</table>`;
            default:
              return '';
          }
        })
        .join('');

      // Extract navigation items from headers
      const headers = content.blocks
        .filter(block => block.type === 'header')
        .map(block => ({ id: `header-${block.id}`, text: block.data.text }));
      setNavItems(headers);
      setPreviewContent(htmlContent);
      setIsPreview(true); // Switch to preview mode
    } catch (error) {
      console.error('Error generating preview:', error);
      showErrorToast('Failed to generate preview.');
    }
  };

  const scrollToSection = id => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const togglePreview = async () => {
    if (isPreview) {
      // Switch back to edit mode
     
      setIsPreview(false);

      // Ensure editorRef is available
      if (editorRef.current) {
        const content = await editorRef.current.save();
        setEditorData(content); // Update the editor data state
      }
    } else {
      // Generate preview and switch to preview mode
      await generatePreview(); // This internally handles content saving and switching to preview mode
    }
  };

  return (
    <Container>
      <Row className="d-flex justify-content-center mb-3">
        <Col lg={12} className="d-flex justify-content-center gap-3">
          <Form.Control
            type="text"
            placeholder="Enter title here..."
            value={documentTitle}
            onChange={e => setDocumentTitle(e.target.value)}
            className="mb-2"
            style={{ maxWidth: '400px' }}
          />
          <Button onClick={saveContent} variant="primary" className="mr-2">
            Save Content
          </Button>

          <Button
            onClick={togglePreview}
            variant="info"
            style={{ marginLeft: '15px' }}
          >
            {isPreview ? 'Edit' : 'Preview'}
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        {isPreview ? (
          <>
            <Col lg={2}>
              {isPreview && navItems.length > 0 && (
                <nav
                  style={{
                    position: 'sticky',
                    top: '0',
                    height: '100vh',
                    overflowY: 'auto',
                    borderRight: '1px solid #ccc',
                    padding: '10px',
                    backgroundColor: '#f8f9fa'
                  }}
                >
                  <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                    {navItems.map(item => (
                      <li key={item.id} style={{ margin: '5px 0' }}>
                        <Button
                          variant="link"
                          style={{
                            textDecoration: 'none',
                            color: '#007bff',
                            textAlign: 'left',
                            wordWrap: 'break-word',
                            whiteSpace: 'normal'
                          }}
                          onClick={() => scrollToSection(item.id)}
                        >
                          {item.text}
                        </Button>
                      </li>
                    ))}
                  </ul>
                </nav>
              )}
            </Col>
            <Col lg={10}>
              <div
                className="p-3"
                style={{
                  border: '1px solid #cccccc',
                  minHeight: '400px',
                  backgroundColor: '#f9f9f9'
                }}
                dangerouslySetInnerHTML={{ __html: previewContent }}
              />
            </Col>
          </>
        ) : (
          <>
            <Col lg={12}>
              <div
                id="editorjs"
                className="w-100"
                style={{ border: '1px solid #cccccc', minHeight: '400px' }}
              ></div>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

export default BlockEditor;
