import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/base/Button";
import { useAuth } from "providers/AuthContext";
import { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import apiCall from "services/api";
import regexPatterns from "common/regexPatterns";
import { handleCutomError } from "services/handleCutomError";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "providers/ToastProvider";
import { useProjectContext } from "providers/ProjectProvider";
import { KanbanBoardMembers } from "data/kanban";
import { PRESENT_USER_SUMMARY, PROJECT_PRESENT_USER_PERMISSION_DATA, TOGGLE_USER_PERMISSION_MODAL } from "reducers/ProjectReducer";
import { UserPermission } from "types/project";
import calculateSerialNumber from "common/calculateSerialNumber";




const UpdateUserPermissionModal = () => {

  const { alias } = useParams();
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const { showErrorToast, showSuccessToast } = useToast();
  const { projectAllUsers, toggleUpdateModal, updateUserPermission, projectDispatch } = useProjectContext();
  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigation = useNavigate();
  const [users, setUsers] = useState<KanbanBoardMembers[]>([]);
  const [formData, setFormData] = useState({
    id: 0,
    user_id: 0,
    role_in_project: "",
    is_project_admin: 0,
    is_story_admin: 0,
    is_meeting_admin: 0,
    is_diary_admin: 0,
    alias: "",
    user_designation: 0
  });

  const [errorExisting, setErrorExisting] = useState({
    id: "",
    user_id: "",
    role_in_project: "",
    is_project_admin: "",
    is_story_admin: "",
    is_meeting_admin: "",
    is_diary_admin: "",
    alias: "",
  });
  useEffect(() => {
    loadUsers();
  }, [alias]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      id: updateUserPermission?.id || 0,
      user_id: updateUserPermission?.user_id || 0,
      role_in_project: updateUserPermission?.role_in_project !== "" ? updateUserPermission?.role_in_project : "",
      is_project_admin: updateUserPermission?.is_project_admin || 0,
      is_story_admin: updateUserPermission?.is_story_admin || 0,
      is_meeting_admin: updateUserPermission?.is_meeting_admin || 0,
      is_diary_admin: updateUserPermission?.is_diary_admin || 0,
      user_designation: updateUserPermission?.user?.designation_id ?? 0,
    }));
  }, [updateUserPermission]);

  const loadUsers = async () => {
    try {

      const response = await apiCall({
        url: `project/${alias}/users`,
        method: 'GET',
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });

      let userData = response?.data?.users
      setUsers(userData);

    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        showErrorToast(error);
      }
    } finally {
      setLoading(false)
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    let errorMessage = "";

    if (name === "user_id") {
      if (!value.trim()) {
        errorMessage = "User is required.";
      }
    }

    if (name === "role_in_project") {
      if (!value.trim()) {
        errorMessage = "Role is required.";
      } if (!regexPatterns.titleRegex14.test(value)) {
        errorMessage = "Role should be 3 to 14 alphabetic characters.";
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrorExisting({
      ...errorExisting,
      [name]: errorMessage,
    });
  };

  const handleSubmitExisting = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission behavior
    e.stopPropagation();

    // Add the following line to set the form validation state
    setValidated(true);
    if (           // Check if there are any errors before making the API call
      errorExisting.user_id ||
      errorExisting.role_in_project

    ) {
      setLoading(false);
      return;
    }
    if (!formData.role_in_project.trim() || !formData.user_id) {
      setErrorExisting({
        ...errorExisting,
        role_in_project: !formData.role_in_project.trim() ? "Role is required." : "",
        user_id: !formData.user_id ? "User is required." : "",

      });
      setLoading(false);
      return;
    }

    const formDataSubmit = new FormData();
    formDataSubmit.append("user_id", String(formData?.user_id));
    formDataSubmit.append("is_project_admin", String(formData.is_project_admin));
    formDataSubmit.append("is_story_admin", String(formData.is_story_admin));
    formDataSubmit.append("is_meeting_admin", String(formData.is_meeting_admin));
    formDataSubmit.append("is_diary_admin", String(formData.is_diary_admin));
    formDataSubmit.append("role_in_project", String(formData.role_in_project));

    try {
      const response = await apiCall({
        url: `admin/project/${alias}/permissions/${formData?.id}/update`,  //admin api using here because, it's used when loggedin by admin
        method: 'POST',
        data: formDataSubmit,
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });

      if (response.status === 200) {
        if (response?.data?.errors) {
          const errors = response?.data.errors;
          // Dynamically set errors based on the response
          Object.keys(errors).forEach((key) => {
            const errorMessages = errors[key];
            const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
            // Set the error state for the corresponding field
            setErrorExisting((prevData) => ({
              ...prevData,
              [key]: firstErrorMessage,
            }));
          });
        } else {
          fetchExistingUsers();
        }
      } else {
        console.error("error message:");
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        showErrorToast(error)
      }
    } finally {
      setLoading(false); // Set loading to false after the API call is completed 
    }
  };

  const fetchExistingUsers = async () => {
    try {
      setLoading(true)

      const [response2] = await Promise.all([

        apiCall({
          url: `admin/project/${alias}/permissions/list?expand=user&page=1`,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        }),
      ])

      if (response2.status === 200) {
        let permissionData = response2?.data?.data?.permission
        const presentUsers = permissionData.filter((user: UserPermission) => user.status === 1);

        const updatedPresentUsers = presentUsers?.map((users: any, index: any) => ({
          ...users,
          serialNumber: calculateSerialNumber(1, index, response2?.data?.data?.summary?.pageSize) // Assuming 10 items per page
        }));
        projectDispatch({ type: PROJECT_PRESENT_USER_PERMISSION_DATA, payload: updatedPresentUsers });
        const summary = response2?.data?.data?.summary;
        projectDispatch({
          type: PRESENT_USER_SUMMARY, payload: {
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          }
        });
        showSuccessToast("Data Updated Successfully!")
        handleClose();
      } else {
        console.error("error message:");
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        handleCutomError(error, signOut, navigation);
      } else {
        console.error('An error occurred:', error);
        showErrorToast(error)
      }
    } finally {
      setLoading(false)
    }
  };

  const handleClose = () => {
    projectDispatch({
      type: TOGGLE_USER_PERMISSION_MODAL,
      payload: false
    });
  };

  return (
    <Modal show={toggleUpdateModal} onHide={handleClose} className="p-0" centered size="lg" backdrop="static">
      <Modal.Header className="p-4 d-flex gap-2 border-0">
        <h5 className="mb-0 text-body-emphasis fw-semibold flex-1">Update user</h5>
        <Button className="p-0 ms-auto" onClick={handleClose}>
          <FontAwesomeIcon icon={faXmark} className="fs-7" />
        </Button>
      </Modal.Header>
      <PhoenixDocCard className='pt-3  pb-4 mt-1 border_design'>

        <Form noValidate validated={validated} onSubmit={handleSubmitExisting}>
          <Modal.Body className="pt-4 pb-2 ">
            <Row className="mb-3 d-flex gap-1">
              <Col>
                <FloatingLabel controlId="lead-woner" label="User">
                  <Form.Select
                    required
                    className="w-100"
                    value={formData.user_id}
                    onChange={(e) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        user_id: Number(e.target.value)
                      }))
                    }}
                    disabled={true}
                  >
                    <option value="">Select</option>
                    {users.map(user => (
                      <option key={user.id} value={user.id}>{`${user.first_name} ${user.last_name}`}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    User is required.
                  </Form.Control.Feedback>
                </FloatingLabel>

              </Col>
            </Row>
            <Row className="mb-3 d-flex gap-1">
              <Col>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label='ROLL WITH IN PROJECT *'
                >
                  <Form.Control
                    type="text"
                    placeholder="Role"
                    name="role_in_project"
                    value={formData?.role_in_project}
                    onChange={handleInputChange}
                    isInvalid={!!errorExisting?.role_in_project}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorExisting?.role_in_project}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <div className="d-flex justify-content-between flex-wrap">
              <Form.Check type="checkbox" className="mb-0 ">
                <Form.Check.Input
                  type="checkbox"
                  name="is_project_admin"
                  id="is_project_admin"
                  checked={formData.is_project_admin === 1 ? true : false}
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      is_project_admin: e.target.checked ? 1 : 0
                    }))
                  }}
                />
                <Form.Check.Label htmlFor="projectadmin" className="mb-0">
                  Project Admin
                </Form.Check.Label>
              </Form.Check>
              <Form.Check type="checkbox" className="mb-0 ">
                <Form.Check.Input
                  type="checkbox"
                  name="is_story_admin"
                  id="is_story_admin"
                  checked={formData.is_story_admin === 1 ? true : false}
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      is_story_admin: e.target.checked ? 1 : 0
                    }))
                  }}
                />
                <Form.Check.Label htmlFor="storyadmin" className="mb-0">
                  Story Admin
                </Form.Check.Label>
              </Form.Check>
              <Form.Check type="checkbox" className="mb-0 ">
                <Form.Check.Input
                  type="checkbox"
                  name="is_meeting_admin"
                  id="is_meeting_admin"
                  checked={formData.is_meeting_admin === 1 ? true : false}
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      is_meeting_admin: e.target.checked ? 1 : 0
                    }))
                  }}
                />
                <Form.Check.Label htmlFor="meetingadmin" className="mb-0">
                  Meeting Admin
                </Form.Check.Label>
              </Form.Check>
              <Form.Check type="checkbox" className="mb-0">
                <Form.Check.Input
                  type="checkbox"
                  name="is_diary_admin"
                  id="is_diary_admin"
                  checked={formData.is_diary_admin === 1 ? true : false}
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      is_diary_admin: e.target.checked ? 1 : 0
                    }))
                  }}
                />
                <Form.Check.Label htmlFor="diaryadmin" className="mb-0">
                  Diary Admin
                </Form.Check.Label>
              </Form.Check>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center align-items-center px-4 pb-4 border-0 pt-3">
            <Button
              variant="outline-primary"
              size="sm"
              className="px-7 fs-9  my-0 btn"
              type="submit"
              disabled={loading}
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>

      </PhoenixDocCard>

    </Modal>
  );
};

export default UpdateUserPermissionModal;
