import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Breadcrumb } from 'react-bootstrap';

export interface PageBreadcrumbItem {
  label: string;
  url?: string;
  active?: boolean;
}

interface PageBreadcrumbProps {
  items: PageBreadcrumbItem[];
  className?: string;
  navigation?: any;
}

const PageBreadcrumb = ({
  items,
  className,
  navigation
}: PageBreadcrumbProps) => {
  return (
    <Breadcrumb className={classNames(className, 'mb-2')}>
      {items.map(item => (
        <Breadcrumb.Item
          onClick={() => {
            console.warn(navigation(item.url));
          }}
          active={item.active}
          key={item.label}
          className='fs-8'
        >
          {item.label ? item.label : <FontAwesomeIcon icon={faHome} />}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default PageBreadcrumb;
