import { faTimes, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ConfirmationModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  text: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ show, onHide, onConfirm, text }) => {
  return (
    <Modal show={show} onHide={onHide} className="p-0" backdrop="static">
      <Modal.Header className="p-4 d-flex gap-2 border-0">
        <h5 className="mb-0 text-body-emphasis fw-semibold flex-1">Confirm</h5>
        <Button className="p-0 ms-auto" onClick={onHide}>
            <FontAwesomeIcon icon={faXmark} className="fs-7" />
          </Button>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="phoenix-primary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="phoenix-primary" onClick={onConfirm}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
