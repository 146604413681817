import {
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import {
  initialSummary,
  KanbanBoardItem,
  KanbanBoardTask,
  KanbanTaskHistroy,
  SummaryProps,
  taskAttachementInitialData,
  TaskAttachmentFormData,
  taskLabelInitialData,
  TaskLabelsFormData
} from 'data/kanban';
import { Card, Col, Modal, Row } from 'react-bootstrap';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';
import KanbanEditTaskModal from './KanbanEditTaskModal';
import { useEffect, useRef, useState } from 'react';
import apiCall from 'services/api';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'providers/AuthContext';
import { AxiosError } from 'axios';
import { handleCutomError } from 'services/handleCutomError';
import Avatar from 'components/base/Avatar';
import { dateAndTime } from 'common/timeStampToDate';
import { useKanbanContext } from 'providers/KanbanProvider';
import { useToast } from 'providers/ToastProvider';
import { Spinner } from 'react-bootstrap';
import usePhoenixForm from 'hooks/usePhoenixForm';
import ToggleViewAvtar from 'components/common/ToggleViewAvtar';
import EditableTitle from './card-update/EditableTitle';
import CardStatusChange from './card-update/CardStatusChange';
import EditableDiscription from './card-update/EditableDiscription';
import EditableAssignTo from './card-update/EditableAssignTo';
import EditableColor from './card-update/EditableColor';
import EditableEndDate from './card-update/EditableEndDate';
import EditablePriority from './card-update/EditablePriority';
import EditableCategory from './card-update/EditableCategory';
import EditableTags from './card-update/EditableTags';
import EditableUser from './card-update/EditableUser';
import EditableAttachments from './card-update/EditableAttachments';
import EditableLinks from './card-update/EditableLinks';
import { TaskHistory } from './history/TaskHistory';

interface KanbanTaskDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  task: KanbanBoardTask;
  list: KanbanBoardItem;
}

export interface CardLoading {
  dataLoad: boolean,
  historyLoad: boolean,
  statusChange: boolean,
  addLabel: boolean,
  uploadFile: boolean,
  removeAttachment: boolean,
  removeLabels: boolean,
  removeAttachmentId: number,
  removeLabelId: number
}

export interface CardDateTime {
  cardDate: string,
  cardTime: string
}



const KanbanTaskDetailsModal = ({
  show,
  handleClose,
  task,
  list
}: KanbanTaskDetailsModalProps) => {
  const { alias, id } = useParams();
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const { showErrorToast, showSuccessToast } = useToast();
  const navigation = useNavigate() as NavigateFunction;
  const { userPermissionOnBoard, taskHistory, histotySummary, pageIndexHistory, kanbanDispatch } = useKanbanContext();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [cardDetails, setCardDetails] = useState<KanbanBoardTask | undefined>(undefined);
  const [cardDateTime, setCardDateTime] = useState<CardDateTime>({
    cardDate: '',
    cardTime: ''
  });
  const [loading, setLoading] = useState<CardLoading>({
    dataLoad: false,
    historyLoad: false,
    statusChange: false,
    uploadFile: false,
    addLabel: false,
    removeAttachment: false,
    removeLabels: false,
    removeAttachmentId: 0,
    removeLabelId: 0
  });

  const scrollContainerRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [cardDetails?.history]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading((prev) => ({
          ...prev,
          dataLoad: true
        })); // Set loading to true
        const response = await apiCall({
          url: `project/${alias}/boards/${id}/card/${task?.id}/view`,  // updated
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            workspace: workSpaceTkn
          }
        });

        if (response?.status === 200) {
          // Calculate serial numbers for the projects on the current page
          const cardData = response?.data?.data?.boardListCard;
          const { formattedDate, formattedTime } = dateAndTime(cardData.end_datetime)
          setCardDateTime({
            cardDate: formattedDate,
            cardTime: formattedTime
          })
          setCardDetails(cardData);
          setLoading((prev) => ({
            ...prev,
            dataLoad: false
          }));
        } else {
          console.error('error messag');
        }
      } catch (error: any) {
        if (error instanceof AxiosError) {
          handleCutomError(error, signOut, navigation);
        } else {
          console.error('An unexpected error occurred:', error);
          showErrorToast(error);
        }
      } finally {
        setLoading((prev) => ({
          ...prev,
          dataLoad: false
        }));
      }
    };
    if (show === true) {
      fetchData();
    }

  }, [show]);



  useEffect(() => {
    if (!show) {
      kanbanDispatch({
        type: 'TASK_HISTORY_PAGE_INDEX',
        payload: 1,
      });
      kanbanDispatch({
        type: 'TASK_HISTORY_DATA',
        payload: [],
      });
    }
  }, [show]);


  const handleModalClose = () => {
    handleClose();
  }
  const updateTaskHistory = async (taskId: number) => {
    let currentPage = 1;
    const allHistoryData: any[] = []; // Store all history data
    const totalPages = pageIndexHistory; // Default to 1 page initially
  
    try {
      kanbanDispatch({ type: 'TASK_HOSTORY_LOAD', payload: true });
  
      do {
        const response = await apiCall({
          url: `project/${alias}/boards/${id}/card/${taskId}/card-history?page=${currentPage}`,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            workspace: workSpaceTkn,
          },
        });
  
        if (response?.status === 200) {
          const historyData = response?.data?.data?.boardListCardHistory;
          const summary = response?.data?.data?.summary;
          kanbanDispatch({
            type: 'TASK_HISTORY_SUMMARY',
            payload: {
              ...histotySummary, 
              total_page: summary?.total_page, 
            },
          })
          allHistoryData.push(...historyData); // Add current page data to the array
          currentPage += 1; // Move to the next page
        } else {
          console.error('Error fetching history data');
          break;
        }
      } while (currentPage <= totalPages);
  
      // Remove duplicates and update state
      const uniqueHistoryData = allHistoryData.filter(
        (value, index, self) =>
          self.findIndex(
            (v) =>
              v.narration === value.narration && v.updatedAt === value.updatedAt,
          ) === index,
      );
  
      kanbanDispatch({
        type: 'TASK_HISTORY_DATA',
        payload: uniqueHistoryData,
      });
  
  
    } catch (error: any) {
      if (error instanceof AxiosError) {
        handleCutomError(error, signOut, navigation);
      } else {
        console.error('An unexpected error occurred:', error);
        showErrorToast(error);
      }
    } finally {
      kanbanDispatch({ type: 'TASK_HOSTORY_LOAD', payload: false });
    }
  };
  
  // const updateTaskHistory = async (taskId: number) => {
  //   try {
  //     kanbanDispatch({ type: 'TASK_HOSTORY_LOAD', payload: true });
  //     if (pageIndexHistory === 1) {
  //       kanbanDispatch({ type: 'TASK_HISTORY_DATA', payload: [] });
  //     }
  //     const response = await apiCall({
  //       url: `project/${alias}/boards/${id}/card/${taskId}/card-history?page=${pageIndexHistory}`,  // updated
  //       method: 'GET',
  //       headers: {
  //         'x-access-token': userTkn,
  //         workspace: workSpaceTkn
  //       }
  //     });

  //     if (response?.status === 200) {
  //       // Calculate serial numbers for the projects on the current page
  //       const historyData = response?.data?.data?.boardListCardHistory;
  //       const summary = response?.data?.data?.summary;
  //       const updatedTaskHistory = [...taskHistory, ...historyData].filter(
  //         (value, index, self) =>
  //           self.findIndex(
  //             (v) =>
  //               v.narration === value.narration &&
  //               v.updatedAt === value.updatedAt,
  //           ) === index,
  //       );

  //       kanbanDispatch({
  //         type: 'TASK_HISTORY_DATA',
  //         payload: updatedTaskHistory,
  //       });
  //       kanbanDispatch({
  //         type: 'TASK_HISTORY_SUMMARY', payload: {
  //           total: summary?.total,
  //           page: summary?.page,
  //           pageSize: summary?.pageSize,
  //           total_page: summary?.total_page,
  //         }
  //       });
  //       kanbanDispatch({ type: 'TASK_HOSTORY_LOAD', payload: false });
  //     } else {
  //       console.error('error messag');
  //     }
  //   } catch (error: any) {
  //     if (error instanceof AxiosError) {
  //       handleCutomError(error, signOut, navigation);
  //     } else {
  //       console.error('An unexpected error occurred:', error);
  //       showErrorToast(error);
  //     }
  //   } finally {
  //     kanbanDispatch({ type: 'TASK_HOSTORY_LOAD', payload: false });
  //   }
  // }

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        onHide={handleModalClose}
        fullscreen="xl-down"
        centered
        className="modal-xl p-0 "
        contentClassName="rounded-top-md-4 rounded-bottom-md-4 overflow-hidden h-75"
        scrollable
      >
        <Modal.Header className="p-4 d-flex gap-2 border-0 bg-color-card">
          <h3 className="mb-0 text-body-emphasis fw-medium fs-8 flex-1 mx-3">
            Card Details
          </h3>
          <Button className="p-0 ms-auto" onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} className="fs-7" />
          </Button>
        </Modal.Header>
        {loading?.dataLoad ? <div className='d-flex h-100  justify-content-center align-items-center'>
          <Spinner animation="border" variant="secondary" />
        </div> :
          <>
            <Modal.Body className="p-0 bg-color-card">
              <Row className="gy-4 py-0 gx-5 h-100">
                <Col xs={12} lg={12} className="h-100 scrollbar">
                  <Row className="mt-0 top-0  pb-3 gx-0 px-3">
                    <div className='px-3 me-4 bg-color-card'>
                      <Row className='py-1 mx-4 bg-color-card border-translucent border-bottom sticky-row'>
                        <Col xs={3} sm={2}>
                          <h6 className="text-body-tertiary fw-semibold lh-sm mt-1 fs-8">
                            Title
                          </h6>
                        </Col>
                        <Col xs={9} sm={10} >
                          <h4 className="mb-0 text-body-emphasis lh-sm">
                            <EditableTitle task={cardDetails as KanbanBoardTask} list={list} setCardDetails={setCardDetails} setCardDateTime={setCardDateTime} onHistoryUpdate={() => updateTaskHistory(Number(cardDetails?.id))}/>
                          </h4>
                        </Col>

                      </Row>
                      <Row className='py-1 mx-4 bg-color-card border-translucent border-bottom'>
                        <Col xs={3} sm={2}>
                          <h6 className="text-body-tertiary fw-semibold lh-sm mt-1 fs-8">
                            Status
                          </h6>
                        </Col>
                        <Col xs={9} sm={10}>
                          {userPermissionOnBoard && <CardStatusChange task={cardDetails as KanbanBoardTask} setCardDetails={setCardDetails} setCardDateTime={setCardDateTime} list={list} onHistoryUpdate={() => updateTaskHistory(Number(cardDetails?.id))}/>}
                        </Col>
                      </Row>
                      <Row className='py-1 mx-4 bg-color-card border-translucent border-bottom'>
                        <Col xs={3} sm={2}>
                          <h6 className="text-body-tertiary fw-semibold lh-sm mt-1 fs-8">
                            Description
                          </h6>
                        </Col>
                        <Col xs={9} sm={10}>
                          <EditableDiscription task={cardDetails as KanbanBoardTask} list={list} setCardDetails={setCardDetails} setCardDateTime={setCardDateTime} onHistoryUpdate={() => updateTaskHistory(Number(cardDetails?.id))}/>
                        </Col>
                      </Row>
                      <Row className='py-1 mx-4 bg-color-card border-translucent border-bottom'>
                        <Col xs={3} sm={2}>
                          <h6 className="text-body-tertiary fw-semibold lh-sm mt-1 fs-8">
                            Assign to
                          </h6>
                        </Col>
                        <Col xs={9} sm={10}>
                          <EditableAssignTo task={cardDetails as KanbanBoardTask} list={list} setCardDetails={setCardDetails} setCardDateTime={setCardDateTime} onHistoryUpdate={() => updateTaskHistory(Number(cardDetails?.id))}/>
                        </Col>
                      </Row>
                      <Row className='py-1 mx-4 bg-color-card border-translucent border-bottom'>
                        <Col xs={3} sm={2}>
                          <h6 className="text-body-tertiary fw-semibold lh-sm mt-1 fs-8">
                            Members
                          </h6>
                        </Col>
                        <Col xs={9} sm={10}>
                          <EditableUser task={cardDetails as KanbanBoardTask} list={list} setCardDetails={setCardDetails} setCardDateTime={setCardDateTime} onHistoryUpdate={() => updateTaskHistory(Number(cardDetails?.id))}/>
                        </Col>
                      </Row>
                      <Row className='py-1 mx-4 bg-color-card border-translucent border-bottom'>
                        <Col xs={3} sm={2}>
                          <h6 className="text-body-tertiary fw-semibold lh-sm mt-1 fs-8">
                            Cover
                          </h6>
                        </Col>
                        <Col xs={9} sm={10}>
                          <EditableColor task={cardDetails as KanbanBoardTask} list={list} setCardDetails={setCardDetails} setCardDateTime={setCardDateTime} onHistoryUpdate={() => updateTaskHistory(Number(cardDetails?.id))}/>
                        </Col>
                      </Row>
                      <Row className='py-1 mx-4 bg-color-card border-translucent border-bottom'>
                        <Col xs={3} sm={2}>
                          <h6 className="text-body-tertiary fw-semibold lh-sm mt-1 fs-8">
                            End Date
                          </h6>
                        </Col>
                        <Col xs={9} sm={10}>
                          <EditableEndDate task={cardDetails as KanbanBoardTask} list={list} cardDateTime={cardDateTime} setCardDateTime={setCardDateTime} setCardDetails={setCardDetails} onHistoryUpdate={() => updateTaskHistory(Number(cardDetails?.id))}/>
                        </Col>
                      </Row>
                      <Row className='py-1 mx-4 bg-color-card border-translucent border-bottom'>
                        <Col xs={3} sm={2}>
                          <h6 className="text-body-tertiary fw-semibold lh-sm mt-1 fs-8">
                            PRIORITY
                          </h6>
                        </Col>
                        <Col xs={9} sm={10}>
                          <EditablePriority task={cardDetails as KanbanBoardTask} list={list} setCardDateTime={setCardDateTime} setCardDetails={setCardDetails} onHistoryUpdate={() => updateTaskHistory(Number(cardDetails?.id))}/>
                        </Col>
                      </Row>
                      <Row className='py-1 mx-4 bg-color-card border-translucent border-bottom'>
                        <Col xs={3} sm={2}>
                          <h6 className="text-body-tertiary fw-semibold lh-sm mt-1 fs-8">
                            CATEGORY
                          </h6>
                        </Col>
                        <Col xs={9} sm={10}>
                          <EditableCategory task={cardDetails as KanbanBoardTask} list={list} setCardDateTime={setCardDateTime} setCardDetails={setCardDetails} onHistoryUpdate={() => updateTaskHistory(Number(cardDetails?.id))}/>
                        </Col>
                      </Row>
                      <Row className='py-1 mx-4 bg-color-card border-translucent border-bottom'>
                        <Col xs={3} sm={2}>
                          <h6 className="text-body-tertiary fw-semibold lh-sm mt-1 fs-8">
                            Attachments
                          </h6>
                        </Col>
                        <Col xs={9} sm={10}>
                          <EditableAttachments cardDetails={cardDetails as KanbanBoardTask} list={list} setCardDetails={setCardDetails} setCardDateTime={setCardDateTime} onHistoryUpdate={() => updateTaskHistory(Number(cardDetails?.id))}/>
                        </Col>
                      </Row>
                      <Row className='py-1 mx-4 bg-color-card border-translucent border-bottom'>
                        <Col xs={3} sm={2}>
                          <h6 className="text-body-tertiary fw-semibold lh-sm mt-1 fs-8">
                            Labels
                          </h6>
                        </Col>
                        <Col xs={3} sm={10}>
                          <EditableTags task={cardDetails as KanbanBoardTask} list={list} setCardDetails={setCardDetails} setCardDateTime={setCardDateTime} onHistoryUpdate={() => updateTaskHistory(Number(cardDetails?.id))}/>
                        </Col>
                      </Row>
                      <Row className='py-1 mx-4 bg-color-card border-translucent border-bottom'>
                        <Col xs={3} sm={2}>
                          <h6 className="text-body-tertiary fw-semibold lh-sm mt-1 fs-8">
                            Links
                          </h6>
                        </Col>
                        <Col xs={9} sm={10}>
                          <EditableLinks task={cardDetails as KanbanBoardTask} list={list} setCardDetails={setCardDetails} setCardDateTime={setCardDateTime} onHistoryUpdate={() => updateTaskHistory(Number(cardDetails?.id))}/>
                        </Col>
                      </Row>
                      <Row className='py-1 mx-4 bg-color-card border-translucent border-bottom'>
                        <h5 className="mb-3 mt-4 bg-color-card fs-7 pt-2">Activities</h5>
                        <TaskHistory taskId={Number(cardDetails?.id)} />
                      </Row>

                    </div>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
          </>
        }
      </Modal>
      {cardDetails && (
        <KanbanEditTaskModal
          show={openEditModal}
          task={cardDetails}
          list={list}
          handleClose={() => setOpenEditModal(false)}
        />)
      }
    </>
  );
};

export default KanbanTaskDetailsModal;
