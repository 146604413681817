import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { useKanbanContext } from 'providers/KanbanProvider';
// import { Link } from 'react-router-dom';
import { BOARD_MODAL_TYPE, BOARD_UPDATE_DATA, TOGGLE_ADD_BOARD_MODAL } from 'reducers/KanbanReducer';

const KanbanAddBoardCard = () => {
  const { kanbanDispatch } = useKanbanContext();
  return (
    <Button
      variant="phoenix-primary"
      className="d-flex flex-center h-100 w-100 fs-7 fw-semibold"
      // as={Link}
      // to="/apps/kanban/create-board"
      onClick={() => {
        kanbanDispatch({
          type: TOGGLE_ADD_BOARD_MODAL,
          payload: true
        });
        kanbanDispatch({
          type: BOARD_MODAL_TYPE,
          payload: false
        });
        kanbanDispatch({
          type: BOARD_UPDATE_DATA,
          payload: {
            id: 0,
            title: '',
            description: '',
            banner: ''
          }
        });
      }}
    >
      <FontAwesomeIcon icon={faPlusCircle} className="fs-8 me-2" />
      Create New Board
    </Button>
  );
};

export default KanbanAddBoardCard;
