import { faTimes, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/base/Button";
import { useAuth } from "providers/AuthContext";
import { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Tab, Table, Modal, Nav, Row } from "react-bootstrap";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import apiCall from "services/api";
import regexPatterns from "common/regexPatterns";
import Constants from "common/Constants";
import { useMaster } from "providers/MasterContext";
import { handleCutomError } from "services/handleCutomError";
import { useNavigate, useParams } from "react-router-dom";
import { useProjectContext } from "providers/ProjectProvider";
import { TOGGLE_PROJECT_ADD_USER_MODAL } from "reducers/ProjectReducer";
import AddExistingUserForm from "./AddExistingUserForm";
import AddNewUserForm from "./AddNewUserForm";

interface User {
  id: number;
  first_name: string;
  last_name: string;
}
type ReactSelectOption = {
  value: number;
  label: string;
};

const AddExistingUserModal = () => {
  const { addUserModal, projectDispatch } = useProjectContext();

  const handleClose = () => {
    projectDispatch({
      type: TOGGLE_PROJECT_ADD_USER_MODAL,
      payload: false
    });
  };
  return (
    <Modal show={addUserModal} onHide={handleClose} className="p-0" backdrop="static" centered size="lg">
      <Modal.Header className="p-4 d-flex gap-2 border-0">
        <h5 className="mb-0 text-body-emphasis fw-semibold flex-1">Add User</h5>
        <Button className="p-0 ms-auto" onClick={handleClose}>
          <FontAwesomeIcon icon={faXmark} className="fs-7" />
        </Button>
      </Modal.Header>
      <Row>

        <Tab.Container defaultActiveKey="existinguser">
          <Nav variant="underline" className="justify-content-center gap-3">
            <Nav.Item className="under_line">
              <Nav.Link eventKey="existinguser">Existing User</Nav.Link>
            </Nav.Item>
            <Nav.Item className="under_line">
              <Nav.Link eventKey="pastuser">Add New User</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content style={{ marginTop: "-2px" }}>
            <Tab.Pane eventKey="existinguser">
              <AddExistingUserForm />
            </Tab.Pane>
            <Tab.Pane eventKey="pastuser">
              <AddNewUserForm />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>


      </Row>
    </Modal>
  );
};

export default AddExistingUserModal;
