import { AxiosError } from 'axios';
import { dateAndTime } from 'common/timeStampToDate';
import Button from 'components/base/Button';
import { KanbanBoardItem, KanbanBoardTask } from 'data/kanban';
import { useAuth } from 'providers/AuthContext';
import { useKanbanContext } from 'providers/KanbanProvider';
import { useToast } from 'providers/ToastProvider';
import React, { useState, useEffect, useRef } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import apiCall from 'services/api';
import { handleCutomError } from 'services/handleCutomError';
import { CardDateTime } from '../KanbanTaskDetailsModal';
import Unicon from 'components/base/Unicon';
import { UilPen } from '@iconscout/react-unicons';
import TinymceEditor from 'components/base/TinymceEditor';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface EditableDiscriptionProps {
    task: KanbanBoardTask;
    list: KanbanBoardItem;
    setCardDateTime: React.Dispatch<React.SetStateAction<CardDateTime>>;
    setCardDetails: React.Dispatch<React.SetStateAction<KanbanBoardTask | undefined>>;
    onHistoryUpdate?: () => void;
}

export interface Loading {
    descriptionUpdate: boolean

}

const EditableDiscription: React.FC<EditableDiscriptionProps> = ({ task, list, setCardDateTime, setCardDetails, onHistoryUpdate }) => {
    const { alias, id } = useParams();
    const { boardLists, kanbanDispatch } = useKanbanContext();

    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const { showErrorToast, showSuccessToast } = useToast();
    const navigation = useNavigate();
    const [validated, setValidated] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [taskData, setTaskData] = useState<KanbanBoardTask | undefined>(undefined);
    const [loading, setLoading] = useState<Loading>({
        descriptionUpdate: false
    });
    const [originalDescription, setOriginalDescription] = useState<string>('');
    const [error, setError] = useState({
        description: '',
    })
    const inputRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (task) {
            setTaskData({
                ...task,
                id: task.id
            });
            setOriginalDescription(task?.description);
        }
    }, [task]);

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus(); // Set focus to input field
        }
    }, [isEditing]);

    const handleDescriptionClick = () => {

        setIsEditing(true); //on Click Enable editing mode
    };



    const handleEditorChange = (content: string) => {
        setTaskData((prevData) => {
            if (prevData) {
                return {
                    ...prevData,
                    description: content
                };
            }
            return prevData;
        });
    };

    const normalizeDescription = (description: string) => {
        // Use DOMParser to strip HTML tags and return the plain text
        const parser = new DOMParser();
        const doc = parser.parseFromString(description, 'text/html');
        return doc.body.textContent || '';
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const normalizedDescription = normalizeDescription(taskData?.description || '');
        const normalizedOriginalDescription = normalizeDescription(originalDescription);

        if (normalizedDescription === normalizedOriginalDescription) {
            setIsEditing(false);
            return; // No change in description, don't submit
        }
        try {
            setLoading((prev) => ({
                ...prev,
                descriptionUpdate: true
            }));
            const formData = new FormData();
            formData.append('description', taskData?.description || '');
            const response = await apiCall({
                url: `project/${alias}/boards/${id}/card/${taskData?.id}/update`,
                method: 'POST',
                data: formData,
                headers: {
                    'x-access-token': userTkn,
                    workspace: workSpaceTkn
                }
            });
            if (response.status === 200) {
                showSuccessToast('Card updated successfully!');
                const updatedItem = response?.data?.data?.boardListCard;

                const updatedBoardLists = boardLists.map((item) => {
                    if (Number(item.id) === Number(list.id)) {
                        // Update the board list by replacing the card within boardListCards
                        return {
                            ...item,
                            boardListCards: item.boardListCards.map(card =>
                                // If the card matches, replace it, otherwise keep it as is
                                card.id === updatedItem.id ? updatedItem : card
                            )
                        };
                    }
                    return item;
                });
                const { formattedDate, formattedTime } = dateAndTime(updatedItem.end_datetime)
                setCardDateTime({
                    cardDate: formattedDate,
                    cardTime: formattedTime
                })
                setCardDetails(updatedItem);
                setOriginalDescription(updatedItem.description);
                kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });
                if (onHistoryUpdate) onHistoryUpdate();
            } else {
                throw new Error('Failed to update card');
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An unexpected error occurred:', error);
                showErrorToast('An unexpected error occurred');
            }
        } finally {
            setLoading((prev) => ({
                ...prev,
                descriptionUpdate: false
            }));
            setIsEditing(false);
        }
    }

    const handleCancel = () => {
        // Reset the description to the original state and exit edit mode
        setTaskData((prevData) => {
            if (prevData) {
                return {
                    ...prevData,
                    description: originalDescription
                };
            }
            return prevData;
        });
        setIsEditing(false); // Close the editor without submitting changes
    };


    return (
        <>

            {isEditing ? (
                <div className='d-flex gap-1'>
                    <div className='w-100 bg-white p-3 mb-2 rounded-1'>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <TinymceEditor
                                value={taskData?.description || ''}
                                onChange={handleEditorChange}
                                options={{
                                    height: '20rem',
                                    plugins: 'image',
                                    toolbar: 'undo redo | image',
                                    image_title: true,
                                    file_picker_types: 'image',
                                    file_picker_callback: (callback: any, value: string, meta: any) => {
                                        if (meta.filetype === 'image') {
                                            // Open image upload dialog
                                            const input = document.createElement('input');
                                            input.setAttribute('type', 'file');
                                            input.setAttribute('accept', 'image/*');
                                            input.click();

                                            input.onchange = function () {
                                                const file = input.files && input.files[0];
                                                if (file) {
                                                    const reader = new FileReader();
                                                    reader.onload = function (e) {
                                                        if (e.target) {
                                                            // Create an image element from the uploaded file
                                                            const img = new Image();
                                                            img.onload = function () {
                                                                // Create a canvas to resize the image
                                                                const canvas = document.createElement('canvas');
                                                                const ctx = canvas.getContext('2d');
                                                                if (ctx) {
                                                                    const maxWidth = 300; // Set maximum width (adjust as needed)
                                                                    const maxHeight = 200; // Set maximum height (adjust as needed)
                                                                    let width = img.width;
                                                                    let height = img.height;

                                                                    // Calculate new dimensions while maintaining aspect ratio
                                                                    if (width > height) {
                                                                        if (width > maxWidth) {
                                                                            height = Math.round(height * (maxWidth / width));
                                                                            width = maxWidth;
                                                                        }
                                                                    } else {
                                                                        if (height > maxHeight) {
                                                                            width = Math.round(width * (maxHeight / height));
                                                                            height = maxHeight;
                                                                        }
                                                                    }

                                                                    // Set canvas size and draw the resized image
                                                                    canvas.width = width;
                                                                    canvas.height = height;
                                                                    ctx.drawImage(img, 0, 0, width, height);

                                                                    // Convert the resized image to a data URL and pass it to the callback
                                                                    const resizedImage = canvas.toDataURL('image/jpeg');
                                                                    callback(resizedImage, { alt: file.name });
                                                                }
                                                            };
                                                            img.src = e.target.result as string;
                                                        }
                                                    };
                                                    reader.readAsDataURL(file);
                                                }
                                            };
                                        }
                                    },
                                    content_style: `
                                                img {
                                                max-width: 300px;
                                                max-height: 200px;
                                                width: auto;
                                                height: auto;
                                                }`
                                }}
                            />
                            <div className='d-flex gap-2 justify-content-center align-items-center mt-2'>
                                <Button
                                    startIcon={<FontAwesomeIcon icon={faTimes} />}
                                    onClick={handleCancel}
                                    className="p-0"
                                    disabled={loading?.descriptionUpdate}
                                >
                                    cancel
                                </Button>
                                <Button
                                    variant="outline-primary"
                                    className=""
                                    type='submit'
                                    disabled={loading?.descriptionUpdate}
                                >
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </div>
                    {loading?.descriptionUpdate && <Spinner animation="border" variant="secondary" size="sm" />}
                </div>

            ) : (
                <div className="d-flex gap-2 fs-9 mb-0 text-body-emphasis lh-sm w-100 editable-field description-img">
                    <div>
                        {taskData?.description
                            ? parse(taskData?.description || '')
                            : ''}
                    </div>

                    <div >
                        <Button
                            variant=""
                            className="btn-icon"
                            onClick={handleDescriptionClick}
                        >
                            <Unicon icon={UilPen} size={16} />
                        </Button>
                    </div>
                </div>
            )}

        </>
    );
};

export default EditableDiscription;
