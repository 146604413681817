import { faTimes, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import regexPatterns from "common/regexPatterns";
import { dbDateWithTimeStamp } from "common/timeStampToDate";
import Button from "components/base/Button";
import DatePicker from "components/base/DatePicker";
import DatePicker2 from "components/base/DatePicker2";
import ReactSelect from "components/base/ReactSelect";
import ActivityTimelineCustom from "components/timelines/ActivityTimelineCustom";
import { useAuth } from "providers/AuthContext";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import apiCall from "services/api";
import { handleCutomError } from "services/handleCutomError";

interface ReopenActionPointModalProps {
    show: boolean;
    handleClose: () => void;
    setAddStatus: React.Dispatch<React.SetStateAction<boolean>>; // Add this line
    toast: any;
    presentUser: any;
    projectsData: any;
    editActionPoint: any;
    setPageFilters: any;
    actionPointData: any;
    navigation: any;
}

interface FormData {
    id: string;
    project_name: string;
    title: string;
    description: string;
    due_date: string;
    assign_to: string;
    auditor_id: string;
    viewers: string[]; // Explicitly define the type as an array of numbers
    comment: string;
}

const ReopenActionPointModal = ({ show, handleClose, setAddStatus, toast, navigation, presentUser, projectsData, editActionPoint, setPageFilters, actionPointData }: ReopenActionPointModalProps) => {
    const [validated, setValidated] = useState<boolean>(false);
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const [error, setError] = useState({
        project_name: "",
        title: "",
        description: "",
        due_date: "",
        assign_to: "",
        auditor_id: "",
        viewers: "",
        comment: ""
    });
    const [formData, setFormData] = useState<FormData>({
        id: "",
        project_name: "",
        title: "",
        description: "",
        due_date: "",
        assign_to: "",
        auditor_id: "",
        viewers: [],
        comment: ""
    });

    useEffect(() => {

        setOptions(prevOptions => (
            presentUser.map((item: any) => ({ // Assuming 'any' type for 'item' for now
                value: item.id,
                label: `${item.first_name} ${item.last_name}`
            }))
        ));
    }, [presentUser]);

    useEffect(() => {

        setFormData({
            ...formData,
            id: editActionPoint.id,
            project_name: editActionPoint.project_name,
            title: editActionPoint.title,
            description: editActionPoint.description,
            due_date: editActionPoint.due_date,
            assign_to: editActionPoint.assign_to,
            auditor_id: editActionPoint.auditor_id,
            viewers: editActionPoint.viewers,
        });

        setPageFilters((prevData: any) => ({
            ...prevData,
            project_alias: editActionPoint.project_name
        }));
    }, [editActionPoint]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | { target: { name: string; value: string; } }) => {
        const { name, value } = e.target;

        let errorMessage = "";

        // Validation for first name
        if (name === "title") {
            if (!value.trim()) {
                errorMessage = "Title is required.";
            } else if (!regexPatterns.titleRegex255.test(value)) {
                errorMessage = "Title should contain at 3-255 characters [a-zA-Z0-9] with space.";
            }
        }


        if (name === "due_date") {
            let newValue = dbDateWithTimeStamp(value)
            setFormData({
                ...formData,
                [name]: newValue,
            });

        } else {
            setFormData({
                ...formData,
                [name]: value,
            });

        }


        setError((prevData) => ({
            ...prevData,
            [name]: errorMessage,
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // Prevent default form submission behavior
        e.stopPropagation();
        // Add the following line to set the form validation state
        setValidated(true);
        // Check if there are any errors before making the API call
        if (
            error.title ||
            error.assign_to ||
            error.due_date ||
            error.auditor_id ||
            error.viewers ||
            error.description ||
            error.comment ||
            error.project_name
        ) {
            return;
        }
        // Check if there are any errors before making the API call
        if (
            !formData.title.trim() ||
            !formData.assign_to ||
            !formData.due_date.trim() ||
            !formData.auditor_id.trim() ||
            !formData.description.trim() ||
            !formData.project_name.trim()
        ) {
            setError({
                ...error,
                project_name: !formData.project_name.trim() ? "Project name is required." : "",
                title: !formData.title.trim() ? "Title is required." : "",
                assign_to: !formData.assign_to ? "This is required." : "",
                due_date: !formData.due_date.trim() ? "Date is required." : "",
                auditor_id: !formData.auditor_id ? "Auditor is required." : "",
                description: !formData.description.trim() ? "Description is required." : "",
            });
            return;
        }
        // Perform any necessary logic or API calls here
        const formDataSubmit = new FormData();
        // formDataSubmit.append("id", formData?.id);
        formDataSubmit.append("title", formData?.title);
        formDataSubmit.append("assign_to", formData?.assign_to);
        formDataSubmit.append("due_date", formData?.due_date);
        formDataSubmit.append("auditor_id", formData?.auditor_id);
        formDataSubmit.append("description", formData?.description);
        formDataSubmit.append("comment", formData?.comment);
        // Append each viewer ID to the formDataSubmit object
        formData.viewers.forEach(viewerId => {
            formDataSubmit.append("viewers[]", viewerId);
        });
        try {
            setLoading(true)
            const response = await apiCall({
                url: `project/${formData.project_name}/actionpoints/${formData?.id}/reopen`,
                method: 'POST',
                data: formDataSubmit,
                headers: {
                    'x-access-token': userTkn,
                    'workspace': workSpaceTkn
                },
            });

            if (response.status === 200) {
                if (response?.data?.errors) {
                    const errors = response?.data.errors;
                    // Dynamically set errors based on the response
                    Object.keys(errors).forEach((key) => {
                        const errorMessages = errors[key];
                        const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                        // Set the error state for the corresponding field
                        setError((prevData) => ({
                            ...prevData,
                            [key]: firstErrorMessage,
                        }));
                    });
                } else {
                    // Call the refetchData function from the context to fetch the data again
                    setAddStatus(true);
                    handleClose();
                }
            } else {
                console.error("Api Failed");
            }
        } catch (error: any) {
            if (error?.isAxiosError) {
                // Access the error message
                handleCutomError(error, signOut, navigation);
            } else {
                // Handle other types of errors
                console.error('An error occurred:', error);
                // Display a generic error message to the user
                toast.error('An unexpected error occurred', {
                    position: "top-center"
                });
            }
        } finally {
            setLoading(false); // Set loading to false after the API call is completed
        }
    };
    const handleViewerChange = (selectedOptions: any) => {
        setFormData({
            ...formData,
            viewers: selectedOptions.map((option: any) => option.value),
        });
    };

    const formatDueDate = (date: Date): string => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hours = date.getHours() % 12 || 12;
        const minutes = date.getMinutes();
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

        return `${day}/${month}/${year} ${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    };


    return (
        <Modal show={show} onHide={handleClose} className="p-0" centered size="xl" backdrop="static">
            <Modal.Header className="p-4 d-flex gap-2 border-0">
                <h5 className="mb-0 text-body-emphasis fw-semibold flex-1">Reopen</h5>
                <Button className="p-0 ms-auto" onClick={handleClose}>
                    <FontAwesomeIcon icon={faXmark} className="fs-7" />
                </Button>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body className="pt-4 pb-2 px-4">
                    <Row >
                        <Col md={8} className="mb-3">
                            <Row >
                                <Col md={12} className="mb-3">
                                    <FloatingLabel
                                        controlId="floatingInputGrid"
                                        label='Project Name'
                                    >
                                        <Form.Control
                                            disabled
                                            required
                                            type="text"
                                            placeholder="Re-Password"
                                            name="project_name"
                                            value={formData.project_name}
                                            onChange={handleInputChange}
                                            isInvalid={!!error.project_name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {error?.project_name}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Group className=" text-start">
                                        <FloatingLabel
                                            controlId="floatingInputGrid"
                                            label={`Title *`}
                                        >
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Title"
                                                name="title"
                                                value={formData.title}
                                                onChange={handleInputChange}
                                                isInvalid={!!error.title}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {error?.title}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <FloatingLabel controlId="lead-woner" label="Assign To *">
                                        <Form.Select
                                            required
                                            value={formData.assign_to}
                                            name="assign_to"
                                            className="w-100"
                                            onChange={handleInputChange}
                                            isInvalid={!!error.assign_to}
                                        >
                                            <option value="">Select</option>
                                            {
                                                options?.map((item: any, index: number) => (
                                                    <option value={item.value} key={item.value}>{item.label}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {error?.assign_to}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6} className="mb-3">

                                    <Form.Group className=" text-start">
                                        <DatePicker2
                                            // value={formData.due_date ? new Date(formData.due_date).toLocaleDateString('en-GB') : ''}
                                            value={formData.due_date ? formatDueDate(new Date(formData.due_date)) : ''}
                                            onChange={(date: Date[]) => {
                                                handleInputChange({
                                                    target: {
                                                        name: 'due_date',
                                                        value: date[0].toString()
                                                    }
                                                });
                                            }}
                                            options={{
                                                enableTime: true,
                                                dateFormat: "d/m/Y h:i K",
                                                minDate: new Date().setHours(0, 0, 0, 0)
                                            }}
                                            isError={error?.due_date}
                                            id="due_date"
                                            name="due_date"
                                            render={(_, ref) => {
                                                const isInvalid = !!error.due_date;
                                                return (
                                                    <Form.Floating>
                                                        <Form.Control
                                                            type="text"
                                                            ref={ref}
                                                            placeholder="d/m/Y  h:i"
                                                            readOnly
                                                            isInvalid={isInvalid}

                                                        />
                                                        <label htmlFor="startDate" className="ps-6">
                                                            {`Due Date *`}
                                                        </label>
                                                        <Form.Control.Feedback type="invalid">
                                                            {error?.due_date}
                                                        </Form.Control.Feedback>
                                                    </Form.Floating>
                                                );
                                            }}
                                        />

                                    </Form.Group>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <FloatingLabel controlId="lead-woner" label="Auditor *">
                                        <Form.Select
                                            required
                                            value={formData.auditor_id}
                                            name="auditor_id"
                                            className="w-100"
                                            onChange={handleInputChange}
                                            isInvalid={!!error.auditor_id}
                                        >
                                            <option value="">Select</option>
                                            {
                                                options?.map((item: any, index: number) => (
                                                    <option value={item.value} key={item.value}>{item.label}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {error?.auditor_id}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md={12} className="mb-3">

                                    <ReactSelect
                                        name="viewers"
                                        options={options}
                                        onChange={handleViewerChange}
                                        value={options.filter((option: any) => formData.viewers.includes(option.value))}
                                        isMulti
                                        placeholder="Select Viewers"
                                        required
                                        classNames={{
                                            control: () => 'py-3',
                                            valueContainer: () => 'lh-1'
                                        }}
                                    />

                                </Col>




                            </Row>
                            <Row className="mb-3 d-flex gap-1">
                                <Form.Group className=" text-start">
                                    <FloatingLabel
                                        controlId="floatingInputGrid"
                                        label={`Description *`}
                                    >
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="description"
                                            name="description"
                                            value={formData.description}
                                            onChange={handleInputChange}
                                            isInvalid={!!error.description}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {error?.description}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3 d-flex gap-1">
                                <Col>
                                    <Form.Group className=" text-start">
                                        <FloatingLabel
                                            controlId="floatingInputGrid"
                                            label={`Comment`}
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Comments"
                                                name="comment"
                                                value={formData.comment}
                                                onChange={handleInputChange}
                                                isInvalid={!!error.comment}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {error?.comment}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4} className="mb-3">
                            <Card className="timeline-size">
                                <Card.Body className="d-flex flex-column justify-content-between pb-3 px-3">
                                    <ActivityTimelineCustom data={actionPointData?.history} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center align-items-center px-4 pb-4 border-0 pt-3">
                    <Button
                        variant="outline-primary"
                        size="sm"
                        className="px-7 fs-9  my-0 btn"
                        type="submit"
                        disabled={loading}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ReopenActionPointModal;
