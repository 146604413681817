import { Col, Form, Modal, Row, Tab } from 'react-bootstrap';
import boardIcon from 'assets/img/kanban/board.png';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLink, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Link, NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { FormEvent, useEffect, useRef, useState } from 'react';
import apiCall from 'services/api';
import { useAuth } from 'providers/AuthContext';
import { useKanbanContext } from 'providers/KanbanProvider';
import { handleCutomError } from 'services/handleCutomError';
import { useToast } from 'providers/ToastProvider';
import usePhoenixForm from 'hooks/usePhoenixForm';
import ReactSelect from 'components/base/ReactSelect';
import { KanbanBoardMembers } from 'data/kanban';
import { Nav } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { UilTrash } from '@iconscout/react-unicons';
import Unicon from 'components/base/Unicon';
import { UPDATE_BOARD_USERS } from 'reducers/KanbanReducer';


interface User {
  label: string;
  value: string;
}
interface AddColumnFormData {
  user: string;
  is_manager: string
}
const initialFormData: AddColumnFormData = {
  user: '',
  is_manager: ''
};

interface UserFormError {
  user_id: string,
  is_manager: string
}


interface KanbanInviteModalProps {
  show: boolean;
  handleClose: () => void;
}

const KanbanInviteModal = ({ show, handleClose }: KanbanInviteModalProps) => {
  const { alias, id } = useParams();
  const { boardUsersList, openAddBoardModal, kanbanDispatch } = useKanbanContext();
  const { showErrorToast, showSuccessToast } = useToast()
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const navigation = useNavigate() as NavigateFunction;
  const [userOptions, setUserOptions] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef<HTMLTableElement | null>(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [error, setError] = useState<UserFormError>({
    user_id: '',
    is_manager: ''
  });

  const { formData, setFormData, onChange } =
    usePhoenixForm<AddColumnFormData>(initialFormData);

  useEffect(() => {
    loadUsers();
  }, [boardUsersList]);

  const loadUsers = async () => {
    try {

      const response = await apiCall({
        // url: `project/${projAlias}/users`,
        url: `project/${alias}/users?pagination=0&status=${10}`,   //using this admin api, because if loggedin user is admin
        method: 'GET',
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });

      let userData = response?.data?.users
      const boardUserIds = boardUsersList.map((user) => Number(user.id));
      const remainingUsers = userData.filter(
        (user: any) => !boardUserIds.includes(Number(user.id))
      );
      const options = remainingUsers.map((user: any) => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      }));

      setUserOptions(options);

    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        showErrorToast('An unexpected error occurred user list');
      }
    } finally {
      setLoading(false);
    }
  };
  const handleViewerChange = (selectedOption: any) => {
    setFormData({
      ...formData,
      user: selectedOption?.value, // Store a single user ID
    });

    setError((prev) => (
      {
        ...prev,
        user_id: '',
      }
    ))
  };


  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      setLoading(true);
      const formDataSubmit = new FormData();
      formDataSubmit.append('user_id', formData?.user); // Append each user ID to FormData
      if (formData?.is_manager) {
        formDataSubmit.append('is_manager', formData?.is_manager);
      }

      const response = await apiCall({
        url: `project/${alias}/boards/${id}/add-user`,
        method: 'POST',
        data: formDataSubmit,
        headers: {
          'x-access-token': userTkn,
          workspace: workSpaceTkn
        }
      });

      if (response.status === 200) {
        if (response?.data?.errors) {
          const errors = response?.data.errors;

          // Dynamically set errors based on the response
          Object.keys(errors).forEach((key) => {
            const errorMessages = errors[key];
            const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
            // Set the error state for the corresponding field
            setError((prevData) => ({
              ...prevData,
              [key]: firstErrorMessage,
            }));
          });
        } else {
          showSuccessToast('List added successfully!');
          const addedItem = response?.data?.data?.board.users;
          kanbanDispatch({
            type: UPDATE_BOARD_USERS,
            payload: addedItem
          });
          handleClose()
        }

      } else {
        showErrorToast('Failed to add list');
      }
    } catch (error) {
      console.error('Error adding list:', error);
      showErrorToast('Failed to add list');
    } finally {
      setLoading(false);
    }
  };

  // This function will open the confirmation modal
  const handleDeleteClick = (userId: string) => {
    setSelectedUserId(userId);
    setShowConfirm(true); // Show the confirmation modal
  };

  // This function handles the delete action after confirmation
  const handleDeleteConfirm = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setShowConfirm(false);
    e.preventDefault()
    if (selectedUserId) {
      try {
        setLoading(true);
        const formDataSubmit = new FormData();
        formDataSubmit.append('user_id', selectedUserId); // Append each user ID to FormData
        const response = await apiCall({
          url: `project/${alias}/boards/${id}/remove-user`,
          method: 'POST',
          data: formDataSubmit,
          headers: {
            'x-access-token': userTkn,
            workspace: workSpaceTkn
          }
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Dynamically set errors based on the response
            Object.keys(errors).forEach((key) => {
              const errorMessages = errors[key];
              const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
              // Set the error state for the corresponding field
              setError((prevData) => ({
                ...prevData,
                [key]: firstErrorMessage,
              }));
            });
          } else {
            showSuccessToast('User Removed successfully!');
            const addedItem = response?.data?.data?.board.users;

            kanbanDispatch({
              type: UPDATE_BOARD_USERS,
              payload: addedItem
            });

            handleClose()
          }

        } else {
          showErrorToast('Failed to remove list');
        }
      } catch (error) {
        showErrorToast('Failed to remove list');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteCancel = () => {
    setShowConfirm(false); // Close the confirmation modal without doing anything
  };

  return (
    <>
      <Modal show={show} centered onHide={handleClose} backdrop="static">
        <Modal.Header className="p-4 d-flex gap-2 border-0">
          {/* <img src={boardIcon} height={24} width={18} /> */}
          <h3 className="mb-0 text-body-emphasis fw-semibold flex-1">
            User
          </h3>
          <Button className="p-0 ms-auto" onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} className="fs-7" />
          </Button>
        </Modal.Header>
        <Modal.Body className="p-4 pt-0">

          <Tab.Container defaultActiveKey="existinguser">
            <Nav variant="underline" className="justify-content-center gap-3">
              <Nav.Item className="under_line">
                <Nav.Link eventKey="existinguser">Add User</Nav.Link>
              </Nav.Item>
              <Nav.Item className="under_line">
                <Nav.Link eventKey="pastuser">Remove User</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="existinguser" className='mt-2'>
                <form onSubmit={handleSubmit}>
                  <Row className="g-2 mb-2 ">
                    <Col xs={12}>
                      <ReactSelect
                        name="user"
                        options={userOptions}
                        onChange={handleViewerChange}
                        value={userOptions?.filter((option: any) => option.value === formData?.user)}
                        // isMulti
                        closeMenuOnSelect={true}
                        placeholder="Select Viewers"
                        required
                        classNames={{
                          control: () => 'py-3',
                          valueContainer: () => 'lh-1'
                        }}
                      />
                      <span className="text-danger fs-9">{error?.user_id}</span>
                    </Col>
                    <Col xs={12}>
                      <Form.Check type="checkbox" className="mb-0 ">
                        <Form.Check.Input
                          type="checkbox"
                          name="is_project_admin"
                          id="is_project_admin"
                          checked={Number(formData.is_manager) === 1 ? true : false}
                          onChange={(e) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              is_manager: e.target.checked ? '1' : '0'
                            }))
                          }}
                        />
                        <Form.Check.Label htmlFor="projectadmin" className="mb-0">
                         Is Manager
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col xs="auto" sm={12}>
                      <Button variant="outline-primary" type='submit' disabled={loading}>
                        <FontAwesomeIcon icon={faUser} className="fs-10 me-sm-2" />
                        <span className="d-none d-sm-inline-block">Add</span>
                      </Button>
                    </Col>
                  </Row>
                </form>
              </Tab.Pane>
              <Tab.Pane eventKey="pastuser">
                <Table
                  className="phoenix-table fs-9 project-table border rounded mt-2"
                  size="sm"
                  striped
                  hover
                  responsive
                  ref={tableRef}
                >
                  <thead>
                    <tr id={"a1"}>
                      <th >User</th>
                      <th
                        className="px-1"
                      >
                        Action
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {boardUsersList &&
                      boardUsersList.map((item: KanbanBoardMembers, index: number) => {
                        return (
                          <tr key={item?.id || index}>
                            <td>{item?.first_name} {item?.last_name}</td>
                            <td >
                              <Button
                                variant="phoenix-primary"
                                className="btn-icon rounded-1"
                                onClick={() => handleDeleteClick(String(item?.id))}
                                disabled={loading}
                              >
                                <Unicon icon={UilTrash} size={20} id="ongoing" />
                              </Button>
                            </td>


                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

        </Modal.Body>
      </Modal>

      {/* Confirmation Modal */}
      <Modal show={showConfirm} onHide={handleDeleteCancel} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this user?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCancel}>
            No
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

    </>

  );
};

export default KanbanInviteModal;
