import { Dropdown, Form } from 'react-bootstrap';
import Button from 'components/base/Button';
import { CreateNewLabel, initialCreateNewLabelData, KanbanBoardItem, KanbanBoardTask, selectColor } from 'data/kanban';
import { useEffect, useState } from 'react';
import Unicon from 'components/base/Unicon';
import { UilAngleLeftB, UilPen, UilPlus, UilTimes } from '@iconscout/react-unicons';
import { useToast } from 'providers/ToastProvider';
import { useKanbanContext } from 'providers/KanbanProvider';
import CreateTags from './tags/CreateTags';
import { CardDateTime } from '../KanbanTaskDetailsModal';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'providers/AuthContext';
import apiCall from 'services/api';
import { AxiosError } from 'axios';
import { handleCutomError } from 'services/handleCutomError';
import { dateAndTime } from 'common/timeStampToDate';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';


interface EditableTitleProps {
  task: KanbanBoardTask;
  list: KanbanBoardItem;
  setCardDateTime: React.Dispatch<React.SetStateAction<CardDateTime>>;
  setCardDetails: React.Dispatch<React.SetStateAction<KanbanBoardTask | undefined>>;
  onHistoryUpdate?: () => void;
}

const EditableTags: React.FC<EditableTitleProps> = ({ task, list, setCardDateTime, setCardDetails, onHistoryUpdate }) => {
  const { alias, id } = useParams();
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const { showErrorToast, showSuccessToast } = useToast();
  const { toggleLabelForms, boardLabelsList, boardLists, createUpdateLabel, kanbanDispatch } = useKanbanContext();
  const navigation = useNavigate() as NavigateFunction;
  const [taskData, setTaskData] = useState<KanbanBoardTask | undefined>(undefined);
  const [showDropdown, setShowDropdown] = useState(false);
  const [filterdBoardLabels, setFilterdBoardLabels] = useState<CreateNewLabel[]>([]);
  const [filterCardLabels, setFilterdCardLabels] = useState<CreateNewLabel[]>([]);
  const [addedLabels, setAddedLabels] = useState<number[]>([]);
  const [hoveredId, setHoveredId] = useState<number | null>(null);


  const handleToggle = () => setShowDropdown((prev) => !prev);
  const handleClose = () => setShowDropdown(false);

  useEffect(() => {
    if (task) {
      setTaskData({
        ...task,
        id: task.id
      });
    }
  }, [task]);


  useEffect(() => {
    const filteredItems = boardLabelsList.map((label) => {
      // Find matching color item based on color code
 
      const matchingColorItem = selectColor.find((colorItem) => colorItem.className === label.color);

      if (matchingColorItem) {
        // Include matching color item and title in the result
        return {
          id: label.id,
          colorPalette: matchingColorItem,
          title: label.title,
        };
      }
      return null; // Return null if no matching color item is found
    }).filter((item): item is CreateNewLabel => item !== null);

    setFilterdBoardLabels(filteredItems);

    const filteredCardItems = task?.labels?.map((label) => {
      // Find matching color item based on color code
      const matchingColorItem = selectColor.find((colorItem) => colorItem.className === label.color);

      if (matchingColorItem) {
        // Include matching color item and title in the result
        return {
          id: label.id,
          colorPalette: matchingColorItem,
          title: label.title,
        };
      }
      return null; // Return null if no matching color item is found
    }).filter((item): item is CreateNewLabel => item !== null) || [];
    setFilterdCardLabels(filteredCardItems)

    const addedLabels = task?.labels?.map((item) => item?.id).filter((id): id is number => Boolean(id));
    setAddedLabels(addedLabels || []);


  }, [task, boardLabelsList])

  const handleCheckboxChange = (colorCode: number, action: "add" | "remove") => {
    const clickedItem = boardLabelsList.find((colorItem) => colorItem?.id === colorCode);
    if (clickedItem && action === 'add') {
      addLabelInCard(clickedItem?.id, action)
    } else if (clickedItem && action === 'remove') {
      removeLabelInCard(clickedItem?.id, action)
    }
  }

  const addLabelInCard = async (labelId: number, action: string) => {
    try {
      // setLoading((prev) => ({
      //     ...prev,
      //     assignToUpdate: true
      // }));
      const formData = new FormData();
      if (labelId && action === 'add') {
        formData.append('board_master_label_id', String(labelId) || '');
      }

      const URL = `project/${alias}/boards/${id}/card/${task?.id}/add-label`;

      const response = await apiCall({
        url: URL,
        method: 'POST',
        data: formData,
        headers: {
          'x-access-token': userTkn,
          workspace: workSpaceTkn
        }
      });
      if (response.status === 200) {
        showSuccessToast('Card updated successfully!');
        const updatedItem = response?.data?.data?.boardListCard;

        const updatedBoardLists = boardLists.map((item) => {
          if (Number(item.id) === Number(list.id)) {
            // Update the board list by replacing the card within boardListCards
            return {
              ...item,
              boardListCards: item.boardListCards.map(card =>
                // If the card matches, replace it, otherwise keep it as is
                card.id === updatedItem.id ? updatedItem : card
              )
            };
          }
          return item;
        });
        const { formattedDate, formattedTime } = dateAndTime(updatedItem.end_datetime)
        setCardDateTime({
          cardDate: formattedDate,
          cardTime: formattedTime
        })
        setCardDetails(updatedItem);
        kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });
        if (onHistoryUpdate) onHistoryUpdate();
      } else {
        throw new Error('Failed to update card');
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        handleCutomError(error, signOut, navigation);
      } else {
        console.error('An unexpected error occurred:', error);
        showErrorToast('An unexpected error occurred');
      }
    } finally {
      // setLoading((prev) => ({
      //   ...prev,
      //   assignToUpdate: false
      // }));
      // setIsEditing(false);
    }
  }
  const removeLabelInCard = async (labelId: number, action: string) => {
    try {
      // setLoading((prev) => ({
      //     ...prev,
      //     assignToUpdate: true
      // }));
      const formData = new FormData();
      if (labelId && action === 'remove') {
        formData.append('label_id', String(labelId) || '');
      }

      const URL = `project/${alias}/boards/${id}/card/${task?.id}/remove-label?label_id=${labelId}`

      const response = await apiCall({
        url: URL,
        method: 'POST',
        headers: {
          'x-access-token': userTkn,
          workspace: workSpaceTkn
        }
      });
      if (response.status === 200) {
        showSuccessToast('Card updated successfully!');
        const updatedItem = response?.data?.data?.boardListCard;

        const updatedBoardLists = boardLists.map((item) => {
          if (Number(item.id) === Number(list.id)) {
            // Update the board list by replacing the card within boardListCards
            return {
              ...item,
              boardListCards: item.boardListCards.map(card =>
                // If the card matches, replace it, otherwise keep it as is
                card.id === updatedItem.id ? updatedItem : card
              )
            };
          }
          return item;
        });
        const { formattedDate, formattedTime } = dateAndTime(updatedItem.end_datetime)
        setCardDateTime({
          cardDate: formattedDate,
          cardTime: formattedTime
        })
        setCardDetails(updatedItem);
        kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });
        if (onHistoryUpdate) onHistoryUpdate();
      } else {
        throw new Error('Failed to update card');
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        handleCutomError(error, signOut, navigation);
      } else {
        console.error('An unexpected error occurred:', error);
        showErrorToast('An unexpected error occurred');
      }
    } finally {
      // setLoading((prev) => ({
      //   ...prev,
      //   assignToUpdate: false
      // }));
      // setIsEditing(false);
    }
  }

  const handleUpdateLabel = (item: CreateNewLabel) => {
    kanbanDispatch({
      type: 'TOGGLE_LABEL_FORMS', payload: {
        labels: false,
        labelsList: false,
        createLabel: true
      }
    });
    kanbanDispatch({ type: 'TOGGLE_CREATE_UPDATE_LABEL', payload: true })
    kanbanDispatch({ type: 'CREATE_NEW_LABEL', payload: item });
  }

  return (
    <>
      <div className="attachments-container d-flex align-items-center">
        {filterCardLabels?.map((item, index) => {

          return (
            <OverlayTrigger
              overlay={
                <Tooltip id="overlay-trigger-example">
                  Color: {item?.colorPalette?.name}, Title: {item?.title ? item?.title : 'none'}
                </Tooltip>
              }
            >
              <div key={index} className="">
                <div className="d-flex flex-row gap-1">
                  <div className={`fs-10 fw-bold px-2 rounded-1 customLabel ${item?.colorPalette?.className}`} style={{ backgroundColor: item?.colorPalette?.code }}>
                    {item?.title}
                  </div>
                </div>
              </div>
            </OverlayTrigger>
          )
        })}



        <Dropdown show={showDropdown} onToggle={handleToggle}>
          <Dropdown.Toggle bsPrefix="custom-toggle" variant=""
            className="rounded-1 bg-transparent" id="dropdown-basic"
            onClick={() => {
              kanbanDispatch({
                type: 'TOGGLE_LABEL_FORMS', payload: {
                  labels: false,
                  labelsList: true,
                  createLabel: false
                }
              });
              handleToggle()
            }}
          >
            <Unicon icon={UilPlus} size={20} />
          </Dropdown.Toggle>

          <Dropdown.Menu as="div" className="custom-dropdown-menu pt-1 pb-1" style={{ minWidth: "400px" }}>
            {toggleLabelForms?.labelsList && <div className='d-flex justify-content-between align-items-center'>
              <div></div>
              <div className='text-center fs-8 fw-bold'>Labels</div>
              <Button
                variant=""
                className="btn-icon me-2 rounded-1 bg-transparent mt-2"
                onClick={() => {
                  kanbanDispatch({
                    type: 'TOGGLE_LABEL_FORMS', payload: {
                      labels: false,
                      labelsList: false,
                      createLabel: false
                    }
                  });
                  handleClose();
                }}
              >
                <Unicon icon={UilTimes} size={20} />
              </Button>
            </div>
            }
            {
              toggleLabelForms?.labelsList &&
              <div className='bg-white px-1'>

                {
                  filterdBoardLabels?.map((item, index) => (
                    <div className='d-flex flex-row gap-2 mb-2 align-items-center' key={index}>
                      <div>
                        <Form.Check
                          type='checkbox'
                          checked={addedLabels.includes(item.id)}
                          onChange={() => handleCheckboxChange(item.id, addedLabels.includes(item.id) ? "remove" : "add")}
                        />

                      </div>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="overlay-trigger-example">
                            Color: {item?.colorPalette?.name}, Title: {item?.title ? item?.title : 'none'}
                          </Tooltip>
                        }
                      >
                        <div className={`custom-labels ps-3 pt-2 ${item?.colorPalette?.className}`}
                          style={{
                            // backgroundColor: item?.colorPalette.code 
                            backgroundColor: hoveredId === item.id ? item.colorPalette?.codeHoverd : item.colorPalette?.code,
                          }}
                          onMouseEnter={() => setHoveredId(item.id)}
                          onMouseLeave={() => setHoveredId(null)}
                        >
                          {item?.title}
                        </div>
                      </OverlayTrigger>
                      <div>
                        <Button
                          variant=""
                          className="btn-icon"
                          onClick={() => { handleUpdateLabel(item) }}
                        >
                          <Unicon icon={UilPen} size={16} />
                        </Button>
                      </div>
                    </div>
                  ))
                }
                <div className='d-flex justify-content-center pb-2'>
                  <Button
                    variant="outline-primary"
                    className="px-2"
                    onClick={() => {
                      kanbanDispatch({
                        type: 'TOGGLE_LABEL_FORMS', payload: {
                          labels: false,
                          labelsList: false,
                          createLabel: true
                        }
                      });
                      kanbanDispatch({ type: 'TOGGLE_CREATE_UPDATE_LABEL', payload: false })
                      kanbanDispatch({ type: 'CREATE_NEW_LABEL', payload: initialCreateNewLabelData });
                    }}
                  > Create New Label</Button>
                </div>

              </div>
            }
            {
              toggleLabelForms?.createLabel &&
              <div className='bg-white p-3 pt-0 pb-0'>
                <div className='d-flex justify-content-between align-items-center'>
                  <Button
                    variant=""
                    className="btn-icon me-2 rounded-1 bg-transparent mt-2"
                    onClick={() => {
                      kanbanDispatch({
                        type: 'TOGGLE_LABEL_FORMS', payload: {
                          labels: false,
                          labelsList: true,
                          createLabel: false
                        }
                      });
                    }}
                  >
                    <Unicon icon={UilAngleLeftB} size={20} />
                  </Button>
                  <div> {createUpdateLabel ? "Update Label" : "Create Label"}</div>
                  <Button
                    variant=""
                    className="btn-icon me-2 rounded-1 bg-transparent mt-2"
                    onClick={() => {
                      kanbanDispatch({
                        type: 'TOGGLE_LABEL_FORMS', payload: {
                          labels: false,
                          labelsList: true,
                          createLabel: false
                        }
                      });
                      handleClose();
                    }}
                  >
                    <Unicon icon={UilTimes} size={20} />
                  </Button>
                </div>
                <CreateTags list={list} task={task} setCardDetails={setCardDetails} setCardDateTime={setCardDateTime} />
              </div>


            }



          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default EditableTags;
