import Constants from 'common/Constants';
import { getImageDimensions } from 'common/getImageDimensions';
import regexPatterns from 'common/regexPatterns';
import Button from 'components/base/Button';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';
import { useAuth } from 'providers/AuthContext';
import { useMaster } from 'providers/MasterContext';
import { useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useNavigate, NavigateFunction, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { timeStampToDbDate } from 'common/timeStampToDate';
import apiCall from 'services/api';
import DatePicker2 from 'components/base/DatePicker2';
import PageBreadcrumb, { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import { getAdminPageBreadCrumbs } from 'services/getAdminPageBreadCrumbs';
import { handleCutomError } from 'services/handleCutomError';

interface FormData {
  title: string;
  logo: File | null;
  banner: File | string;
  color: string;
  start_date: Date | string | null;
  end_date: Date | string | null;
  category: string;
  phase: string;
  description: string;
}

interface formError {
  title: string;
  logo: string;
  banner: string;
  color:string;
  start_date: string;
  end_date: string;
  category: string;
  phase: string;
  description: string;
}

type ReactSelectOption = {
  value: number;
  label: string;
};

const AdminCreateProject: React.FC = () => {
  const initialBreadcrumb: PageBreadcrumbItem[] = [];
  const [pageName, setPageName] = useState("AdminCreateProject")
  const [breadCrumb, setBreadCrumb] = useState<PageBreadcrumbItem[]>(initialBreadcrumb);
  const { workspace } = useParams();
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const { tags, projCategory, phases } = useMaster();
  const [tagsData, setTagsData] = useState<ReactSelectOption[] | undefined>();
  const [validated, setValidated] = useState<boolean>(false);
  const navigation = useNavigate() as NavigateFunction; // Explicitly define the type 

  useSettingsMountEffect({
    showSettingPanelButton: false
  });

  const [formData, setFormData] = useState<FormData>({
    title: '',
    logo: null, // Initialize with null
    start_date: '',
    end_date: '',
    category: '',
    phase: '',
    description: '',
    banner: '',
    color: ''
  });
  const [error, setError] = useState<formError>({
    title: '',
    logo: '',
    start_date: '',
    end_date: '',
    category: '',
    phase: '',
    description: '',
    banner: '',
    color: ''
  });

  const [submitError, setSubmitError] = useState<any>("")


  //update Breadcrumb
  useEffect(() => {
    getAdminPageBreadCrumbs({ pageName, workspace, setBreadCrumb });
  }, [workspace, pageName, workspace]);



  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | { target: { name: string; value: string; } }) => {
    // Handle ChangeEvent case
    const { name, value } = 'target' in e ? e.target : e;
    let errorMessage = "";


    if (name === 'logo') {
      const fileInput = e.target as HTMLInputElement;
      const selectedFile = fileInput.files && fileInput.files[0]; // Check if files are present

      if (selectedFile) {
        const fileSizeKB = selectedFile.size / 1024; // Convert to KB
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();

        if (fileSizeKB > 15) {
          errorMessage = "File size exceeds the maximum limit of 15KB.";
        } else if (!allowedTypes.includes(selectedFile.type) && !allowedTypes.includes(`image/${fileExtension}`)) {
          errorMessage = "Only JPEG, JPG, or PNG files are allowed.";
        } else {
          try {
            const { width, height } = await getImageDimensions(selectedFile);

            if (width < 25 || height < 25 || width > 40 || height > 40) {
              // errorMessage = "Image dimensions should be between 25x25 and 50x50 pixels.";
              errorMessage = "Image dimensions should be between 25x25 and 40x40 pixels.";
            } else {
              setFormData({
                ...formData,
                [name]: selectedFile
              });
            }
          } catch (error) {
            errorMessage = "Error getting image dimensions.";
          }
        }
      } else {
        setFormData({
          ...formData,
          [name]: null
        });
      }

      setError({
        ...error,
        [name]: errorMessage,
      });
      return; // Exit early
    }
    if (name === 'banner') {

      const fileInput = e.target as HTMLInputElement;
      const selectedFile = fileInput.files && fileInput.files[0]; // Check if files are present

      if (selectedFile) {
        const fileSizeKB = selectedFile.size / 1024; // Convert to KB
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();

        // if (fileSizeKB > 15) {
        //   errorMessage = "File size exceeds the maximum limit of 15KB.";
        // } else 
        if (!allowedTypes.includes(selectedFile.type) && !allowedTypes.includes(`image/${fileExtension}`)) {
          errorMessage = "Only JPEG, JPG, or PNG files are allowed.";
        } else {
          try {
            const { width, height } = await getImageDimensions(selectedFile);

            if (width < 200 || height < 200 || width > 2000 || height > 2000) {
              // errorMessage = "Image dimensions should be between 25x25 and 50x50 pixels.";
              errorMessage = "Image dimensions should be between 200x200 and 2000x2000 pixels.";
            } else {
              setFormData({
                ...formData,
                [name]: selectedFile
              });
            }
          } catch (error) {
            errorMessage = "Error getting image dimensions.";
          }
        }
      } else {
        setFormData({
          ...formData,
          [name]: ''
        });
      }

      setError({
        ...error,
        [name]: errorMessage,
      });
      return;
    }
    // Validation for email
    if (name === "title") {
      if (!value.trim()) {
        errorMessage = "Title is required.";
      } else if (!regexPatterns.titleRegex.test(value)) {
        errorMessage = "Title should contain at least 3 and max 75  alphabetic characters.";
      }
    }

    // Validation for start date
    if (name === "start_date") {
      if (formData?.end_date && new Date(value) > new Date(formData.end_date)) {
        errorMessage = "Start date should be less than or equal to end date.";
      }
    }
    // Validation for end date
    if (name === "end_date") {
      if (formData.start_date && value !== null && new Date(value) < new Date(formData.start_date)) {
        errorMessage = "End date should be greater than or equal to start date.";
      }
    }
    // Handle description input
    if (name === "description" && value.trim() !== "") {
      if (value.trim().length > 255) {
        errorMessage = "Description should not exceed 255 characters.";
      }
    }

    // Handle description input
    if (name === "donar" && value.trim() !== "") {
      if (!regexPatterns.titleAlphaNumeric.test(value)) {
        errorMessage = "Donar should be 3 to 75 alphanumeric (a-z, A-Z, 0-9) characters.";
      }
    }

    if (name === "end_date" || name === "start_date") {
      const date = new Date(value);
      let newValue = timeStampToDbDate(date)

      setFormData({
        ...formData,
        [name]: newValue,
      });

    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

    }
    setError({
      ...error,
      [name]: errorMessage,
    });

  };




  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission behavior
    e.stopPropagation();
    // Add the following line to set the form validation state
    setValidated(true);
    if (           // Check if there are any errors before making the API call
      error.title ||
      error.start_date ||
      error.end_date ||
      error.category ||
      error.phase

    ) {
      return;
    }


    if (
      !formData.title.trim() ||
      (
        formData.start_date &&
        formData.end_date &&
        new Date(formData.end_date) < new Date(formData.start_date)
      )
    ) {
      setError({
        ...error,
        title: !formData.title.trim() ? "Title is required." : "",
        end_date: formData.start_date && formData.end_date && new Date(formData.end_date) < new Date(formData.start_date) ? "End date should be greater than or equal to start date." : ""

      });
      return;
    }

    const formDataForSubmission = new FormData();
    formDataForSubmission.append('title', formData?.title);
    formDataForSubmission.append('description', formData.description);
    formDataForSubmission.append('category', formData.category);
    formDataForSubmission.append('phase', formData.phase);
    formDataForSubmission.append('start_date', String(formData.start_date));
    formDataForSubmission.append('end_date', String(formData.end_date));
    if (formData.logo) {
      formDataForSubmission.append('logo', formData.logo);
    }
    if (formData.banner) {
      formDataForSubmission.append('banner', formData.banner);
    }
    if (formData.color) {
      formDataForSubmission.append('color', formData.color);
    }
    try {

      const response = await apiCall({
        url: `admin/project/create`,
        method: 'POST',
        data: formDataForSubmission,
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });
      if (response.status === 200) {
        if (response?.data?.errors) {
          const errors = response?.data.errors;

          // Dynamically set errors based on the response
          Object.keys(errors).forEach((key) => {
            const errorMessages = errors[key];
            const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
            // Set the error state for the corresponding field
            setError((prevData) => ({
              ...prevData,
              [key]: firstErrorMessage,
            }));
          });
        } else {
          toast.success(response.data.message, {
            position: "top-center"
          });
          navigation(`/${workspace}/admin/project/list`)
        }



      } else {
        console.error("error message:");
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        toast.error('An unexpected error occurred', {
          position: "top-center"
        });
      }
    }
  };

  useEffect(() => {
    const transformedData = tags?.map(item => ({
      value: item.id,
      label: item.name
    }));
    setTagsData(transformedData)
  }, [tags])


  return (
    <div>
      <PageBreadcrumb items={breadCrumb} navigation={navigation} />
      <h2 className="mb-4">{Constants.create_project}</h2>
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <Row>
          <Col xs={12} xl={9}>
            <Row className="g-3 mb-6">
              <Col sm={6} md={12}>
                <Form.Group className=" text-start">
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label={`${Constants.project_title} *`}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Project title"
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      isInvalid={!!error.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error?.title ? error?.title : " Please enter the project title."}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={6} md={6}>
                <Form.Group className=" text-start">
                  <DatePicker2
                    value={formData.start_date ? new Date(formData.start_date).toLocaleDateString('en-GB') : ''}
                    onChange={(date: Date[]) => {
                      handleInputChange({
                        target: {
                          name: 'start_date',
                          value: date[0].toString()
                        }
                      });
                    }}
                    options={{
                      dateFormat: "d/m/Y",
                      minDate: new Date().setHours(0, 0, 0, 0)
                    }}
                    isError={error?.start_date}
                    id="start_date"
                    name="start_date"
                    render={(_, ref) => {
                      const isInvalid = !!error.start_date;
                      return (
                        <Form.Floating>
                          <Form.Control
                            type="text"
                            ref={ref}
                            placeholder="d-m-y"
                            readOnly
                            isInvalid={isInvalid}
                          />
                          <label htmlFor="startDate" className="ps-6">
                            {`${Constants.start_date}`}
                          </label>
                          <Form.Control.Feedback type="invalid">
                            {error?.start_date}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      );
                    }}
                  />

                </Form.Group>
              </Col>
              <Col sm={6} md={6}>
                <Form.Group className=" text-start">
                  <DatePicker2
                    value={formData.end_date ? new Date(formData.end_date).toLocaleDateString('en-IN') : ''}
                    onChange={(date: Date[]) => {
                      handleInputChange({
                        target: {
                          name: 'end_date',
                          value: date[0].toString()
                        }
                      });
                    }}
                    options={{
                      dateFormat: "d/m/Y",
                      minDate: new Date().setHours(0, 0, 0, 0)
                    }}
                    isError={error?.end_date}
                    id="end_date"
                    name="end_date"
                    render={(_, ref) => {
                      const isInvalid = !!error.end_date;
                      return (
                        <Form.Floating>
                          <Form.Control
                            type="text"
                            ref={ref}
                            placeholder="d-m-y"
                            readOnly
                            isInvalid={isInvalid}
                          />
                          <label htmlFor="startDate" className="ps-6">
                            {`${Constants.end_date}`}
                          </label>
                          <Form.Control.Feedback type="invalid">
                            {error?.end_date}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      );
                    }}
                  />

                </Form.Group>
              </Col>
              <Col sm={6} md={6}>
                <Form.Group className=" text-start">
                  <FloatingLabel
                    controlId="floatingSelectTask"
                    label={`${Constants.category}`}
                  >
                    <Form.Select value={formData.category} name='category'
                      onChange={handleInputChange}
                      isInvalid={!!error.category}
                    >
                      <option value={""}>Select</option>
                      {projCategory && projCategory?.map((item, index: number) => {
                        return (
                          <option value={item.id} key={item.id}>{item?.title}</option>
                        )
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {error?.category}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={6} md={6}>
                <Form.Group className=" text-start">
                  <FloatingLabel
                    controlId="floatingSelectPrivacy"
                    label={`${Constants.phase}`}
                  >
                    <Form.Select value={formData.phase} name='phase'
                      onChange={handleInputChange}
                      isInvalid={!!error.phase}
                    >
                      <option value={""}>Select phase</option>
                      {phases && phases?.map((item, index: number) => {
                        return (
                          <option value={item.id} key={item.id}>{item?.title}</option>
                        )
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {error?.phase ? error?.phase : "Please select the phase"}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col sm={6} md={4}>
                <Form.Group controlId="imageUpload" className='input-group custom-file-button'>
                  <label className="input-group-text" htmlFor="inputGroupFile">Upload Icon</label>
                  <Form.Control type="file" size='lg' name='logo' onChange={handleInputChange} isInvalid={!!error.logo} />
                  <Form.Control.Feedback type="invalid">
                    {error?.logo ? error?.logo : " Please enter the start date."}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} md={4}>
                <Form.Group controlId="imageUpload" className='input-group custom-file-button'>
                  <label className="input-group-text" htmlFor="inputGroupFile">Upload Banner</label>
                  <Form.Control type="file" size='lg' name='banner' onChange={handleInputChange} isInvalid={!!error.banner} />
                  <Form.Control.Feedback type="invalid">
                    {error?.banner ? error?.banner : " Please enter the start date."}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} md={4}>
       
                    <div className="d-flex align-items-center gap-1">
                      <input
                        type="color"
                        className="form-control w-25"
                        name="color"
                        value={formData?.color || '#000000'}
                        onChange={handleInputChange}
                      />
                    </div>
                  
              </Col>
              <Col xs={12} className="">
                <FloatingLabel
                  controlId="floatingProjectOverview"
                  label={`Discription`}
                >
                  <Form.Control
                    type="text"
                    placeholder="Leave a comment here"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    isInvalid={!!error.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {error?.description}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col xs={12} className="gy-6">
                {submitError && <div className="invalid-feedback" style={{ display: "block" }}>{submitError}</div>}
                <div className="d-flex justify-content-end gap-3">
                  <Button variant="phoenix-primary" className="px-5" onClick={() => { navigation(`/${workspace}/admin/project/list`); }}>
                    Cancel
                  </Button>
                  <Button variant="outline-primary" type="submit" className="px-5 px-sm-15">
                    Create Project
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <ToastContainer />
    </div>
  );
};

export default AdminCreateProject;
