import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosError } from "axios";
import classNames from "classnames";
import { dateAndTime } from "common/timeStampToDate";
import Avatar from "components/base/Avatar";
import Button from "components/base/Button";
import ToggleViewAvtar from "components/common/ToggleViewAvtar";
import { useAuth } from "providers/AuthContext";
import { useKanbanContext } from "providers/KanbanProvider";
import { useToast } from "providers/ToastProvider";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import apiCall from "services/api";
import { handleCutomError } from "services/handleCutomError";

interface TaskHistoryProps {
    taskId: number;
}
export const TaskHistory = ({ taskId }: TaskHistoryProps) => {
    const { taskHistory, histotySummary, pageIndexHistory, historyLoad, kanbanDispatch } = useKanbanContext();
    const { alias, id } = useParams();
    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const { showErrorToast, showSuccessToast } = useToast();
    const navigation = useNavigate() as NavigateFunction;
    // const [loading, setLoading] = useState<boolean>(false)
    
    if(!taskId){
        return
    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                kanbanDispatch({ type: 'TASK_HOSTORY_LOAD', payload: true });
                if (pageIndexHistory === 1) {
                    kanbanDispatch({ type: 'TASK_HISTORY_DATA', payload: [] });
                }
                const response = await apiCall({
                    url: `project/${alias}/boards/${id}/card/${taskId}/card-history?page=${pageIndexHistory}`,  // updated
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                        workspace: workSpaceTkn
                    }
                });

                if (response?.status === 200) {
                    // Calculate serial numbers for the projects on the current page
                    const historyData = response?.data?.data?.boardListCardHistory;
                    const summary = response?.data?.data?.summary;
                    const updatedTaskHistory = [...taskHistory, ...historyData].filter(
                        (value, index, self) =>
                            self.findIndex(
                                (v) =>
                                    v.narration === value.narration &&
                                    v.updatedAt === value.updatedAt,
                            ) === index,
                    );

                    kanbanDispatch({
                        type: 'TASK_HISTORY_DATA',
                        payload: updatedTaskHistory,
                    });
                    kanbanDispatch({
                        type: 'TASK_HISTORY_SUMMARY', payload: {
                            total: summary?.total,
                            page: summary?.page,
                            pageSize: summary?.pageSize,
                            total_page: summary?.total_page,
                        }
                    });
                    kanbanDispatch({ type: 'TASK_HOSTORY_LOAD', payload: false });
                } else {
                    console.error('error messag');
                }
            } catch (error: any) {
                if (error instanceof AxiosError) {
                    handleCutomError(error, signOut, navigation);
                } else {
                    console.error('An unexpected error occurred:', error);
                    showErrorToast(error);
                }
            } finally {
                kanbanDispatch({ type: 'TASK_HOSTORY_LOAD', payload: false });
            }
        };
     
            fetchData();
        
    }, [ pageIndexHistory])

    const handleLoadMore = () => {
        if (pageIndexHistory <= histotySummary?.total_page) {
          // Set loading state for "Load More" button
          const newPage = pageIndexHistory + 1;
          // setPageIndex(prevPage => prevPage + 1);
          kanbanDispatch({
            type: 'TASK_HISTORY_PAGE_INDEX',
            payload: newPage,
        });
        }
      };

    return (
        <>
            <div className="d-flex flex-column gap-3 bg-color-card p-0"
            //   ref={scrollContainerRef}
            //   style={{ overflowY: 'auto', maxHeight: maxHeight ? `${maxHeight}px` : 'auto' }}
            >
                {taskHistory?.map((activity, index) => {
                    const { formattedDate, formattedTime } = dateAndTime(activity.updatedAt)
                    return (
                        <div
                            className={classNames(
                                'd-flex gap-2 pb-3 border-translucent ',
                                {
                                    'border-bottom':
                                        index !== taskHistory?.length - 1
                                }
                            )}
                            key={index}
                        >
                            <ToggleViewAvtar
                                tooltip={`${activity?.updator?.first_name} ${activity?.updator?.last_name}`}
                            >
                                <Avatar size="m" variant="name" key={activity?.updator?.short_name}>{activity?.updator?.short_name}</Avatar>
                            </ToggleViewAvtar>
                            <div className="activity-item">
                                <p
                                    className="mb-1 fs-8 fw-normal"
                                    dangerouslySetInnerHTML={{
                                        __html: `${activity?.action_type + ','} ${activity?.narration}`
                                    }}
                                />
                                <div className="d-flex align-items-center gap-2  fs-9">
                                    <p className="mb-0 fs-8 fw-bold">
                                        <FontAwesomeIcon
                                            icon={faClock}
                                            className="me-1"
                                        />
                                        {formattedTime}
                                    </p>
                                    <p className="mb-0 fs-10">{formattedDate}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
                )}
            </div>
            <>
                {Number(histotySummary.total_page) > 1 && (
                    <div className="custom-btn-size d-flex justify-content-center mt-3">
                        {Number(histotySummary?.page) === Number(histotySummary?.total_page) ? (
                            <div className="d-flex justify-content-center"></div>
                        ) : (
                            <Button
                                onClick={handleLoadMore}
                                variant="outline-primary"
                            >
                                {historyLoad ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    ""
                                )}
                                <span className="ml-2 fs-8">{"Load More"}</span>
                            </Button>
                        )}
                    </div>
                )}
            </>
        </>
    )

};