import React, {
  CSSProperties,
  Dispatch,
  Fragment,
  SetStateAction
} from 'react';
import {
  UilArrowFromRight,
  UilLeftArrowToLeft
} from '@iconscout/react-unicons';
import Button from 'components/base/Button';
import { KanbanBoardItem } from 'data/kanban';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useKanbanContext } from 'providers/KanbanProvider';
import { LIST_MODAL_TYPE, LIST_UPDATE_DATA, TOGGLE_ADD_LIST_MODAL } from 'reducers/KanbanReducer';

interface KanbanListHeaderProps {
  list: KanbanBoardItem;
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}

const kanbanHeaderActions = [
  {
    id: 1,
    label: 'Edit',
    actionType: 'edit'
  }
];

const KanbanListHeader = ({
  list,
  collapsed,
  setCollapsed
}: KanbanListHeaderProps) => {
  const { kanbanDispatch } = useKanbanContext();
  return (
    <div className="kanban-column-header px-4 hover-actions-trigger">
      <div
        className={`d-flex align-items-center py-3`}
        style={
          {
            '--phoenix-border-color': list.borderColor
          } as CSSProperties
        }
      >
        <h5 className="mb-0 kanban-column-title fs-8 fw-bold">
          {list.title}
          <span className="kanban-title-badge">
            {list.boardListCards.length} 
          </span>
        </h5>
        <Dropdown autoClose="outside">
          <Dropdown.Toggle
            variant=""
            size="sm"
            className="hover-actions dropdown-caret-none kanban-header-dropdown-btn"
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="py-2 border-translucent"
            style={{ width: '15rem' }}
          >
            {kanbanHeaderActions.map(action => (
              <Fragment key={action.id}>
                <Dropdown.Item
                  href="#!"
                  key={action.label}
                  className="d-flex flex-between-center"
                  onClick={() => {
                            kanbanDispatch({
                              type: TOGGLE_ADD_LIST_MODAL,
                              payload: true
                            });
                            kanbanDispatch({
                              type: LIST_MODAL_TYPE,
                              payload: true
                            });
                            kanbanDispatch({
                              type: LIST_UPDATE_DATA,
                              payload: {
                                id: list?.id,
                                title: list?.title
                              }
                            });
                          }}
                >
                  {action.label}
                </Dropdown.Item>
              </Fragment>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Button
          className="ms-auto kanban-collapse-icon p-0"
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? (
            <UilArrowFromRight size={16} />
          ) : (
            <UilLeftArrowToLeft size={16} />
          )}
        </Button>
      </div>
    </div>
  );
};

export default KanbanListHeader;
