import { faTimes, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "common/Constants";
import regexPatterns from "common/regexPatterns";
import Button from "components/base/Button";
import { useAuth } from "providers/AuthContext";
import { useMaster } from "providers/MasterContext";
import { useState } from "react";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import apiCall from "services/api";
import { handleCutomError } from "services/handleCutomError";

interface AddPriorityModalProps {
  show: boolean;
  handleClose: () => void;
  setAddStatus: React.Dispatch<React.SetStateAction<boolean>>; // Add this line
  toast: any;
  navigation:any;
}

const AddPriorityModal = ({ show, handleClose, setAddStatus, toast, navigation }: AddPriorityModalProps) => {
  const {refetchData} = useMaster();
  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState(false)
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const [error, setError] = useState({
    title: "",
    color:""
  });
  const [formData, setFormData] = useState({
    title: "",
    color:"",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    let errorMessage = "";

    // Validation for first name
    if (name === "title") {
      if (!value.trim()) {
        errorMessage = "Title is required.";
      } else if (!regexPatterns.titleRegex.test(value)) {
        errorMessage = "Title should contain at least 3 alphabetic characters with space.";
      }
    }

   // Validation for color
   if (name === "color") {
    if (!value.trim()) {
        errorMessage = "Color is required.";
    } else if (!/^#[a-zA-Z0-9]{3}$|^#[a-zA-Z0-9]{6}$/.test(value)) {
        errorMessage = "Color should start with '#' and contain only alphanumeric characters. It should have a length of 4 or 7 characters.";
    }
}
    setFormData({
      ...formData,
      [name]: value,
    });


    setError((prevData) => ({
      ...prevData,
      [name]: errorMessage,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission behavior
    e.stopPropagation();
    // Add the following line to set the form validation state
    setValidated(true);

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      return;
    }

    // Check if there are any errors before making the API call
    if (
      error.title ||
      error.color
    ) {
      return;
    }
    if ( !formData.title.trim() ||
      !formData.color.trim()
    ){
      setError({
          ...error,
          title: !formData.title.trim() ? "Title is required" : "",
          color: !formData.color.trim() ? "Color code is required" : "",
        });
        return;
      }
    // Perform any necessary logic or API calls here
   
    const formDataSubmit = new FormData();
    formDataSubmit.append("title", formData?.title);
    formDataSubmit.append("color", formData?.color);
    try {
      setLoading(true)
      const response = await apiCall({
        url: `admin/master/priority/create`,
        method: 'POST',
        data: formDataSubmit,
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn,
        },
      });
  

      if (response.status === 200) {
    
        // navigation("/")
        // Call the refetchData function from the context to fetch the data again
        refetchData()
        setAddStatus(true);
        handleClose();
      } else {
        console.error("Api Faild");
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
           // Display a generic error message to the user
           toast.error('An unexpected error occurred', {
            position: "top-center"
        });
      }
    } finally {
      setLoading(false); // Set loading to false after the API call is completed
    }
  };

  return (
    <Modal show={show} onHide={handleClose} className="p-0" backdrop="static" centered>
      <Modal.Header className="p-4 d-flex gap-2 border-0">
        <h5 className="mb-0 text-body-emphasis fw-semibold flex-1">Add New Priority</h5>
        <Button className="p-0 ms-auto" onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} className="fs-7" />
          </Button>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body className="pt-4 pb-2 px-4">
          <div className="mb-3">
            <Form.Group className="mb-3 text-start">
              <FloatingLabel
                controlId="floatingInputGrid"
                label={`${Constants.new_priority} *`}
              >
                <Form.Control
                  required
                  type="text"
                  placeholder="Title"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  isInvalid={!!error.title}
                />
                <Form.Control.Feedback type="invalid">
                  {error?.title ? error?.title : "Please enter the Priority"}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3 text-start">
              <FloatingLabel
                controlId="floatingInputGrid"
                label={`${Constants.color} *`}
              >
                <Form.Control
                  required
                  type="text"
                  placeholder="Color"
                  name="color"
                  value={formData.color}
                  onChange={handleInputChange}
                  isInvalid={!!error.color}
                />
                <Form.Control.Feedback type="invalid">
                  {error?.color ? error?.color : "Please enter the Color Code"}
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                  Color code in HEX Ex: #6E7891.
                </Form.Text>
              </FloatingLabel>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center align-items-center px-4 pb-4 border-0 pt-3">

          <Button
            variant="outline-primary"
            size="sm"
            className="px-7 fs-9  my-0 btn"
            type="submit" // Add this line
            disabled={loading}
          >
            save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddPriorityModal;
