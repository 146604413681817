import { UilCube } from '@iconscout/react-unicons';
import Unicon from 'components/base/Unicon';
import './sarwar.css';
import { Col, Container, Row } from 'react-bootstrap';
import Calendar from '../calendar/Calendar';

const events = [
  {
    time: '8:30–9:00 PM',
    title: 'Create Notification page'
  },
  {
    time: '2:30 PM',
    title: 'Spider review'
  },
  {
    time: '8:30–4:00 PM',
    title: 'Spider project list change'
  },
  {
    time: '8:30–9:00 AM',
    title: 'Spider poroject list change'
  },
  {
    time: '8:30–9:00 AM'
  }
];

const Calender = () => {
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
  return (
    <>
      <div className="sideSection">
        <div className="d-flex flex-wrap mb-4 justify-content-between">
          <h2 className="mb-0">
            <Unicon icon={UilCube} size={28} />
            <span className="me-3">{' Calendar'}</span>
          </h2>
        </div>
        <Container fluid>
          <Row className="p-0">
            <Col xl={3}>
              <div className="bgBodr h-100">
                <div>
                  <div className="fitterText">
                    <h3 className="mb-3">Fitters:</h3>
                  </div>
                  <div>
                    <div className="checkBox d-flex align-items-center gap-2 mb-2">
                      <input type="checkbox" className="colorChange" />
                      <span className="bg-ic active">Meetings</span>
                    </div>
                    <div className="checkBox d-flex align-items-center gap-2 mb-2">
                      <input type="checkbox" className="colorChange" />
                      <span className="bg-ic">Action Points</span>
                    </div>
                    <div className="checkBox d-flex align-items-center gap-2 mb-2">
                      <input type="checkbox" className="colorChange" />
                      <span className="bg-ic">Story</span>
                    </div>
                    <div className="checkBox d-flex align-items-center gap-2 mb-2">
                      <input type="checkbox" className="colorChange" />
                      <span className="bg-ic">Others</span>
                    </div>
                  </div>
                </div>
                <div className="events-container">
                  <div className="today-label text-center mb-5">
                    <a href="#" className="today-text text-decoration-none">
                      TODAY
                    </a>
                    <a href="#" className="today-date text-decoration-none">
                      {formattedDate}
                    </a>
                  </div>
                  {events.map((event, index) => (
                    <div
                      className="event-item d-flex mb-4 flex-column"
                      key={index}
                    >
                      <div className="event-bullet d-flex align-items-center gap-2">
                        <div className="bullet"></div>
                        <span className="event-time">{event.time}</span>
                      </div>
                      <div className="event-title">{event.title}</div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col xl={9}>
              <Calendar />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Calender;
