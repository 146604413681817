import { AxiosError } from 'axios';
import { dateAndTime } from 'common/timeStampToDate';
import { KanbanBoardItem, KanbanBoardTask } from 'data/kanban';
import { useAuth } from 'providers/AuthContext';
import { useKanbanContext } from 'providers/KanbanProvider';
import { useToast } from 'providers/ToastProvider';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Form, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import apiCall from 'services/api';
import { handleCutomError } from 'services/handleCutomError';
import { CardDateTime } from '../KanbanTaskDetailsModal';
import Button from 'components/base/Button';
import Unicon from 'components/base/Unicon';
import { UilPen } from '@iconscout/react-unicons';

interface EditableTitleProps {
    task: KanbanBoardTask;
    list: KanbanBoardItem;
    setCardDetails: React.Dispatch<React.SetStateAction<KanbanBoardTask | undefined>>;
    setCardDateTime: React.Dispatch<React.SetStateAction<CardDateTime>>;
    onHistoryUpdate?: () => void;
}

export interface Loading {
    titleUpdate: boolean

}

const EditableTitle: React.FC<EditableTitleProps> = ({ task, list, setCardDetails, setCardDateTime, onHistoryUpdate }) => {
    const { alias, id } = useParams();
    const { boardLists, kanbanDispatch } = useKanbanContext();

    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const { showErrorToast, showSuccessToast } = useToast();
    const navigation = useNavigate();
    const [validated, setValidated] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [taskData, setTaskData] = useState<KanbanBoardTask | undefined>(undefined);
    const [loading, setLoading] = useState<Loading>({
        titleUpdate: false
    });
    const [originalTitle, setOriginalTitle] = useState<string>('');
    const [error, setError] = useState({
        title: '',
    })
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {

        if (task) {
            setTaskData({
                ...task,
                id: task.id
            });
            setOriginalTitle(task.title);
        }
    }, [task]);

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus(); // Set focus to input field
        }
    }, [isEditing]);

    const handleTitleClick = () => {
        setIsEditing(true); //on Click Enable editing mode
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const inputElement = e.target as HTMLInputElement;
        inputElement.form?.requestSubmit();
    };
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const inputElement = e.target as HTMLInputElement;
            inputElement.form?.requestSubmit();
        }
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value } = e.target;

        setTaskData((prevData) => {
            if (prevData) {
                return {
                    ...prevData,
                    [name]: value,
                };
            }
            return prevData;
        });
    };
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!taskData?.title || taskData.title.trim() === '') {
            setError((prevError) => ({
                ...prevError,
                title: 'Title is required',
            }));
            return;
        } else {
            setError((prevError) => ({
                ...prevError,
                title: '',
            }));
        }

        if (taskData?.title === originalTitle) {
            setIsEditing(false);
            return; // No change in title, don't submit
        }
        try {
            setLoading((prev) => ({
                ...prev,
                titleUpdate: true
            }));
            const formData = new FormData();
            formData.append('title', taskData?.title);
            const response = await apiCall({
                url: `project/${alias}/boards/${id}/card/${taskData?.id}/update`,
                method: 'POST',
                data: formData,
                headers: {
                    'x-access-token': userTkn,
                    workspace: workSpaceTkn
                }
            });
            if (response.status === 200) {
                showSuccessToast('Card updated successfully!');
                const updatedItem = response?.data?.data?.boardListCard;
                const updatedBoardLists = boardLists.map((item) => {
                    if (Number(item.id) === Number(list.id)) {
                        // Update the board list by replacing the card within boardListCards
                        return {
                            ...item,
                            boardListCards: item.boardListCards.map(card =>
                                // If the card matches, replace it, otherwise keep it as is
                                card.id === updatedItem.id ? updatedItem : card
                            )
                        };
                    }
                    return item;
                });
                const { formattedDate, formattedTime } = dateAndTime(updatedItem.end_datetime)
                setCardDateTime({
                    cardDate: formattedDate,
                    cardTime: formattedTime
                })
                setCardDetails(updatedItem);
                setOriginalTitle(updatedItem.title);
                kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });
                if (onHistoryUpdate) onHistoryUpdate();
            } else {
                throw new Error('Failed to update card');
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An unexpected error occurred:', error);
                showErrorToast('An unexpected error occurred');
            }
        } finally {
            setLoading((prev) => ({
                ...prev,
                titleUpdate: false
            }));
            setIsEditing(false);
        }
    }


    return (
        <>

            {isEditing ? (
                <div className='d-flex gap-1'>
                    <div className='w-100'>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Control
                                ref={inputRef}
                                type="text"
                                placeholder="title"
                                name="title"
                                value={taskData?.title || ''}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyPress}
                                onBlur={handleBlur}
                                isInvalid={!!error?.title}
                                className='editable-field'
                            />
                            <Form.Control.Feedback type="invalid">
                                {error?.title}
                            </Form.Control.Feedback>
                        </Form>
                    </div>
                    {loading?.titleUpdate && <Spinner animation="border" variant="secondary" size="sm" />}
                </div>

            ) : (
                <div className="d-flex gap-2 fs-9 mb-0 text-body-emphasis lh-sm w-100 editable-field">
                    <h4
                        className="mb-0 text-body-emphasis lh-sm"
                        onClick={handleTitleClick}
                        style={{ cursor: 'pointer' }} // Indicate clickable text
                    >
                        {taskData?.title}
                    </h4>
                    <div >
                        <Button
                            variant=""
                            className="btn-icon"
                            onClick={handleTitleClick}
                        >
                            <Unicon icon={UilPen} size={16} />
                        </Button>
                    </div>
                </div>

            )}

        </>
    );
};

export default EditableTitle;
