import { SummaryProps } from 'data/kanban';
import { ProjectState } from 'providers/ProjectProvider';
import { DraggableLocation } from 'react-beautiful-dnd';
import { Project, ProjectCategoryType, ProjectPriorityType, UserPermission } from 'types/project';

//Action types
export const PROJECT_CORE_DATA = 'PROJECT_CORE_DATA';
export const PROJECT_ALL_USERS = 'PROJECT_ALL_USERS';
export const PROJECT_PRESENT_USER_PERMISSION_DATA = 'PROJECT_PRESENT_USER_PERMISSION_DATA';
export const PROJECT_PAST_USER_PERMISSION_DATA = 'PROJECT_PAST_USER_PERMISSION_DATA';
export const TOGGLE_PROJECT_ADD_USER_MODAL = 'TOGGLE_PROJECT_ADD_USER_MODAL';
export const PRESENT_USER_SUMMARY = 'PRESENT_USER_SUMMARY';
export const PRESENT_USER_INDEX_VALUE_FIRST = 'PRESENT_USER_INDEX_VALUE_FIRST';
export const PRESENT_USER_INDEX_VALUE_LAST = 'PRESENT_USER_INDEX_VALUE_LAST';
export const PRESENT_USER_PAGE_INDEX = 'PRESENT_USER_PAGE_INDEX';
export const PAST_USER_SUMMARY = 'PAST_USER_SUMMARY';
export const PAST_USER_INDEX_VALUE_FIRST = 'PAST_USER_INDEX_VALUE_FIRST';
export const PAST_USER_INDEX_VALUE_LAST = 'PAST_USER_INDEX_VALUE_LAST';
export const PAST_USER_PAGE_INDEX = 'PAST_USER_PAGE_INDEX';
export const USER_PERMISSION_DATA = 'USER_PERMISSION_DATA';
export const TOGGLE_USER_PERMISSION_MODAL = 'TOGGLE_USER_PERMISSION_MODAL';
export const TOGGLE_USER_DELETE_MODAL = 'TOGGLE_USER_DELETE_MODAL';
export const TOGGLE_ACTIVE_TAB = 'TOGGLE_ACTIVE_TAB';

//Project Priority
export const TOGGLE_ADD_PRIORITY_MODAL = "TOGGLE_ADD_PRIORITY_MODAL";
export const PROJECT_PRIORITY_DATA = "PROJECT_PRIORITY_DATA";
export const PRIORITY_SUMMARY = 'PRIORITY_SUMMARY';
export const PRIORITY_INDEX_VALUE_FIRST = 'PRIORITY_INDEX_VALUE_FIRST';
export const PRIORITY_INDEX_VALUE_LAST = 'PRIORITY_INDEX_VALUE_LAST';
export const PRIORITY_PAGE_INDEX = 'PRIORITY_PAGE_INDEX';
export const PRIORITY_EDIT_MODE = 'PRIORITY_EDIT_MODE';
export const UPDATE_PRIORITY_DATA = 'UPDATE_PRIORITY_DATA';
export const TOGGLE_PRIORITY_DELETE_MODAL = "TOGGLE_PRIORITY_DELETE_MODAL";

//Project Priority
export const TOGGLE_ADD_CATEGORY_MODAL = "TOGGLE_ADD_CATEGORY_MODAL";
export const PROJECT_CATEGORY_DATA = "PROJECT_CATEGORY_DATA";
export const CATEGORY_SUMMARY = 'CATEGORY_SUMMARY';
export const CATEGORY_INDEX_VALUE_FIRST = 'CATEGORY_INDEX_VALUE_FIRST';
export const CATEGORY_INDEX_VALUE_LAST = 'CATEGORY_INDEX_VALUE_LAST';
export const CATEGORY_PAGE_INDEX = 'CATEGORY_PAGE_INDEX';
export const CATEGORY_EDIT_MODE = 'CATEGORY_EDIT_MODE';
export const TOGGLE_CATEGORY_SEQUENCE_MODAL = 'TOGGLE_CATEGORY_SEQUENCE_MODAL';
export const UPDATE_CATEGORY_DATA = 'UPDATE_CATEGORY_DATA';
export const TOGGLE_CATEGORY_DELETE_MODAL = "TOGGLE_CATEGORY_DELETE_MODAL";
export const TOGGLE_PRIORITY_SEQUENCE_MODAL = "TOGGLE_PRIORITY_SEQUENCE_MODAL";


//Action ts type
export type ACTIONTYPE =
    | {
        type: typeof PROJECT_CORE_DATA;
        payload?: Project;
    }
    | {
        type: typeof PROJECT_ALL_USERS;
        payload?: number[];
    }
    | {
        type: typeof PROJECT_PRESENT_USER_PERMISSION_DATA;
        payload?: UserPermission[];
    }
    | {
        type: typeof PROJECT_PAST_USER_PERMISSION_DATA;
        payload?: UserPermission[];
    }
    | {
        type: typeof TOGGLE_PROJECT_ADD_USER_MODAL;
        payload?: boolean;
    }
    | {
        type: typeof PRESENT_USER_SUMMARY;
        payload?: SummaryProps;
    }
    | {
        type: typeof PRESENT_USER_INDEX_VALUE_FIRST;
        payload?: number;
    }
    | {
        type: typeof PRESENT_USER_INDEX_VALUE_LAST;
        payload?: number;
    }
    | {
        type: typeof PRESENT_USER_PAGE_INDEX;
        payload?: number;
    }
    | {
        type: typeof PAST_USER_SUMMARY;
        payload?: SummaryProps;
    }
    | {
        type: typeof PAST_USER_INDEX_VALUE_FIRST;
        payload?: number;
    }
    | {
        type: typeof PAST_USER_INDEX_VALUE_LAST;
        payload?: number;
    }
    | {
        type: typeof PAST_USER_PAGE_INDEX;
        payload?: number;
    }
    | {
        type: typeof USER_PERMISSION_DATA;
        payload?: UserPermission;
    }
    | {
        type: typeof TOGGLE_USER_PERMISSION_MODAL;
        payload?: boolean;
    }
    | {
        type: typeof TOGGLE_USER_DELETE_MODAL;
        payload?: boolean;
    }
    | {
        type: typeof TOGGLE_ACTIVE_TAB;
        payload?: string;
    }
    // Priprity Add/ etc
    | {
        type: typeof TOGGLE_ADD_PRIORITY_MODAL;
        payload?: boolean;
    }
    | {
        type: typeof PROJECT_PRIORITY_DATA;
        payload?: ProjectPriorityType[];
    }
    | {
        type: typeof PRIORITY_SUMMARY;
        payload?: SummaryProps;
    }
    | {
        type: typeof PRIORITY_INDEX_VALUE_FIRST;
        payload?: number;
    }
    | {
        type: typeof PRIORITY_INDEX_VALUE_LAST;
        payload?: number;
    }
    | {
        type: typeof PRIORITY_PAGE_INDEX;
        payload?: number;
    }
    | {
        type: typeof PRIORITY_EDIT_MODE;
        payload?: boolean;
    }
    | {
        type: typeof UPDATE_PRIORITY_DATA;
        payload?: ProjectPriorityType;
    }
    | {
        type: typeof TOGGLE_PRIORITY_DELETE_MODAL;
        payload?: boolean;
    }
     // Category Add/ etc
     | {
        type: typeof TOGGLE_ADD_CATEGORY_MODAL;
        payload?: boolean;
    }
    | {
        type: typeof PROJECT_CATEGORY_DATA;
        payload?: ProjectCategoryType[];
    }
    | {
        type: typeof CATEGORY_SUMMARY;
        payload?: SummaryProps;
    }
    | {
        type: typeof CATEGORY_INDEX_VALUE_FIRST;
        payload?: number;
    }
    | {
        type: typeof CATEGORY_INDEX_VALUE_LAST;
        payload?: number;
    }
    | {
        type: typeof CATEGORY_PAGE_INDEX;
        payload?: number;
    }
    | {
        type: typeof CATEGORY_EDIT_MODE;
        payload?: boolean;
    }
    | {
        type: typeof UPDATE_CATEGORY_DATA;
        payload?: ProjectCategoryType;
    }
    | {
        type: typeof TOGGLE_CATEGORY_DELETE_MODAL;
        payload?: boolean;
    }
     | {
        type: typeof TOGGLE_CATEGORY_SEQUENCE_MODAL;
        payload?: boolean;
    }
    | {
        type: typeof TOGGLE_PRIORITY_SEQUENCE_MODAL;
        payload?: boolean;
    }
    ;
    
    
// Reducer function
export const projectReducer = (state: ProjectState, action: ACTIONTYPE) => {
    switch (action.type) {
        case PROJECT_CORE_DATA: {
            const { payload } = action;
            return {
                ...state,
                projectData: payload || state.projectData
            };
        }

        case PROJECT_ALL_USERS: {
            const { payload } = action;
            return {
                ...state,
                projectAllUsers: payload || state.projectAllUsers
            };
        }

        case PROJECT_PRESENT_USER_PERMISSION_DATA: {
            const { payload } = action;
            return {
                ...state,
                presentUserPermission: payload || state.presentUserPermission
            };
        }

        case PROJECT_PAST_USER_PERMISSION_DATA: {
            const { payload } = action;
            return {
                ...state,
                pastUserPermission: payload || state.pastUserPermission
            };
        }
        case TOGGLE_PROJECT_ADD_USER_MODAL: {
            const { payload } = action;
            return {
                ...state,
                addUserModal: payload !== undefined ? payload : !state.addUserModal
            };
        }

        case PRESENT_USER_SUMMARY: {
            const { payload } = action;
            return {
                ...state,
                summaryPresentUser: payload || state.summaryPresentUser
            };
        }
        case PRESENT_USER_INDEX_VALUE_FIRST: {
            const { payload } = action;
            return {
                ...state,
                indexValueFirstPresentUser: payload || state.indexValueFirstPresentUser
            };
        }

        case PRESENT_USER_INDEX_VALUE_LAST: {
            const { payload } = action;
            return {
                ...state,
                indexValueLastPresentUser: payload || state.indexValueLastPresentUser
            };
        }
        case PRESENT_USER_PAGE_INDEX: {
            const { payload } = action;
            return {
                ...state,
                pageIndexPresentUser: payload || state.pageIndexPresentUser
            };
        }

        case PAST_USER_SUMMARY: {
            const { payload } = action;
            return {
                ...state,
                summaryPastUser: payload || state.summaryPastUser
            };
        }
        case PAST_USER_INDEX_VALUE_FIRST: {
            const { payload } = action;
            return {
                ...state,
                indexValueFirstPastUser: payload || state.indexValueFirstPastUser
            };
        }

        case PAST_USER_INDEX_VALUE_LAST: {
            const { payload } = action;
            return {
                ...state,
                indexValueLastPastUser: payload || state.indexValueLastPastUser
            };
        }
        case PAST_USER_PAGE_INDEX: {
            const { payload } = action;
            return {
                ...state,
                pageIndexPastUser: payload || state.pageIndexPastUser
            };
        }
        // for update user Permission
        case USER_PERMISSION_DATA: {
            const { payload } = action;
            return {
                ...state,
                updateUserPermission: payload || state.updateUserPermission
            };
        }
        case TOGGLE_USER_PERMISSION_MODAL: {
            const { payload } = action;
            return {
                ...state,
                toggleUpdateModal: payload !== undefined ? payload : !state.toggleUpdateModal
            };
        }

        case TOGGLE_USER_DELETE_MODAL: {
            const { payload } = action;
            return {
                ...state,
                toggleDeleteModal: payload !== undefined ? payload : !state.toggleUpdateModal
            };
        }

        //Active tab in project settings
        case TOGGLE_ACTIVE_TAB: {
            const { payload } = action;
            return {
                ...state,
                activeTabSetting: payload || state.activeTabSetting
            };
        }

        //Project PRIORITY 
        case TOGGLE_ADD_PRIORITY_MODAL: {
            const { payload } = action;
            return {
                ...state,
                toggleAddPriority: payload !== undefined ? payload : !state.toggleAddPriority
            };
        }
        case PROJECT_PRIORITY_DATA: {
            const { payload } = action;
            return {
                ...state,
                priorityData: payload || state.priorityData
            };
        }
        case PRIORITY_SUMMARY: {
            const { payload } = action;
            return {
                ...state,
                summaryPriorityProject: payload || state.summaryPriorityProject
            };
        }
        case PRIORITY_INDEX_VALUE_FIRST: {
            const { payload } = action;
            return {
                ...state,
                indexValueFirstPriorityProject: payload || state.indexValueFirstPriorityProject
            };
        }

        case PRIORITY_INDEX_VALUE_LAST: {
            const { payload } = action;
            return {
                ...state,
                indexValueLastPriorityProject: payload || state.indexValueLastPriorityProject
            };
        }
        case PRIORITY_PAGE_INDEX: {
            const { payload } = action;
            return {
                ...state,
                pageIndexPriorityProject: payload || state.pageIndexPriorityProject
            };
        }
        case PRIORITY_EDIT_MODE: {
            const { payload } = action;
            return {
                ...state,
                isEditModePriority: payload !== undefined ? payload : !state.isEditModePriority
            };
        }
        case UPDATE_PRIORITY_DATA: {
            const { payload } = action;
            return {
                ...state,
                updatePriorityData: payload || state.updatePriorityData
            };
        }
        case TOGGLE_PRIORITY_DELETE_MODAL: {
            const { payload } = action;
            return {
                ...state,
                toggleDeletePriorityModal: payload !== undefined ? payload : !state.toggleDeletePriorityModal
            };
        }

        //Project Category
        case TOGGLE_ADD_CATEGORY_MODAL: {
            const { payload } = action;
            return {
                ...state,
                toggleAddCategory: payload !== undefined ? payload : !state.toggleAddCategory
            };
        }
        case PROJECT_CATEGORY_DATA: {
            const { payload } = action;
            return {
                ...state,
                categoryData: payload || state.categoryData
            };
        }
        case CATEGORY_SUMMARY: {
            const { payload } = action;
            return {
                ...state,
                summaryCategoryProject: payload || state.summaryCategoryProject
            };
        }
        case CATEGORY_INDEX_VALUE_FIRST: {
            const { payload } = action;
            return {
                ...state,
                indexValueFirstCategoryProject: payload || state.indexValueFirstCategoryProject
            };
        }

        case CATEGORY_INDEX_VALUE_LAST: {
            const { payload } = action;
            return {
                ...state,
                indexValueLastCategoryProject: payload || state.indexValueLastCategoryProject
            };
        }
        case CATEGORY_PAGE_INDEX: {
            const { payload } = action;
            return {
                ...state,
                pageIndexCategoryProject: payload || state.pageIndexCategoryProject
            };
        }
        case CATEGORY_EDIT_MODE: {
            const { payload } = action;
            return {
                ...state,
                isEditModeCategory: payload !== undefined ? payload : !state.isEditModeCategory
            };
        }
        case UPDATE_CATEGORY_DATA: {
            const { payload } = action;
            return {
                ...state,
                updateCategoryData: payload || state.updateCategoryData
            };
        }
        case TOGGLE_CATEGORY_DELETE_MODAL: {
            const { payload } = action;
            return {
                ...state,
                toggleDeleteCategoryModal: payload !== undefined ? payload : !state.toggleDeleteCategoryModal
            };
        }
        case TOGGLE_CATEGORY_SEQUENCE_MODAL: {
            const { payload } = action;
            return {
                ...state,
                toggleCategorySequenceModal: payload !== undefined ? payload : !state.toggleCategorySequenceModal
            };
        }
        case TOGGLE_PRIORITY_SEQUENCE_MODAL: {
            const { payload } = action;
            return {
                ...state,
                togglePrioritySequenceModal: payload !== undefined ? payload : !state.togglePrioritySequenceModal
            };
        }

        default:
            return state;
    }
};
