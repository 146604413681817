import { faTimes, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/base/Button";
import { useAuth } from "providers/AuthContext";
import { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Tab, Table, Modal, Nav, Row } from "react-bootstrap";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import apiCall from "services/api";
import regexPatterns from "common/regexPatterns";
import Constants from "common/Constants";
import { useMaster } from "providers/MasterContext";
import { handleCutomError } from "services/handleCutomError";
import { useNavigate, useParams } from "react-router-dom";
import { useProjectContext } from "providers/ProjectProvider";
import { PRESENT_USER_SUMMARY, PROJECT_PAST_USER_PERMISSION_DATA, PROJECT_PRESENT_USER_PERMISSION_DATA, TOGGLE_PROJECT_ADD_USER_MODAL } from "reducers/ProjectReducer";
import AddExistingUserForm from "./AddExistingUserForm";
import { KanbanBoardMembers } from "data/kanban";
import { UserPermission } from "types/project";
import calculateSerialNumber from "common/calculateSerialNumber";
import { useToast } from "providers/ToastProvider";
type ReactSelectOption = {
    value: number;
    label: string;
  };

  interface ErrorNewUserType {
    first_name: string;
    last_name: string;
    email: string;
    user_password: string;
    user_password_re: string;
    short_name: string;
    designation_id: string;
    role_in_project: string;
    is_meeting_admin: string;
    is_diary_admin: string;
    is_project_admin: string;
    is_story_admin: string;
  }
  const newErrors: ErrorNewUserType = {
    first_name: "",
    last_name: "",
    email: "",
    user_password: "",
    user_password_re: "",
    short_name: "",
    designation_id: "",
    role_in_project: "",
    is_meeting_admin: "",
    is_diary_admin: "",
    is_project_admin: "",
    is_story_admin: "",
  };

const AddNewUserForm =()=>{
      const { alias } = useParams();
      const { userTkn, workSpaceTkn, signOut } = useAuth();
      const { showErrorToast, showSuccessToast } = useToast();
      const { designation } = useMaster();
      const { projectAllUsers, addUserModal, projectDispatch } = useProjectContext();
      const navigation = useNavigate();
      const [loading, setLoading] = useState(false)
      const [validated, setValidated] = useState<boolean>(false);
      const [designationListData, setDesignationListData] = useState<ReactSelectOption[] | undefined>();

      const [formDataNewUser, setFormDataNewUser] = useState({
        first_name: "",
        last_name: "",
        email: "",
        user_password: "",
        user_password_re: "",
        short_name: "",
        designation_id: "",
        role_in_project: "",
        is_meeting_admin: false,
        is_diary_admin: false,
        is_project_admin: false,
        is_story_admin: false,
      });
    
      const [errorNewUser, setErrorNewUser] = useState<ErrorNewUserType>(newErrors);

      useEffect(() => {
        const transformedDataDesignation = designation?.map((item) => ({
          value: item.id,
          label: `${item.title}`,
        }));
        setDesignationListData(transformedDataDesignation);
    
      }, [designation]);

      const handleInputChangeNewUser = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        let errorMessage = "";
    
        // Validation for first name
        if (name === "first_name") {
          if (!regexPatterns.titleRegexNoSpace.test(value)) {
            errorMessage = "First name should contain at least 3 alphabetic characters without space.";
          }
        }
    
        // Validation for last name
        if (name === "last_name") {
          if (value.trim() && !regexPatterns.titleRegex.test(value)) {
            errorMessage = "Last name should contain at least 3 alphabetic characters.";
          }
        }
    
        if (name === "short_name") {
          if (value.trim().length !== 2) {
            errorMessage = "Short name should be 2 characters.";
          }
        }
        // Validation for email
        if (name === "email") {
          if (!value.trim()) {
            errorMessage = "Email is required.";
          } else if (!regexPatterns.emailRegex.test(value)) {
            errorMessage = "Invalid email address.";
          }
        }
    
        // Validation for password
        if (name === "user_password") {
          if (!regexPatterns.passwordRedex3.test(value)) {
            errorMessage = "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character (!, @, #, $, %, ^, &, *, (, ), [, ], -, _), and be between 6 and 14 characters long.";
          } else if (value !== formDataNewUser.user_password_re && formDataNewUser.user_password_re !== '') {
            errorMessage = "Passwords and Repeat Password do not match.";
          } else if (value === formDataNewUser.user_password_re) {
    
            setErrorNewUser(prevErrors => ({
              ...prevErrors,
              user_password_re: "",
            }));
    
          }
        }
    
        // Validation for matching passwords
        if (name === "user_password_re") {
          if (value !== formDataNewUser.user_password) {
            errorMessage = "Passwords and Repeat Password do not match.";
          }
        }
    
    
        if (name === "role_in_project") {
          if (!regexPatterns.titleRegex14.test(value)) {
            errorMessage = "Role should be 3 to 14 alphabetic characters.";
          }
        }
    
        // Handle description input
        if (name === "description" && value.trim() !== "") {
          if (value.trim().length > 255) {
            errorMessage = "Description should not exceed 255 characters.";
          }
        }
    
    
        // Handle description input
        if (name === "designation_id") {
          if (!value.trim()) {
            errorMessage = "Designation is required.";
          }
        }
    
    
        setFormDataNewUser(prevData => ({
          ...prevData,
          [name]: value,
        }));
    
    
        setErrorNewUser(prevErrors => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
      };

      /*for new user*/
      const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);  
    
        if (   
          errorNewUser.first_name ||
          errorNewUser.last_name ||
          errorNewUser.email ||
          errorNewUser.user_password ||
          errorNewUser.user_password_re ||
          errorNewUser.short_name ||
          errorNewUser.designation_id ||
          errorNewUser.role_in_project ||
          errorNewUser.is_meeting_admin ||
          errorNewUser.is_diary_admin ||
          errorNewUser.is_project_admin ||
          errorNewUser.is_story_admin
    
        ) {
          return;
        }
    
        if (!formDataNewUser.first_name.trim()) {
          newErrors.first_name = "First name is required.";
        }
        if (!formDataNewUser.email.trim()) {
          newErrors.email = "Email is required.";
        } else if (!/^\S+@\S+\.\S+$/.test(formDataNewUser.email)) {
          newErrors.email = "Please enter a valid email address.";
        }
        if (!formDataNewUser.user_password.trim()) {
          newErrors.user_password = "Password is required.";
        } else if (formDataNewUser.user_password.trim().length < 6) {
          newErrors.user_password = "Password must be at least 6 characters.";
        }
        if (!formDataNewUser.designation_id) {
          newErrors.designation_id = "Designation is required.";
        }
        if (!formDataNewUser.role_in_project) {
          newErrors.role_in_project = "Role in project is required.";
        }
 
        if (Object.values(newErrors).some((error) => error !== "")) {
          setErrorNewUser(newErrors);
          return;
        }
    
        const formDataSubmit = new FormData();
        formDataSubmit.append('first_name', formDataNewUser?.first_name);
        formDataSubmit.append('last_name', formDataNewUser?.last_name);
        formDataSubmit.append('short_name', formDataNewUser?.short_name);
        formDataSubmit.append('email', formDataNewUser?.email);
        formDataSubmit.append('user_password', formDataNewUser?.user_password);
        formDataSubmit.append('designation_id', formDataNewUser?.designation_id);
        formDataSubmit.append('role_in_project', formDataNewUser?.role_in_project);
        formDataSubmit.append('is_meeting_admin', String(formDataNewUser.is_meeting_admin == true ? 1 : 0));
        formDataSubmit.append('is_diary_admin', String(formDataNewUser.is_diary_admin == true ? 1 : 0));
        formDataSubmit.append('is_project_admin', String(formDataNewUser.is_project_admin == true ? 1 : 0));
        formDataSubmit.append('is_story_admin', String(formDataNewUser.is_story_admin == true ? 1 : 0));
    
        try {
          const response = await apiCall({
            url: `admin/project/${alias}/user-create-and-project-assign`,
            method: 'POST',
            data: formDataSubmit,
            headers: {
              'x-access-token': userTkn,
              'workspace': workSpaceTkn
            },
          });
    
          if (response.status === 200) {
            if (response?.data?.errors) {
              const errors = response?.data.errors;
    
              // Dynamically set errors based on the response
              Object.keys(errors).forEach((key) => {
                const errorMessages = errors[key];
                const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                // Set the error state for the corresponding field
                setErrorNewUser((prevData) => ({
                  ...prevData,
                  [key]: firstErrorMessage,
                }));
              });
            } else {
              fetchExistingUsers();
            }
          } else {
            console.error("error message:");
          }
        } catch (error: any) {
          if (error?.isAxiosError) {
            // Access the error message
            handleCutomError(error, signOut, navigation);
          } else {
            // Handle other types of errors
            console.error('An error occurred:', error);
         
          }
        }
      }

      const fetchExistingUsers = async () => {
        try {
            setLoading(true)

            const [response2] = await Promise.all([

                apiCall({
                    url: `admin/project/${alias}/permissions/list?expand=user&page=1`,
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                        'workspace': workSpaceTkn
                    },
                }),
            ])

            if (response2.status === 200) {
                let permissionData = response2?.data?.data?.permission
                const presentUsers = permissionData.filter((user: UserPermission) => user.status === 1);
                const pastUsers = permissionData.filter((user: UserPermission) => user.status !== 1);
                const updatedPresentUsers = presentUsers?.map((users: any, index: any) => ({
                    ...users,
                    serialNumber: calculateSerialNumber(1, index, response2?.data?.data?.summary?.pageSize) // Assuming 10 items per page
                }));

                const updatedPastUsers = pastUsers?.map((users: any, index: any) => ({
                    ...users,
                    serialNumber: calculateSerialNumber(1, index, response2?.data?.data?.summary?.pageSize) // Assuming 10 items per page
                }));


                projectDispatch({ type: PROJECT_PRESENT_USER_PERMISSION_DATA, payload: updatedPresentUsers });
                projectDispatch({ type: PROJECT_PAST_USER_PERMISSION_DATA, payload: updatedPastUsers });
                const summary = response2?.data?.data?.summary;

                projectDispatch({
                    type: PRESENT_USER_SUMMARY, payload: {
                        "total": summary.total,
                        "page": summary.page,
                        "pageSize": summary.pageSize,
                        "total_page": summary.total_page,
                    }
                });
                showSuccessToast("User Added sucessfully!")
             
            } else {
                console.error("error message:");
            }
        } catch (error: any) {
            if (error?.isAxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An error occurred:', error);
                showErrorToast(error)
            }
        } finally {
            setLoading(false)
        }
    };

    return(
        <PhoenixDocCard className='pt-3  pb-4 mt-1 border_design' >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body className="pt-4 pb-2 ">
            <Row className="">
              <Col md={6} className="mb-3">
                <Form.Group className=" text-start">
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label={`${Constants.first_name}`}
                  >
                    <Form.Control

                      type="text"
                      placeholder="First Name"
                      name="first_name"
                      value={formDataNewUser.first_name}
                      onChange={handleInputChangeNewUser}
                      isInvalid={!!errorNewUser.first_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorNewUser?.first_name}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label={`${Constants.last_name}`}
                >
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    name="last_name"
                    value={formDataNewUser.last_name}
                    onChange={handleInputChangeNewUser}
                    isInvalid={!!errorNewUser.last_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorNewUser?.last_name}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6} className="mb-3">
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label={`${Constants.short_name}`}
                >
                  <Form.Control

                    type="text"
                    placeholder="Short Name"
                    name="short_name"
                    value={formDataNewUser.short_name}
                    onChange={handleInputChangeNewUser}
                    isInvalid={!!errorNewUser.short_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorNewUser?.short_name}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6} className="mb-3">
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label={`${Constants.email} *`}
                >
                  <Form.Control
                    required
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={formDataNewUser.email}
                    onChange={handleInputChangeNewUser}
                    isInvalid={!!errorNewUser.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorNewUser?.email}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6} className="mb-3">
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label='User Password '
                >
                  <Form.Control

                    type="text"
                    placeholder="Password"
                    name="user_password"
                    value={formDataNewUser.user_password}
                    onChange={handleInputChangeNewUser}
                    isInvalid={!!errorNewUser.user_password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorNewUser?.user_password}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6} className="mb-3">
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label='Repeat Password '
                >
                  <Form.Control

                    type="text"
                    placeholder="Re-Password"
                    name="user_password_re"
                    value={formDataNewUser.user_password_re}
                    onChange={handleInputChangeNewUser}
                    isInvalid={!!errorNewUser.user_password_re}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorNewUser?.user_password_re}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group className=" text-start">
                  <FloatingLabel controlId="lead-woner" label="Designation">
                    <Form.Select

                      value={formDataNewUser.designation_id}
                      name="designation_id"
                      className="w-100"
                      onChange={handleInputChangeNewUser}
                      isInvalid={!!errorNewUser.designation_id}
                    >
                      <option value="" key={0}>Select</option>
                      {
                        designationListData?.map((item, index) => (
                          <option value={item.value} key={item.value}>{item.label}</option>
                        ))
                      }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errorNewUser?.designation_id}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>

              </Col>
              <Col md={6} className="mb-3">
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label='ROLL WITH IN PROJECT '
                >
                  <Form.Control
                    type="text"
                    placeholder="Role"
                    name="role_in_project"
                    value={formDataNewUser?.role_in_project}
                    onChange={handleInputChangeNewUser}
                    isInvalid={!!errorNewUser?.role_in_project}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorNewUser?.role_in_project}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center align-items-center px-4 pb-4 border-0 pt-3">
            <Button
              variant="outline-primary"
              size="sm"
              className="px-7 fs-9  my-0 btn"
              type="submit"
              disabled={loading}
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </PhoenixDocCard>
    )
}

export default AddNewUserForm;