import { faTimes, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import regexPatterns from "common/regexPatterns";
import { dbDateWithTimeStamp, formatDueDate } from "common/timeStampToDate";
import Button from "components/base/Button";
import DatePicker2 from "components/base/DatePicker2";
import { useAuth } from "providers/AuthContext";
import { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import apiCall from "services/api";
import { handleCutomError } from "services/handleCutomError";


interface UpdateNoteProps {
    show: boolean;
    handleClose: () => void;
    setAddStatus: React.Dispatch<React.SetStateAction<boolean>>;
    toast: any;
    projectData: any;
    noteData: any;
    navigation: any;
}


interface FormData {
    project_name: string;
    id:string;
    title: string;
    description: string;
    reminder: string;
    link: string;
    is_star: string;
    type: string; 
}

const UpdateNote = ({ show, handleClose, setAddStatus, toast, navigation, projectData, noteData }: UpdateNoteProps) => {


    const [validated, setValidated] = useState<boolean>(false);
    const [loading, setLoading] = useState(false)
    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const [error, setError] = useState({
        project_name: "",
        title: "",
        description: "",
        reminder: "",
        link: "",
        is_star: "",
        type: "",
    });
    const [formData, setFormData] = useState<FormData>({
        project_name: "",
        id:"",
        title: "",
        description: "",
        reminder: "",
        link: "",
        is_star: "0",
        type: ""
    });


    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            project_name: projectData?.title || '',
        }));
    }, [])

    useEffect(()=>{
        if (noteData) {
            setFormData(prevFormData => ({
                ...prevFormData,
                id: noteData.id,
                title: noteData.title,
                description: noteData.description,
                reminder: noteData.edited_at,
                link: noteData.link,
                type: noteData.type ? noteData.type.toString() : '', // Added a check for noteData.type
                is_star: noteData.usernote?.is_star || false, // Added a default value in case usernote is undefined
            }));
        }
    },[noteData])
    

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | { target: { name: string; value: string; } }) => {
        const { name, value } = e.target;

        let errorMessage = "";

        // Validation for first name
        if (name === "title") {
            if (!value.trim()) {
                errorMessage = "Title is required.";
            } else if (!regexPatterns.titleRegex255.test(value)) {
                errorMessage = "Title should contain at 3-255 characters [a-zA-Z0-9] with space.";
            }
        }

        if (name === "description") {
            if (!value.trim()) {
                errorMessage = "Description is required.";
            } else if (!regexPatterns.titleRegex255.test(value)) {
                errorMessage = "Description should contain at 3-255 characters [a-zA-Z0-9] with space.";
            }
        }


        if (name === "reminder") {
            let newValue = dbDateWithTimeStamp(value)
            setFormData({
                ...formData,
                [name]: newValue,
            });

        } else {
            setFormData({
                ...formData,
                [name]: value,
            });

        }


        setError((prevData) => ({
            ...prevData,
            [name]: errorMessage,
        }));
    }

    const handleWatchingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked ? '1' : '0';
        setFormData((prevState: FormData) => ({
            ...prevState,
            is_star: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // Prevent default form submission behavior
        e.stopPropagation();

        setValidated(true);

        // Check if there are any errors before making the API call
        if (
            error.title ||
            error.description ||
            error.reminder ||
            error.link ||
            error.is_star ||
            error.type
        ) {
            return;
        }


        // Check if there are any errors before making the API call
        if (
            !formData.title?.trim() ||
            !formData.description?.trim() 
         

        ) {
            setError({
                ...error,
                title: !formData?.title?.trim() ? "Title is required." : "",
                description: !formData?.description ? "This is required." : "",

            });
            return;
        }

        // Perform any necessary logic or API calls here

        const formDataSubmit = new FormData();
        formDataSubmit.append("title", formData?.title);
        formDataSubmit.append("description", formData?.description);
        formDataSubmit.append("type", formData?.type);
        formDataSubmit.append("reminder", formData?.reminder);
        formDataSubmit.append("is_star", formData?.is_star);
        formDataSubmit.append("link", formData?.link);
        formDataSubmit.append("id", formData?.id);

        try {
            setLoading(true)
            const response = await apiCall({
              url: `project/${projectData.alias}/notes/update`,
              method: 'POST',
              data: formDataSubmit,
              headers: {
                'x-access-token': userTkn,
                'workspace': workSpaceTkn
              },
            });
      
       
            if (response.status === 200) {
      
              if (response?.data?.errors) {
                const errors = response?.data.errors;
                // Dynamically set errors based on the response
                Object.keys(errors).forEach((key) => {
                  const errorMessages = errors[key];
                  const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                  // Set the error state for the corresponding field
                  setError((prevData) => ({
                    ...prevData,
                    [key]: firstErrorMessage,
                  }));
                });
              } else {
                // Call the refetchData function from the context to fetch the data again
                setAddStatus(true);
                handleClose();
              }
            } else {
              console.error("Api Failed");
            }
          } catch (error: any) {
            if (error?.isAxiosError) {
              // Access the error message
              handleCutomError(error, signOut, navigation);
            } else {
              // Handle other types of errors
              console.error('An error occurred:', error);
              // Display a generic error message to the user
              toast.error('An unexpected error occurred', {
                position: "top-center"
              });
            }
          } finally {
            setLoading(false); // Set loading to false after the API call is completed
          }

    };

    return (
        <Modal show={show} onHide={handleClose} className="p-0" size="lg" backdrop="static">
            <Modal.Header className="p-4 d-flex gap-2 border-0">
                <h5 className="mb-0 text-body-emphasis fw-semibold flex-1">Update New Note</h5>
                <Button className="p-0 ms-auto" onClick={handleClose}>
                    <FontAwesomeIcon icon={faXmark} className="fs-7" />
                </Button>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body className="pt-4 pb-2 px-4" >
                    <Row >
                        <Col md={12} className="mb-3">
                            <FloatingLabel
                                controlId="floatingInputGrid"
                                label='Project Name *'
                            >
                                <Form.Control
                                    disabled
                                    required
                                    type="text"
                                    placeholder="Re-Password"
                                    name="project_name"
                                    value={formData.project_name}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.project_name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error?.project_name}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                        <Col md={12} className="mb-3">
                            <Form.Group className=" text-start">
                                <FloatingLabel
                                    controlId="floatingInputGrid"
                                    label={`Title *`}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Title"
                                        name="title"
                                        value={formData.title}
                                        onChange={handleInputChange}
                                        isInvalid={!!error.title}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {error?.title}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                        <Col md={12} className="mb-3">
                            <Form.Group className=" text-start">
                                <FloatingLabel
                                    controlId="floatingInputGrid"
                                    label={`Description *`}
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="description"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        isInvalid={!!error.description}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {error?.description}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group className="text-start">
                                <DatePicker2
                                    value={formData.reminder ? formatDueDate(new Date(formData.reminder)) : ''}
                                    onChange={(date: Date[]) => {
                                        handleInputChange({
                                            target: {
                                                name: 'reminder',
                                                value: date[0].toString()
                                            }
                                        });
                                    }}
                                    options={{
                                        enableTime: true,
                                        dateFormat: "d/m/Y h:i K",
                                        minDate: new Date().setHours(0, 0, 0, 0)
                                    }}
                                    isError={error?.reminder}
                                    id="reminder"
                                    name="reminder"
                                    render={(_, ref) => {
                                        const isInvalid = !!error.reminder;
                                        return (
                                            <Form.Floating>
                                                <Form.Control
                                                    type="text"
                                                    ref={ref}
                                                    placeholder="d/m/Y  h:i"
                                                    readOnly
                                                    isInvalid={isInvalid}
                                                />
                                                <label htmlFor="startDate" className="ps-6">
                                                    {`Reminder *`}
                                                </label>
                                                <Form.Control.Feedback type="invalid">
                                                    {error?.reminder}
                                                </Form.Control.Feedback>
                                            </Form.Floating>
                                        );
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <FloatingLabel controlId="lead-woner" label="Type *">
                                <Form.Select
                                    required
                                    value={formData.type}
                                    name="type"
                                    className="w-100"
                                    onChange={handleInputChange}
                                    isInvalid={!!error.type}
                                >
                                    <option value="">Select</option>
                                    <option value="1">Private</option>
                                    <option value="2">Public</option>
                                    <option value="3">Shared</option>

                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {error?.type}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group className=" text-start">
                                <FloatingLabel
                                    controlId="floatingInputGrid"
                                    label={`Link`}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Link"
                                        name="link"
                                        value={formData.link}
                                        onChange={handleInputChange}
                                        isInvalid={!!error.link}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {error?.link}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Check type="checkbox" className="mb-0">
                                <Form.Check.Input
                                    type="checkbox"
                                    name="remember-me"
                                    id="remember-me"
                                    checked={formData.is_star === '1'}
                                    onChange={handleWatchingChange}
                                />
                                <Form.Check.Label htmlFor="remember-me" className="mb-0">
                                    <span style={{ color: "#8A94AD", fontWeight: "700" }}>Star</span>
                                </Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center align-items-center px-4 pb-4 border-0 pt-3">
                    <Button
                        variant="outline-primary"
                        size="sm"
                        className="px-7 fs-9  my-0 btn"
                        type="submit"
                        disabled={loading}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default UpdateNote;
