import { faTimes, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "common/Constants";
import regexPatterns from "common/regexPatterns";
import Button from "components/base/Button";
import { useAuth } from "providers/AuthContext";
import { useMaster } from "providers/MasterContext";
import { useState } from "react";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import apiCall from "services/api";
import { handleCutomError } from "services/handleCutomError";

interface AddPhaseModalProps {
  show: boolean;
  handleClose: () => void;
  setAddStatus: React.Dispatch<React.SetStateAction<boolean>>; // Add this line
  toast: any;
  navigation: any;
}

const AddPhaseModal = ({ show, handleClose, setAddStatus, toast, navigation }: AddPhaseModalProps) => {
  const {refetchData} = useMaster();
  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState(false)
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const [error, setError] = useState({
    title: ""
  });
  const [formData, setFormData] = useState({
    title: ""
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    let errorMessage = "";

    // Validation for first name
    if (name === "title") {
      if (!value.trim()) {
        errorMessage = "Title is required.";
      } else if (!regexPatterns.titleRegex.test(value)) {
        errorMessage = "Title should contain at least 3 alphabetic characters with space.";
      }
    }


    setFormData({
      ...formData,
      [name]: value,
    });


    setError((prevData) => ({
      ...prevData,
      [name]: errorMessage,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission behavior
    e.stopPropagation();
    // Add the following line to set the form validation state
    setValidated(true);

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      return;
    }

    // Check if there are any errors before making the API call
    if (
      error.title
    ) {
      return;
    }
    // Perform any necessary logic or API calls here
   
    const formDataSubmit = new FormData();
    formDataSubmit.append("title", formData?.title);
    try {
      setLoading(true)
      const response = await apiCall({
        url: `admin/master/phases/create`,
        method: 'POST',
        data: formDataSubmit,
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn,
        },
      });
  

      if (response.status === 200) {
    
        // navigation("/")
        // Call the refetchData function from the context to fetch the data again
        refetchData()
        setAddStatus(true);
        handleClose();
      } else {
        console.error("Api Faild");
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
           // Display a generic error message to the user
           toast.error('An unexpected error occurred', {
            position: "top-center"
        });
      }
    } finally {
      setLoading(false); // Set loading to false after the API call is completed
    }
  };

  return (
    <Modal show={show} onHide={handleClose} className="p-0" backdrop="static" centered>
      <Modal.Header className="p-4 d-flex gap-2 border-0">
        <h5 className="mb-0 text-body-emphasis fw-semibold flex-1">Add New Phase</h5>
        <Button className="p-0 ms-auto" onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} className="fs-7" />
          </Button>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body className="pt-4 pb-2 px-4">
          <div className="mb-3">
            <Form.Group className="mb-3 text-start">
              <FloatingLabel
                controlId="floatingInputGrid"
                label={`${Constants.new_phase} *`}
              >
                <Form.Control
                  required
                  type="text"
                  placeholder="Title"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  isInvalid={!!error.title}
                />
                <Form.Control.Feedback type="invalid">
                  {error?.title ? error?.title : "Please enter the Phase"}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center align-items-center px-4 pb-4 border-0 pt-3">

          <Button
            variant="outline-primary"
            size="sm"
            className="px-7 fs-9  my-0 btn"
            type="submit" // Add this line
            disabled={loading}
          >
            save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddPhaseModal;
