import { AxiosError } from 'axios';
import { dateAndTime } from 'common/timeStampToDate';
import Button from 'components/base/Button';
import { KanbanBoardItem, KanbanBoardTask } from 'data/kanban';
import { useAuth } from 'providers/AuthContext';
import { useKanbanContext } from 'providers/KanbanProvider';
import { useToast } from 'providers/ToastProvider';
import React, { useState, useEffect, useRef } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import apiCall from 'services/api';
import { handleCutomError } from 'services/handleCutomError';
import { CardDateTime } from '../KanbanTaskDetailsModal';
import Unicon from 'components/base/Unicon';
import { UilPen } from '@iconscout/react-unicons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface EditableColorProps {
    task: KanbanBoardTask;
    list: KanbanBoardItem;
    setCardDateTime: React.Dispatch<React.SetStateAction<CardDateTime>>;
    setCardDetails: React.Dispatch<React.SetStateAction<KanbanBoardTask | undefined>>;
    onHistoryUpdate?: () => void;
}

export interface Loading {
    colorUpdate: boolean

}

const EditableColor: React.FC<EditableColorProps> = ({ task, list, setCardDateTime, setCardDetails, onHistoryUpdate }) => {
    const { alias, id } = useParams();
    const { boardLists, kanbanDispatch } = useKanbanContext();

    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const { showErrorToast, showSuccessToast } = useToast();
    const navigation = useNavigate();
    const [validated, setValidated] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [taskData, setTaskData] = useState<KanbanBoardTask | undefined>(undefined);
    const [loading, setLoading] = useState<Loading>({
        colorUpdate: false
    });
    const [originalColor, setOriginalColor] = useState<string>('');
    const [error, setError] = useState({
        color: '',
    })
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {

        if (task) {
            setTaskData({
                ...task,
                id: task.id
            });
            setOriginalColor(task.color);
        }
    }, [task]);

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus(); // Set focus to input field
        }
    }, [isEditing]);

    const handleColorClick = () => {
        setIsEditing(true); //on Click Enable editing mode
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value } = e.target;

        setTaskData((prevData) => {
            if (prevData) {
                return {
                    ...prevData,
                    [name]: value,
                };
            }
            return prevData;
        });
    };
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!taskData?.color || taskData.color.trim() === '') {
            setError((prevError) => ({
                ...prevError,
                color: 'Color is required',
            }));
            return;
        } else {
            setError((prevError) => ({
                ...prevError,
                color: '',
            }));
        }

        if (taskData?.color === originalColor) {
            setIsEditing(false);
            return; // No change in color, don't submit
        }
        try {
            setLoading((prev) => ({
                ...prev,
                colorUpdate: true
            }));
            const formData = new FormData();
            formData.append('color', taskData?.color);
            const response = await apiCall({
                url: `project/${alias}/boards/${id}/card/${taskData?.id}/update`,
                method: 'POST',
                data: formData,
                headers: {
                    'x-access-token': userTkn,
                    workspace: workSpaceTkn
                }
            });
            if (response.status === 200) {
                showSuccessToast('Card updated successfully!');
                const updatedItem = response?.data?.data?.boardListCard;
                const updatedBoardLists = boardLists.map((item) => {
                    if (Number(item.id) === Number(list.id)) {
                        // Update the board list by replacing the card within boardListCards
                        return {
                            ...item,
                            boardListCards: item.boardListCards.map(card =>
                                // If the card matches, replace it, otherwise keep it as is
                                card.id === updatedItem.id ? updatedItem : card
                            )
                        };
                    }
                    return item;
                });
                const { formattedDate, formattedTime } = dateAndTime(updatedItem.end_datetime)
                setCardDateTime({
                    cardDate: formattedDate,
                    cardTime: formattedTime
                })
                setCardDetails(updatedItem);
                setOriginalColor(updatedItem.color);
                kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });
                if (onHistoryUpdate) onHistoryUpdate();
            } else {
                throw new Error('Failed to update card');
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An unexpected error occurred:', error);
                showErrorToast('An unexpected error occurred');
            }
        } finally {
            setLoading((prev) => ({
                ...prev,
                colorUpdate: false
            }));
            setIsEditing(false);
        }
    }

    const handleCancel = () => {
        // Reset the description to the original state and exit edit mode
        setTaskData((prevData) => {
            if (prevData) {
                return {
                    ...prevData,
                    color: originalColor

                };
            }
            return prevData;
        });
        setIsEditing(false); // Close the editor without submitting changes
    };
    return (
        <>

            {isEditing ? (
                <div >
                    <div className='w-100'>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <div className='d-flex gap-1 flex-row align-items-center bg-white p-3 mb-2 rounded-1'>
                                <div>
                                    <Form.Control
                                        ref={inputRef}
                                        type="color"
                                        placeholder="color"
                                        name="color"
                                        value={taskData?.color || ''}
                                        onChange={handleInputChange}
                                        // onKeyDown={handleKeyPress}
                                        // onBlur={handleBlur}
                                        isInvalid={!!error?.color}
                                        className='editable-field'
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {error?.color}
                                    </Form.Control.Feedback>
                                </div>
                                <div className='d-flex gap-2 justify-content-center align-items-center mt-2'>
                                    <Button
                                        startIcon={<FontAwesomeIcon icon={faTimes} />}
                                        onClick={handleCancel}
                                        className="p-0"
                                        disabled={loading?.colorUpdate}
                                    >
                                        cancel
                                    </Button>
                                    <Button
                                        variant="outline-primary"
                                        className=""
                                        type='submit'
                                        disabled={loading?.colorUpdate}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    {loading?.colorUpdate && <Spinner animation="border" variant="secondary" size="sm" />}
                </div>

            ) : (
                <div className='editable-field d-flex gap-2' >
                    <div>
                        <div

                            className="color-indicator "
                            style={{
                                width: '0.7rem',
                                height: '0.7rem',
                                borderRadius: '50%',
                                backgroundColor: taskData?.color || '#ffffff',

                            }}
                        />
                    </div>

                    <div >
                        <Button
                            variant=""
                            className="btn-icon"
                            onClick={handleColorClick}
                        >
                            <Unicon icon={UilPen} size={16} />
                        </Button>
                    </div>
                </div>
            )}

        </>
    );
};

export default EditableColor;
