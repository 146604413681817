import { Form, Spinner } from 'react-bootstrap';
import Button from 'components/base/Button';
import { KanbanBoardItem, KanbanBoardTask, taskAttachementInitialData, TaskAttachmentFormData } from 'data/kanban';
import { useState } from 'react';
import Unicon from 'components/base/Unicon';
import { UilPlus, UilTrash } from '@iconscout/react-unicons';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'providers/AuthContext';
import { useToast } from 'providers/ToastProvider';
import apiCall from 'services/api';
import { useKanbanContext } from 'providers/KanbanProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import usePhoenixForm from 'hooks/usePhoenixForm';
import pdf1 from 'assets/img/kanban/pdf.png';
import word1 from 'assets/img/kanban/word.png';
import excel1 from 'assets/img/kanban/excel.jpeg';
import ppt1 from 'assets/img/kanban/ppt.png';
import Dropzone from 'components/base/Dropzone';
import { CardDateTime } from '../KanbanTaskDetailsModal';
import { dateAndTime } from 'common/timeStampToDate';

interface EditableTitleProps {
  cardDetails: KanbanBoardTask;
  list: KanbanBoardItem;
  setCardDetails: React.Dispatch<React.SetStateAction<KanbanBoardTask | undefined>>;
  setCardDateTime: React.Dispatch<React.SetStateAction<CardDateTime>>;
  onHistoryUpdate?: () => void;
}

export interface CardLoading {
  uploadFile: boolean,
  removeAttachment: boolean,
  removeAttachmentId: number
}

const EditableAttachments: React.FC<EditableTitleProps> = ({ cardDetails, list, setCardDetails, setCardDateTime, onHistoryUpdate }) => {
  const { alias, id } = useParams();
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const { showErrorToast, showSuccessToast } = useToast();
  const { boardLists, kanbanDispatch } = useKanbanContext();
  const navigation = useNavigate() as NavigateFunction;
  const [validated, setValidated] = useState<boolean>(false);
  const { formData, setFormData, onChange } = usePhoenixForm<TaskAttachmentFormData>(taskAttachementInitialData);
  const [error, setError] = useState<{ attachment: string, title: string }>({
    attachment: '',
    title: ''
  });
  const [loading, setLoading] = useState<CardLoading>({
    uploadFile: false,
    removeAttachment: false,
    removeAttachmentId: 0,
  });
  const [toggleForm, setToggleForm] = useState({
    attachment: false
  })

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setError((prevData) => ({
      ...prevData,
      [name]: '',
    }));
  };
  //Add attachment 
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!formData.attachment || !(formData.attachment instanceof File)) {
      setError((prevData) => ({
        ...prevData,
        attachment: 'Please select a valid file to upload.',
      }));
      return;
    }
    if (formData?.title?.trim() && formData.title.trim().length > 75) {
      setError((prev) => ({
        ...prev,
        title: 'Title must not exceed 75 characters',
      }));
      return;
    }
    try {
      setLoading((prev) => ({
        ...prev,
        uploadFile: true
      }));
      const formDataSubmit = new FormData();
      if (formData.attachment instanceof File) {
        formDataSubmit.append('attachment', formData.attachment);
        formDataSubmit.append('title', formData.title);

        const apiUrl = `project/${alias}/boards/${id}/card/${cardDetails?.id}/add-attachment`;
        const response = await apiCall({
          url: apiUrl,
          method: 'POST',
          data: formDataSubmit,
          headers: {
            'x-access-token': userTkn,
            workspace: workSpaceTkn
          }
        });
        if (response?.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data.errors;
            // Dynamically set errors based on the response
            Object.keys(errors).forEach((key) => {
              const errorMessages = errors[key];
              const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
              // Set the error state for the corresponding field
              setError((prevData) => ({
                ...prevData,
                [key]: firstErrorMessage,
              }));
            });
          } else {
            const cardData = response?.data?.data?.boardListCard;
            const updatedBoardLists = boardLists.map((item) => {
              if (Number(item.id) === Number(list.id)) {
                // Update the board list by replacing the card within boardListCards
                return {
                  ...item,
                  boardListCards: item.boardListCards.map(card =>
                    // If the card matches, replace it, otherwise keep it as is
                    card.id === cardData.id ? cardData : card
                  )
                };
              }
              return item;
            });
            kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });
            const { formattedDate, formattedTime } = dateAndTime(cardData.end_datetime)
            setCardDateTime({
              cardDate: formattedDate,
              cardTime: formattedTime
            })
            setCardDetails(cardData);
            setToggleForm((prev) => ({
              ...prev,
              attachment: false
            }))
            setFormData(taskAttachementInitialData)
            if (onHistoryUpdate) onHistoryUpdate();
          }
          
        }
      }
    } catch (error: any) {
      showErrorToast('413 Request Entity Too Large');

    } finally {
      setLoading((prev) => ({
        ...prev,
        uploadFile: false
      }));
    }
  }

  const removeAttachment = async (attachmentId: number) => {
    const isConfirmed = window.confirm("Are you sure you want to remove this attachment?");

    if (!isConfirmed) {
      // If the user clicks "No", simply return without doing anything
      return;
    }


    try {
      setLoading((prev) => ({
        ...prev,
        removeAttachment: true,
        removeAttachmentId: attachmentId
      }));

      const apiUrl = `project/${alias}/boards/${id}/card/${cardDetails?.id}/remove-attachment?attachment_id=${attachmentId}`
      const response = await apiCall({
        url: apiUrl,
        method: 'POST',
        headers: {
          'x-access-token': userTkn,
          workspace: workSpaceTkn
        }
      });
      if (response?.status === 200) {
        if (response?.data?.errors) {
          const errors = response?.data.errors;
          // Dynamically set errors based on the response
          Object.keys(errors).forEach((key) => {
            const errorMessages = errors[key];
            const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
            // Set the error state for the corresponding field
            setError((prevData) => ({
              ...prevData,
              [key]: firstErrorMessage,
            }));
          });
        } else {
          const cardData = response?.data?.data?.boardListCard;
          const updatedBoardLists = boardLists.map((item) => {
            if (Number(item.id) === Number(list.id)) {
              // Update the board list by replacing the card within boardListCards
              return {
                ...item,
                boardListCards: item.boardListCards.map(card =>
                  // If the card matches, replace it, otherwise keep it as is
                  card.id === cardData.id ? cardData : card
                )
              };
            }
            return item;
          });
          kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });
          const { formattedDate, formattedTime } = dateAndTime(cardData.end_datetime)
          setCardDateTime({
            cardDate: formattedDate,
            cardTime: formattedTime
          })
          setCardDetails(cardData);
          if (onHistoryUpdate) onHistoryUpdate();
        }
      }

    } catch (error: any) {
      showErrorToast('413 Request Entity Too Large');

    } finally {
      setLoading((prev) => ({
        ...prev,
        removeAttachment: false,
        removeAttachmentId: 0
      }));
    }
  }




  return (
    <>
      <div className="attachments-container">
        {cardDetails?.attachments?.map((item, index) => {
          const fileExtension = item?.file?.split('.').pop()?.toLowerCase() || '';
          let fileThumbnail;

          if (['jpeg', 'jpg', 'png'].includes(fileExtension)) {
            fileThumbnail = item?.file;
          } else if (fileExtension === 'pdf') {
            fileThumbnail = pdf1;
          } else if (['doc', 'docx'].includes(fileExtension)) {
            fileThumbnail = word1;
          } else if (['ppt', 'pptx'].includes(fileExtension)) {
            fileThumbnail = ppt1;
          } else if (['xls', 'xlsx'].includes(fileExtension)) {
            fileThumbnail = excel1;
          } else {
            fileThumbnail = item?.file;
          }
          return (
            <div key={index} className="attachment-item">
              <div className="attachment-image-wrapper ">
                <img
                  width={100}
                  height={100}
                  src={fileThumbnail}
                  alt="attachment-image"
                  className="img-thumbnail "
                  onClick={() => window.open(item?.file, '_blank')}
                  style={{
                    width: '100px',
                    height: '100px',
                    objectFit: 'unset',
                    minHeight: '100px',
                    maxHeight: '100px'
                  }}
                />

                <Button
                  variant="phoenix-primary"
                  className="btn-icon delete-button"
                  onClick={() => removeAttachment(item?.id)}
                >
                  <Unicon icon={UilTrash} size={16} />
                </Button>
                {loading?.removeAttachmentId === item?.id && <div className="position-absolute top-50 start-50 translate-middle">
                  <Spinner animation="border" size="sm" />
                </div>
                }
              </div>
            </div>
          )
        })}
        {!toggleForm?.attachment && <Button
          variant="phoenix-primary"
          className="btn-icon me-2 rounded-1 bg-transparent mt-2"
          onClick={() => {
            setToggleForm((prev) => ({
              ...prev,
              attachment: true
            }))
          }}
        >
          <Unicon icon={UilPlus} size={20} />
        </Button>
        }
      </div>

      {toggleForm?.attachment &&
        <Form noValidate validated={validated} onSubmit={handleSubmit} className='h-100 scrollbar mt-2 '>
          <div className='d-flex flex-column bg-white p-3 mb-2 rounded-1'>

            <div>
              <Dropzone
                multiple={false}
                accept={{
                  'image/jpeg': [],
                  'image/png': [],
                  'image/jpg': [],
                  'application/pdf': [],
                  'application/msword': [], // For .doc
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // For .docx
                  'application/vnd.ms-excel': [], // For .xls
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // For .xlsx
                  'application/vnd.ms-powerpoint': [], // For .ppt
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [], // For .pptx
                }}
                onDrop={(acceptedFiles: File[]) => {
                  if (acceptedFiles.length > 0) {
                    const file = acceptedFiles[0];
                    if (
                      file.type === 'image/jpeg' ||
                      file.type === 'image/png' ||
                      file.type === 'image/jpg' ||
                      file.type === 'application/pdf' ||
                      file.type === 'application/msword' ||
                      file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                      file.type === 'application/vnd.ms-excel' ||
                      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                      file.type === 'application/vnd.ms-powerpoint' ||
                      file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
                    ) {
                      setFormData((prev) => ({
                        ...prev,
                        attachment: file, // Update the `banner` in the formData
                      }));
                      setError((prev) => ({
                        ...prev,
                        attachment: '', // Update the `banner` in the formData
                      }));
                    } else {
                      showErrorToast('Only JPEG, JPG, and PNG files are allowed.');
                    }
                  }
                }}
              />
              {error?.attachment && <div className='text-danger fs-9'>{error?.attachment}</div>}
            </div>
            <div className='mt-3'>
              <div >
                <Form.Control
                  type="text"
                  placeholder="title"
                  name="title"
                  value={formData?.title || ''}
                  onChange={handleInputChange}
                  isInvalid={!!error?.title}
                  className='editable-field'
                />
                <Form.Control.Feedback type="invalid">
                  {error?.title}
                </Form.Control.Feedback>
              </div>
            </div>
            <div className='d-flex justify-content-between pt-2 '>
              <div>
                <Button
                  startIcon={<FontAwesomeIcon icon={faTimes} />}
                  onClick={() => {
                    setToggleForm((prev) => ({
                      ...prev,
                      attachment: false
                    }));
                    setFormData((prev) => ({
                      ...prev,
                      attachment: ''
                    }))
                    setError((prev) => ({
                      ...prev,
                      attachment: ''
                    }))
                  }}
                  className="p-0"
                  disabled={loading?.uploadFile}
                >
                  cancel
                </Button>
              </div>
              <div>
                <Button
                  variant="outline-primary"
                  className="px-2"
                  type='submit'
                  disabled={loading?.uploadFile}
                >
                  Upload
                </Button>
              </div>
            </div>
          </div>
        </Form>
      }
    </>
  );
};

export default EditableAttachments;
