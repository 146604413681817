import { faChevronRight, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import logo from 'assets/img/icons/logo-sign.png';
import regexPatterns from 'common/regexPatterns';
import apiCall from 'services/api';
import VersionType from 'components/custom/VersionType';

const ForgotPasswordForm = ({
  layout
}: {
  layout?: 'simple' | 'card' | 'split';
}) => {
  const [commonError, setCommonError] = useState<any>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(false);
  const [toggleForm, setToggleForm] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: ''
  });
  const [error, setError] = useState({
    email: ''
  });

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    let errorMessage = '';
    if (name === 'email') {
      if (!value.trim()) {
        errorMessage = 'Email is required.';
      } else if (!regexPatterns.emailRegex.test(value)) {
        errorMessage = 'Please enter a valid email address.';
      }
    }

    setData({
      ...data,
      [name]: value
    });

    setError({
      ...error,
      [name]: errorMessage
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      return;
    } else if (error.email) {
      return;
    } else {
      const formData = new FormData();
      formData.append('email', data.email);
      setLoading(true);
      try {
        // const response = await axios.post(`${Constants.BASE_URL2}forget-password`, formData, {
        //   headers: {
        //     'x-api-key': 'web_qwertyuiop', // Add custom headers here
        //     "Access-Control-Allow-Origin": "*"
        //   },
        // });

        const response = await apiCall({
          url: `forget-password`,
          method: 'POST',
          data: formData,
          headers: {
            'x-api-key': 'web_qwertyuiop', // Add custom headers here
            'Access-Control-Allow-Origin': '*'
          }
        });

        if (response.status === 200) {
          if (response?.data?.errors) {
            const errors = response?.data?.errors;

            // Dynamically set errors based on the response
            Object.keys(errors).forEach(key => {
              const errorMessages = errors[key];
              const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message

              // Set the error state for the corresponding field
              setError(prevData => ({
                ...prevData,
                [key]: firstErrorMessage
              }));
            });
          } else {
            setData({
              email: ''
            });
            setToggleForm(true);

            setSuccessMessage(response?.data?.message);
            // navigate("/reset-password", { state: response?.data })
            return true;
          }
        } else {
          console.error('api failed');
        }
      } catch (error: any) {
        console.error('Sign in failed:', error);
        const errorMessage =
          error.response?.data || 'An error occurred during sign-in.';
        setCommonError(errorMessage);
      } finally {
        // Set loading back to false when the API call is complete
        setLoading(false);
      }
    }
  };

  return (
    <div className={classNames({ 'px-xxl-5': !(layout === 'split') })}>
      <div
        className={classNames('text-center', { 'mb-6': !(layout === 'split') })}
      >
        <div className="text-center mb-3">
          <img src={logo} alt="" width={160} className="rounded-2  mt-3" />
          <VersionType />
        </div>
        {!toggleForm ? (
          <>
            <h4 className="text-1000">Forgot your password?</h4>
            <p className="text-700 mb-5">
              Enter your email below and we will send{' '}
              <br className="d-sm-none" />
              you a reset link
            </p>
            <Form
              className={`d-flex align-items-center mb-5`}
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <Row className="mb-3">
                <Form.Group className="mb-3 text-start">
                  <Form.Label htmlFor="email">Email</Form.Label>
                  <div className="form-icon-container">
                    <Form.Control
                      required
                      id="email"
                      type="email"
                      name="email"
                      className="form-icon-input"
                      placeholder="name@example.com"
                      value={data.email}
                      onChange={handleEmailChange}
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                      isInvalid={error.email !== ''}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error?.email && error?.email !== ''
                        ? error?.email
                        : 'Please enter a valid email address.'}
                    </Form.Control.Feedback>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-900 fs-9 form-icon"
                    />
                  </div>
                </Form.Group>
                {commonError?.errors?.email && (
                  <div
                    style={{
                      marginBottom: '0.25rem',
                      color: '#ed2000',
                      fontSize: '75%',
                      paddingLeft: '1rem',
                      textAlign: 'left'
                    }}
                  >
                    {commonError?.error_code} {commonError?.errors?.email[0]}
                  </div>
                )}
                <Row className="justify-content-end mb-3 px-0 mx-0">
                  <Col xs="auto"></Col>
                  <Col xs="auto">
                    <div className="float-end">
                      <Button
                        variant="outline-secondary"
                        className="ms-2 btn-custom-outline-secondry"
                        onClick={() => {
                          navigate('/sign-in');
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="outline-primary"
                        className="ms-2"
                        endIcon={
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="ms-2"
                          />
                        }
                        disabled={loading} // Disable the button when loading is true
                        type="submit"
                      >
                        Send
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Row>
              {/* <Form.Control
            type="email"
            id="email"
            className="flex-1"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          /> */}
            </Form>
          </>
        ) : (
          <>
            <h5 style={{ fontSize: '20px', color: '#222834' }}>
              Sent Reset Password
            </h5>
            {successMessage && (
              <div
                style={{
                  marginBottom: '0.25rem',
                  color: '#525B75',
                  fontSize: '16px',
                  textAlign: 'center'
                }}
              >
                {successMessage}
              </div>
            )}
            <Link to={`/sign-in`} className="fs-9 fw-semi-bold">
              <Button
                variant="outline-primary"
                className="w-100 mt-4"
                type="submit"
              >
                Go to login page
              </Button>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
