import { faList, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import Unicon from 'components/base/Unicon';
import {
  UilReceipt,
  UilSchedule,
  UilSync,
  UilPen,
  UilCheck,
  UilThLarge,
  UilCheckCircle,
  UilTimesSquare
} from '@iconscout/react-unicons';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState, useRef, Suspense } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import PhoenixDocCard from 'components/base/PhoenixDocCard';
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Placeholder,
  Row,
  Spinner,
  Table
} from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import { NavPositionVariant, NavTopShapeVariant } from 'config';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';
import { projectsTablecolumns } from 'components/tables/customTables/ProjectsTablesList';
import { useAuth } from 'providers/AuthContext';
import Badge from 'components/base/Badge';
import CustomPagination from 'components/sp-common/CustomPagination';
import calculateSerialNumber from 'common/calculateSerialNumber';
import DatePicker from 'components/base/DatePicker';
import apiCall from 'services/api';
import { toast, ToastContainer } from 'react-toastify';
import { formatDueDate } from 'common/timeStampToDate';
import Avatar3 from 'components/base/Avatar3';
import Filtersort from '../project-management/view/action-point/Filtersort';
import { handleSpecialFilters } from '../project-management/view/action-point/handleSpecialFilters';
import ActionsPointsSpecialFilters from '../project-management/view/action-point/ActionsPointsSpecialFilters';
import AddActionPointModal from './modals/AddActionPointModal';
import UpdateActionPointModal from './modals/UpdateActionPointModal ';
import DeclineActionPointModal from './modals/DeclineActionPointModal';
import ReqExpDateModal from './modals/ReqExpDateModal';
import MarkAsDoneModal from './modals/MarkAsDoneModal';
import ApprovedActionPointModal from './modals/ApprovedActionPointModal';
import ReopenActionPointModal from './modals/ReopenActionPointModal';
import ActionPointDetailsModal from './modals/ActionPointDetailsModal ';
import PageBreadcrumb, {
  PageBreadcrumbItem
} from 'components/common/PageBreadcrumb';
import { getPageBreadCrumbs } from 'services/getPageBreadCrumbs';
import ToggleViewButton from 'components/common/ToggleViewbutton';
import { handleCutomError } from 'services/handleCutomError';

// Assuming you have a type for your data objects
interface Creator {
  id: number;
  username: string;
  short_name: string;
  color_code: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string | null;
  is_external: number;
  is_super_admin: number;
  designation_id: number;
  description: string | null;
  status: number;
  is_user_super_admin: number;
  status_name: string;
  entity: Entity | null;
}

interface Assignee {
  id: number;
  username: string;
  short_name: string;
  color_code: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string | null;
  is_external: number;
  is_super_admin: number;
  designation_id: number;
  description: string | null;
  status: number;
  is_user_super_admin: number;
  status_name: string;
  entity: Entity | null;
}

interface Auditor {
  id: number;
  username: string;
  short_name: string;
  color_code: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string | null;
  is_external: number;
  is_super_admin: number;
  designation_id: number;
  description: string | null;
  status: number;
  is_user_super_admin: number;
  status_name: string;
  entity: Entity | null;
}

interface Viewers {
  id: number;
  username: string;
  short_name: string;
  color_code: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string | null;
  is_external: number;
  is_super_admin: number;
  designation_id: number;
  description: string | null;
  status: number;
  is_user_super_admin: number;
  status_name: string;
  entity: Entity | null;
}

interface Entity {
  id: number;
  name: string;
  short_name: string | null;
  alias: string;
  status: number;
}

interface Project {
  id: number;
  title: string;
  short_name: string;
  alias: string;
  description: string;
  parent_id: number | null;
  start_date: string;
  end_date: string;
  editable: number;
  category: number;
  phase: number;
  donar: string;
  entity_id: number;
  status: number;
  projectlogo: string;
}

interface ActionPoint {
  id: number;
  title: string;
  description: string;
  due_date: string;
  expected_date: string | null;
  assign_to: number;
  auditor_id: number;
  assign_by: number;
  working_status: number;
  is_approved: number;
  completion_time: string | null;
  is_complete_after_due_date: number;
  is_reopen: number;
  reopen_reason: string | null;
  updated_at: number;
  status: number;
  action_point_status: string;
  creator: Creator;
  assignee: Assignee;
  auditor: Auditor;
  viewers: Viewers[]; // Define the type of viewers array as needed
  history: any[];
  project: Project;
  serialNumber: number;
}
interface User {
  id: number;
  user_id: number;
  project_id: number;
  role_id: number;
  role_in_project: string;
  projectlogo: string;
  user_designation: number;
  description: string | null;
  last_action_datetime: number;
  is_user_watching: number;
  is_project_admin: number;
  is_story_admin: number;
  is_meeting_admin: number;
  is_diary_admin: number;
  status: number;
  user: {
    id: number;
    username: string;
    short_name: string;
    color_code: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string | null;
    is_external: number;
    is_super_admin: number;
    designation_id: number | null;
    description: string | null;
    status: number;
    is_user_super_admin: number;
    status_name: string;
    entity: {
      id: number;
      name: string;
      short_name: string | null;
      alias: string;
      status: number;
    } | null;
  };
}

interface EditActionPoint {
  id: string;
  project_name: string;
  title: string;
  description: string;
  due_date: string;
  assign_to: string;
  auditor_id: string;
  viewers: number[]; // Assuming viewers is an array of IDs
}

// Define the type for the page filters state
type PageFiltersState = {
  shorting: string;
  project_alias: string;
  project_id: string;
  assign_to: string;
  due_date_range: string;
  finish_date_range: string;
  action_point_status: string;
  assign_by: string;
  auditor_id: string;
  is_approved: string;
  flag: string;
};

const AllActionPoints = () => {
  const initialBreadcrumb: PageBreadcrumbItem[] = [];
  const [pageName, setPageName] = useState('AllActionPoints');
  const [breadCrumb, setBreadCrumb] =
    useState<PageBreadcrumbItem[]>(initialBreadcrumb);
  const { alias, workspace } = useParams();
  const { userTkn, user, workSpaceTkn, signOut } = useAuth();
  const navigation = useNavigate() as NavigateFunction; // Explicitly define the type
  const [data, setData] = useState<ActionPoint[] | null>(null);
  const [summary, setSummary] = useState<any | null>({
    total: 0,
    page: 1,
    pageSize: 0,
    total_page: 1
  });

  const [loggedInUser, setLoggedInUser] = useState(user);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [addStatus, setAddStatus] = useState<boolean>(false);

  const [indexValueFirst, setValueFirst] = useState<number | null>(1);
  const [indexValueLast, setValueLast] = useState<number | null>(1);
  const tableRef = useRef<HTMLTableElement | null>(null);
  const [presentUser, setPresentUser] = useState<User[]>([]);
  const [projectsData, setProjectsData] = useState<Project[]>([]);
  const [editActionPoint, setEditActionPoint] = useState<EditActionPoint>({
    id: '',
    project_name: '',
    title: '',
    description: '',
    due_date: '',
    assign_to: '',
    auditor_id: '',
    viewers: []
  });
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openReqExpDateModal, setOpenReqExpDateModal] = useState(false);
  const [actionPointData, setActionPointData] = useState<any>('');
  const [declineActionPointModal, setDeclineActionPointModal] = useState(false);
  const [markAsDoneModal, setMarkAsDoneModal] = useState(false);
  const [reopanModal, setReopanModal] = useState(false);
  const [approvedModal, setApprovedModal] = useState(false);
  const [actionPointDetailsModal, setActionPointDetailsModal] = useState(false);
  const [pageFilters, setPageFilters] = useState<PageFiltersState>({
    shorting: 'when_ascending',
    project_alias: '',
    project_id: '',
    assign_to: '',
    due_date_range: '',
    finish_date_range: '',
    action_point_status: '',
    assign_by: '',
    auditor_id: '',
    is_approved: '',
    flag: ''
  });
  //for filters
  const [pageIndex, setPageIndex] = useState<number | null>(1);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const [toggleView, setToggleView] = useState<boolean>(false);
  const {
    config: { theme, navbarPosition },
    setConfig
  } = useAppContext();

  //set top navigation
  useEffect(() => {
    const value = 'horizontal';
    const value2 = 'slim';
    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant
    });
  }, []);
  // called when filter changed

  //update Breadcrumb
  useEffect(() => {
    getPageBreadCrumbs({ pageName, workspace, setBreadCrumb });
  }, [workspace, pageName, workspace]);

  // set page to 1 when special filter applyed
  useEffect(() => {
    setSummary((prev: any | null) => ({
      ...prev,
      page: 1
    }));
  }, [
    pageFilters.assign_to,
    pageFilters.due_date_range,
    pageFilters.finish_date_range,
    pageFilters.action_point_status,
    pageFilters.assign_by,
    pageFilters.auditor_id,
    pageFilters.shorting,
    pageFilters.is_approved,
    pageFilters.flag
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true
        const response = await apiCall({
          url: `actionpoints/list?expand=project&project_id=${pageFilters?.project_id}&page=${pageIndex}&assign_to=${pageFilters?.assign_to}&due_date_range=${pageFilters?.due_date_range}&finish_date_range=${pageFilters?.finish_date_range}&action_point_status=${pageFilters.action_point_status}&assign_by=${pageFilters.assign_by}&auditor_id=${pageFilters.auditor_id}&sort=${pageFilters.shorting}&is_approved=${pageFilters.is_approved}&flag=${pageFilters.flag}`,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            workspace: workSpaceTkn
          }
        });

        if (response?.status === 200) {
          // Calculate serial numbers for the projects on the current page
          const updatedprojects = response?.data?.data?.actionpoints.map(
            (projects: any, index: any) => ({
              ...projects,
              serialNumber: calculateSerialNumber(pageIndex || 1, index, 10) // Assuming 10 items per page
            })
          );
          setData(updatedprojects);
          setAddStatus(false);
          let summary = response?.data?.data?.summary;
          setSummary({
            total: summary.total,
            page: summary.page,
            pageSize: summary.pageSize,
            total_page: summary.total_page
          });
          setLoading(false);
        } else {
          console.error('error messag');
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: 'top-center'
          });
        }
      } finally {
        setLoading(false); // Set loading to false after the API call is completed
      }
    };
    fetchData();
  }, [
    userTkn,
    addStatus,
    pageIndex,
    pageFilters.assign_to,
    pageFilters.due_date_range,
    pageFilters.finish_date_range,
    pageFilters.action_point_status,
    pageFilters.assign_by,
    pageFilters.auditor_id,
    pageFilters.shorting,
    pageFilters.is_approved,
    pageFilters.flag,
    pageFilters?.project_id
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await apiCall({
          url: `project/list?pagination=0`,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            workspace: workSpaceTkn
          }
        });

        setProjectsData(response?.data);
        if (response.status === 200) {
          let data = response.data.data;
          const projects: Project[] = data?.projects.map((item: any) => ({
            id: item?.id,
            alias: item.alias,
            title: item.title
          }));

          setProjectsData(projects);
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: 'top-center'
          });
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userTkn]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiCall({
          url: `project/${pageFilters.project_alias}/users`,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            workspace: workSpaceTkn
          }
        });
        if (response.status === 200) {
          setPresentUser(response?.data?.users);
        } else {
          console.error('error message:');
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: 'top-center'
          });
        }
      } finally {
      }
    };
    if (pageFilters.project_alias) {
      fetchData();
    }
  }, [userTkn, pageFilters.project_alias]);

  useEffect(() => {
    if (pageFilters.project_alias.length === 0) {
      setPresentUser([]);
      setPageFilters({
        shorting: 'when_ascending',
        project_alias: '',
        project_id: '',
        assign_to: '',
        due_date_range: '',
        finish_date_range: '',
        action_point_status: '',
        assign_by: '',
        auditor_id: '',
        is_approved: '',
        flag: ''
      });
    }
  }, [pageFilters.project_alias]);

  useSettingsMountEffect({
    showSettingPanelButton: false
  });

  const table = useAdvanceTable({
    data: data || [],
    columns: projectsTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true
    // selection: true
  });

  useEffect(() => {
    // Now 'tableRef.current' contains the reference to the table element
    const table = tableRef.current;

    if (table) {
      const firstRow = table?.rows[1];
      const lastRowIndex = table?.rows.length - 1;
      const lastRow = table?.rows[lastRowIndex];
      if (firstRow) {
        const firstCell = firstRow.cells[0];
        if (firstCell) {
          const cellValue = firstCell.textContent || firstCell.innerText;
          let value = Number(cellValue);
          setValueFirst(value);
        }
      }

      if (lastRow) {
        const firstCell = lastRow.cells[0];
        if (firstCell) {
          const cellValue = firstCell.textContent || firstCell.innerText;
          let value = Number(cellValue);
          setValueLast(value);
        }
      }
    }
  }, [data]); // This effect runs once when the component mounts

  const getActionOptions = (item: any, loggedInUser: number) => {
    if (loggedInUser === Number(item?.creator?.id)) {
      return (
        <div className="d-flex gap-2 justify-content-start ">
          {item?.status === 1 && (
            <Button
              variant="phoenix-primary"
              className="p-1 bg-transparent"
              onClick={() => {
                const viewersIds = item?.viewers?.map(
                  (viewer: any) => viewer?.id
                );
                setEditActionPoint({
                  ...editActionPoint,
                  id: String(item?.id) || '',
                  project_name: String(item?.project?.alias) || '',
                  title: String(item?.title) || '',
                  description: String(item?.description) || '',
                  due_date: String(item?.due_date) || '',
                  assign_to: String(item?.assign_to) || '',
                  auditor_id: String(item?.auditor?.id) || '',
                  viewers: viewersIds
                });
                setActionPointData(item);
                setOpenUpdateModal(true); // Optionally open the update modal
              }}
            >
              <Unicon icon={UilPen} size={20} id="Meeting" />
              <Tooltip anchorSelect="#Meeting" clickable>
                Edit
              </Tooltip>
            </Button>
          )}
          {loggedInUser === Number(item?.assignee?.id) &&
            item?.status === 1 && (
              <Button
                variant="phoenix-primary"
                className="p-1 bg-transparent"
                onClick={() => {
                  setActionPointData(item);
                  setMarkAsDoneModal(true);
                }}
              >
                <Unicon icon={UilCheck} size={20} id="mark-as-complete" />
                <Tooltip anchorSelect="#mark-as-complete" clickable>
                  Mark as Complete
                </Tooltip>
              </Button>
            )}

          {(item?.status === 2 || item?.status === 3) && (
            <Button
              variant="phoenix-primary"
              className="p-1 bg-transparent"
              onClick={() => {
                const viewersIds = item?.viewers?.map(
                  (viewer: any) => viewer?.id
                );
                setEditActionPoint({
                  ...editActionPoint,
                  id: String(item?.id) || '',
                  project_name: String(item?.project?.alias) || '',
                  title: String(item?.title) || '',
                  description: String(item?.description) || '',
                  due_date: String(item?.due_date) || '',
                  assign_to: String(item?.assign_to) || '',
                  auditor_id: String(item?.auditor?.id) || '',
                  viewers: viewersIds
                });
                setActionPointData(item);
                setReopanModal(true); // Optionally open the update modal
              }}
            >
              <Unicon icon={UilSync} size={20} id="reopen" />
              <Tooltip anchorSelect="#reopen">Reopen</Tooltip>
            </Button>
          )}
          {item.status === 2 && item?.is_approved === 0 && (
            <Button
              variant="phoenix-primary"
              className="p-1 bg-transparent"
              onClick={() => {
                setActionPointData(item);
                setApprovedModal(true);
              }}
            >
              <Unicon icon={UilCheckCircle} size={20} id="approved" />
              <Tooltip anchorSelect="#approved" clickable>
                Approved
              </Tooltip>
            </Button>
          )}
          {loggedInUser === Number(item?.assignee?.id) &&
            item?.status === 1 && (
              <Button
                variant="phoenix-primary"
                className="p-1 bg-transparent"
                onClick={() => {
                  setActionPointData(item);
                  setOpenReqExpDateModal(true);
                }}
              >
                <Unicon icon={UilSchedule} size={20} id="exp-date-time" />
                <Tooltip anchorSelect="#exp-date-time" clickable>
                  Expect Date & Time
                </Tooltip>
              </Button>
            )}
          {loggedInUser === Number(item?.assignee?.id) &&
            item?.status === 1 && (
              <Button
                variant="phoenix-primary"
                className="p-1 bg-transparent"
                onClick={() => {
                  setActionPointData(item);
                  setDeclineActionPointModal(true);
                }}
              >
                <Unicon icon={UilTimesSquare} size={20} id="decline" />
                <Tooltip anchorSelect="#decline" clickable>
                  Decline
                </Tooltip>
              </Button>
            )}
        </div>
      );
    } else if (loggedInUser === Number(item?.assignee?.id)) {
      return (
        <div className="d-flex gap-2 justify-content-start ">
          {item?.status === 1 && (
            <Button
              variant="phoenix-primary"
              className="p-1 bg-transparent"
              onClick={() => {
                setActionPointData(item);
                setMarkAsDoneModal(true);
              }}
            >
              <Unicon icon={UilCheck} size={20} id="mark-as-complete" />
              <Tooltip anchorSelect="#mark-as-complete" clickable>
                Mark as Complete
              </Tooltip>
            </Button>
          )}

          {item.status === 1 && (
            <Button
              variant="phoenix-primary"
              className="p-1 bg-transparent"
              onClick={() => {
                setActionPointData(item);
                setOpenReqExpDateModal(true);
              }}
            >
              <Unicon icon={UilSchedule} size={20} id="exp-date-time" />
              <Tooltip anchorSelect="#exp-date-time" clickable>
                Expect Date & Time
              </Tooltip>
            </Button>
          )}
          {item.status === 1 && (
            <Button
              variant="phoenix-primary"
              className="p-1 bg-transparent"
              onClick={() => {
                setActionPointData(item);
                setDeclineActionPointModal(true);
              }}
            >
              <Unicon icon={UilTimesSquare} size={20} id="decline" />
              <Tooltip anchorSelect="#decline" clickable>
                Decline
              </Tooltip>
            </Button>
          )}
        </div>
      );
    } else if (loggedInUser === Number(item?.auditor?.id)) {
      return (
        <div className="d-flex gap-2 justify-content-start ">
          {item.status === 2 && item?.is_approved === 0 && (
            <Button
              variant="phoenix-primary"
              className="p-1 bg-transparent"
              onClick={() => {
                setActionPointData(item);
                setApprovedModal(true);
              }}
            >
              <Unicon icon={UilCheckCircle} size={20} id="approved" />
              <Tooltip anchorSelect="#approved" clickable>
                Approved
              </Tooltip>
            </Button>
          )}
        </div>
      );
    }
  };

  const getBedgeBg = (title: string) => {
    switch (title) {
      case 'open':
        return 'secondary';
        break;
      case 'pending':
        return 'warning';
        break;
      case 'declined':
        return 'danger';
        break;
      case 'completed':
        return 'success';
        break;
      case 'reopen':
        return 'info';
        break;
    }
  };

  //handle sortig when assending, A-Z, Z-A
  const handleDropDown = (value: any) => {
    setPageFilters(prevData => ({
      ...prevData,
      shorting: value
    }));
  };

  // for handling special filters
  const handleSpecialFiltersUpdate = (itemName: string) => {
    handleSpecialFilters(itemName, setPageFilters, loggedInUser);
  };
  return (
    <div>
      <PageBreadcrumb items={breadCrumb} navigation={navigation} />
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 align-items-center justify-content-between">
          <h2 className="mb-0">
            <span className="me-3">
              {' '}
              <Unicon icon={UilReceipt} size={28} /> s{' '}
            </span>{' '}
          </h2>
          <Button
            variant="outline-primary"
            className="btn px-5"
            onClick={() => setOpenAddModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            New Action Item
          </Button>
        </div>

        <ActionsPointsSpecialFilters
          handleSpecialFilters={handleSpecialFiltersUpdate}
        />
        <Row className="g-3 justify-content-between mb-3">
          <Col xs={12} className="col-md-11 custom_selectWidth">
            <div className="d-flex align-items-center gap-2 flex-wrap ">
              <Filtersort
                handleDropDown={handleDropDown}
                value={pageFilters.shorting}
              />
              <FloatingLabel controlId="lead-woner" label="Project">
                <Form.Select
                  onChange={e => {
                    const selectedOption =
                      e.target.options[e.target.selectedIndex];
                    const projectId = selectedOption.dataset.projectId || '';

                    setPageFilters(prevData => ({
                      ...prevData,
                      project_alias: e.target.value,
                      project_id: projectId
                    }));
                  }}
                >
                  <option value={''} key={'a1'}>
                    {'Select'}{' '}
                  </option>
                  {projectsData &&
                    projectsData?.length > 0 &&
                    projectsData?.map((item: any, index: number) => {
                      return (
                        <option
                          value={item?.alias}
                          data-project-id={item?.id}
                          key={item?.id}
                        >
                          {item?.title}
                        </option>
                      );
                    })}
                </Form.Select>
              </FloatingLabel>

              <FloatingLabel controlId="lead-woner" label="Who">
                <Form.Select
                  value={pageFilters?.assign_to}
                  onChange={e => {
                    setLoading(true);
                    setPageFilters(prevData => ({
                      ...prevData,
                      assign_to: e.target.value
                    }));
                    setPageIndex(1);
                  }}
                >
                  <option value={''} key={'a1'}>
                    {'Select'}{' '}
                  </option>
                  {presentUser &&
                    presentUser.map((item: any, index: number) => {
                      return (
                        <option value={item?.id} key={item?.id}>
                          {item?.first_name} {item?.last_name}
                        </option>
                      );
                    })}
                </Form.Select>
              </FloatingLabel>
              <Form.Group className=" text-start">
                <DatePicker
                  value={
                    pageFilters.due_date_range
                      ? pageFilters.due_date_range
                          .split(' - ')
                          .map(dateString => new Date(dateString))
                      : ''
                  }
                  onChange={(date: Date[]) => {
                    if (date.length === 2) {
                      const startDate = date[0].toLocaleDateString('en-GB');
                      const endDate = date[1].toLocaleDateString('en-GB');

                      let new_start_date = startDate.split('/');
                      let new_end_date = endDate.split('/');
                      setPageFilters(prevData => ({
                        ...prevData,
                        due_date_range: `${new_start_date[2]}-${new_start_date[1]}-${new_start_date[0]} - ${new_end_date[2]}-${new_end_date[1]}-${new_end_date[0]}`
                      }));

                      setPageIndex(1);
                    }
                  }}
                  options={{
                    mode: 'range',
                    dateFormat: 'd/m/Y'
                  }}
                  id="due_date_range"
                  name="due_date_range"
                  render={(_, ref) => {
                    return (
                      <Form.Floating>
                        <Form.Control
                          className="custom-date-filter"
                          type="text"
                          ref={ref}
                          placeholder="d/m/Y  h:i"
                          readOnly
                        />
                        <label htmlFor="startDate" className="ps-6">
                          {`When Date`}
                        </label>
                        {pageFilters.due_date_range &&
                          pageFilters.due_date_range.length > 0 && (
                            <FontAwesomeIcon
                              icon={faTimes}
                              className="position-absolute end-0 top-50 translate-middle-y me-2"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setPageFilters(prevData => ({
                                  ...prevData,
                                  due_date_range: ''
                                }));
                              }}
                            />
                          )}
                      </Form.Floating>
                    );
                  }}
                />
              </Form.Group>

              <Form.Group className=" text-start">
                <DatePicker
                  value={
                    pageFilters.finish_date_range
                      ? pageFilters.finish_date_range
                          .split(' - ')
                          .map(dateString => new Date(dateString))
                      : ''
                  }
                  onChange={(date: Date[]) => {
                    if (date.length === 2) {
                      const startDate = date[0].toLocaleDateString('en-GB');
                      const endDate = date[1].toLocaleDateString('en-GB');

                      let new_start_date = startDate.split('/');
                      let new_end_date = endDate.split('/');
                      setPageFilters(prevData => ({
                        ...prevData,
                        finish_date_range: `${new_start_date[2]}-${new_start_date[1]}-${new_start_date[0]} - ${new_end_date[2]}-${new_end_date[1]}-${new_end_date[0]}`
                      }));

                      setPageIndex(1);
                    }
                  }}
                  options={{
                    mode: 'range',
                    dateFormat: 'd/m/Y'
                  }}
                  id="finish_date_range"
                  name="finish_date_range"
                  render={(_, ref) => {
                    return (
                      <Form.Floating>
                        <Form.Control
                          className="custom-date-filter"
                          type="text"
                          ref={ref}
                          placeholder="d/m/Y  h:i"
                          readOnly
                        />
                        <label htmlFor="startDate" className="ps-6">
                          {`Finish Date`}
                        </label>
                        {pageFilters.finish_date_range &&
                          pageFilters.finish_date_range.length > 0 && (
                            <FontAwesomeIcon
                              icon={faTimes}
                              className="position-absolute end-0 top-50 translate-middle-y me-2"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setPageFilters(prevData => ({
                                  ...prevData,
                                  finish_date_range: ''
                                }));
                                setPageIndex(1);
                              }}
                            />
                          )}
                      </Form.Floating>
                    );
                  }}
                />
              </Form.Group>

              <FloatingLabel controlId="lead-woner" label="Status">
                <Form.Select
                  onChange={e => {
                    setLoading(true);
                    setPageFilters(prevData => ({
                      ...prevData,
                      action_point_status: e.target.value
                    }));
                    setPageIndex(1);
                  }}
                >
                  <option value={''} key={'a1'}>
                    {'All'}{' '}
                  </option>
                  <option value={'completed'}> completed </option>
                  <option value={'open'}> open </option>
                  <option value={'declined'}> declined </option>
                  <option value={'pending'}> pending </option>
                  <option value={'reopen'}> reopen </option>
                </Form.Select>
              </FloatingLabel>
              <FloatingLabel controlId="lead-woner" label="Created By">
                <Form.Select
                  value={pageFilters?.assign_by}
                  onChange={e => {
                    setLoading(true);
                    setPageFilters(prevData => ({
                      ...prevData,
                      assign_by: e.target.value
                    }));
                    setPageIndex(1);
                  }}
                >
                  <option value={''} key={'a1'}>
                    {'Select'}{' '}
                  </option>
                  {presentUser &&
                    presentUser.map((item: any, index: number) => {
                      return (
                        <option value={item?.id} key={item?.id}>
                          {item?.first_name} {item?.last_name}
                        </option>
                      );
                    })}
                </Form.Select>
              </FloatingLabel>
              <FloatingLabel controlId="lead-woner" label="Auditor">
                <Form.Select
                  value={pageFilters?.auditor_id}
                  onChange={e => {
                    setLoading(true);
                    setPageFilters(prevData => ({
                      ...prevData,
                      auditor_id: e.target.value
                    }));
                    setPageIndex(1);
                  }}
                >
                  <option value={''} key={'a1'}>
                    {'Select'}{' '}
                  </option>
                  {presentUser &&
                    presentUser.map((item: any, index: number) => {
                      return (
                        <option value={item?.id} key={item?.id}>
                          {item?.first_name} {item?.last_name}
                        </option>
                      );
                    })}
                </Form.Select>
              </FloatingLabel>
            </div>
          </Col>

          <Col xs={12} className="col-md-1">
            <div className="d-flex align-items-center gap-2 justify-content-end">
              <div className="d-md-block d-none">
                <ToggleViewButton
                  tooltip="List view"
                  active={toggleView === false}
                  onClick={() => {
                    setToggleView(false);
                  }}
                >
                  <FontAwesomeIcon icon={faList} className="fs-9" />
                </ToggleViewButton>
              </div>
              <div className="d-md-block d-none">
                <ToggleViewButton
                  tooltip="Card view"
                  active={toggleView === true}
                  onClick={() => {
                    setToggleView(true);
                  }}
                >
                  <Unicon icon={UilThLarge} size={14} />
                </ToggleViewButton>
              </div>
            </div>
          </Col>
        </Row>
        <div className="">
          {!toggleView ? (
            <PhoenixDocCard className="p-sm-4 p-2 mt-3 ">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" variant="light" />{' '}
                </div>
              ) : (
                <>
                  <Table
                    className="phoenix-table fs-9 phoenix-table fs-9 project-table project_padding border rounded"
                    size="md"
                    striped
                    hover
                    responsive
                    ref={tableRef}
                  >
                    <thead>
                      <tr id={'a1'}>
                        <th
                          style={{
                            width: '2%',
                            minWidth: '10px',
                            padding: '16px 0px'
                          }}
                        >
                          #
                        </th>
                        <th
                          className="px-1"
                          style={{ width: '2%', minWidth: '10px' }}
                        >
                          Who
                        </th>
                        <th
                          className="px-1"
                          style={{ width: '26%', minWidth: '80px' }}
                        >
                          What
                        </th>
                        <th
                          className="px-1"
                          style={{ width: '7.5%', minWidth: '100px' }}
                        >
                          When
                        </th>
                        <th
                          className="px-1"
                          style={{ width: '9.5%', minWidth: '100px' }}
                        >
                          Finish
                        </th>
                        <th
                          className="px-1"
                          style={{ width: '7.5%', minWidth: '100px' }}
                        >
                          Status
                        </th>
                        <th
                          className="px-1"
                          style={{ width: '7.5%', minWidth: '100px' }}
                        >
                          Creator
                        </th>
                        <th
                          className="px-1"
                          style={{ width: '7.5%', minWidth: '100px' }}
                        >
                          Auditor
                        </th>
                        <th
                          className="px-1"
                          style={{ width: '7.5%', minWidth: '100px' }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data?.map((item: ActionPoint, index: number) => {
                          //check and change due_date format
                          let due_date: string[] = (item?.due_date ?? '').split(
                            ' '
                          );
                          let formattedDueDate = '';
                          if (due_date[0]) {
                            formattedDueDate = formatDueDate(
                              new Date(item?.due_date)
                            );
                          }
                          //check and change expected_date format
                          let completion_time: string[] = (
                            item?.completion_time ?? ''
                          ).split(' ');
                          let formattedCompleteDate = '';
                          if (completion_time[0]) {
                            formattedCompleteDate = formatDueDate(
                              new Date(
                                `${completion_time[0]} ${completion_time[1]}`
                              )
                            );
                          }

                          return (
                            <tr key={item?.id || index + 1}>
                              <td>{item?.serialNumber}</td>
                              <td>
                                {loading ? (
                                  <Placeholder xs={7} />
                                ) : (
                                  <div className="avatar_width">
                                    <Avatar3
                                      size="m"
                                      variant="name"
                                      className={`me-1 ${
                                        Number(loggedInUser?.id) ===
                                        Number(item?.assignee?.id)
                                          ? 'loggedin-user'
                                          : 'normal-user'
                                      }`}
                                      border={
                                        Number(loggedInUser?.id) ===
                                        Number(item?.assignee?.id)
                                          ? '2'
                                          : '0'
                                      }
                                    >
                                      {item?.assignee?.short_name?.slice(0, 2)}
                                    </Avatar3>
                                  </div>
                                )}
                              </td>
                              <td>
                                <div>
                                  <div
                                    // state={{ user: item }}

                                    className="text-decoration-none fw-semi-bold fs-8 action-point-title"
                                    onClick={() => {
                                      setActionPointData(item);
                                      setActionPointDetailsModal(true);
                                    }}
                                  >
                                    {item?.title.length > 60
                                      ? `${item.title.substring(0, 60)}...`
                                      : item.title}
                                  </div>
                                  <div className="d-flex gap-1 pt-1">
                                    <span className="fs-16 text-capitlize">
                                      {' '}
                                      {item?.project?.title}
                                    </span>
                                    {/* <Badge bg="secondary" variant="phoenix" iconPosition="end" className="fs-16 text-capitlize"> Ui/Ux</Badge>
                                                                            <Badge bg="secondary" variant="phoenix" iconPosition="end" className="fs-16 text-capitlize"> Ui Design</Badge> */}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span
                                  style={{
                                    color:
                                      item?.action_point_status === 'pending'
                                        ? 'red'
                                        : ''
                                  }}
                                >
                                  {formattedDueDate}
                                </span>
                              </td>
                              <td>{formattedCompleteDate}</td>
                              <td>
                                <Badge
                                  bg={getBedgeBg(item?.action_point_status)}
                                  variant="phoenix"
                                  iconPosition="end"
                                  className="fs-16 "
                                >
                                  {' '}
                                  {item?.action_point_status}{' '}
                                  {Number(item?.is_reopen) === 1 ? '(R)' : ''}
                                  {Number(item?.is_approved) === 1 ? (
                                    <Unicon
                                      icon={UilCheckCircle}
                                      size={12}
                                      id="approved"
                                    />
                                  ) : (
                                    ''
                                  )}
                                </Badge>
                              </td>
                              <td>
                                <div className="avatar_width">
                                  <Avatar3
                                    size="m"
                                    variant="name"
                                    className={`me-1 ${
                                      Number(loggedInUser?.id) ===
                                      Number(item?.creator?.id)
                                        ? 'loggedin-user'
                                        : 'normal-user'
                                    }`}
                                    border={
                                      Number(loggedInUser?.id) ===
                                      Number(item?.creator?.id)
                                        ? '2'
                                        : '0'
                                    }
                                  >
                                    {item?.creator?.short_name?.slice(0, 2)}
                                  </Avatar3>
                                </div>
                              </td>
                              <td>
                                <div className="avatar_width">
                                  <Avatar3
                                    size="m"
                                    variant="name"
                                    className={`me-1 ${
                                      Number(loggedInUser?.id) ===
                                      Number(item?.auditor?.id)
                                        ? 'loggedin-user'
                                        : 'normal-user'
                                    }`}
                                    border={
                                      Number(loggedInUser?.id) ===
                                      Number(item?.auditor?.id)
                                        ? '2'
                                        : '0'
                                    }
                                  >
                                    {item?.auditor?.short_name?.slice(0, 2)}
                                  </Avatar3>
                                </div>
                              </td>

                              <td>
                                {loggedInUser &&
                                  getActionOptions(item, loggedInUser.id)}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>

                  {summary?.total !== 0 ? (
                    <Row className="align-items-center pt-3 row">
                      <Col className="d-flex fs-9">
                        <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900">
                          {indexValueFirst} to {indexValueLast}
                          <span className="text-600"> items of </span>
                          {summary?.total}
                        </p>
                      </Col>
                      <Col xs="auto">
                        <CustomPagination
                          pageIndex={pageIndex}
                          totalPage={summary?.total_page}
                          activePage={Number(summary?.page)}
                          setPageIndex={setPageIndex}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
                      <span className="text-600"> No record found !!</span>
                    </p>
                  )}
                </>
              )}
            </PhoenixDocCard>
          ) : (
            <div>
              <Row className="g-3 mb-4 ">
                {data &&
                  data.map((item: ActionPoint, index: number) => {
                    //check and change due_date format
                    let due_date: string[] = (item?.due_date ?? '').split(' ');
                    let formattedDueDate = '';
                    if (due_date[0]) {
                      formattedDueDate = formatDueDate(
                        new Date(item?.due_date)
                      );
                    }
                    //check and change expected_date format
                    let completion_time: string[] = (
                      item?.completion_time ?? ''
                    ).split(' ');
                    let formattedCompleteDate = '';
                    if (completion_time[0]) {
                      formattedCompleteDate = formatDueDate(
                        new Date(`${completion_time[0]} ${completion_time[1]}`)
                      );
                    }
                    return (
                      <Col xs={12} md={6} xl={4} xxl={3} key={index}>
                        <Card className="h-100 hover-actions-trigger">
                          <Card.Body className="py-3">
                            <div className="d-flex justify-content-between align-items-center  text-700 fw-semi-bold mb-3">
                              <Badge
                                bg={getBedgeBg(item?.action_point_status)}
                                variant="phoenix"
                                iconPosition="end"
                                className="fs-16 "
                              >
                                {' '}
                                {item?.action_point_status}{' '}
                                {item?.is_reopen === 1 ? '(R)' : ''}
                                {item?.is_approved === 1 ? (
                                  <Unicon
                                    icon={UilCheckCircle}
                                    size={12}
                                    id="approved"
                                  />
                                ) : (
                                  ''
                                )}
                              </Badge>
                              {loggedInUser &&
                                getActionOptions(item, loggedInUser?.id)}
                            </div>
                            <div className="d-flex align-items-center gap-1 mb-3">
                              <div className="avatar_width w-auto">
                                <Avatar3
                                  size="s"
                                  variant="name"
                                  className={`me-1 ${
                                    Number(loggedInUser?.id) ===
                                    Number(item?.assignee?.id)
                                      ? 'loggedin-user'
                                      : 'normal-user'
                                  }`}
                                  border={
                                    Number(loggedInUser?.id) ===
                                    Number(item?.assignee?.id)
                                      ? '2'
                                      : '0'
                                  }
                                >
                                  {item?.assignee?.short_name?.slice(0, 2)}
                                </Avatar3>
                              </div>
                              <div
                                className="text-decoration-none fw-semi-bold fs-8 action-point-title"
                                onClick={() => {
                                  setActionPointData(item);
                                  setActionPointDetailsModal(true);
                                }}
                              >
                                {item?.title.length > 60
                                  ? `${item.title.substring(0, 60)}...`
                                  : item.title}
                              </div>
                            </div>
                            <Row>
                              <Col>
                                <div className="d-flex align-items-center gap-1 fs-9 fw-semi-bold">
                                  <div className="avatar_width w-auto">
                                    <Avatar3
                                      size="s"
                                      variant="name"
                                      className={`me-1 ${
                                        Number(loggedInUser?.id) ===
                                        Number(item?.creator?.id)
                                          ? 'loggedin-user'
                                          : 'normal-user'
                                      }`}
                                      border={
                                        Number(loggedInUser?.id) ===
                                        Number(item?.creator?.id)
                                          ? '2'
                                          : '0'
                                      }
                                    >
                                      {item?.creator?.short_name?.slice(0, 2)}
                                    </Avatar3>
                                  </div>
                                  <span
                                    style={{
                                      color:
                                        item?.action_point_status === 'pending'
                                          ? 'red'
                                          : ''
                                    }}
                                  >
                                    {' '}
                                    {formattedDueDate}
                                  </span>
                                </div>
                              </Col>
                              <Col>
                                <div className="d-flex align-items-center gap-1 justify-content-end fs-9 fw-semi-bold">
                                  <span className="text-1000 ">
                                    {' '}
                                    {formattedCompleteDate}
                                  </span>
                                  <div className="avatar_width w-auto">
                                    <Avatar3
                                      size="s"
                                      variant="name"
                                      className={`me-1 ${
                                        Number(loggedInUser?.id) ===
                                        Number(item?.auditor?.id)
                                          ? 'loggedin-user'
                                          : 'normal-user'
                                      }`}
                                      border={
                                        Number(loggedInUser?.id) ===
                                        Number(item?.auditor?.id)
                                          ? '2'
                                          : '0'
                                      }
                                    >
                                      {item?.auditor?.short_name?.slice(0, 2)}
                                    </Avatar3>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
              {summary?.total !== 0 ? (
                <Row className="align-items-center pt-3 row">
                  <Col className="d-flex fs-9">
                    <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900">
                      {indexValueFirst} to {indexValueLast}
                      <span className="text-600"> items of </span>
                      {summary?.total}
                    </p>
                  </Col>
                  <Col xs="auto">
                    <CustomPagination
                      pageIndex={pageIndex}
                      totalPage={summary?.total_page}
                      activePage={Number(summary?.page)}
                      setPageIndex={setPageIndex}
                    />
                  </Col>
                </Row>
              ) : (
                <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
                  <span className="text-600"> No record found !!</span>
                </p>
              )}
              {loading && <Spinner animation="border" variant="primary" />}
            </div>
          )}
        </div>
      </AdvanceTableProvider>

      <Suspense fallback={<div>Loading...</div>}>
        {openAddModal && (
          <AddActionPointModal
            show={openAddModal}
            handleClose={() => setOpenAddModal(false)}
            setAddStatus={setAddStatus}
            toast={toast}
            presentUser={presentUser}
            projectsData={projectsData}
            setPageFilters={setPageFilters}
            navigation={navigation}
          />
        )}
        {openUpdateModal && (
          <UpdateActionPointModal
            show={openUpdateModal}
            handleClose={() => setOpenUpdateModal(false)}
            setAddStatus={setAddStatus}
            toast={toast}
            presentUser={presentUser}
            projectsData={projectsData}
            setPageFilters={setPageFilters}
            editActionPoint={editActionPoint}
            actionPointData={actionPointData}
            navigation={navigation}
          />
        )}
        {openReqExpDateModal && (
          <ReqExpDateModal
            show={openReqExpDateModal}
            handleClose={() => setOpenReqExpDateModal(false)}
            setAddStatus={setAddStatus}
            toast={toast}
            actionPointData={actionPointData}
            navigation={navigation}
          />
        )}
        {declineActionPointModal && (
          <DeclineActionPointModal
            show={declineActionPointModal}
            handleClose={() => setDeclineActionPointModal(false)}
            setAddStatus={setAddStatus}
            toast={toast}
            actionPointData={actionPointData}
            navigation={navigation}
          />
        )}
        {markAsDoneModal && (
          <MarkAsDoneModal
            show={markAsDoneModal}
            handleClose={() => setMarkAsDoneModal(false)}
            setAddStatus={setAddStatus}
            toast={toast}
            actionPointData={actionPointData}
            navigation={navigation}
          />
        )}

        {approvedModal && (
          <ApprovedActionPointModal
            show={approvedModal}
            handleClose={() => setApprovedModal(false)}
            setAddStatus={setAddStatus}
            toast={toast}
            actionPointData={actionPointData}
            navigation={navigation}
          />
        )}

        {reopanModal && (
          <ReopenActionPointModal
            show={reopanModal}
            handleClose={() => setReopanModal(false)}
            setAddStatus={setAddStatus}
            toast={toast}
            presentUser={presentUser}
            projectsData={projectsData}
            editActionPoint={editActionPoint}
            setPageFilters={setPageFilters}
            actionPointData={actionPointData}
            navigation={navigation}
          />
        )}
        {actionPointDetailsModal && (
          <ActionPointDetailsModal
            show={actionPointDetailsModal}
            handleClose={() => setActionPointDetailsModal(false)}
            actionPointData={actionPointData}
            navigation={navigation}
          />
        )}
      </Suspense>
      <ToastContainer />
    </div>
  );
};

export default AllActionPoints;
