import { Button, Form, Tooltip } from "react-bootstrap"
import ColorPalette from "./ColorPalette"
import PhoenixFloatingLabel from "components/base/PhoenixFloatingLabel"
import { BoardLabels, CreateNewLabel, KanbanBoardItem, KanbanBoardTask } from "data/kanban";
import { useEffect, useState } from "react";
import apiCall from "services/api";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "providers/AuthContext";
import { useToast } from "providers/ToastProvider";
import { useKanbanContext } from "providers/KanbanProvider";
import { CardDateTime } from "../../KanbanTaskDetailsModal";
import { dateAndTime } from "common/timeStampToDate";
import { AxiosError } from "axios";
import { handleCutomError } from "services/handleCutomError";
import { OverlayTrigger } from "react-bootstrap";

export interface CardLoading {
    addLabel: boolean,
    removeLabels: boolean,
    removeLabelId: number
}

interface CreateTagsProps {
    list: KanbanBoardItem;
    task: KanbanBoardTask;
    setCardDateTime: React.Dispatch<React.SetStateAction<CardDateTime>>;
    setCardDetails: React.Dispatch<React.SetStateAction<KanbanBoardTask | undefined>>;
}

const regexPatterns = {
    titleAlphaNumeric: /^[a-zA-Z0-9\s]{3,75}$/, // Adjusted to include spaces
    hexColor: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, // Matches #RRGGBB or #RGB
};

const CreateTags: React.FC<CreateTagsProps> = ({ list, task, setCardDateTime, setCardDetails }) => {
    const { alias, id } = useParams();
    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const { showErrorToast, showSuccessToast } = useToast();
    const { boardLists, createUpdateLabel, createNewLabelFormData, boardLabelsList, kanbanDispatch } = useKanbanContext();
    const navigation = useNavigate() as NavigateFunction;
    const [validated, setValidated] = useState<boolean>(false);
    const [hoveredId, setHoveredId] = useState<number | null>(null);
    const [loading, setLoading] = useState<CardLoading>({
        addLabel: false,
        removeLabels: false,
        removeLabelId: 0
    });
    const [errorLabel, setErrorLabel] = useState({
        title: '',
        colorPalette: ''
    });

    const [updatedLabel, setUpdatedLabel] = useState<BoardLabels>({
        id: 0,
        title: '',
        color: ''
    });

    useEffect(() => {
        if (createUpdateLabel) {
            const labelId = boardLabelsList.find((label) => label.color === createNewLabelFormData.colorPalette.code); // get updated label  Data
            if (labelId) {
                setUpdatedLabel(labelId); // Set the label if found
            } else {
                setUpdatedLabel({
                    id: 0,
                    title: '',
                    color: '',
                });
            }
        }

    }, [boardLabelsList, createNewLabelFormData])

    const handleSubmitLabels = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        // Validate label and color
        let isValid = true;
        const newErrors: { title: string; colorPalette: string } = {
            title: '',
            colorPalette: ''
        };

        // if (!createNewLabelFormData.title.trim()) {
        //     newErrors.title = "Label is required.";
        //     isValid = false;
        // } else if (createNewLabelFormData.title.trim().length < 3) {
        //     newErrors.title = "Label must be at least 3 characters long.";
        //     isValid = false;
        // } else if (!regexPatterns.titleAlphaNumeric.test(createNewLabelFormData.title)) {
        //     newErrors.title = "Name should contain 3 to 75 alphanumeric characters.";
        //     isValid = false;
        // }

        if (!regexPatterns.hexColor.test(createNewLabelFormData?.colorPalette?.code)) {
            newErrors.colorPalette = "Color must be a valid hex code (e.g., #FFFFFF).";
            isValid = false;
        }

        if (!isValid) {
            setErrorLabel(newErrors);
            return;
        }

        try {
            setLoading((prev) => ({
                ...prev,
                addLabel: true
            }));
            const formDataSubmit = new FormData();

            formDataSubmit.append('title', createNewLabelFormData.title);
            formDataSubmit.append('color', createNewLabelFormData.colorPalette.className);


            const apiUrl = createUpdateLabel ? `project/${alias}/boards/${id}/masters/label/${updatedLabel?.id}/update` : `project/${alias}/boards/${id}/masters/label/create`
            const response = await apiCall({
                url: apiUrl,
                method: 'POST',
                data: formDataSubmit,
                headers: {
                    'x-access-token': userTkn,
                    workspace: workSpaceTkn
                }
            });
            if (response?.status === 200) {
                if (response?.data?.errors) {
                    const errors = response?.data.errors;
                    // Dynamically set errors based on the response
                    Object.keys(errors).forEach((key) => {
                        const errorMessages = errors[key];
                        const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                        // Set the error state for the corresponding field
                        setErrorLabel((prevData) => ({
                            ...prevData,
                            [key]: firstErrorMessage,
                        }));
                    });
                } else {
                    const labelData = response?.data?.data?.boardMasterLabel;
                    if (labelData?.id) {
                        await addLabelInCard(labelData?.id);
                        await fetchLabelData()
                        kanbanDispatch({
                            type: 'TOGGLE_LABEL_FORMS', payload: {
                                labels: false,
                                labelsList: true,
                                createLabel: false
                            }
                        });
                        kanbanDispatch({ type: 'TOGGLE_CREATE_UPDATE_LABEL', payload: false })
                    }

                }
            }

        } catch (error: any) {
            showErrorToast('413 Request Entity Too Large');

        } finally {
            setLoading((prev) => ({
                ...prev,
                addLabel: false
            }));
        }
    }
    const onChangeLabel = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        let errorMessage = "";

        if (name === "label") {
            if (!value.trim()) {
                errorMessage = "Label is required.";
            } else if (value.trim().length < 3) {
                errorMessage = "Label must be at least 3 characters long.";
            } else if (!regexPatterns.titleAlphaNumeric.test(value)) {
                errorMessage = "Name should contain 3 to 75 alphanumeric characters.";
            }
        }
        const newLabel: CreateNewLabel = {
            id: createNewLabelFormData?.id,
            colorPalette: createNewLabelFormData.colorPalette,
            title: value || "",
        };
        kanbanDispatch({ type: 'CREATE_NEW_LABEL', payload: newLabel });

        setErrorLabel((prevData) => ({
            ...prevData,
            [name]: errorMessage,
        }));
    };

    const addLabelInCard = async (labelId: number) => {
        try {
            setLoading((prev) => ({
                ...prev,
                assignToUpdate: true
            }));
            const formData = new FormData();
            if (labelId) {
                formData.append('board_master_label_id', String(labelId) || '');
            }
            const response = await apiCall({
                url: `project/${alias}/boards/${id}/card/${task?.id}/add-label`,
                method: 'POST',
                data: formData,
                headers: {
                    'x-access-token': userTkn,
                    workspace: workSpaceTkn
                }
            });
            if (response.status === 200) {
                showSuccessToast('Card updated successfully!');
                const updatedItem = response?.data?.data?.boardListCard;

                const updatedBoardLists = boardLists.map((item) => {
                    if (Number(item.id) === Number(list.id)) {
                        // Update the board list by replacing the card within boardListCards
                        return {
                            ...item,
                            boardListCards: item.boardListCards.map(card =>
                                // If the card matches, replace it, otherwise keep it as is
                                card.id === updatedItem.id ? updatedItem : card
                            )
                        };
                    }
                    return item;
                });
                const { formattedDate, formattedTime } = dateAndTime(updatedItem.end_datetime)
                setCardDateTime({
                    cardDate: formattedDate,
                    cardTime: formattedTime
                })
                setCardDetails(updatedItem);
                kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });
            } else {
                throw new Error('Failed to update card');
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An unexpected error occurred:', error);
                showErrorToast('An unexpected error occurred');
            }
        } finally {
            setLoading((prev) => ({
                ...prev,
                assignToUpdate: false
            }));
            // setIsEditing(false);
        }
    }

    const deleteLabelFromList = async (labelId: number) => {
        try {
            setLoading((prev) => ({
                ...prev,
                addLabel: true
            }));

            const apiUrl = `project/${alias}/boards/${id}/masters/label/${updatedLabel?.id}/delete`;
            const response = await apiCall({
                url: apiUrl,
                method: 'POST',
                headers: {
                    'x-access-token': userTkn,
                    workspace: workSpaceTkn
                }
            });
            if (response?.status === 200) {
                if (response?.data?.errors) {
                    const errors = response?.data.errors;
                    // Dynamically set errors based on the response
                    Object.keys(errors).forEach((key) => {
                        const errorMessages = errors[key];
                        const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                        // Set the error state for the corresponding field
                        setErrorLabel((prevData) => ({
                            ...prevData,
                            [key]: firstErrorMessage,
                        }));
                    });
                } else {
                    kanbanDispatch({
                        type: 'TOGGLE_LABEL_FORMS', payload: {
                            labels: false,
                            labelsList: true,
                            createLabel: false
                        }
                    });
                    kanbanDispatch({ type: 'TOGGLE_CREATE_UPDATE_LABEL', payload: false })

                    if (updatedLabel?.id) {
                        removeLabelInCard(updatedLabel?.id)
                        fetchLabelData();
                    }

                }
            }

        } catch (error: any) {
            showErrorToast('413 Request Entity Too Large');

        } finally {
            setLoading((prev) => ({
                ...prev,
                addLabel: false
            }));
        }
    }

    const removeLabelInCard = async (labelId: number) => {
        try {
            // setLoading((prev) => ({
            //     ...prev,
            //     assignToUpdate: true
            // }));

            const URL = `project/${alias}/boards/${id}/card/${task?.id}/remove-label?label_id=${labelId}`

            const response = await apiCall({
                url: URL,
                method: 'POST',
                headers: {
                    'x-access-token': userTkn,
                    workspace: workSpaceTkn
                }
            });
            if (response.status === 200) {
                showSuccessToast('Card updated successfully!');
                const updatedItem = response?.data?.data?.boardListCard;

                const updatedBoardLists = boardLists.map((item) => {
                    if (Number(item.id) === Number(list.id)) {
                        // Update the board list by replacing the card within boardListCards
                        return {
                            ...item,
                            boardListCards: item.boardListCards.map(card =>
                                // If the card matches, replace it, otherwise keep it as is
                                card.id === updatedItem.id ? updatedItem : card
                            )
                        };
                    }
                    return item;
                });
                const { formattedDate, formattedTime } = dateAndTime(updatedItem.end_datetime)
                setCardDateTime({
                    cardDate: formattedDate,
                    cardTime: formattedTime
                })
                setCardDetails(updatedItem);
                kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });

            } else {
                throw new Error('Failed to update card');
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An unexpected error occurred:', error);
                showErrorToast('An unexpected error occurred');
            }
        } finally {
            // setLoading((prev) => ({
            //   ...prev,
            //   assignToUpdate: false
            // }));
            // setIsEditing(false);
        }
    }

    const fetchLabelData = async () => {
        try {


            const [boardLabelsResponse] = await Promise.all([


                apiCall({
                    url: `project/${alias}/boards/${id}/masters/label`,  // updated
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                        workspace: workSpaceTkn
                    }
                })
            ])
            if (boardLabelsResponse?.status === 200) {
                const labelsData = boardLabelsResponse?.data?.data?.boardMasterLabel;
                kanbanDispatch({ type: 'BOARD_LABELS_LIST', payload: labelsData })
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                showErrorToast('An unexpected error occurred');
            }
        } finally {

        }
    };
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmitLabels} className='h-100 scrollbar mt-2'>
            <div className="d-flex flex-column gy-3">
                <div className='mb-3'>
                    <div className="selected-color-container">
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="overlay-trigger-example">
                                    Color: {createNewLabelFormData?.colorPalette?.name} Title: {createNewLabelFormData?.title ? createNewLabelFormData?.title : 'none'}
                                </Tooltip>
                            }
                        >
                            <div className={`selected-color  d-flex align-items-center ${createNewLabelFormData?.colorPalette?.className}`}
                                style={{
                                    backgroundColor: hoveredId === createNewLabelFormData.id ? createNewLabelFormData.colorPalette?.codeHoverd : createNewLabelFormData.colorPalette?.code,
                                }}
                                onMouseEnter={() => setHoveredId(createNewLabelFormData?.id)}
                                onMouseLeave={() => setHoveredId(null)}
                            >
                                <span className="fs-9">
                                    {createNewLabelFormData?.title}
                                </span>
                            </div>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className='mb-3'>
                    <PhoenixFloatingLabel
                        label="Label Title"
                        className="flex-1"
                    >
                        <Form.Control
                            type="text"
                            placeholder="Label"
                            name="title"
                            value={createNewLabelFormData.title}
                            onChange={onChangeLabel}
                            isInvalid={!!errorLabel?.title}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errorLabel?.title}
                        </Form.Control.Feedback>
                    </PhoenixFloatingLabel>
                </div>
                <div>Select a color</div>
                <ColorPalette />

            </div>
            <div className='d-flex justify-content-between pb-2 mt-2'>
                <div>
                    {createUpdateLabel && <Button
                        variant="outline-primary"
                        className="px-2"
                        onClick={() => { deleteLabelFromList(updatedLabel?.id) }}
                        disabled={loading?.addLabel}
                    >
                        Delete
                    </Button>
                    }
                </div>
                <div>
                    <Button
                        variant="outline-primary"
                        className="px-2"
                        type='submit'
                        disabled={loading?.addLabel}
                    >
                        {createUpdateLabel ? 'Update' : 'Create'}
                    </Button>
                </div>
            </div>
        </Form>
    )
}

export default CreateTags