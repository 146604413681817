import { UilPen, UilTrash } from "@iconscout/react-unicons";
import calculateSerialNumber from "common/calculateSerialNumber";
import Constants from "common/Constants";
import Avatar from "components/base/Avatar";
import Button from "components/base/Button";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import Unicon from "components/base/Unicon";
import CustomPagination from "components/sp-common/CustomPagination";
import { initialSummary, SummaryProps } from "data/kanban";
import { useAuth } from "providers/AuthContext";
import { useMaster } from "providers/MasterContext";
import { useProjectAdminContext } from "providers/ProjectAdminProvider";
import { useProjectContext } from "providers/ProjectProvider";
import { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Link, NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { PAST_USER_INDEX_VALUE_FIRST, PAST_USER_INDEX_VALUE_LAST, PAST_USER_PAGE_INDEX, PAST_USER_SUMMARY, PROJECT_PAST_USER_PERMISSION_DATA, PROJECT_PRESENT_USER_PERMISSION_DATA } from "reducers/ProjectReducer";
import apiCall from "services/api";
import { handleCutomError } from "services/handleCutomError";
import { UserPermission } from "types/project";
import { geDesignationNameById } from "utils/getNames";

const ProjectPastUser = () => {
    const { alias, id, workspace } = useParams();
    const { projectData, pastUserPermission, pageIndexPastUser, summaryPastUser, activeTabSetting, indexValueFirstPastUser, indexValueLastPastUser, projectDispatch } = useProjectContext();
    const { userTkn, workSpaceTkn, isUserAdmin, signOut } = useAuth();
    const { adminValues, setAdminValues } = useProjectAdminContext();
    const { projCategory, designation, phases } = useMaster();
    const navigation = useNavigate() as NavigateFunction;
    const tableRef = useRef<HTMLTableElement | null>(null);
    // const [summary, setSummary] = useState<SummaryProps>(initialSummary);
    // const [indexValueFirst, setValueFirst] = useState<number | null>(1);
    // const [indexValueLast, setValueLast] = useState<number | null>(1);
    // const [pageIndex, setPageIndex] = useState<number | null>(1);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true)

                const [response2] = await Promise.all([

                    apiCall({
                        url: `admin/project/${alias}/permissions/list?expand=user&page=${pageIndexPastUser}`,
                        method: 'GET',
                        headers: {
                            'x-access-token': userTkn,
                            'workspace': workSpaceTkn
                        },
                    }),
                ])

                if (response2.status === 200) {
                    let permissionData = response2?.data?.data?.permission

                    const pastUsers = permissionData.filter((user: UserPermission) => user.status !== 1);


                    const updatedPastUsers = pastUsers?.map((users: any, index: any) => ({
                        ...users,
                        serialNumber: calculateSerialNumber(pageIndexPastUser || 1, index, response2?.data?.data?.summary?.pageSize) // Assuming 10 items per page
                    }));

                    projectDispatch({ type: PROJECT_PAST_USER_PERMISSION_DATA, payload: updatedPastUsers });
                    const summary = response2?.data?.data?.summary;

                    projectDispatch({
                        type: PAST_USER_SUMMARY, payload: {
                            "total": summary.total,
                            "page": summary.page,
                            "pageSize": summary.pageSize,
                            "total_page": summary.total_page,
                        }
                    });
                } else {
                    console.error("error message:");
                }
            } catch (error: any) {
                if (error?.isAxiosError) {
                    // Access the error message
                    handleCutomError(error, signOut, navigation);
                } else {
                    // Handle other types of errors
                    console.error('An error occurred:', error);
                    // Display a generic error message to the user
                    //   toast.error('An unexpected error occurred', {
                    //     position: "top-center"
                    //   });
                }
            } finally {
                setLoading(false)
            }
        };
        fetchData();
    }, [userTkn, pageIndexPastUser, activeTabSetting]);

    useEffect(() => {
        // Now 'tableRef.current' contains the reference to the table element
        const table = tableRef.current;

        if (table) {
            const firstRow = table?.rows[1];
            const lastRowIndex = table?.rows.length - 1;
            const lastRow = table?.rows[lastRowIndex];
            if (firstRow) {
                const firstCell = firstRow.cells[0];
                if (firstCell) {
                    const cellValue = firstCell.textContent || firstCell.innerText;
                    const value = Number(cellValue)
                    projectDispatch({ type: PAST_USER_INDEX_VALUE_FIRST, payload: value });
                }
            }

            if (lastRow) {
                const firstCell = lastRow.cells[0];
                if (firstCell) {
                    const cellValue = firstCell.textContent || firstCell.innerText;
                    const value = Number(cellValue)
                    projectDispatch({ type: PAST_USER_INDEX_VALUE_LAST, payload: value });
                }
            }
        }
    }, [pastUserPermission]);

    const setPageIndex = (newPageIndex: number) => {
        projectDispatch({ type: PAST_USER_PAGE_INDEX, payload: newPageIndex });
    };
    return (
        <>

            <Table className="phoenix-table fs-9 project-table border rounded" size='sm' striped hover responsive ref={tableRef}>
                <thead>
                    <tr id={'a1'} >
                        <th style={{ width: '2%', maxWidth: '20px', padding: "16px 0px" }}>#</th>
                        <th className="px-1" style={{ width: '25%', minWidth: '200px' }}>{Constants.name}</th>
                        <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.email}</th>
                        <th className="px-1" style={{ width: '20%', minWidth: '100px' }}>{Constants.designation}</th>
                        {adminValues.is_project_admin === 1 && Number(projectData?.status) !== 0 ? <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.action}</th> : null}
                    </tr>
                </thead>
                <tbody>
                    {pastUserPermission && pastUserPermission?.map((item: UserPermission, index: number) => (
                        <tr key={index}>
                            <td>{item?.serialNumber}</td>
                            <td> <div className="d-flex align-items-center mb-2 order-2">
                                <div className="avatar_width">
                                    <Avatar
                                        size="m"
                                        variant="name"
                                        className="me-1 "
                                    >
                                        {item?.user?.short_name?.slice(0, 2)}
                                    </Avatar>
                                </div>
                                <div className="fw-bold mb-0 text-truncate lh-1">
                                    <div className="d-flex gap-2 align-items-center">
                                        <span className="fw-semi-bold ms-1 fs-8 text-800">
                                            {`${item?.user?.first_name} ${item?.user?.last_name}`}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            </td>
                            <td> {item?.user?.email}</td>
                            <td>
                                {geDesignationNameById(Number(item?.user?.designation_id), designation)}
                            </td>
                            {adminValues.is_project_admin === 1 && Number(projectData?.status) !== 0 ?
                                <td>
                                    <div className="d-flex gap-2  align-items-center">
                                        <Button
                                            variant="phoenix-primary"
                                            className="btn-icon rounded-1 bg-transparent"
                                        // onClick={() => {

                                        //   setUserPermission({
                                        //     id: String(item.id),
                                        //     user_id: String(item?.user_id) || "",
                                        //     role_in_project: item?.role_in_project !== null ? item?.role_in_project : "",
                                        //     projectlogo: String(item?.projectlogo) || "",
                                        //     is_project_admin: String(item?.is_project_admin) || "",
                                        //     is_story_admin: String(item?.is_story_admin) || "",
                                        //     is_meeting_admin: String(item?.is_meeting_admin) || "",
                                        //     is_diary_admin: String(item?.is_diary_admin) || "",
                                        //     user_designation: String(item?.user.designation_id) || "",
                                        //   });
                                        //   setOpenUpdateModal(true); // Optionally open the update modal
                                        // }}
                                        >
                                            <Unicon icon={UilPen} size={18} />
                                        </Button>
                                        <Button
                                            variant="phoenix-primary"
                                            className="btn-icon rounded-1 bg-transparent"
                                        // onClick={() => { setConfirmedItemAlias({ id: item.id, name: `${item?.user?.first_name} ${item?.user?.last_name}` }); setShowConfirmation(true) }}
                                        >
                                            <Unicon icon={UilTrash} size={18} />
                                        </Button>

                                    </div>
                                </td>
                                : null
                            }
                        </tr>
                    ))}
                </tbody>
            </Table>
            {summaryPastUser?.total !== 0 ? <Row className="align-items-center pt-3 row">
                <Col className="d-flex fs-9">
                    <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900">
                        {indexValueFirstPastUser} to {indexValueLastPastUser}
                        <span className="text-600"> items of </span>
                        {summaryPastUser?.total}
                    </p>
                </Col>
                <Col xs="auto">
                    <CustomPagination
                        pageIndex={pageIndexPastUser}
                        totalPage={summaryPastUser?.total_page}
                        activePage={Number(summaryPastUser?.page)}
                        setPageIndex={setPageIndex}
                    />
                </Col>
            </Row>
                :
                <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
                    <span className="text-600"> No record found !!</span>
                </p>
            }
        </>
    )
}

export default ProjectPastUser;