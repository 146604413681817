import { AxiosError } from 'axios';
import Button from 'components/base/Button';
import { initialLinkData, KanbanBoardItem, KanbanBoardTask, TaskLinks } from 'data/kanban';
import { useAuth } from 'providers/AuthContext';
import { useKanbanContext } from 'providers/KanbanProvider';
import { useToast } from 'providers/ToastProvider';
import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import apiCall from 'services/api';
import { handleCutomError } from 'services/handleCutomError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactSelect from 'components/base/ReactSelect';
import Unicon from 'components/base/Unicon';
import { UilPen, UilTrash } from '@iconscout/react-unicons';
import { CardDateTime } from '../KanbanTaskDetailsModal';
import { dateAndTime } from 'common/timeStampToDate';
import ToggleViewAvtar from 'components/common/ToggleViewAvtar';
import Avatar from 'components/base/Avatar';


interface EditableLinksProps {
    task: KanbanBoardTask;
    list: KanbanBoardItem;
    setCardDetails: React.Dispatch<React.SetStateAction<KanbanBoardTask | undefined>>;
    setCardDateTime: React.Dispatch<React.SetStateAction<CardDateTime>>;
    onHistoryUpdate?: () => void;
}

export interface Loading {
    linksUpdate: boolean;
    deleteUser: number;
}


const EditableLinks: React.FC<EditableLinksProps> = ({ task, list, setCardDetails, setCardDateTime, onHistoryUpdate }) => {
    const { alias, id } = useParams();
    const { boardLists, cardCategory, userPermissionOnBoard, kanbanDispatch } = useKanbanContext();
    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const { showErrorToast, showSuccessToast } = useToast();
    const navigation = useNavigate();
    const [validated, setValidated] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [linkData, setLinkData] = useState<TaskLinks>(initialLinkData)
    const [loading, setLoading] = useState<Loading>({
        linksUpdate: false,
        deleteUser: 0
    });

    const [error, setError] = useState({
        link: '',
        title: ''
    });

    const handleLinkClick = () => {

        setIsEditing(true);
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value } = e.target;
    
        setLinkData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setError((prevData) => ({
            ...prevData,
            [name]: '',
        }));
    };


    const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        e?.stopPropagation();
        setError({
            link: '',
            title: '',
        });
        let isValid = true;
    
        // Validate link
        if (!linkData?.link?.trim()) {
            setError((prev) => ({
                ...prev,
                link: 'Link is required',
            }));
            isValid = false;
        }
    
        // Validate title if present
        if (linkData?.title?.trim() && linkData.title.trim().length > 75) {
            setError((prev) => ({
                ...prev,
                title: 'Title must not exceed 75 characters',
            }));
            isValid = false;
        }
        if (!isValid) {
            return;
        }

        try {
            setLoading((prev) => ({
                ...prev,
                linksUpdate: true
              }));

              const formDataSubmit = new FormData();
              formDataSubmit.append('link', linkData.link);
              if(linkData.title){
                formDataSubmit.append('title', linkData.title);
              }
              const apiUrl = `project/${alias}/boards/${id}/card/${task?.id}/add-link`;
              const response = await apiCall({
                url: apiUrl,
                method: 'POST',
                data: formDataSubmit,
                headers: {
                  'x-access-token': userTkn,
                  workspace: workSpaceTkn
                }
              });

              if (response?.status === 200) {
                if (response?.data?.errors) {
                  const errors = response?.data.errors;
                  // Dynamically set errors based on the response
                  Object.keys(errors).forEach((key) => {
                    const errorMessages = errors[key];
                    const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                    // Set the error state for the corresponding field
                    setError((prevData) => ({
                      ...prevData,
                      [key]: firstErrorMessage,
                    }));
                  });
                } else {
                  const cardData = response?.data?.data?.boardListCard;
             
                  const updatedBoardLists = boardLists.map((item) => {
                    if (Number(item.id) === Number(list.id)) {
                      // Update the board list by replacing the card within boardListCards
                      return {
                        ...item,
                        boardListCards: item.boardListCards.map(card =>
                          // If the card matches, replace it, otherwise keep it as is
                          card.id === cardData.id ? cardData : card
                        )
                      };
                    }
                    return item;
                  });
                  kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });
                  setCardDetails(cardData);
                  setLinkData(initialLinkData)
                  setIsEditing(false);
                  if (onHistoryUpdate) onHistoryUpdate();
           
                }
              }

        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An unexpected error occurred:', error);
                showErrorToast('An unexpected error occurred');
            }
        } finally {
            setLoading((prev) => ({
                ...prev,
                linksUpdate: false
            }));
          
        }
    }

    const handleCancel = () => {
        // Reset the description to the original state and exit edit mode

        setIsEditing(false); // Close the editor without submitting changes
    };

  
    const removeLink = async (linkId: number) => {
        const isConfirmed = window.confirm("Are you sure you want to remove this Link?");

        if (!isConfirmed) {
            // If the user clicks "No", simply return without doing anything
            return;
        }
        if(linkId === 0){
            showErrorToast('An unexpected error occurred');
            return
        }
        try {
            setLoading((prev) => ({
                ...prev,
                deleteUser: linkId
            }));
            const formData = new FormData();
       
            const response = await apiCall({
                url: `project/${alias}/boards/${id}/card/${task?.id}/remove-link?link_id=${linkId}`,
                method: 'POST',
                headers: {
                    'x-access-token': userTkn,
                    workspace: workSpaceTkn
                }
            });
            if (response.status === 200) {
                showSuccessToast('Link deleted successfully!');
                const cardData = response?.data?.data?.boardListCard;
                const updatedBoardLists = boardLists.map((item) => {
                    if (Number(item.id) === Number(list.id)) {
                      // Update the board list by replacing the card within boardListCards
                      return {
                        ...item,
                        boardListCards: item.boardListCards.map(card =>
                          // If the card matches, replace it, otherwise keep it as is
                          card.id === cardData.id ? cardData : card
                        )
                      };
                    }
                    return item;
                  });
                  kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });
                  setCardDetails(cardData);
                  if (onHistoryUpdate) onHistoryUpdate();
        
            } else {
                throw new Error('Failed to update card');
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An unexpected error occurred:', error);
                showErrorToast('An unexpected error occurred');
            }
        } finally {
            setLoading((prev) => ({
                ...prev,
                deleteUser: 0
            }));
        }
    }

    return (
        <>

            {isEditing ? (
                <div className='d-flex gap-1 bg-white p-3 mb-2 rounded-1'>
                    <div className='w-100'>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row>
                                <Col lg={12}>
                                    <Form.Control
                                        type="text"
                                        placeholder="link"
                                        name="link"
                                        value={linkData?.link || ''}
                                        onChange={handleInputChange}
                                        isInvalid={!!error?.link}
                                        className='editable-field'
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {error?.link}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col lg={12}>
                                    <Form.Control
                                        type="text"
                                        placeholder="title"
                                        name="title"
                                        value={linkData?.title || ''}
                                        onChange={handleInputChange}
                                        isInvalid={!!error?.title}
                                        className='editable-field'
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {error?.title}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>

                            <div className='d-flex gap-2 justify-content-center align-items-center mt-2'>
                                <Button
                                    startIcon={<FontAwesomeIcon icon={faTimes} />}
                                    onClick={handleCancel}
                                    className="p-0"
                                    disabled={loading?.linksUpdate}
                                >
                                    cancel
                                </Button>
                                <Button
                                    variant="outline-primary"
                                    type='submit'
                                    disabled={loading?.linksUpdate}
                                >
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </div>
                    {loading?.linksUpdate && <Spinner animation="border" variant="secondary" size="sm" />}
                </div>

            ) : (

                <div
                    className="d-flex gap-2 fs-9 mb-0 text-body-emphasis lh-sm w-100 editable-field jjj"

                >
                      <div>
                        {task?.links
                            ?
                            <div className='link-container'>
                                {task?.links?.map((item, index) => (
                                    <div className='d-flex gap-1'>
                                    
                                    <a href={item?.link} target="_blank" rel="noopener noreferrer" className='fs-9 fw-bold'>{item?.title}</a>
                              
                                       {userPermissionOnBoard && item?.id &&  <div>
                                            <Button
                                                variant="phoenix-primary"
                                                className="btn-icon"
                                                onClick={() => removeLink(item?.id || 0)}
                                            >
                                                <Unicon icon={UilTrash} size={16} />
                                            </Button>
                                            {loading?.deleteUser === item?.id && <Spinner animation="border" variant="secondary" size="sm" />}
                                        </div>
                                        }
                                    </div>
                                ))

                                }
                            </div>
                            : ''}
                    </div>
                    <div >
                        <Button
                            variant=""
                            className="btn-icon"
                            onClick={handleLinkClick}
                        >
                            <Unicon icon={UilPen} size={16} />
                        </Button>
                    </div>
                </div>
            )}

        </>
    );
};

export default EditableLinks;
