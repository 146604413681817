import { faAngleRight, faCircle, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KanbanBoardItem, KanbanBoardTask } from 'data/kanban';
import { Card, Dropdown } from 'react-bootstrap';
import { Fragment, useState } from 'react';
import classNames from 'classnames';
import KanbanTaskDetailsModal from './KanbanTaskDetailsModal';
import { getPriorityColor } from 'helpers/utils';
import { useKanbanContext } from 'providers/KanbanProvider';
import Avatar from 'components/base/Avatar';
import ToggleViewAvtar from 'components/common/ToggleViewAvtar';

const actions = [
  {
    id: 1,
    label: 'View',
    isNested: true
  },
  // {
  //   id: 2,
  //   label: 'Duplicate'
  // },
  // {
  //   id: 3,
  //   label: 'Jump to top'
  // },
  // {
  //   id: 4,
  //   label: 'Jump to bottom'
  // },
  // {
  //   id: 5,
  //   hr: true
  // },
  // {
  //   id: 6,
  //   label: 'Print/Download'
  // },
  // {
  //   id: 7,
  //   label: 'Share',
  //   isNested: true
  // },
  // {
  //   id: 8,
  //   hr: true
  // },
  // {
  //   id: 9,
  //   label: 'Move to archive',
  //   isNested: true
  // },
  // {
  //   id: 10,
  //   label: 'Delete',
  //   class: 'text-danger'
  // }
];

const KanbanListItemCard = ({
  task,
  list,
  className
}: {
  task: KanbanBoardTask;
  list: KanbanBoardItem;
  className?: string;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { cardPriority } = useKanbanContext();
  

  return (
    <>
      <Card
        className={classNames(className, 'sortable-item hover-actions-trigger  border border-gray-200 border hover-border-navy-blue', { 'bg-white': task?.status === 1, 'text-white bg-dark-subtle bg-gradient': task?.status === 0 })}

      >
        <Card.Body className="p-3 rounded-2" style={{ backgroundColor: task?.status === 1 ? task?.color : '' }}>
          {/* {task.coverImage && (
            <div
              className="position-relative mb-2 overflow-hidden rounded w-100"
              style={{ height: 200 }}
            >
              <div
                className="bg-holder banner-bg"
                style={{
                  backgroundImage: `url(${task.coverImage})`,
                  backgroundPosition: 'bottom left'
                }}
              />
            </div>
          )} */}
          <div className="kanban-status mb-1 position-relative lh-1">
            {/* <FontAwesomeIcon
              transform="shrink-1 down-3"
              icon={faCircle}
              className={`me-2 d-inline-block text-${task.status.color}`}
            /> */}
            {/* <Badge
              variant="phoenix"
              bg={task.status.color as BadgeBg}
              className="fs-10"
            >
              {task.status.label}
              <FontAwesomeIcon
                icon={task.status.icon}
                transform="up-2"
                className="ms-1 d-inline-block"
                style={{ height: 7.8, width: 7.8 }}
              />
            </Badge> */}
            <Dropdown autoClose="outside" className="position-static">
              <Dropdown.Toggle
                variant=""
                size="sm"
                className="hover-actions dropdown-caret-none kanban-item-dropdown-btn"
              >
                <FontAwesomeIcon icon={faEllipsisV} />
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-2" style={{ width: '15rem' }}>
                {actions.map(action => (

                  <Dropdown.Item
                    href="#!"
                    key={action.label}
                    className="d-flex flex-between-center"
                    onClick={() => setOpenModal(true)}
                  >
                    {action.label}
                  </Dropdown.Item>

                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className='d-flex justify-content-between'>
            <div className="attachments-container">
              {task?.priority && <div className="mb-0  text-body-emphasis fw-semibold priority-label">
                <FontAwesomeIcon
                  icon={faCircle}
                  transform="shrink-6 down-1"
                  color={task?.priority?.color}
                />
                {
                  task?.priority?.title
                }
              </div>
              }

              {task?.labels?.map((item, index) => (
                <div key={index} className="">
                  <div className="d-flex flex-row gap-1">
                    <div className={`fs-9 px-2 rounded-1 customLabel ${item?.color}`}>
                      {item?.title}
                    </div>
                  </div>
                </div>
              ))}

            </div>
          </div>
          <p className="mb-2 stretched-link fs-8 fw-semibold" onClick={() => setOpenModal(true)}>
            {task.title}
          </p>
          <div className="d-flex mt-2 align-items-center">
            {/* {task.date && (
              <p className="mb-0 text-body-tertiary text-opactity-85 fs-9 lh-1 me-3 white-space-nowrap">
                <FontAwesomeIcon
                  icon={faCalendarXmark}
                  className="fs-0 me-2 d-inline-block"
                />
                <>{task.date}</>
              </p>
            )} */}
            {/* {task.attachments && (
              <p className="mb-0 text-body-tertiary text-opactity-85 fs-9 lh-1">
                <FontAwesomeIcon
                  icon={faPaperclip}
                  className="fs-0 me-2 d-inline-block"
                />
                {task.attachments}
              </p>
            )} */}
            {/* {task.completedTasks && (
              <p className="mb-0 text-body-tertiary text-opactity-85 fs-9 lh-1">
                <FontAwesomeIcon
                  icon={faPaperclip}
                  className="fs-0 me-2 d-inline-block"
                />
                {task.completedTasks[0]} / {task.completedTasks[1]}
              </p>
            )} */}
            {/* {task.members && (
              <Avatar.Group
                total={task.members.length}
                size="s"
                className="ms-auto"
              >
                {task.members.slice(0, 4).map(member => (
                  <Avatar key={member.id} size="s" src={member.avatar} />
                ))}
              </Avatar.Group>
            )} */}
            {task?.assignTo?.short_name &&
              <ToggleViewAvtar
                tooltip={`${task?.assignTo?.short_name} ${task?.assignTo?.last_name}`}
              >
                <Avatar size="s" variant="name" key={task?.assignTo?.short_name}>{task?.assignTo?.short_name}</Avatar>
              </ToggleViewAvtar>
            }
          </div>
        </Card.Body>
      </Card>

      <KanbanTaskDetailsModal
        show={openModal}
        handleClose={() => setOpenModal(false)}
        task={task}
        list={list}
      />
    </>
  );
};

export default KanbanListItemCard;
