import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithCredential } from "firebase/auth";
import { GoogleSignin } from "@react-native-google-signin/google-signin";
import firebaseApp from "firebaseConfig";


const auth = getAuth(firebaseApp);

// ReactJS Google Login
export const loginWithGoogleWeb = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    return user;
  } catch (error) {
    console.error("Google login error:", error);
  }
};

// React Native Google Login
export const loginWithGoogleNative = async () => {
  try {
    GoogleSignin.configure({
      webClientId: "247195563552-vo0uaaijd4uj3rs61q4c81lcdvbhtt80.apps.googleusercontent.com",
    });

    const { idToken } = await GoogleSignin.signIn();
    const credential = GoogleAuthProvider.credential(idToken);
    const userCredential = await signInWithCredential(auth, credential);
    const user = userCredential.user;

    return user;
  } catch (error) {
    console.error("Google login error:", error);
  }
};
