import { AxiosError } from 'axios';
import { dateAndTime } from 'common/timeStampToDate';
import Button from 'components/base/Button';
import { KanbanBoardItem, KanbanBoardMembers, KanbanBoardTask } from 'data/kanban';
import { useAuth } from 'providers/AuthContext';
import { useKanbanContext } from 'providers/KanbanProvider';
import { useToast } from 'providers/ToastProvider';
import React, { useState, useEffect, useRef } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import apiCall from 'services/api';
import { handleCutomError } from 'services/handleCutomError';
import { CardDateTime } from '../KanbanTaskDetailsModal';
import Unicon from 'components/base/Unicon';
import { UilPen, UilTrash } from '@iconscout/react-unicons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactSelect from 'components/base/ReactSelect';
import ToggleViewAvtar from 'components/common/ToggleViewAvtar';
import Avatar from 'components/base/Avatar';


interface EditableUserProps {
    task: KanbanBoardTask;
    list: KanbanBoardItem;
    setCardDateTime: React.Dispatch<React.SetStateAction<CardDateTime>>;
    setCardDetails: React.Dispatch<React.SetStateAction<KanbanBoardTask | undefined>>;
    onHistoryUpdate?: () => void;
}

export interface Loading {
    userUpdate: boolean,
    deleteUser: number

}
interface User {
    label: string;
    value: string;
}

const EditableUser: React.FC<EditableUserProps> = ({ task, list, setCardDateTime, setCardDetails, onHistoryUpdate }) => {
    const { alias, id } = useParams();
    const { boardLists, boardUsersList, userPermissionOnBoard, kanbanDispatch } = useKanbanContext();
    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const { showErrorToast, showSuccessToast } = useToast();
    const navigation = useNavigate();
    const [validated, setValidated] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [taskData, setTaskData] = useState<KanbanBoardTask | undefined>(undefined);
    const [userOptions, setUserOptions] = useState<User[]>([]);
    const [loading, setLoading] = useState<Loading>({
        userUpdate: false,
        deleteUser: 0
    });
    const [originalUser, setOriginalUser] = useState<KanbanBoardMembers[]>([]);
    const [addUser, setAddUser] = useState<User | undefined>(undefined);
    const [error, setError] = useState({
        user: '',
    })
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (task) {
            setTaskData({
                ...task,
                id: task.id
            });
            setOriginalUser(task?.users || []);
        }
    }, [task]);

    useEffect(() => {
        //filter if user added in assignTo
        const updatedUser = boardUsersList?.filter((item) => item?.id !== taskData?.assignTo?.id);
        //filter if user added into user list
        const filteredUser = updatedUser?.filter((item) =>
            !taskData?.users?.map((user) => user.id).includes(item.id)
        );
        const options = filteredUser?.map((user: any) => ({
            value: user.id,
            label: `${user.first_name} ${user.last_name}`,
        }));
        setUserOptions(options);
    }, [boardUsersList, taskData]);

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus(); // Set focus to input field
        }
    }, [isEditing]);

    const handleUserClick = () => {
        setIsEditing(true);
        //on Click Enable editing mode
    };

    const handleUserChange = async (selectedOption: any) => {
        setAddUser(selectedOption);
        setError((prevError) => ({
            ...prevError,
            user: '',
        }));

        // Call handleSubmit to auto-save the selection
        // await handleSubmit();
    };

    const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        e?.stopPropagation();
        if (!addUser?.value) {
            return
        }
        try {
            setLoading((prev) => ({
                ...prev,
                userUpdate: true
            }));
            const formData = new FormData();
            if (addUser?.value) {
                formData.append('user', String(addUser?.value) || '');
            }
            const response = await apiCall({
                url: `project/${alias}/boards/${id}/card/${taskData?.id}/add-user`,
                method: 'POST',
                data: formData,
                headers: {
                    'x-access-token': userTkn,
                    workspace: workSpaceTkn
                }
            });
            if (response.status === 200) {
                showSuccessToast('Card updated successfully!');
                const updatedItem = response?.data?.data?.boardListCard;
                setTaskData(updatedItem);
                const updatedBoardLists = boardLists.map((item) => {
                    if (Number(item.id) === Number(list.id)) {
                        // Update the board list by replacing the card within boardListCards
                        return {
                            ...item,
                            boardListCards: item.boardListCards.map(card =>
                                // If the card matches, replace it, otherwise keep it as is
                                card.id === updatedItem.id ? updatedItem : card
                            )
                        };
                    }
                    return item;
                });
                const { formattedDate, formattedTime } = dateAndTime(updatedItem.end_datetime)
                setCardDateTime({
                    cardDate: formattedDate,
                    cardTime: formattedTime
                })
                setCardDetails(updatedItem);
                setOriginalUser(updatedItem.user);
                kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });
                if (onHistoryUpdate) onHistoryUpdate();
            } else {
                throw new Error('Failed to update card');
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An unexpected error occurred:', error);
                showErrorToast('An unexpected error occurred');
            }
        } finally {
            setLoading((prev) => ({
                ...prev,
                userUpdate: false
            }));
            setIsEditing(false);
        }
    }

    const handleCancel = () => {
        // Reset the description to the original state and exit edit mode
        setTaskData((prevData) => {
            if (prevData) {
                return {
                    ...prevData,
                    users: originalUser
                };
            }
            return prevData;
        });
        setIsEditing(false); // Close the editor without submitting changes
    };

    const removeUser = async (userId: number) => {
        const isConfirmed = window.confirm("Are you sure you want to remove this user?");

        if (!isConfirmed) {
            // If the user clicks "No", simply return without doing anything
            return;
        }
        try {
            setLoading((prev) => ({
                ...prev,
                deleteUser: userId
            }));
            const formData = new FormData();
            if (userId) {
                formData.append('user', String(userId) || '');
            }
            const response = await apiCall({
                url: `project/${alias}/boards/${id}/card/${taskData?.id}/remove-user`,
                method: 'POST',
                data: formData,
                headers: {
                    'x-access-token': userTkn,
                    workspace: workSpaceTkn
                }
            });
            if (response.status === 200) {
                showSuccessToast('User deleted successfully!');
                const updatedItem = response?.data?.data?.boardListCard;
                setTaskData(updatedItem);
                const updatedBoardLists = boardLists.map((item) => {
                    if (Number(item.id) === Number(list.id)) {
                        // Update the board list by replacing the card within boardListCards
                        return {
                            ...item,
                            boardListCards: item.boardListCards.map(card =>
                                // If the card matches, replace it, otherwise keep it as is
                                card.id === updatedItem.id ? updatedItem : card
                            )
                        };
                    }
                    return item;
                });
                const { formattedDate, formattedTime } = dateAndTime(updatedItem.end_datetime)
                setCardDateTime({
                    cardDate: formattedDate,
                    cardTime: formattedTime
                })
                setCardDetails(updatedItem);
                setOriginalUser(updatedItem.user);
                kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });
                if (onHistoryUpdate) onHistoryUpdate();
            } else {
                throw new Error('Failed to update card');
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An unexpected error occurred:', error);
                showErrorToast('An unexpected error occurred');
            }
        } finally {
            setLoading((prev) => ({
                ...prev,
                deleteUser: 0
            }));
        }
    }

    return (
        <>

            {isEditing ? (
                <div className='d-flex gap-1 bg-white p-3 mb-2 rounded-1'>
                    <div className='w-100'>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <ReactSelect
                                name="user"
                                options={userOptions}
                                onChange={handleUserChange}
                                value={userOptions?.filter((option: any) => Number(option.value) === Number(addUser?.value))}
                                // isMulti
                                closeMenuOnSelect={true}
                                placeholder="Select Member"
                                required
                                classNames={{
                                    control: () => 'py-2',
                                    valueContainer: () => 'lh-1'
                                }}
                            />
                            <span className="text-danger fs-9">{error?.user}</span>
                            <div className='d-flex gap-2 justify-content-center align-items-center mt-2'>
                                <Button
                                    startIcon={<FontAwesomeIcon icon={faTimes} />}
                                    onClick={handleCancel}
                                    className="p-0"
                                    disabled={loading?.userUpdate}
                                >
                                    cancel
                                </Button>
                                <Button
                                    variant="outline-primary"
                                    className=""
                                    type='submit'
                                    disabled={loading?.userUpdate}
                                >
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </div>
                    {loading?.userUpdate && <Spinner animation="border" variant="secondary" size="sm" />}
                </div>

            ) : (
                <div
                    className="fs-9 mb-0 d-flex gap-2 text-body-emphasis lh-sm w-100 editable-field "
                >
                    <div>
                        {taskData?.users
                            ?
                            <div className='attachments-container'>
                                {taskData?.users?.map((item, index) => (
                                    <div className='d-flex gap-1'>
                                        <ToggleViewAvtar
                                            tooltip={`${item?.first_name} ${item?.last_name}`}
                                        >
                                            <Avatar size="m" variant="name" key={item?.id}>{item?.short_name}</Avatar>
                                        </ToggleViewAvtar>
                                       {userPermissionOnBoard && <div>
                                            <Button
                                                variant="phoenix-primary"
                                                className="btn-icon"
                                                onClick={() => removeUser(item?.id)}
                                            >
                                                <Unicon icon={UilTrash} size={16} />
                                            </Button>
                                            {loading?.deleteUser === item?.id && <Spinner animation="border" variant="secondary" size="sm" />}
                                        </div>
                                        }
                                    </div>
                                ))

                                }
                            </div>
                            : ''}
                    </div>
                    <div >
                        <Button
                            variant=""
                            className="btn-icon"
                            onClick={handleUserClick}
                            disabled={!userPermissionOnBoard}
                        >
                            <Unicon icon={UilPen} size={16} />
                        </Button>
                    </div>
                </div>
            )}

        </>
    );
};

export default EditableUser;

