import { BoardFormData, BoardLabels, CardCategory, CardPriority, CreateNewLabel, initialCreateNewLabelData, initialDataFormToggle, KanbanBoard, KanbanBoardItem, KanbanBoardMembers, KanbanTaskHistroy, LabelsFormToggle, ListFormData, SummaryProps } from 'data/kanban';
import { KanbanState } from 'providers/KanbanProvider';
import { DraggableLocation } from 'react-beautiful-dnd';

//Action types
export const TOGGLE_DETAILS_OFFCANVAS = 'TOGGLE_DETAILS_OFFCANVAS';
export const TOGGLE_ADD_LIST_MODAL = 'TOGGLE_ADD_LIST_MODAL';
export const TOGGLE_ADD_BOARD_MODAL = 'TOGGLE_ADD_BOARD_MODAL';
export const REMOVE_ITEM_FROM_LIST = 'REMOVE_ITEM_FROM_LIST';
export const MOVE_ITEMS = 'MOVE_ITEMS';
export const ADD_NEW_LIST = 'ADD_NEW_LIST';
export const SET_DATA = 'SET_DATA';
export const UPDATE_BOARD_USERS = 'UPDATE_BOARD_USERS';
export const BOARD_DATA = 'BOARD_DATA';
export const BOARD_MODAL_TYPE = 'BOARD_MODAL_TYPE';
export const LIST_MODAL_TYPE = 'LIST_MODAL_TYPE';
export const BOARD_UPDATE_DATA = 'BOARD_UPDATE_DATA';
export const LIST_UPDATE_DATA = 'LIST_UPDATE_DATA';
export const YOUR_BOARD_DATA_LIST = 'YOUR_BOARD_DATA_LIST';
export const UPDATE_BOARD_DETAILS = 'UPDATE_BOARD_DETAILS';
export const UPDATE_BOARD_LIST_DETAILS = 'UPDATE_BOARD_LIST_DETAILS';
export const CARD_PRIORITY = 'CARD_PRIORITY';
export const CARD_CATEGORY = 'CARD_CATEGORY';
export const BOARD_LABELS_LIST = 'BOARD_LABELS_LIST';
export const BOARD_CARD_FILTER = 'BOARD_CARD_FILTER';
export const USER_PERMISSION_ON_BOARD = 'USER_PERMISSION_ON_BOARD';
export const BOARD_LOADING = 'BOARD_LOADING';
export const TOGGLE_LABEL_FORMS = 'TOGGLE_LABEL_FORMS';
export const TOGGLE_CREATE_UPDATE_LABEL = 'TOGGLE_CREATE_UPDATE_LABEL';
export const CREATE_NEW_LABEL = 'CREATE_NEW_LABEL';
export const TOGGLE_TASK_DETAIL_MODAL = 'TOGGLE_TASK_DETAIL_MODAL';
export const TASK_HISTORY_DATA = 'TASK_HISTORY_DATA';
export const TASK_HISTORY_SUMMARY = 'TASK_HISTORY_SUMMARY';
export const TASK_HISTORY_PAGE_INDEX = 'TASK_HISTORY_PAGE_INDEX';
export const TASK_HOSTORY_LOAD = 'TASK_HOSTORY_LOAD';
//Action ts type
export type ACTIONTYPE =
  | {
    type: typeof TOGGLE_DETAILS_OFFCANVAS;
    payload?: boolean;
  }
  | {
    type: typeof TOGGLE_ADD_LIST_MODAL;
    payload?: boolean;
  }
  | {
    type: typeof TOGGLE_ADD_BOARD_MODAL;
    payload?: boolean;
  }
  | {
    type: typeof REMOVE_ITEM_FROM_LIST;
    payload: { listId: string; itemIndex: number };
  }
  | {
    type: typeof MOVE_ITEMS;
    payload: {
      destination: DraggableLocation;
      source: DraggableLocation;
    };
  }
  | {
    type: typeof ADD_NEW_LIST;
    payload: { list: KanbanBoardItem; columnNo: number };
  }
  | { type: typeof SET_DATA; payload: KanbanBoardItem[] }

  | {
    type: typeof UPDATE_BOARD_USERS;
    payload: KanbanBoardMembers[];
  }
  | {
    type: typeof BOARD_DATA;
    payload: KanbanBoard;
  }
  | {
    type: typeof BOARD_MODAL_TYPE;
    payload: boolean;
  }
  | {
    type: typeof LIST_MODAL_TYPE;
    payload: boolean;
  }
  | {
    type: typeof BOARD_UPDATE_DATA;
    payload: BoardFormData;
  }
  | {
    type: typeof LIST_UPDATE_DATA;
    payload: ListFormData;
  }
  | {
    type: typeof YOUR_BOARD_DATA_LIST;
    payload: KanbanBoard[];
  }
  | {
    type: typeof UPDATE_BOARD_DETAILS;
    payload: { id: number; title: string; description: string }
  }
  | {
    type: typeof UPDATE_BOARD_LIST_DETAILS;
    payload: { id: number; title: string; }
  }
  | {
    type: typeof CARD_PRIORITY;
    payload: CardPriority[]
  }
  | {
    type: typeof CARD_CATEGORY;
    payload: CardCategory[]
  }
  | {
    type: typeof BOARD_LABELS_LIST;
    payload: BoardLabels[]
  }
  | {
    type: typeof BOARD_CARD_FILTER;
    payload: string
  }
  | {
    type: typeof USER_PERMISSION_ON_BOARD;
    payload?: boolean;
  }
  | {
    type: typeof BOARD_LOADING;
    payload?: boolean;
  }
  | {
    type: typeof TOGGLE_LABEL_FORMS;
    payload?: LabelsFormToggle;
  }
  | {
    type: typeof TOGGLE_CREATE_UPDATE_LABEL;
    payload?: boolean;
  }
  | {
    type: typeof CREATE_NEW_LABEL;
    payload?: CreateNewLabel;
  }
  | {
    type: typeof TOGGLE_TASK_DETAIL_MODAL;
    payload?: boolean;
  }
  | {
    type: typeof TASK_HISTORY_DATA;
    payload: KanbanTaskHistroy[]
  }
  | {
    type: typeof TASK_HISTORY_SUMMARY;
    payload?: SummaryProps;
  }
  | {
    type: typeof TASK_HISTORY_PAGE_INDEX;
    payload?: number;
  }
  | {
    type: typeof TASK_HOSTORY_LOAD;
    payload?: boolean;
  };

  
// Reducer function
export const kanbanReducer = (state: KanbanState, action: ACTIONTYPE) => {
  switch (action.type) {
    case TOGGLE_DETAILS_OFFCANVAS: {
      const { payload } = action;
      return {
        ...state,
        openBoardDetailsOffcanvas:
          payload !== undefined ? payload : !state.openBoardDetailsOffcanvas
      };
    }
    case TOGGLE_ADD_LIST_MODAL: {
      const { payload } = action;
      return {
        ...state,
        openAddListModal:
          payload !== undefined ? payload : !state.openAddListModal
      };
    }
    case TOGGLE_ADD_BOARD_MODAL: {
      const { payload } = action;
      return {
        ...state,
        openAddBoardModal:
          payload !== undefined ? payload : !state.openAddBoardModal
      };
    }

    case BOARD_MODAL_TYPE: {
      const { payload } = action;
      return {
        ...state,
        isEditMode:
          payload !== undefined ? payload : !state.isEditMode
      };
    }
    case LIST_MODAL_TYPE: {
      const { payload } = action;
      return {
        ...state,
        isEditModeList:
          payload !== undefined ? payload : !state.isEditModeList
      };
    }

    case REMOVE_ITEM_FROM_LIST: {
      const { payload } = action;
      return {
        ...state,
        boardLists: state.boardLists.map(list =>
          Number(list.id) === Number(payload.listId)
            ? {
              ...list,
              tasks: list.boardListCards.filter(
                (task, index) => index !== payload.itemIndex
              )
            }
            : list
        )
      };
    }
    case MOVE_ITEMS: {
      const {
        payload: { destination, source }
      } = action;

      const updatedList = structuredClone(state.boardLists);

      const task = updatedList
        .find(list => Number(list.id) === Number(source.droppableId))
        ?.boardListCards.splice(source.index, 1)[0];

      if (task && destination) {
        updatedList
          .find(list => Number(list.id) === Number(destination.droppableId))
          ?.boardListCards.splice(destination.index, 0, task);
      }

      return {
        ...state,
        boardLists: updatedList
      };
    }
    case ADD_NEW_LIST: {
      const {
        payload: { list }
      } = action;

      const updatedList = [...state.boardLists];
      // updatedList.splice(columnNo - 1, 1, list);
      updatedList.push(list);

      return {
        ...state,
        boardLists: updatedList,
        openAddListModal: false
      };
    }
    case SET_DATA: {
      const { payload } = action;

      return {
        ...state,
        boardLists: payload // Assuming `payload.tasks` represents the new boardLists data
      };
    }
    case UPDATE_BOARD_USERS: {
      const { payload } = action;

      return {
        ...state,
        boardUsersList: payload
      };
    }

    case BOARD_DATA: {
      const { payload } = action;

      return {
        ...state,
        boardData: payload
      };
    }
    case YOUR_BOARD_DATA_LIST: {
      const { payload } = action;

      return {
        ...state,
        yourBoardData: payload
      };
    }
    case BOARD_UPDATE_DATA: {
      const { payload } = action;

      return {
        ...state,
        doardUpdateData: payload
      };
    }
    case BOARD_CARD_FILTER: {
      const { payload } = action;

      return {
        ...state,
        filterValue: payload
      };
    }
    case LIST_UPDATE_DATA: {
      const { payload } = action;
      return {
        ...state,
        listUpdateData: payload
      };
    }

    case UPDATE_BOARD_DETAILS: {
      const { id, title, description } = action.payload;
      const updatedBoards = state.yourBoardData.map(board =>
        board.id === id ? { ...board, title, description } : board
      );
      return { ...state, yourBoardData: updatedBoards };
    }

    case UPDATE_BOARD_LIST_DETAILS: {
      const { id, title } = action.payload;
      const updatedLists = state.boardLists.map(list =>
        list.id === id ? { ...list, title } : list
      );
      return { ...state, boardLists: updatedLists };
    }
    case CARD_PRIORITY: {
      const { payload } = action;

      return {
        ...state,
        cardPriority: payload
      };
    }
    case CARD_CATEGORY: {
      const { payload } = action;

      return {
        ...state,
        cardCategory: payload
      };
    }
    case BOARD_LABELS_LIST: {
      const { payload } = action;

      return {
        ...state,
        boardLabelsList: payload
      };
    }


    case USER_PERMISSION_ON_BOARD: {
      const { payload } = action;
      return {
        ...state,
        userPermissionOnBoard:
          payload !== undefined ? payload : !state.userPermissionOnBoard
      };
    }
    case BOARD_LOADING: {
      const { payload } = action;
      return {
        ...state,
        boardLoading:
          payload !== undefined ? payload : !state.boardLoading
      };
    }
    case TOGGLE_LABEL_FORMS: {
      const { payload } = action;

      return {
        ...state,
        toggleLabelForms: payload || initialDataFormToggle
      };
    }
    case TOGGLE_CREATE_UPDATE_LABEL: {
      const { payload } = action;
      return {
        ...state,
        createUpdateLabel:
          payload !== undefined ? payload : !state.createUpdateLabel
      };
    }
    case CREATE_NEW_LABEL: {
      const { payload } = action;

      return {
        ...state,
        createNewLabelFormData: payload || initialCreateNewLabelData
      };
    }
    case TOGGLE_TASK_DETAIL_MODAL: {
      const { payload } = action;
      return {
        ...state,
        toggleTaskDetailModal:
          payload !== undefined ? payload : !state.toggleTaskDetailModal
      };
    }
    case TASK_HISTORY_DATA: {
      const { payload } = action;

      return {
        ...state,
        taskHistory: payload
      };
    }
    case TASK_HISTORY_SUMMARY: {
      const { payload } = action;
      return {
        ...state,
        histotySummary: payload || state.histotySummary
      };
    }

    case TASK_HISTORY_PAGE_INDEX: {
      const { payload } = action;
      return {
        ...state,
        pageIndexHistory: payload || state.pageIndexHistory
      };
    }
    case TASK_HOSTORY_LOAD: {
      const { payload } = action;
      return {
        ...state,
        historyLoad:
          payload !== undefined ? payload : !state.historyLoad
      };
    }
    default:
      return state;
  }
};

