import { Button, Dropdown, Modal, Nav } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import NineDotMenu from './NineDotMenu';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThemeToggler from 'components/common/ThemeToggler';
import NotificationDropdownMenu from './NotificationDropdownMenu';
import { useEffect, useState } from 'react';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from 'providers/AuthContext';
import NavbarTopNavCustom from '../navbar-horizontal/NavbarTopNavCustom';
import { Navbar } from 'react-bootstrap';
import Avatar from 'components/base/Avatar';
import Avatar3 from 'components/base/Avatar3';
import VersionType from 'components/custom/VersionType';

interface UserType {
  short_name: string;
  profileImg: string;
}

const NavItemsSlim = () => {
  const { user } = useAuth();
  const [openSearchModal, setOpenSearchModal] = useState(false);
   const [userData, setUserdata] = useState<UserType>({
      short_name: '',
      profileImg: ''
    });
 
    useEffect(() => {
      // Update userData whenever user changes
      if (user !== null) {
        setUserdata({
          short_name: user?.short_name,
          profileImg: user?.profileImg
        });
      }
    }, [user]);



  return (
    <div className="navbar-nav custom_admin navbar-nav-icons flex-row">
      <Navbar.Collapse>
        <VersionType />
        <NavbarTopNavCustom />
      </Navbar.Collapse>

      <Nav.Item>
        <Dropdown autoClose="outside" >
          <Dropdown.Toggle
            as={Button}
            // to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0 "
            variant=""
          >
            <div className="avatar_width w-auto">  {(userData as UserType)?.profileImg ? <Avatar src={userData?.profileImg} size="s" /> : <Avatar3 size="s" variant="name" className="me-1" border={"1"}>{(userData as UserType)?.short_name}</Avatar3>}   </div>
            

          </Dropdown.Toggle>
          <ProfileDropdownMenu />
        </Dropdown>
      </Nav.Item>

      <Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15"
      >
        <Modal.Body className="p-0 bg-transparent">
          <DropdownSearchBox
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            size="lg"
            style={{ width: 'auto' }}
          >
            <SearchResult />
          </DropdownSearchBox>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NavItemsSlim;
