import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/base/Button";
import {Modal} from "react-bootstrap";
import { useProjectContext } from "providers/ProjectProvider";
import { TOGGLE_ADD_CATEGORY_MODAL} from "reducers/ProjectReducer";
import AddCategoryForm from "./AddCategoryForm";

const AddCategoryModal = () => {
  const { toggleAddCategory, isEditModeCategory, projectDispatch } = useProjectContext();
  const handleClose = () => {
    projectDispatch({
      type: TOGGLE_ADD_CATEGORY_MODAL,
      payload: false
    });
  };
  return (
    <Modal show={toggleAddCategory} onHide={handleClose} className="p-0" centered size="sm"  backdrop="static">
      <Modal.Header className="p-4 d-flex gap-2 border-0">
        <h5 className="mb-0 text-body-emphasis fw-semibold flex-1">{isEditModeCategory ? "Update Category" : "Add Category"}</h5>
        <Button className="p-0 ms-auto" onClick={handleClose}>
          <FontAwesomeIcon icon={faXmark} className="fs-7" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <AddCategoryForm />
      </Modal.Body>
    </Modal>
  );
};

export default AddCategoryModal;
