import { faPlus, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/base/Avatar';
import Button from 'components/base/Button';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useKanbanContext } from 'providers/KanbanProvider';
import { useState } from 'react';
import KanbanInviteModal from 'components/modals/KanbanInviteModal';
import { TOGGLE_ADD_BOARD_MODAL } from 'reducers/KanbanReducer';
import { boardInitialData, KanbanBoard, KanbanBoardMembers, kanbanBoardMembers } from 'data/kanban';
import { Form } from 'react-bootstrap';
import ToggleViewButton from 'components/common/ToggleViewbutton';
import Unicon from 'components/base/Unicon';
import { UilThLarge } from '@iconscout/react-unicons';
import ToggleViewAvtar from 'components/common/ToggleViewAvtar';



const KanbanHeader = () => {
  const { boardUsersList, boardData, filterValue, userPermissionOnBoard, kanbanDispatch } = useKanbanContext();
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    kanbanDispatch({ type: 'BOARD_CARD_FILTER', payload: event.target.value });
  };

  return (
    <>
      <div className="kanban-header">
        <div className="border-translucent pb-1">
          <Row className="gx-0 justify-content-between justify-content-md-start">
            <Col xs="auto" className='d-flex align-items-center gap-4'>
              <h2 className='me-3 fs-6 fw-bold'>{boardData?.title} </h2>
            </Col>
            <Col xs="auto" className="d-flex align-items-center gap-4">
              <Avatar.Group
                size="m"
                className="bottom-0  ms-1"
              >
                {boardUsersList?.slice(0, 4).map(user => (
                  <ToggleViewAvtar
                    tooltip={`${user?.first_name} ${user?.last_name}`}
                    key={user?.id}
                  >
                    <Avatar size="m" variant="name" key={user?.id}>{user?.short_name}</Avatar>
                  </ToggleViewAvtar>
                ))}
               {boardUsersList?.length > 4  && <Avatar size="m" variant="name">
                  +{boardUsersList?.length - 4}
                </Avatar>
                 }
              </Avatar.Group>
              {userPermissionOnBoard && <Button
                startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                variant="outline-primary"
                className="fs-10 px-3"
                onClick={() => setOpenInviteModal(!openInviteModal)}
              >
                <span className="d-none d-sm-inline fs-10 fw-bolder">Users</span>
              </Button>
              }
            </Col>
            <Col md="auto" className="d-flex align-items-center gap-4 ms-auto">
              {/* <Button
                variant="outline-primary"
                className="px-3"
                onClick={() => {
                  kanbanDispatch({
                    type: TOGGLE_ADD_BOARD_MODAL,
                    payload: true
                  });
                }}
                startIcon={<FontAwesomeIcon icon={faPlus} className="me-2" />}
              >
                New Board
              </Button> */}
              <Form.Select aria-label="Filter" value={filterValue} onChange={handleFilterChange}>
                {/* <option value="">All</option> */}
                <option value="1">Active</option>
                <option value="0">Suspended</option>
              </Form.Select>
            </Col>
          </Row>
        </div>
      </div>
      <KanbanInviteModal
        show={openInviteModal}
        handleClose={() => setOpenInviteModal(false)}
      />
    </>
  );
};

export default KanbanHeader;
