import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Unicon from 'components/base/Unicon';
import { UilReceipt, UilClock, UilPen, UilCodeBranch, UilLink, UilLockAccess, UilStar, UilArchive, UilTrashAlt, UilBell } from '@iconscout/react-unicons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState, Suspense } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import { useAppContext } from "providers/AppProvider";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import { useAuth } from "providers/AuthContext";
import apiCall from "services/api";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import { projectSettingBreadcrumb } from "data/commonDataBreadcrumbs";
import Accordion from 'react-bootstrap/Accordion';
import CreateNewNote from "./modals/CreateNewNote";
import { toast } from "react-toastify";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import UpdateNote from "./modals/UpdateNote";
import { handleCutomError } from "services/handleCutomError";


type UserNote = {
    is_reminder_set: number;
    reminder_datetime: string;
    is_star: number;
    is_editable: number;
    is_pin: number;
    is_archived: number;
    can_share: number;
};

type NotesReminder = {
    id: number;
    collection: number;
    collection_id: number;
    user_id: number;
    start_date: string;
    end_date: string;
    year: number;
    month: number;
    title: string;
    description: string;
    color_code: string;
    status: number;
};

type Note = {
    id: number;
    title: string;
    description: string;
    link: string;
    type: number;
    status: number;
    edited_at: number;
    usernote: UserNote;
    notesreminder: NotesReminder;
};

type NotesResponse = Note[];





const Notes = () => {
    const { userTkn, user, workSpaceTkn, signOut } = useAuth();
    const { alias } = useParams();
    const navigation = useNavigate() as NavigateFunction; // Explicitly define the type
    //for filters
    const [loading, setLoading] = useState<boolean>(false); // Add loading state
    const [notesData, setNotesData] = useState<NotesResponse>([]);
    const [addStatus, setAddStatus] = useState<boolean>(false);
    const [projectData, setProjectData] = useState<any>({
        alias: "",
        title: ""
    })
    const [openAddModal, setOpenAddModal] = useState(false);
    const [updateNote, setUpdateNote] = useState(false);
    const [noteData, setNoteData] = useState<Note>();



    const {
        config: { theme, navbarPosition },
        setConfig,
    } = useAppContext();

    useEffect(() => {
        if (!workSpaceTkn) {
            navigation("/admin/all-workspace/list")
        }
    },[])

    //set top navigation
    useEffect(() => {
        const value = "combo";
        const value2 = "slim";
        setConfig({
            navbarPosition: value as NavPositionVariant,
            navbarTopShape: value2 as NavTopShapeVariant,
        });
    }, []);

    useSettingsMountEffect({
        showSettingPanelButton: false,
    });
    // called when filter changed

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true); // Set loading to true
                const response = await apiCall({
                    url: `project/${alias}/notes/list`,
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                        'workspace': workSpaceTkn
                    },
                });

                if (response?.status === 200) {
                    // Calculate serial numbers for the projects on the current page
                    setNotesData(response?.data?.data?.notes)


                    setAddStatus(false);
                    setLoading(false);
                } else {
                    console.error("error messag");
                }
            } catch (error: any) {
                if (error?.isAxiosError) {
                    // Access the error message
                    handleCutomError(error, signOut, navigation);
                } else {
                    // Handle other types of errors
                    console.error('An error occurred:', error);
                    // Display a generic error message to the user
                    toast.error('An unexpected error occurred', {
                        position: "top-center"
                    });
                }
            } finally {
                setLoading(false); // Set loading to false after the API call is completed
            }
        };

        fetchData();


    }, [userTkn, addStatus]);

    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true)
                const response = await apiCall({
                    url: `project/${alias}/view`,
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                        'workspace': workSpaceTkn
                    },
                });
                if (response.status === 200) {
                    let data = response.data
                    setProjectData({
                        alias: data?.alias,
                        title: data?.title
                    })
                }
            } catch (error: any) {
                if (error?.isAxiosError) {
                    // Access the error message
                    handleCutomError(error, signOut, navigation);
                } else {
                    // Handle other types of errors
                    console.error('An error occurred:', error);
                    // Display a generic error message to the user
                    toast.error('An unexpected error occurred', {
                        position: "top-center"
                    });
                }
            } finally {
                setLoading(false)
            }
        };
        fetchData();
    }, [userTkn]);


    const markStar = (item: Note) => {
      

    }













    const [activeAccordion, setActiveAccordion] = useState<string | null>(null);
    const handleAccordionToggle = (eventKey: string) => {
        setActiveAccordion(activeAccordion === eventKey ? null : eventKey);
    };

    return (
        <div>
            <PageBreadcrumb items={projectSettingBreadcrumb} navigation={navigation} />

            <div className="d-flex flex-wrap mb-4 align-items-center justify-content-between">
                <h2 className="mb-0">
                    <span className="me-3"> <Unicon icon={UilReceipt} size={28} /> Notes </span>{" "}
                </h2>
                <Button
                    variant="outline-primary"
                    className="btn px-5"
                    onClick={() => { setOpenAddModal(true) }}
                >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    New Note
                </Button>
            </div>



            <Accordion className="position-relative" activeKey={activeAccordion}>
                <Row className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4 gx-2 ">
                    {notesData?.map((item, index) => {
                        const timestamp = item?.edited_at; // Your timestamp here
                        const date = new Date(timestamp); // Convert seconds to milliseconds

                        // Get the date components
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const day = String(date.getDate()).padStart(2, '0');

                        // Get the time components
                        let hours = date.getHours();
                        const am_pm = hours >= 12 ? 'PM' : 'AM';
                        hours = hours % 12 || 12; // Convert to 12-hour format
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');
                        // Construct the formatted date and time string
                        const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${am_pm}`;


                        return (

                            <Col className="mb-3" key={index}>
                                <Accordion.Item eventKey={index.toString()} className="accordion_bg bg-white position-relative px-3 pt-2 pb-3">
                                    <div onClick={() => handleAccordionToggle(index.toString())} className="accordian-header pb-2">
                                        <Accordion.Header className="text-1000" >
                                            <Unicon icon={UilClock} size={15} className="me-1" color="#6e7891" /> <span className="fs-9 fw-semi-bold text-700">{formattedDateTime}</span> <span className="ms-2 fs-9 fw-semi-bold text-500"></span>
                                        </Accordion.Header>
                                        <p style={{ lineHeight: "30px" }} className={`fw-bold fs-8 text-900 ${activeAccordion === index.toString() ? '' : 'showText'}`}>{item?.title}</p>
                                    </div>
                                    <Accordion.Body>
                                        <p className="fw-semi-bold fs-9 mb-2 text-700">{item?.description}</p>
                                    </Accordion.Body>
                                    <div className="footer_accordion d-flex justify-content-between">
                                        {/* <div className="left-icons d-flex gap-2">
                                            <Button variant='phoenix-primary' className="p-1 bg-transparent">  <Unicon icon={UilBell} size={16} color="#525b75" /></Button>
                                            <Button variant='phoenix-primary' className="p-1 bg-transparent" onClick={() => { setNoteData(item); markStar(item) }}><Unicon icon={UilStar} size={16} color="#525b75" /></Button>
                                            <Button variant='phoenix-primary' className="p-1 bg-transparent"><Unicon icon={UilArchive} size={16} color="#525b75" /></Button>
                                            <Button variant='phoenix-primary' className="p-1 bg-transparent"><Unicon icon={UilTrashAlt} size={16} color="#525b75" /></Button>
                                        </div>
                                        <div className="left-icons d-flex gap-2">
                                            <Button variant='phoenix-primary' className="p-1 bg-transparent" onClick={() => { setUpdateNote(true); setNoteData(item) }}><Unicon icon={UilPen} size={16} color="#525b75" /></Button>
                                            <Button variant='phoenix-primary' className="p-1 bg-transparent"><Unicon icon={UilCodeBranch} size={16} color="#525b75" /></Button>
                                            <Button variant='phoenix-primary' className="p-1 bg-transparent"><Unicon icon={UilLink} size={16} color="#525b75" /></Button>
                                            <Button variant='phoenix-primary' className="p-1 bg-transparent"><Unicon icon={UilLockAccess} size={16} color="#525b75" /></Button>
                                        </div> */}
                                    </div>
                                </Accordion.Item>
                            </Col>
                        )
                    })}


                </Row>
            </Accordion>


            <Suspense fallback={<div>Loading...</div>}>
                {openAddModal && (<CreateNewNote
                    show={openAddModal}
                    handleClose={() => setOpenAddModal(false)}
                    setAddStatus={setAddStatus}
                    toast={toast}
                    projectData={projectData}
                    navigation={navigation}
                />
                )}

                {updateNote && (<UpdateNote
                    show={updateNote}
                    handleClose={() => setUpdateNote(false)}
                    setAddStatus={setAddStatus}
                    toast={toast}
                    projectData={projectData}
                    noteData={noteData}
                    navigation={navigation}
                />
                )}
            </Suspense>

        </div>
    );
};

export default Notes;