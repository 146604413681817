import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { dbDateToDate } from 'common/timeStampToDate';
import TimelineCustom from 'components/base/TimelineCustom';
import { ActivityTimelineData } from 'data/project-management/activityTimelineData';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface ActivityTimelineProps {
  data: ActivityTimelineData[];
}

const ActivityTimelineCustom = ({ data }: any) => {
  return (
    <TimelineCustom variant="vertical">
      {data?.map((item: any, index: any) => {
        let action_time: string[] = (item?.action_time ?? '').split(' ');
        let formattedDueDate = '';
        if (action_time[0]) {
          formattedDueDate = `${dbDateToDate(action_time[0])} ${
            action_time[1]
          }`;
        }
        return (
          <TimelineCustom.Item className="position-relative pt-3" key={item.id}>
            <Row className="g-md-1">
              <Col xs={12} md="auto" className="d-flex">
                <TimelineCustom.Separator className="position-md-relative">
                  <TimelineCustom.Dot className="bg-primary-100">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className={classNames('fs-10 dark__text-primary-300', {
                        'text-primary-update': item.actiontype === 'update',
                        'text-primary-create': item.actiontype === 'create',
                        'text-primary-reopen': item.actiontype === 'reopen',
                        'text-primary-completed':
                          item.actiontype === 'completed',
                        'text-primary-pending': item.actiontype === 'pending',
                        'text-primary-approved': item.actiontype === 'approved'
                      })}
                    />
                  </TimelineCustom.Dot>
                  {index !== data.length - 1 && (
                    <TimelineCustom.Bar
                      className={classNames('h-100', {
                        'timeline-custom-update': item.actiontype === 'update',
                        'timeline-custom-create': item.actiontype === 'create',
                        'timeline-custom-reopen': item.actiontype === 'reopen',
                        'timeline-custom-completed':
                          item.actiontype === 'completed',
                        'timeline-custom-pending':
                          item.actiontype === 'pending',
                        'timeline-custom-approved':
                          item.actiontype === 'approved'
                      })}
                    />
                  )}
                </TimelineCustom.Separator>
              </Col>
              <Col className='border-bottom'>
                <TimelineCustom.Content>
                  <h5
                    // className="fs-9 lh-sm"
                    className={classNames('fs-9 lh-sm', {
                      'timeline-details-update': item.actiontype === 'update',
                      'timeline-details-create': item.actiontype === 'create',
                      'timeline-details-reopen': item.actiontype === 'reopen',
                      'timeline-details-completed':
                        item.actiontype === 'completed',
                      'timeline-details-pending': item.actiontype === 'pending',
                      'timeline-details-approved':
                        item.actiontype === 'approved'
                    })}
                  >
                    {item.actiontype} {`(${item.creator.first_name})`}
                  </h5>
                  <p
                    className={classNames('fs-9 mb-0  text-start', {
                      'timeline-details-update': item.actiontype === 'update',
                      'timeline-details-create': item.actiontype === 'create',
                      'timeline-details-reopen': item.actiontype === 'reopen',
                      'timeline-details-completed':
                        item.actiontype === 'completed',
                      'timeline-details-pending': item.actiontype === 'pending',
                      'timeline-details-approved':
                        item.actiontype === 'approved'
                    })}
                  >
                    {item?.comment}
                  </p>
                  <p
                    // className="fs-9  text-600 text-start"
                    className={classNames('fs-9  text-start', {
                      'text-time-update': item.actiontype === 'update',
                      'text-time-create': item.actiontype === 'create',
                      'text-time-reopen': item.actiontype === 'reopen',
                      'text-time-completed': item.actiontype === 'completed',
                      'text-time-pending': item.actiontype === 'pending',
                      'text-time-approved': item.actiontype === 'approved'
                    })}
                  >
                    {formattedDueDate}
                  </p>
                </TimelineCustom.Content>
              </Col>
            </Row>
          </TimelineCustom.Item>
        );
      })}
    </TimelineCustom>
  );
};

export default ActivityTimelineCustom;
