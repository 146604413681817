import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UilCheckCircle } from "@iconscout/react-unicons";
import { formatDueDate } from "common/timeStampToDate";
import Avatar3 from "components/base/Avatar3";
import Badge from "components/base/Badge";
import Button from "components/base/Button";
import Unicon from "components/base/Unicon";
import ActivityTimelineCustom from "components/timelines/ActivityTimelineCustom";
import { useAuth } from "providers/AuthContext";
import { useState } from "react";
import { FloatingLabel } from "react-bootstrap";
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import apiCall from "services/api";
import { handleCutomError } from "services/handleCutomError";

interface DeclineActionPointModalProps {
    show: boolean;
    handleClose: () => void;
    setAddStatus: React.Dispatch<React.SetStateAction<boolean>>; // Add this line
    toast: any;
    presentUser: any;
    projectData: any;
    actionPointData: any;
    navigation: any;
}

const DeclineActionPointModal = ({ show, handleClose, setAddStatus, toast, navigation,  projectData, actionPointData }: DeclineActionPointModalProps) => {

    const [validated, setValidated] = useState<boolean>(false);
    const [loading, setLoading] = useState(false)
    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const [error, setError] = useState({
        id: "",
        comment: ""
    });
    const [formData, setFormData] = useState({
        id: actionPointData?.id,
        comment: ""
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | { target: { name: string; value: string; } }) => {
        const { name, value } = e.target;

        let errorMessage = "";


        setFormData({
            ...formData,
            [name]: value,
        });


        setError((prevData) => ({
            ...prevData,
            [name]: errorMessage,
        }));
    };


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault(); // Prevent default form submission behavior
        e.stopPropagation();
        // Add the following line to set the form validation state
        setValidated(true);

        // Check if there are any errors before making the API call

        // Perform any necessary logic or API calls here

        const formDataSubmit = new FormData();
        // formDataSubmit.append("id", formData?.id);
        formDataSubmit.append("comment", formData?.comment);

        try {
            setLoading(true)
            const response = await apiCall({
                url: `project/${projectData.alias}/actionpoints/${formData?.id}/declined`,
                method: 'POST',
                data: formDataSubmit,
                headers: {
                    'x-access-token': userTkn,
                    'workspace': workSpaceTkn
                },
            });


            if (response.status === 200) {

                if (response?.data?.errors) {
                    const errors = response?.data.errors;
                    // Dynamically set errors based on the response
                    Object.keys(errors).forEach((key) => {
                        const errorMessages = errors[key];
                        const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                        // Set the error state for the corresponding field
                        setError((prevData) => ({
                            ...prevData,
                            [key]: firstErrorMessage,
                        }));
                    });
                } else {
                    // Call the refetchData function from the context to fetch the data again
                    setAddStatus(true);
                    handleClose();
                }
            } else {
                console.error("Api Failed");
            }
        } catch (error: any) {
            if (error?.isAxiosError) {
                // Access the error message
                handleCutomError(error, signOut, navigation);
            } else {
                // Handle other types of errors
                console.error('An error occurred:', error);
                // Display a generic error message to the user
                toast.error('An unexpected error occurred', {
                    position: "top-center"
                });
            }
        } finally {
            setLoading(false); // Set loading to false after the API call is completed
        }
    };

    return (
        <Modal show={show} onHide={handleClose} className="p-0" centered size="xl" backdrop="static">
            <Modal.Header className="p-4 d-flex gap-2 border-0">
                <h5 className="mb-0 text-body-emphasis fw-semibold flex-1">Decline Mark</h5>
                <Button className="p-0 ms-auto" onClick={handleClose}>
                    <FontAwesomeIcon icon={faXmark} className="fs-7" />
                </Button>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body className=" pb-2 px-4">
                    <Row >
                        <Col md={9} className="">
                            <p className="text-1000 lead mb-0">{actionPointData?.title}</p>
                            <p className="fs-9 lh-sm">{actionPointData?.description}</p>
                        </Col>
                        <Col md={3} className=" d-flex justify-content-end">
                            {actionPointData?.action_point_status === "completed" && <div>
                                <Badge bg="success"
                                    variant="phoenix"
                                    iconPosition="end" className="fs-16 "> {actionPointData?.action_point_status}  <Unicon icon={UilCheckCircle} size={12} id="approved" /> </Badge>
                            </div>
                            }
                        </Col>
                    </Row>
                    <Row className="gx-2">
                        <Col md={9} className="mb-3">
                            <Card className="custom-card">
                                <Card.Body className="d-flex flex-column justify-content-between pb-3 " >
                                    <Row className="pb-2">
                                        <Col><p className="fs-9  text-1000 mb-2">When:  <span className="fs-9 fw-semi-bold  text-1000">{formatDueDate(new Date(actionPointData?.expected_date))}</span></p></Col>
                                        <Col><p className="fs-9   text-1000 mb-2">Finish:{actionPointData?.completion_time && <span className="fs-9 fw-semi-bold  text-1000">{formatDueDate(new Date(actionPointData?.completion_time))}</span>} </p></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div>
                                                <p className="fs-9 fw-semi-bold  text-1000 m-0">Creator</p>
                                                <div className=" d-flex  align-items-center">
                                                    <div className="avatar_width">
                                                        <Avatar3 size="m" variant="name" className="me-1" border="0" >
                                                            {actionPointData?.creator?.short_name?.slice(0, 2)}
                                                        </Avatar3>

                                                    </div>
                                                    <p className="fs-8 fw-semi-bold  text-800 m-0">{`${actionPointData?.creator?.first_name} ${actionPointData?.creator?.last_name}`}</p>
                                                </div>

                                            </div>
                                        </Col>
                                        <Col>
                                            <p className="fs-9 fw-semi-bold  text-1000 m-0">Assignee</p>
                                            <div className=" d-flex  align-items-center">
                                                <div className="avatar_width">
                                                    <Avatar3 size="m" variant="name" className="me-1" border="0" >
                                                        {actionPointData?.assignee?.short_name?.slice(0, 2)}
                                                    </Avatar3>
                                                </div>
                                                <p className="fs-8 fw-semi-bold  text-800 m-0">{`${actionPointData?.assignee?.first_name} ${actionPointData?.assignee?.last_name}`}</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <p className="fs-9 fw-semi-bold  text-1000 m-0">Auditor</p>
                                            <div className=" d-flex  align-items-center">
                                                <div className="avatar_width">
                                                    <Avatar3 size="m" variant="name" className="me-1" border="0" >
                                                        {actionPointData?.auditor?.short_name?.slice(0, 2)}
                                                    </Avatar3>
                                                </div>
                                                <p className="fs-8 fw-semi-bold  text-800 m-0">{`${actionPointData?.auditor?.first_name} ${actionPointData?.auditor?.last_name}`}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Row className="mb-3 mt-4">
                                <Form.Group className=" text-start mt-4">
                                    <FloatingLabel
                                        controlId="floatingInputGrid"
                                        label={`Comment`}
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder="Comment"
                                            name="comment"
                                            value={formData.comment}
                                            onChange={handleInputChange}
                                            isInvalid={!!error.comment}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {error?.comment}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                            </Row>

                        </Col>
                        <Col md={3} className="mb-3">
                            <Card className="timeline-size">
                                <Card.Body className="d-flex flex-column justify-content-between pb-3 px-3">
                                    <ActivityTimelineCustom data={actionPointData?.history} />
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center align-items-center px-4 pb-4 border-0 pt-3">
                    <Button
                        variant="outline-primary"
                        size="sm"
                        className="px-7 fs-9  my-0 btn"
                        type="submit"
                        disabled={loading}
                    >
                        Mark as Decline
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default DeclineActionPointModal;
