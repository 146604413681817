import { UilPen, UilTrash } from "@iconscout/react-unicons";
import Button from "components/base/Button";
import Unicon from "components/base/Unicon";
import CustomPagination from "components/sp-common/CustomPagination";
import { useAuth } from "providers/AuthContext";
import { useMaster } from "providers/MasterContext";
import { useProjectAdminContext } from "providers/ProjectAdminProvider";
import { useProjectContext } from "providers/ProjectProvider";
import { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Link, NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { PRIORITY_EDIT_MODE, PRIORITY_INDEX_VALUE_FIRST, PRIORITY_INDEX_VALUE_LAST, PRIORITY_PAGE_INDEX, PRIORITY_SUMMARY, PROJECT_PAST_USER_PERMISSION_DATA, PROJECT_PRESENT_USER_PERMISSION_DATA, PROJECT_PRIORITY_DATA, TOGGLE_ADD_PRIORITY_MODAL, TOGGLE_PRIORITY_DELETE_MODAL, TOGGLE_PRIORITY_SEQUENCE_MODAL, UPDATE_PRIORITY_DATA } from "reducers/ProjectReducer";
import apiCall from "services/api";
import { handleCutomError } from "services/handleCutomError";
import { ProjectPriorityType } from "types/project";
import AddPriorityModal from "./priority/AddPriorityModal";
import calculateSerialNumber from "common/calculateSerialNumber";
import ConfirmationModal from "common/ConfirmModal";
import { useToast } from "providers/ToastProvider";
import UpdatePrioritySequenceModal from "./priority/UpdatePrioritySequenceModal";

const ProjectPriority = () => {
    const { alias } = useParams();
    const { showSuccessToast, showErrorToast } = useToast();
    const { priorityData, summaryPriorityProject, updatePriorityData, toggleDeletePriorityModal, indexValueFirstPriorityProject, indexValueLastPriorityProject, pageIndexPriorityProject, projectDispatch } = useProjectContext();
    const { userTkn, workSpaceTkn, isUserAdmin, signOut } = useAuth();
    const navigation = useNavigate() as NavigateFunction;
    const tableRef = useRef<HTMLTableElement | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true)

                const [response] = await Promise.all([

                    apiCall({
                        url: `project/${alias}/masters/priority`,
                        method: 'GET',
                        headers: {
                            'x-access-token': userTkn,
                            'workspace': workSpaceTkn
                        },
                    }),
                ])

                if (response.status === 200) {
                    const priorityData = response?.data?.data?.ProjectsPriority;
                    const updatedData = priorityData?.map((users: any, index: any) => ({
                        ...users,
                        serialNumber: calculateSerialNumber(pageIndexPriorityProject || 1, index, response?.data?.data?.summary?.pageSize)
                    }));

                    projectDispatch({ type: PROJECT_PRIORITY_DATA, payload: updatedData });
                    const summary = response?.data?.data?.summary;
                    projectDispatch({
                        type: PRIORITY_SUMMARY, payload: {
                            "total": summary.total,
                            "page": summary.page,
                            "pageSize": summary.pageSize,
                            "total_page": summary.total_page,
                        }
                    });

                } else {
                    console.error("error message:");
                }
            } catch (error: any) {
                if (error?.isAxiosError) {
                    // Access the error message
                    handleCutomError(error, signOut, navigation);
                } else {
                    // Handle other types of errors
                    console.error('An error occurred:', error);
                    // Display a generic error message to the user
                    //   toast.error('An unexpected error occurred', {
                    //     position: "top-center"
                    //   });
                }
            } finally {
                setLoading(false)
            }
        };
        fetchData();
    }, [userTkn,]);

    useEffect(() => {
        // Now 'tableRef.current' contains the reference to the table element
        const table = tableRef.current;

        if (table) {
            const firstRow = table?.rows[1];
            const lastRowIndex = table?.rows.length - 1;
            const lastRow = table?.rows[lastRowIndex];
            if (firstRow) {
                const firstCell = firstRow.cells[0];
                if (firstCell) {
                    const cellValue = firstCell.textContent || firstCell.innerText;
                    const value = Number(cellValue)
                    projectDispatch({ type: PRIORITY_INDEX_VALUE_FIRST, payload: value });
                }
            }

            if (lastRow) {
                const firstCell = lastRow.cells[0];
                if (firstCell) {
                    const cellValue = firstCell.textContent || firstCell.innerText;
                    const value = Number(cellValue)
                    projectDispatch({ type: PRIORITY_INDEX_VALUE_LAST, payload: value });
                }
            }
        }
    }, [priorityData]);

    const setPageIndex = (newPageIndex: number) => {
        projectDispatch({ type: PRIORITY_PAGE_INDEX, payload: newPageIndex });
    };

    const setShowConfirmation = () => {
        projectDispatch({ type: TOGGLE_PRIORITY_DELETE_MODAL, payload: false })
    }

    const confirmDelete = async () => {
        // Call ChangeStatus function
        if (updatePriorityData) {
            await DeleteUser(updatePriorityData?.id, `${updatePriorityData?.title}`);
            projectDispatch({ type: TOGGLE_PRIORITY_DELETE_MODAL, payload: false })
        }

    };

    const DeleteUser = async (id: number, name: string) => {
        try {
            setLoading(true);

            const response = await apiCall({
                url: `project/${alias}/masters/priority/${id}/status-change`,
                method: 'POST',
                headers: {
                    'x-access-token': userTkn,
                    'workspace': workSpaceTkn
                },
            });


            if (response?.status === 200) {
                // Ensure that newData is always a valid array
                fetchPriorityData();

            } else {
                console.error("error message:");
            }
        } catch (error: any) {
            if (error?.isAxiosError) {
                // Access the error message
                handleCutomError(error, signOut, navigation);
            } else {
                // Handle other types of errors
                console.error('An error occurred:', error);
                // Display a generic error message to the user
                showErrorToast('An error occurred:', error);
            }
        } finally {
            setLoading(false); // Set loading to false after the API call is completed
        }
    };

    const fetchPriorityData = async () => {
        try {
            setLoading(true)

            const [response] = await Promise.all([

                apiCall({
                    url: `project/${alias}/masters/priority`,
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                        'workspace': workSpaceTkn
                    },
                }),
            ])

            if (response.status === 200) {
                const priorityData = response?.data?.data?.ProjectsPriority;
                const updatedData = priorityData?.map((users: any, index: any) => ({
                    ...users,
                    serialNumber: calculateSerialNumber(1, index, response?.data?.data?.summary?.pageSize)
                }));

                projectDispatch({ type: PROJECT_PRIORITY_DATA, payload: updatedData });
                const summary = response?.data?.data?.summary;
                projectDispatch({
                    type: PRIORITY_SUMMARY, payload: {
                        "total": summary.total,
                        "page": summary.page,
                        "pageSize": summary.pageSize,
                        "total_page": summary.total_page,
                    }
                });

                projectDispatch({
                    type: TOGGLE_ADD_PRIORITY_MODAL,
                    payload: false
                });

            } else {
                console.error("error message:");
            }
        } catch (error: any) {
            if (error?.isAxiosError) {
                // Access the error message
                handleCutomError(error, signOut, navigation);
            } else {
                // Handle other types of errors
                showErrorToast('An error occurred:', error);

            }
        } finally {
            setLoading(false)
        }
    };

    return (
        <>

            <div className="d-flex gap-2 justify-content-end mb-3">
                <div className="">
                    {isUserAdmin ? (
                        <Link
                            className="btn btn-outline-primary px-4"
                            to=""
                            onClick={() => {
                                projectDispatch({
                                    type: TOGGLE_PRIORITY_SEQUENCE_MODAL,
                                    payload: true
                                });
                            }}
                        >
                            Sequence
                        </Link>
                    ) : null}
                </div>
                <div className="">
                    {isUserAdmin ? (
                        <Link
                            className="btn btn-outline-primary px-4"
                            to=""
                            onClick={() => {
                                projectDispatch({
                                    type: TOGGLE_ADD_PRIORITY_MODAL,
                                    payload: true
                                });
                                projectDispatch({ type: PRIORITY_EDIT_MODE, payload: false });
                            }}
                        >
                            Add Priority
                        </Link>
                    ) : null}
                </div>

            </div>

            <Table className="phoenix-table fs-9 project-table border rounded" size='sm' striped hover responsive ref={tableRef}>
                <thead>
                    <tr id={'a1'} >
                        <th style={{ width: '2%', maxWidth: '20px', padding: "16px 0px" }}>#</th>
                        <th className="px-1" style={{ width: '25%', minWidth: '200px' }}>Title</th>
                        <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>Color</th>
                        <th className="px-1" style={{ width: '20%', minWidth: '100px' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {priorityData && priorityData?.map((item: ProjectPriorityType, index: number) => (
                        <tr key={index}>
                            <td>{item?.serialNumber}</td>

                            <td> {item?.title}</td>
                            <td>
                                {item?.color}
                            </td>

                            <td>
                                <div className="d-flex gap-2  align-items-center">
                                    <Button
                                        variant="phoenix-primary"
                                        className="btn-icon rounded-1 bg-transparent"
                                        onClick={() => {
                                            projectDispatch({ type: UPDATE_PRIORITY_DATA, payload: item });
                                            projectDispatch({ type: TOGGLE_ADD_PRIORITY_MODAL, payload: true });
                                            projectDispatch({ type: PRIORITY_EDIT_MODE, payload: true });
                                        }}
                                    >
                                        <Unicon icon={UilPen} size={18} />
                                    </Button>
                                    <Button
                                        variant="phoenix-primary"
                                        className="btn-icon rounded-1 bg-transparent"
                                        onClick={() => { projectDispatch({ type: UPDATE_PRIORITY_DATA, payload: item }); projectDispatch({ type: TOGGLE_PRIORITY_DELETE_MODAL, payload: true }) }}
                                    >
                                        <Unicon icon={UilTrash} size={18} />
                                    </Button>

                                </div>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </Table>
            {summaryPriorityProject?.total !== 0 ?
                <Row className="align-items-center pt-3 row">
                    <Col className="d-flex fs-9">
                        <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900">
                            {indexValueFirstPriorityProject} to {indexValueLastPriorityProject}
                            <span className="text-600"> items of </span>
                            {summaryPriorityProject?.total}
                        </p>
                    </Col>
                    <Col xs="auto">
                        <CustomPagination
                            pageIndex={pageIndexPriorityProject}
                            totalPage={summaryPriorityProject?.total_page}
                            activePage={Number(summaryPriorityProject?.page)}
                            setPageIndex={setPageIndex}
                        />
                    </Col>
                </Row>
                :
                <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
                    <span className="text-600"> No record found !!</span>
                </p>
            }

            <AddPriorityModal />
            <UpdatePrioritySequenceModal />
            <ConfirmationModal
                show={toggleDeletePriorityModal}
                onHide={setShowConfirmation}
                onConfirm={confirmDelete}
                text={`Are you sure you want to delete Priority, "${updatePriorityData?.title}" ?`}
            />
        </>
    )
}

export default ProjectPriority;