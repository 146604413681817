import { AxiosError } from 'axios';
import { dateAndTime } from 'common/timeStampToDate';
import Button from 'components/base/Button';
import { KanbanBoardItem, KanbanBoardMembers, KanbanBoardTask } from 'data/kanban';
import { useAuth } from 'providers/AuthContext';
import { useKanbanContext } from 'providers/KanbanProvider';
import { useToast } from 'providers/ToastProvider';
import React, { useState, useEffect, useRef } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import apiCall from 'services/api';
import { handleCutomError } from 'services/handleCutomError';
import { CardDateTime } from '../KanbanTaskDetailsModal';
import Unicon from 'components/base/Unicon';
import { UilPen } from '@iconscout/react-unicons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactSelect from 'components/base/ReactSelect';
import ToggleViewAvtar from 'components/common/ToggleViewAvtar';
import Avatar from 'components/base/Avatar';

interface EditableAssignToProps {
    task: KanbanBoardTask;
    list: KanbanBoardItem;
    setCardDateTime: React.Dispatch<React.SetStateAction<CardDateTime>>;
    setCardDetails: React.Dispatch<React.SetStateAction<KanbanBoardTask | undefined>>;
    onHistoryUpdate?: () => void;
}

export interface Loading {
    assignToUpdate: boolean

}
interface User {
    label: string;
    value: string;
}

const EditableAssignTo: React.FC<EditableAssignToProps> = ({ task, list, setCardDateTime, setCardDetails, onHistoryUpdate }) => {
    const { alias, id } = useParams();
    const { boardLists, boardUsersList, kanbanDispatch } = useKanbanContext();

    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const { showErrorToast, showSuccessToast } = useToast();
    const navigation = useNavigate();
    const [validated, setValidated] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [taskData, setTaskData] = useState<KanbanBoardTask | undefined>(undefined);
    const [userOptions, setUserOptions] = useState<User[]>([]);
    const [choosedOption, setChoosedOption] = useState<string>('')
    const [loading, setLoading] = useState<Loading>({
        assignToUpdate: false
    });
    const [originalAssignTo, setOriginalAssignTo] = useState<KanbanBoardMembers | undefined>(undefined);
    const [error, setError] = useState({
        assignTo: '',
    })
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (task) {
            setTaskData({
                ...task,
                id: task.id
            });
            setOriginalAssignTo(task?.assignTo);
            setChoosedOption(String(task?.assignTo?.id))
        }
    }, [task]);

    useEffect(() => {
        // loadUsers();
        const options = boardUsersList.map((user: any) => ({
            value: user.id,
            label: `${user.first_name} ${user.last_name} ${user.id}`,
        }));

        setUserOptions(options);
    }, [boardUsersList, task]);

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus(); // Set focus to input field
        }
    }, [isEditing]);

    const handleAssignToClick = () => {

        setIsEditing(true); //on Click Enable editing mode
    };

    const handleUserChange = async (selectedOption: any) => {

        const user = boardUsersList?.find((option: any) => option.id === (selectedOption?.value));

        setChoosedOption(String(user?.id))
        setTaskData((prevData) => {
            if (prevData) {
                const updatedData = { ...prevData, assignTo: user };
                return updatedData;
            }
            return prevData;
        });


        setError((prevError) => ({
            ...prevError,
            assignTo: '',
        }));

        // Call handleSubmit to auto-save the selection
    };


    const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        e?.stopPropagation();
        try {
            setLoading((prev) => ({
                ...prev,
                assignToUpdate: true
            }));
            const formData = new FormData();

 
            if (choosedOption) {
                formData.append('assign_to', choosedOption || '');
            }
            const response = await apiCall({
                url: `project/${alias}/boards/${id}/card/${taskData?.id}/update`,
                method: 'POST',
                data: formData,
                headers: {
                    'x-access-token': userTkn,
                    workspace: workSpaceTkn
                }
            });
            if (response.status === 200) {
                showSuccessToast('Card updated successfully!');
                const updatedItem = response?.data?.data?.boardListCard;

                const updatedBoardLists = boardLists.map((item) => {
                    if (Number(item.id) === Number(list.id)) {
                        // Update the board list by replacing the card within boardListCards
                        return {
                            ...item,
                            boardListCards: item.boardListCards.map(card =>
                                // If the card matches, replace it, otherwise keep it as is
                                card.id === updatedItem.id ? updatedItem : card
                            )
                        };
                    }
                    return item;
                });
                const { formattedDate, formattedTime } = dateAndTime(updatedItem.end_datetime)
                setCardDateTime({
                    cardDate: formattedDate,
                    cardTime: formattedTime
                })
                setCardDetails(updatedItem);
                setOriginalAssignTo(updatedItem.assignTo);
                kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });
                if (onHistoryUpdate) onHistoryUpdate();
            } else {
                throw new Error('Failed to update card');
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An unexpected error occurred:', error);
                showErrorToast('An unexpected error occurred');
            }
        } finally {
            setLoading((prev) => ({
                ...prev,
                assignToUpdate: false
            }));
            setIsEditing(false);
        }
    }

    const handleCancel = () => {
        // Reset the description to the original state and exit edit mode
        setTaskData((prevData) => {
            if (prevData) {
                return {
                    ...prevData,
                    assignTo: originalAssignTo
                };
            }
            return prevData;
        });
        setIsEditing(false); // Close the editor without submitting changes
    };

    return (
        <>

            {isEditing ? (
                <div className='d-flex gap-1 bg-white p-3 mb-2 rounded-1'>
                    <div className='w-100'>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <ReactSelect
                                name="assignTo"
                                options={userOptions}
                                onChange={handleUserChange}
                                value={userOptions?.filter((option: any) => option.value === Number(choosedOption))}
                                // isMulti
                                closeMenuOnSelect={true}
                                placeholder="Select User"
                                required
                                classNames={{
                                    control: () => 'py-2',
                                    valueContainer: () => 'lh-1'
                                }}
                            />
                            <span className="text-danger fs-9">{error?.assignTo}</span>
                            <div className='d-flex gap-2 justify-content-center align-items-center mt-2'>
                                <Button
                                    startIcon={<FontAwesomeIcon icon={faTimes} />}
                                    onClick={handleCancel}
                                    className="p-0"
                                    disabled={loading?.assignToUpdate}
                                >
                                    cancel
                                </Button>
                                <Button
                                    variant="outline-primary"
                                    className=""
                                    type='submit'
                                    disabled={loading?.assignToUpdate}
                                >
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </div>
                    {loading?.assignToUpdate && <Spinner animation="border" variant="secondary" size="sm" />}
                </div>

            ) : (
                <div
                    className="fs-9 d-flex gap-2 mb-0 text-body-emphasis lh-sm w-100 editable-field "

                >
                    <div>
                        {taskData?.assignTo?.id
                            ?
                            <ToggleViewAvtar
                                tooltip={`${taskData?.assignTo?.first_name} ${taskData?.assignTo?.last_name}`}
                            >
                                <Avatar size="m" variant="name" key={taskData?.assignTo?.id}>{taskData?.assignTo?.short_name}</Avatar>
                            </ToggleViewAvtar>
                            : ''}
                    </div>
                    <div >
                        <Button
                            variant=""
                            className="btn-icon"
                            onClick={handleAssignToClick}
                        >
                            <Unicon icon={UilPen} size={16} />
                        </Button>
                    </div>
                </div>

            )}

        </>
    );
};

export default EditableAssignTo;
