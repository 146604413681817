import {
  useLocation,
  Link,
  useNavigate,
  NavigateFunction,
  useParams,
} from "react-router-dom";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import { useAppContext } from "providers/AppProvider";
import { useEffect, useState } from "react";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import Unicon from "components/base/Unicon";
import { UilCube, UilFilePlusAlt, UilPlus } from "@iconscout/react-unicons";
import { Col, Card, Row, Nav, NavLink } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import apiCall from "services/api";
import { useAuth } from "providers/AuthContext";
import { handleCutomError } from "services/handleCutomError";
import { toast, ToastContainer } from "react-toastify";
interface DataItem {
  id: number;
  title: string;
  body: string; // JSON string representing the `EditorBody` structure
}
const ProjectInfo = () => {
  const {
    config: { theme, navbarPosition },
    setConfig,
  } = useAppContext();
  const { userTkn, user, workSpaceTkn, signOut } = useAuth();
  const { alias, workspace } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const navigation = useNavigate() as NavigateFunction;
  const [data, setData] = useState<DataItem[]>([]);
  //set top navigation
  useEffect(() => {
    const value = "combo";
    const value2 = "slim";

    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant,
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true
        const response = await apiCall({
          url: `project/${alias}/documents`,
          method: "GET",
          headers: {
            "x-access-token": userTkn,
            workspace: workSpaceTkn,
          },
        });
       
        if (response?.status === 200) {
          setData(response?.data?.data?.document);
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error("An error occurred:", error);
          // Display a generic error message to the user
          toast.error("An unexpected error occurred", {
            position: "top-center",
          });
        }
      } finally {
        setLoading(false); // Set loading to false after the API call is completed
      }
    };
    fetchData();
  }, []);

  useSettingsMountEffect({
    showSettingPanelButton: false,
  });

  return (
    <>
      <div>
        <div>
          <div className="d-flex flex-wrap mb-4  justify-content-between">
            <h2 className="mb-0">
              <Unicon icon={UilCube} size={28} />
              <span className="me-3">{" Project Info"}</span>
            </h2>
          </div>

          <div className="d-md-block d-block">
            <Row>
              <Col lg={9}>
               
              </Col>
              <Col lg={3}>
                <Card className="projectInfo_card mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5 className="">Related Documents</h5>
                    </div>
                    <Link
                      to={{
                        pathname: `/${workspace}/project/${alias}/block-editor`,
                      }}
                      className="btn btn-outline-primary px-5"
                    >
                      {" "}
                      New
                    </Link>
                  </div>

                  <div className="d-flex flex-column gap-2">
                    {data?.map((item, index) => {
                 
                      return (
                        <Link
                          key={index}
                          to={{
                            pathname: `/${workspace}/project/${alias}/block-editor/${item?.id}`,
                          }}
                          state={{
                            title: item?.title,
                            body: item?.body,
                          }}
                        >
                          {item?.title}
                        </Link>
                      );
                    })}
                  </div>
                </Card>
                
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectInfo;
