import { Nav, Navbar } from 'react-bootstrap';
// import { routes } from 'sitemap';
import { routes } from '../../../sitemap-custom/sitemapProjectMain';
import { capitalize } from 'helpers/utils';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import {
  UilArrowFromRight,
  UilLeftArrowToLeft
} from '@iconscout/react-unicons';
import { useAppContext } from 'providers/AppProvider';
import classNames from 'classnames';
import Button from 'components/base/Button';
import NavbarTopNav from '../navbar-horizontal/NavbarTopNav';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import NavbarVerticalCollapseProvider from './NavbarVerticalCollapseProvider';
import { useLocation } from 'react-router-dom';
import Badge from 'components/base/Badge';
import Avatar3 from 'components/base/Avatar3';
interface NavbarVerticalCustomProps {
  alias: string; // Specify the type of 'alias' as string
  workspace: string;
}
const NavbarVerical = ({ alias, workspace }: NavbarVerticalCustomProps) => {
  const {
    config: {
      navbarPosition,
      openNavbarVertical,
      navbarVerticalAppearance,
      isNavbarVerticalCollapsed
    },
    setConfig
  } = useAppContext();
  let { state } = useLocation();
  const { breakpoints } = useBreakpoints();

  return (
    <NavbarVerticalCollapseProvider>
      <Navbar
        className={classNames('navbar-vertical', {
          'navbar-darker': navbarVerticalAppearance === 'darker'
        })}
        expand="lg"
        variant=""
      >
        <Navbar.Collapse id="navbarVerticalCollapse" in={openNavbarVertical}>
          <div className="navbar-vertical-content custom_navbar-design">
            <div className="d-flex justify-content-start ps-3 mb-3">
              <div className="avatar_width">
                {!isNavbarVerticalCollapsed ? (
                  <Badge bg="info" variant="phoenix">
                    {alias?.slice(0, 14)}
                  </Badge>
                ) : (
                  <Avatar3
                    size="m"
                    variant="name"
                    className={`me-1 menu-project-logo`}
                    border={'0'}
                  >
                    {alias?.slice(0, 2)}
                  </Avatar3>
                )}
              </div>
            </div>
            <Nav className="flex-column" as="ul" id="navbarVerticalNav">
              {routes.map(route => {
                return (
                  <Nav.Item key={route.label}>
                    {!route.labelDisabled && (
                      <>
                        <p className="navbar-vertical-label">
                          {capitalize(route.label)}
                        </p>
                        <hr className="navbar-vertical-line" />
                      </>
                    )}
                    <NavbarVerticalMenu
                      level={1}
                      routes={route.pages}
                      alias={alias || ''}
                      state={state}
                      workspace={workspace}
                    />
                  </Nav.Item>
                );
              })}
            </Nav>

            {navbarPosition === 'combo' && breakpoints.down('lg') && (
              <div className="move-container">
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr" />
                </div>
                <NavbarTopNav />
              </div>
            )}
          </div>
        </Navbar.Collapse>
        <div className="navbar-vertical-footer">
          <Button
            className="navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center"
            onClick={() => {
              setConfig({
                isNavbarVerticalCollapsed: !isNavbarVerticalCollapsed
              });
            }}
          >
            {isNavbarVerticalCollapsed ? (
              <UilArrowFromRight size={16} className="mb-1" />
            ) : (
              <>
                <UilLeftArrowToLeft size={16} className="mb-1" />
                <span className="ms-2 fs-8 fw-bold">Collapsed View</span>
              </>
            )}
          </Button>
        </div>
      </Navbar>
    </NavbarVerticalCollapseProvider>
  );
};

export default NavbarVerical;
