import classNames from 'classnames';
import Button from 'components/base/Button';
import { KanbanBoardItem } from 'data/kanban';
import React, { useEffect, useState } from 'react';
import KanbanListItemCard from './KanbanListItemCard';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import KanbanListHeader from './KanbanListHeader';
import { Draggable } from 'react-beautiful-dnd';
import apiCall from 'services/api';
import { toast } from 'react-toastify';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'providers/AuthContext';
import { handleCutomError } from 'services/handleCutomError';
import { useKanbanContext } from 'providers/KanbanProvider';
import { useToast } from 'providers/ToastProvider';
import { AxiosError } from 'axios';

interface KanbanListProps {
  list: KanbanBoardItem;
}


const KanbanList = ({ list }: KanbanListProps) => {
  const { showErrorToast } = useToast();
  const { alias, id } = useParams();
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const navigation = useNavigate() as NavigateFunction;
  const { boardLists, filterValue,  kanbanDispatch } = useKanbanContext();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  // const [collapsed, setCollapsed] = useState(!!list.isCollapsed);
  const [newTaskTitle, setNewTaskTitle] = useState<string>(''); // State for the input value
  const [loading, setLoading] = useState<boolean>(false);
  // const [filterValue, setFilterValue] = useState<string>('');

  const initialCollapsed =
    localStorage.getItem(`collapsed-${list.id}`) === 'true';
  const [collapsed, setCollapsed] = useState<boolean>(initialCollapsed);


  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Save the collapsed state to localStorage when it changes
    localStorage.setItem(`collapsed-${list.id}`, String(collapsed));
  }, [collapsed, list.id]);

  // Form submit handler
  const handleAddTask = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!newTaskTitle.trim()) {
      toast.error('Card title cannot be empty.');
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('list_id', list.id.toString());
      formData.append('title', newTaskTitle);

      const response = await apiCall({
        url: `project/${alias}/boards/${id}/card/create`,
        method: 'POST',
        data: formData,
        headers: {
          'x-access-token': userTkn,
          workspace: workSpaceTkn
        }
      });
      if (response.status === 200) {
        toast.success('Card added successfully!');
        setNewTaskTitle(''); // Clear the input field
        const addedItem = response?.data?.data?.boardListCard;
        const updatedList = boardLists.find(
          item => Number(item.id) === Number(list.id)
        );
        if (updatedList) {
          updatedList.boardListCards.push(addedItem);
        }
        kanbanDispatch({ type: 'SET_DATA', payload: boardLists });

      } else {
        throw new Error('Failed to add card');
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        handleCutomError(error, signOut, navigation);
      } else {
        console.error('An unexpected error occurred:', error);
        showErrorToast('An unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={classNames('kanban-column scrollbar mt-3', {
        collapsed
      })}
      style={{maxHeight: windowHeight - 200}}
    >
      <div className='list-header'>
      <KanbanListHeader
        list={list}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
      </div>
      <div className="kanban-items-container" >
        {Array.isArray(list.boardListCards) &&
          list.boardListCards.length > 0 ? (
          list.boardListCards?.map((task, index) => {
            return (
              <div
                className="py-2 px-2"
                key={task.id}
              >
                <Draggable
                  key={`${list.id}-${task.id}`}
                  draggableId={`${list.id}-${task.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <>
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <KanbanListItemCard
                          list={list}
                          task={task}
                          className={classNames({
                            'bg-body-emphasis': snapshot.isDragging
                          })}
                        />
                      </div>
                    </>
                  )}
                </Draggable>
              </div>
            );
          })
        ) : (
          <div className='ms-4'>No card available</div>
        )}
      </div>
      <div className="py-3 px-4 kanban-add-task">
        <Form onSubmit={handleAddTask} className="d-flex">

          <Form.Control
            className="search-input rounded-3 me-2  px-3 col-10"
            placeholder="Add new card"
            value={newTaskTitle}
            onChange={e => setNewTaskTitle(e.target.value)}
            disabled={loading}
          />
          <Button
            className="bg-body-tertiary px-0 col-2"
            type="submit"
            disabled={loading}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="text-white dark__text-gray-400"
              transform="grow-4 down-1"
            />
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default KanbanList;
