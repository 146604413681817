import React, { createContext, useState, useContext, ReactNode } from 'react';

interface AdminValues {
  is_diary_admin: number;
  is_meeting_admin: number;
  is_project_admin: number;
  is_story_admin: number;
}

interface ProjectAdminContextType {
  adminValues: AdminValues;
  setAdminValues: React.Dispatch<React.SetStateAction<AdminValues>>;
}

export const ProjectAdminContext = createContext<
  ProjectAdminContextType | undefined
>(undefined);

export const useProjectAdminContext = (): ProjectAdminContextType => {
  const context = useContext(ProjectAdminContext);
  if (!context) {
    throw new Error(
      'useProjectAdminContext must be used within an ProjectAdminContextProvider'
    );
  }
  return context;
};

interface ProjectAdminContextProviderProps {
  children: ReactNode;
}

export const ProjectAdminContextProvider = ({
  children
}: ProjectAdminContextProviderProps) => {
  const [adminValues, setAdminValues] = useState<AdminValues>({
    is_diary_admin: 0,
    is_meeting_admin: 0,
    is_project_admin: 0,
    is_story_admin: 0
  });

  return (
    <ProjectAdminContext.Provider value={{ adminValues, setAdminValues }}>
      {children}
    </ProjectAdminContext.Provider>
  );
};
