import { members } from './users';
import {
  faArrowsUpToLine,
  faBoxArchive,
  faClone,
  faDownload,
  faFileExport,
  faPaperclip,
  faPlus,
  faRandom,
  faShareNodes,
  faSquarePlus,
  faTrashCan
} from '@fortawesome/free-solid-svg-icons';

import attachment1 from 'assets/img/kanban/a1.jpg';
import { FileAttachment } from 'components/common/AttachmentPreview';
import { title } from 'process';
import { ProjectCategoryType, ProjectPriorityType } from 'types/project';

// export interface KanbanBoardTask {
//   id: string;
//   status: {
//     label: string;
//     icon: IconProp;
//     color: string;
//   };
//   title: string;
//   desctiption?: string;
//   priority: 'High' | 'Low' | 'Medium';
//   coverImage?: string;
//   completedTasks?: number[];
//   attachments?: number;
//   date?: Date | string;
//   members?: Member[];
// }
export interface SummaryProps {
  total: number;
  page: number;
  pageSize: number;
  total_page: number;
}
export const initialSummary: SummaryProps = {
  total: 0,
  page: 1,
  pageSize: 12,
  total_page: 1,
};

export interface CardPriority {
  value: number;
  label: string;
  color?: string;
}

export interface CardCategory {
  value: number;
  label: string;
  color?: string;
}

export interface BoardLabels {
  id: number;
  title: string;
  color?: string;
}

interface Updator {
  id: number;
  short_name: string;
  color_code: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  designation_id: number;
  is_super_admin: number;
  status: number;
  status_name: string;
}

export interface KanbanTaskHistroy {
  action_type: string;
  updator: Updator;
  updatedAt: string;
  narration: string;
  project_category_id: number;
  project_priority_id: number;
}

export interface TaskAttachment {
  id: number,
  file: string
}

export interface TaskLables {
  id: number;
  lable: string;
  color: string;
  status: string;
}
export interface TaskLinks {
  id?: number;
  title: string;
  link: string;
}

export const initialLinkData: TaskLinks ={
  title: '',
  link:''
}


export interface KanbanBoardTask {
  id: string;
  title: string;
  description: string;
  links: TaskLinks[];
  priority: ProjectPriorityType;
  project_priority_id?: number;
  category: ProjectCategoryType;
  project_category_id?: number;
  color: string;
  user: string;
  users?: KanbanBoardMembers[];
  coverImage?: string;
  end_datetime: string;
  parent_id?: string;
  sequence: 1;
  assignTo?: KanbanBoardMembers;
  status?: number;
  history: KanbanTaskHistroy[];
  attachments?: TaskAttachment[];
  labels?: BoardLabels[];
  list_id?: number;
  user_id?: string;
}

export interface KanbanBoardMembers {
  id: number;
  short_name: string;
  color_code: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string | null;
  designation_id: number;
  is_super_admin: number;
  status: number;
  status_name: string;
  avatar?: string;
  is_manager?: number
}

export interface KanbanBoard {
  id: number;
  title: string;
  description: string;
  category?: string;
  coverImage?: string;
  banner?: string;
  boardbanner?: string;
  totalTasks?: number;
  comments?: number;
  deadlines?: number;
  users?: KanbanBoardMembers[];
}

export interface BoardFormData {
  id: number;
  title: string;
  description: string;
  banner: File | string;
}

export interface TaskAttachmentFormData {
  id: number;
  attachment: File | string;
  title: string;
}

export interface TaskLabelsFormData {
  lable: string;
  color: string;
}

export interface ListFormData {
  id: number;
  title: string;
  description?: string;
  color?: string;
  columnNo?: string;
}

export interface LabelsFormToggle {
  labels: boolean;
  labelsList: boolean;
  createLabel: boolean;
}

export interface ColorPaletteData {
  id: number;
  code: string;
  codeHoverd: string;
  name: string;
  textColor: string;
  className: string;
}
export interface CreateNewLabel {
  id: number;
  colorPalette: ColorPaletteData;
  title: string;
}
// export interface KanbanBoardItem {
//   id: string;
//   title: string;
//   borderColor: string;
//   isCollapsed?: boolean;
//   tasks: KanbanBoardTask[];
// }
export interface KanbanBoardItem {
  id: number;
  title: string;
  description?: string;
  card_image?: string;
  sequence?: boolean;
  isCollapsed?: boolean;
  borderColor?: string;
  columnNo?: string;
  boardListCards: KanbanBoardTask[];
}

export const boardInitialData = {
  id: 0,
  title: '',
  description: '',
  banner: ''
}
export const listInitialData = {
  id: 0,
  title: '',
  description: '',
  color: '',
  columnNo: ''
}

export const taskAttachementInitialData = {
  id: 0,
  attachment: '',
  title: ''
}

export const taskLabelInitialData = {
  lable: '',
  color: ''
}

export const initialDataFormToggle: LabelsFormToggle = {
  labels: false,
  labelsList: false,
  createLabel: false
}

export const colorPaletteInitialData: ColorPaletteData = {
  id: 0,
  code: '#091e420f',
  codeHoverd: "#091e420f",
  name: 'lite Gray',
  textColor: '#00000',
  className: 'lite-gray'
}

export const initialCreateNewLabelData: CreateNewLabel = {
  id: 0,
  colorPalette: colorPaletteInitialData,
  title: ''
}


export const selectColor = [
  {
    id: 1,
    code: "#baf3db",
    codeHoverd: "#7ee2b8",
    name: "subtle green",
    textColor: '#164b35',
    className: 'subtle-green'
  },
  {
    id: 2,
    code: "#f8e6a0",
    codeHoverd: "#f5cd47",
    name: "subtle yellow",
    textColor: '#533f04',
    className: 'subtle-yellow'
  },
  {
    id: 3,
    code: "#ffe2bd",
    codeHoverd: "#fec57b",
    name: "subtle orange",
    textColor: '#5f3811',
    className: 'subtle-orange'
  },
  {
    id: 4,
    code: "#ffd2cc",
    codeHoverd: "#ff9c8f",
    name: "subtle red",
    textColor: '#601e16',
    className: 'subtle-red'
  },
  {
    id: 5,
    code: "#dfd8fd",
    codeHoverd: "#b8acf6",
    name: "subtle purple",
    textColor: '#352c63',
    className: 'subtle-purple'
  },
  {
    id: 6,
    code: "#4bce97",
    codeHoverd: "#7ee2b8",
    name: "green",
    textColor: '#164b35',
    className: 'green-subtle'
  },
  {
    id: 7,
    code: "#e2b203",
    codeHoverd: "#f5cd47",
    name: "yellow",
    textColor: '#533f04',
    className: 'yellow-subtle'
  },
  {
    id: 8,
    code: "#faa53d",
    codeHoverd: "#fec57b",
    name: "orange",
    textColor: '#5f3811',
    className: 'orange-subtle'
  },
  {
    id: 9,
    code: "#f87462",
    codeHoverd: "#ff9c8f",
    name: "red",
    textColor: '#601e16',
    className: 'red-subtle'
  },
  {
    id: 10,
    code: "#9f8fef",
    codeHoverd: "#b8acf6",
    name: "purple",
    textColor: '#352c63',
    className: 'purple-subtle'
  },
  {
    id: 11,
    code: "#1f845a",
    codeHoverd: "#216e4e",
    name: "bold green",
    textColor: '#ffffff',
    className: 'bold-green'
  },
  {
    id: 12,
    code: "#946f00",
    codeHoverd: "#7f5f01",
    name: "bold yellow",
    textColor: '#ffffff',
    className: 'bold-yellow'
  },
  {
    id: 13,
    code: "#b65c02",
    codeHoverd: "#974f0c",
    name: "bold orange",
    textColor: '#ffffff',
    className: 'bold-orange'
  },
  {
    id: 14,
    code: "#ca3521",
    codeHoverd: "#ae2a19",
    name: "bold red",
    textColor: '#ffffff',
    className: 'bold-red'
  },
  {
    id: 15,
    code: "#6e5dc6",
    codeHoverd: "#5e4db2",
    name: "bold purple",
    textColor: '#ffffff',
    className: 'bold-purple'
  },
  {
    id: 16,
    code: "#cce0ff",
    codeHoverd: "#85b8ff",
    name: "subtle blue",
    textColor: '#09326c',
    className: 'subtle-blue'
  },
  {
    id: 17,
    code: "#c1f0f5",
    codeHoverd: "#8bdbe5",
    name: "subtle sky",
    textColor: '#1d474c',
    className: 'subtle-sky'
  },
  {
    id: 18,
    code: "#D3F1A7",
    codeHoverd: "#b3df72",
    name: "subtle lime",
    textColor: '#37471f',
    className: 'subtle-lime'
  },
  {
    id: 19,
    code: "#fdd0ec",
    codeHoverd: "#f797d2",
    name: "subtle pink",
    textColor: '#50253f',
    className: 'subtle-pink'
  },
  {
    id: 21,
    code: "#dcdfe4",
    codeHoverd: "#b3b9c4",
    name: "subtle black",
    textColor: '#091e42',
    className: 'subtle-black'
  },
  {
    id: 22,
    code: "#579dff",
    codeHoverd: "#85b8ff",
    name: "blue",
    textColor: '#09326c',
    className: 'blue'
  },
  {
    id: 23,
    code: "#60c6d2",
    codeHoverd: "#8bdbe5",
    name: "sky",
    textColor: '#1d474c',
    className: 'sky'
  },
  {
    id: 24,
    code: "#94c748",
    codeHoverd: "#b3df72",
    name: "lime",
    textColor: '#37471f',
    className: 'lime'
  },
  {
    id: 25,
    code: "#e774bb",
    codeHoverd: "#f797d2",
    name: "pink",
    textColor: '#50253f',
    className: 'pink'
  },
  {
    id: 26,
    code: "#8590a2",
    codeHoverd: "#b3b9c4",
    name: "black",
    textColor: '#8590a2',
    className: 'black'
  },
 
];

export const kanbanBoardMembers = {
  admin: members[3],
  members: members.slice(0, 4),
  guests: members.slice(4, 8)
};

export const kanbanAttachments: FileAttachment[] = [
  {
    name: 'Silly_sight_1.png',
    size: '123.34 KB',
    format: 'jpg',
    preview: attachment1,
    date: '21st December, 12:56 PM'
  },
  {
    name: 'All_images.zip',
    size: '123.34 KB',
    format: 'zip',
    date: '21st December, 12:56 PM'
  }
];
export const kanbanActions = [
  {
    icon: faFileExport,
    label: 'Move'
  },
  {
    icon: faClone,
    label: 'Duplicate'
  },
  {
    icon: faShareNodes,
    label: 'Share'
  },
  {
    icon: faSquarePlus,
    label: 'Create template'
  },
  {
    icon: faArrowsUpToLine,
    label: 'Jump to top'
  },
  {
    icon: faBoxArchive,
    label: 'Move to Archive'
  },
  {
    icon: faTrashCan,
    label: 'Move to Trash'
  },
  {
    icon: faDownload,
    label: 'Print/Download'
  }
];

export const kanbanActivities = [
  {
    id: '1',
    task: '<span class="fw-bold"> Alfen Loebe </span> Moved the task <a href="#!">"the standard chunk" </a>from <span class="fw-bold">Doing</span> to <span class="fw-bold">To Do</span>',
    time: '10:41 AM',
    date: 'Aughst 7,2022',
    icon: faRandom,
    iconColor: 'warning'
  },
  {
    id: '2',
    task: '<span class="fw-bold"> Jessie Samson </span> Attached image3.png to the task <a href="#!">"the standard chunk" </a>',
    time: '10:41 AM',
    date: 'Aughst 7,2022',
    icon: faPaperclip,
    iconColor: 'info'
  },
  {
    id: '3',
    task: '<span class="fw-bold"> Alfen Loebe </span> Moved the task <a href="#!">"the standard chunk" </a>from <span class="fw-bold">Doing</span> to <span class="fw-bold">To Do</span>',
    time: '10:41 AM',
    date: 'Aughst 7,2022',
    icon: faPlus,
    iconColor: 'info'
  },
  {
    id: '4',
    task: '<span class="fw-bold"> Alfen Loebe </span> Moved the task <a href="#!">"the standard chunk" </a>from <span class="fw-bold">Doing</span> to <span class="fw-bold">To Do</span>',
    time: '10:41 AM',
    date: 'Aughst 7,2022',
    icon: faRandom,
    iconColor: 'primary'
  }
];

