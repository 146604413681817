// firebaseConfig.js
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyA49YMjBs6s81ovgXE8Jcx7EdvGkUE4KkA",
    authDomain: "spider-404009.firebaseapp.com",
    projectId: "spider-404009",
    storageBucket: "spider-404009.firebasestorage.app",
    messagingSenderId: "725587406306",
    appId: "1:725587406306:web:2e32b0b302d6bfb9099dbb"
  };

const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
