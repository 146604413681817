import { AxiosError } from 'axios';
import Button from 'components/base/Button';
import { KanbanBoardItem, KanbanBoardTask } from 'data/kanban';
import { useAuth } from 'providers/AuthContext';
import { useKanbanContext } from 'providers/KanbanProvider';
import { useToast } from 'providers/ToastProvider';
import React, { useState, useEffect } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import apiCall from 'services/api';
import { handleCutomError } from 'services/handleCutomError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactSelect from 'components/base/ReactSelect';
import Unicon from 'components/base/Unicon';
import { UilPen } from '@iconscout/react-unicons';
import { CardDateTime } from '../KanbanTaskDetailsModal';
import { dateAndTime } from 'common/timeStampToDate';


interface EditableCategoryProps {
    task: KanbanBoardTask;
    list: KanbanBoardItem;
    setCardDateTime: React.Dispatch<React.SetStateAction<CardDateTime>>;
    setCardDetails: React.Dispatch<React.SetStateAction<KanbanBoardTask | undefined>>;
    onHistoryUpdate?: () => void;
}

export interface Loading {
    categoryUpdate: boolean

}
interface User {
    label: string;
    value: string;
}

const CustomSingleValue = ({ data }: any) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
            style={{
                width: 10,
                height: 10,
                borderRadius: '50%',
                backgroundColor: data.color,
                marginRight: 8,

            }}
        ></span>
        {data.label}
    </div>
);

// Custom option rendering
const CustomOption = (props: any) => {
    const { data, innerRef, innerProps } = props;
    return (
        <div
            ref={innerRef}
            {...innerProps}
            style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                cursor: 'pointer',
                backgroundColor: props.isFocused ? '#f0f0f0' : '#fff',
            }}
        >
            <span
                style={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: data.color,
                    marginRight: 8,
                }}
            ></span>
            {data.label}
        </div>
    );
};


const EditableCategory: React.FC<EditableCategoryProps> = ({ task, list, setCardDateTime, setCardDetails, onHistoryUpdate }) => {
    const { alias, id } = useParams();
    const { boardLists, cardCategory, kanbanDispatch } = useKanbanContext();
    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const { showErrorToast, showSuccessToast } = useToast();
    const navigation = useNavigate();
    const [validated, setValidated] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [taskData, setTaskData] = useState<KanbanBoardTask | undefined>(undefined);
    const [loading, setLoading] = useState<Loading>({
        categoryUpdate: false
    });
    const [originalCategory, setOriginalCategory] = useState({
        category: {
            value: 0,
            label: '',
            color: ''
        },
        project_category_id: 0
    });
    const [error, setError] = useState({
        category: '',
    });

    useEffect(() => {
        if (task) {
            setTaskData({
                ...task,
                id: task.id,
                category: task?.category,
                project_category_id: task?.category?.id

            });
            setOriginalCategory({
                category: {
                    value: taskData?.category?.id || 0,
                    label: taskData?.category?.title || '',
                    color: taskData?.category?.color || '',
                },
                project_category_id: task?.category?.id
            });
        }
    }, [task]);


    const handleCategoryClick = () => {

        setIsEditing(true); //on Click Enable editing mode
    };

    const handleCategoryChange = async (selectedOption: any) => {
        setTaskData((prevData) => {
            if (prevData) {
                return {
                    ...prevData,
                    project_category_id: selectedOption?.value,
                    category: selectedOption
                };
            }
            return prevData;
        });


        setError((prevError) => ({
            ...prevError,
            category: '',
        }));
    };



    const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        e?.stopPropagation();
 
        if (onHistoryUpdate) onHistoryUpdate();
        if (taskData?.project_category_id === originalCategory?.project_category_id) {
            setIsEditing(false);
            return; // No change in title, don't submit
        }
        try {
            setLoading((prev) => ({
                ...prev,
                categoryUpdate: true
            }));
            const formData = new FormData();
            if (taskData?.project_category_id) {
                formData.append('project_category_id', String(taskData?.project_category_id) || '');
            }
            const response = await apiCall({
                url: `project/${alias}/boards/${id}/card/${taskData?.id}/update`,
                method: 'POST',
                data: formData,
                headers: {
                    'x-access-token': userTkn,
                    workspace: workSpaceTkn
                }
            });
            if (response.status === 200) {
                showSuccessToast('Card updated successfully!');
                const updatedItem = response?.data?.data?.boardListCard;
                const { formattedDate, formattedTime } = dateAndTime(updatedItem.end_datetime)
                setCardDateTime({
                    cardDate: formattedDate,
                    cardTime: formattedTime
                })
                setCardDetails(updatedItem);
                const updatedBoardLists = boardLists.map((item) => {
                    if (Number(item.id) === Number(list.id)) {
                        // Update the board list by replacing the card within boardListCards
                        return {
                            ...item,
                            boardListCards: item.boardListCards.map(card =>
                                // If the card matches, replace it, otherwise keep it as is
                                card.id === updatedItem.id ? updatedItem : card
                            )
                        };
                    }
                    return item;
                });

                setOriginalCategory({
                    category: {
                        value: updatedItem?.category?.id || 0,
                        label: updatedItem?.category?.title || '',
                        color: updatedItem?.category?.color || '',
                    },
                    project_category_id: task?.category?.id
                });
                kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });
                
            } else {
                throw new Error('Failed to update card');
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An unexpected error occurred:', error);
                showErrorToast('An unexpected error occurred');
            }
        } finally {
            setLoading((prev) => ({
                ...prev,
                categoryUpdate: false
            }));
            setIsEditing(false);
        }
    }

    const handleCancel = () => {
        // Reset the description to the original state and exit edit mode
        setTaskData((prevData) => {
            if (prevData) {
                return {
                    ...prevData,
                    project_category_id: originalCategory?.project_category_id,
                    category: {
                        id: originalCategory?.category?.value,
                        title: originalCategory?.category?.label,
                        color: originalCategory?.category?.color
                    }
                };
            }
            return prevData;
        });
        setIsEditing(false); // Close the editor without submitting changes
    };

    const getCategoryColor = (value: number) => {
        const category = cardCategory.find((category) => Number(category.value) === value);
        return category?.color || "#ffffff"
    }

    const getCategoryLabel = (value: number) => {
        const category = cardCategory.find((category) => Number(category.value) === value);
        return category?.label || ""
    }

    return (
        <>

            {isEditing ? (
                <div className='d-flex gap-1 bg-white p-3 mb-2 rounded-1'>
                    <div className='w-100'>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <ReactSelect
                                name="category"
                                options={cardCategory}
                                onChange={handleCategoryChange}
                                value={cardCategory?.filter((option: any) => option.value === taskData?.project_category_id)}
                                // isMulti
                                closeMenuOnSelect={true}
                                placeholder="Select Category"
                                required
                                classNames={{
                                    control: () => 'py-2',
                                    valueContainer: () => 'lh-1'
                                }}
                                components={{
                                    SingleValue: CustomSingleValue,
                                    Option: CustomOption,
                                }}
                            />
                            <span className="text-danger fs-9">{error?.category}</span>
                            <div className='d-flex gap-2 justify-content-center align-items-center mt-2'>
                                <Button
                                    startIcon={<FontAwesomeIcon icon={faTimes} />}
                                    onClick={handleCancel}
                                    className="p-0"
                                    disabled={loading?.categoryUpdate}
                                >
                                    cancel
                                </Button>
                                <Button
                                    variant="outline-primary"
                                    className=""
                                    type='submit'
                                    disabled={loading?.categoryUpdate}
                                >
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </div>
                    {loading?.categoryUpdate && <Spinner animation="border" variant="secondary" size="sm" />}
                </div>

            ) : (

                <div
                    className="d-flex gap-2 fs-9 mb-0 text-body-emphasis lh-sm w-100 editable-field"

                >
                    <div>
                        {taskData?.project_category_id && <div className="mb-0 text-body-emphasis fs-8 fw-mediums">
                            <FontAwesomeIcon
                                icon={faCircle}
                                transform="shrink-6 down-1"
                                color={getCategoryColor(Number(taskData?.project_category_id)) || '#ffffff'}
                            />
                            {getCategoryLabel(Number(taskData?.project_category_id))}
                        </div>
                        }
                    </div>
                    <div >
                        <Button
                            variant=""
                            className="btn-icon"
                            onClick={handleCategoryClick}
                        >
                            <Unicon icon={UilPen} size={16} />
                        </Button>
                    </div>
                </div>
            )}

        </>
    );
};

export default EditableCategory;
