import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import { Dispatch, SetStateAction } from 'react';

interface BreadeCrumbData {
  workspace?: string;
  pageName?: string;
  projectTitle?: string;
  board?: string;
  setBreadCrumb: Dispatch<SetStateAction<PageBreadcrumbItem[]>>;
}
export const getPageBreadCrumbs = ({
  pageName,
  workspace,
  setBreadCrumb,
  projectTitle,
  board
}: BreadeCrumbData) => {
  switch (pageName) {
    case 'ProjectList':
      if (workspace) {
        setBreadCrumb([
          {
            label: '',
            url: `/${workspace}`
          },
          {
            label: workspace,
            active: true
          }
        ]);
      }
      break;
    case 'CreateProject':
      if (workspace) {
        setBreadCrumb([
          {
            label: '',
            url: `/${workspace}`
          },
          {
            label: workspace,
            active: true
          },
          {
            label: 'create project',
            active: true
          }
        ]);
      }
      break;

    case 'ProjectSettings':
      if (workspace && projectTitle) {
        setBreadCrumb([
          {
            label: '',
            url: `/${workspace}`
          },
          {
            label: workspace,
            active: true
          },
          // {
          //     label: 'project',
          //     url: `/${workspace}/project/list`,
          // },
          {
            label: projectTitle,
            active: true
          }
        ]);
      }
      break;

    case 'ProjectOverView':
      if (workspace && projectTitle) {
        setBreadCrumb([
          {
            label: '',
            url: `/${workspace}`
          },
          {
            label: workspace,
            active: true
          },
          // {
          //     label: 'Project',
          //     url: `/${workspace}/project/list`,
          // },
          {
            label: projectTitle,
            active: true
          }
        ]);
      }
      break;

    case 'UpdateProject':
      if (workspace && projectTitle) {
        setBreadCrumb([
          {
            label: '',
            url: `/${workspace}`
          },
          {
            label: workspace,
            active: true
          },
          // {
          //     label: 'Project',
          //     url: `/${workspace}/project/list`,
          // },
          {
            label: projectTitle,
            active: true
          }
        ]);
      }
      break;

    case 'ProjectWiseActionPoints':
      if (workspace && projectTitle) {
        setBreadCrumb([
          {
            label: '',
            url: `/${workspace}`
          },
          {
            label: workspace,
            active: true
          },
          // {
          //     label: 'Project',
          //     url: `/${workspace}/project/list`,
          // },
          {
            label: projectTitle,
            active: true
          }
        ]);
      }
      break;

    case 'AllActionPoints':
      if (workspace) {
        setBreadCrumb([
          {
            label: '',
            url: `/${workspace}`
          },
          {
            label: workspace,
            active: true
          }
        ]);
      }
      break;

    case 'Trello':
      if (workspace && projectTitle) {
        setBreadCrumb([
          {
            label: '',
            url: `/${workspace}`
          },
          {
            label: workspace,
            active: true
          },
          {
            label: projectTitle,
            active: true
          }
        ]);
      }
      break;

      case 'TrelloView':
        if (workspace && projectTitle) {
          setBreadCrumb([
            {
              label: '',
              url: `/${workspace}`
            },
            {
              label: workspace,
              active: true
            },
            {
              label: projectTitle,
              active: true
            },
            {
              label: 'boards',
               url: `/${workspace}/project/${projectTitle}/boards`
            }
          ]);
        }
        break;
  }
};
