import { UilPen, UilTrash } from "@iconscout/react-unicons";
import calculateSerialNumber from "common/calculateSerialNumber";
import Constants from "common/Constants";
import Avatar from "components/base/Avatar";
import Button from "components/base/Button";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import Unicon from "components/base/Unicon";
import CustomPagination from "components/sp-common/CustomPagination";
import { useAuth } from "providers/AuthContext";
import { useMaster } from "providers/MasterContext";
import { useProjectAdminContext } from "providers/ProjectAdminProvider";
import { useProjectContext } from "providers/ProjectProvider";
import { Suspense, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Link, NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { PRESENT_USER_INDEX_VALUE_FIRST, PRESENT_USER_INDEX_VALUE_LAST, PRESENT_USER_PAGE_INDEX, PRESENT_USER_SUMMARY, PROJECT_PAST_USER_PERMISSION_DATA, PROJECT_PRESENT_USER_PERMISSION_DATA, TOGGLE_PROJECT_ADD_USER_MODAL, TOGGLE_USER_DELETE_MODAL, TOGGLE_USER_PERMISSION_MODAL, USER_PERMISSION_DATA } from "reducers/ProjectReducer";
import apiCall from "services/api";
import { handleCutomError } from "services/handleCutomError";
import { UserPermission } from "types/project";
import { geDesignationNameById } from "utils/getNames";
import AddExistingUserModal from "./modals/AddExistingUserModal";
import UpdateUserPermissionModal from "../UpdateUserPermissionModal";
import ConfirmationModal from "common/ConfirmModal";
import { useToast } from "providers/ToastProvider";

const ProjectPresentUser = () => {
    const { alias } = useParams();
    const { projectData, presentUserPermission, summaryPresentUser,
        indexValueFirstPresentUser, indexValueLastPresentUser, pageIndexPresentUser, updateUserPermission, toggleDeleteModal, projectDispatch } = useProjectContext();
    const { userTkn, workSpaceTkn, isUserAdmin, signOut } = useAuth();
    const { adminValues, setAdminValues } = useProjectAdminContext();
    const { designation } = useMaster();
    const { showSuccessToast, showErrorToast } = useToast()
    const navigation = useNavigate() as NavigateFunction;
    const tableRef = useRef<HTMLTableElement | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true)

                const [response2] = await Promise.all([

                    apiCall({
                        url: `admin/project/${alias}/permissions/list?expand=user&page=${pageIndexPresentUser}`,
                        method: 'GET',
                        headers: {
                            'x-access-token': userTkn,
                            'workspace': workSpaceTkn
                        },
                    }),
                ])

                if (response2.status === 200) {
                    let permissionData = response2?.data?.data?.permission
                    const presentUsers = permissionData.filter((user: UserPermission) => user.status === 1);
                    const pastUsers = permissionData.filter((user: UserPermission) => user.status !== 1);
                    const updatedPresentUsers = presentUsers?.map((users: any, index: any) => ({
                        ...users,
                        serialNumber: calculateSerialNumber(pageIndexPresentUser || 1, index, response2?.data?.data?.summary?.pageSize) // Assuming 10 items per page
                    }));

                    const updatedPastUsers = pastUsers?.map((users: any, index: any) => ({
                        ...users,
                        serialNumber: calculateSerialNumber(pageIndexPresentUser || 1, index, response2?.data?.data?.summary?.pageSize) // Assuming 10 items per page
                    }));


                    projectDispatch({ type: PROJECT_PRESENT_USER_PERMISSION_DATA, payload: updatedPresentUsers });
                    projectDispatch({ type: PROJECT_PAST_USER_PERMISSION_DATA, payload: updatedPastUsers });
                    const summary = response2?.data?.data?.summary;

                    projectDispatch({
                        type: PRESENT_USER_SUMMARY, payload: {
                            "total": summary.total,
                            "page": summary.page,
                            "pageSize": summary.pageSize,
                            "total_page": summary.total_page,
                        }
                    });

                } else {
                    console.error("error message:");
                }
            } catch (error: any) {
                if (error?.isAxiosError) {
                    // Access the error message
                    handleCutomError(error, signOut, navigation);
                } else {
                    // Handle other types of errors
                    console.error('An error occurred:', error);
                    showErrorToast('An error occurred:', error)
                }
            } finally {
                setLoading(false)
            }
        };
        fetchData();
    }, [userTkn, pageIndexPresentUser]);

    useEffect(() => {
        // Now 'tableRef.current' contains the reference to the table element
        const table = tableRef.current;

        if (table) {
            const firstRow = table?.rows[1];
            const lastRowIndex = table?.rows.length - 1;
            const lastRow = table?.rows[lastRowIndex];
            if (firstRow) {
                const firstCell = firstRow.cells[0];
                if (firstCell) {
                    const cellValue = firstCell.textContent || firstCell.innerText;
                    let value = Number(cellValue)
                    //   setValueFirst(value)
                    projectDispatch({ type: PRESENT_USER_INDEX_VALUE_FIRST, payload: value });

                }
            }

            if (lastRow) {
                const firstCell = lastRow.cells[0];
                if (firstCell) {
                    const cellValue = firstCell.textContent || firstCell.innerText;
                    let value = Number(cellValue)
                    projectDispatch({ type: PRESENT_USER_INDEX_VALUE_LAST, payload: value });
                    //   setValueLast(value)
                }
            }
        }
    }, [presentUserPermission]);

    const setPageIndex = (newPageIndex: number) => {
        projectDispatch({ type: PRESENT_USER_PAGE_INDEX, payload: newPageIndex });
    };

    const confirmDelete = async () => {
        // Call ChangeStatus function
        if (updateUserPermission) {
            await DeleteUser(updateUserPermission?.id, `${updateUserPermission?.user?.first_name}`);
            projectDispatch({ type: TOGGLE_USER_DELETE_MODAL, payload: false })
        }

    };

    const DeleteUser = async (id: number, name: string) => {
        try {
            setLoading(true);

            const response = await apiCall({
                url: `admin/project/${alias}/permissions/${id}/delete`,
                method: 'POST',
                headers: {
                    'x-access-token': userTkn,
                    'workspace': workSpaceTkn
                },
            });


            if (response?.status === 200) {
                // Ensure that newData is always a valid array
                fetchExistingUsers();

            } else {
                console.error("error message:");
            }
        } catch (error: any) {
            if (error?.isAxiosError) {
                // Access the error message
                handleCutomError(error, signOut, navigation);
            } else {
                // Handle other types of errors
                console.error('An error occurred:', error);
                // Display a generic error message to the user
                showErrorToast(error);
            }
        } finally {
            setLoading(false); // Set loading to false after the API call is completed
        }
    };

    const fetchExistingUsers = async () => {
        try {
            setLoading(true)

            const [response2] = await Promise.all([

                apiCall({
                    url: `admin/project/${alias}/permissions/list?expand=user&page=1`,
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                        'workspace': workSpaceTkn
                    },
                }),
            ])

            if (response2.status === 200) {
                let permissionData = response2?.data?.data?.permission
                const presentUsers = permissionData.filter((user: UserPermission) => user.status === 1);

                const updatedPresentUsers = presentUsers?.map((users: any, index: any) => ({
                    ...users,
                    serialNumber: calculateSerialNumber(1, index, response2?.data?.data?.summary?.pageSize) // Assuming 10 items per page
                }));

                projectDispatch({ type: PROJECT_PRESENT_USER_PERMISSION_DATA, payload: updatedPresentUsers });
                const summary = response2?.data?.data?.summary;

                projectDispatch({
                    type: PRESENT_USER_SUMMARY, payload: {
                        "total": summary.total,
                        "page": summary.page,
                        "pageSize": summary.pageSize,
                        "total_page": summary.total_page,
                    }
                });
                showSuccessToast("User Permission Deleted!");
            } else {
                console.error("error message:");
            }
        } catch (error: any) {
            if (error?.isAxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An error occurred:', error);
                showErrorToast(error)
            }
        } finally {
            setLoading(false)
        }
    };

    const setShowConfirmation = () => {
        projectDispatch({ type: TOGGLE_USER_DELETE_MODAL, payload: false })
    }

    return (
        <>
            <PhoenixDocCard className='pt-3 px-4 pb-4 mt-1'>
                <Row className="justify-content-end mb-3">
                    <Col className="d-flex gap-3 justify-content-end">
                        {isUserAdmin && Number(projectData?.status) !== 0 ? (
                            <Link
                                className="btn btn-outline-primary px-4"
                                to=""
                                onClick={() => {
                                    projectDispatch({
                                        type: TOGGLE_PROJECT_ADD_USER_MODAL,
                                        payload: true
                                    })
                                }}
                            >
                                {Constants.existing_user}
                            </Link>
                        ) : null}
                    </Col>

                </Row>
                <Table className="phoenix-table fs-9 project-table border rounded" size='sm' striped hover responsive ref={tableRef}>
                    <thead>
                        <tr id={'a1'} >
                            <th style={{ width: '2%', maxWidth: '20px', padding: "16px 0px" }}>#</th>
                            <th className="px-1" style={{ width: '25%', minWidth: '200px' }}>{Constants.name}</th>
                            <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.email}</th>
                            <th className="px-1" style={{ width: '20%', minWidth: '100px' }}>{Constants.designation}</th>
                            {adminValues.is_project_admin === 1 && Number(projectData?.status) !== 0 ? <th className="px-1" style={{ width: '15%', minWidth: '200px' }}>{Constants.action}</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {presentUserPermission && presentUserPermission?.map((item: UserPermission, index: number) => (
                            <tr key={index}>
                                <td>{item?.serialNumber}</td>
                                <td> <div className="d-flex align-items-center mb-2 order-2">
                                    <div className="avatar_width">
                                        <Avatar
                                            size="m"
                                            variant="name"
                                            className="me-1 "
                                        >
                                            {item?.user?.short_name?.slice(0, 2)}
                                        </Avatar>
                                    </div>
                                    <div className="fw-bold mb-0 text-truncate lh-1">
                                        <div className="d-flex gap-2 align-items-center">
                                            <span className="fw-semi-bold ms-1 fs-8 text-800">
                                                {`${item?.user?.first_name} ${item?.user?.last_name}`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                </td>
                                <td> {item?.user?.email}</td>
                                <td>
                                    {geDesignationNameById(Number(item?.user?.designation_id), designation)}
                                </td>
                                {adminValues.is_project_admin === 1 && Number(projectData?.status) !== 0 ?
                                    <td>
                                        <div className="d-flex gap-2  align-items-center">
                                            <Button
                                                variant="phoenix-primary"
                                                className="btn-icon rounded-1 bg-transparent"
                                                onClick={() => {
                                                    projectDispatch({ type: USER_PERMISSION_DATA, payload: item });
                                                    projectDispatch({ type: TOGGLE_USER_PERMISSION_MODAL, payload: true });
                                                }}
                                            >
                                                <Unicon icon={UilPen} size={18} />
                                            </Button>
                                            <Button
                                                variant="phoenix-primary"
                                                className="btn-icon rounded-1 bg-transparent"
                                                onClick={() => { projectDispatch({ type: USER_PERMISSION_DATA, payload: item }); projectDispatch({ type: TOGGLE_USER_DELETE_MODAL, payload: true }) }}
                                            >
                                                <Unicon icon={UilTrash} size={18} />
                                            </Button>

                                        </div>
                                    </td>
                                    : null
                                }
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {summaryPresentUser?.total !== 0 ? <Row className="align-items-center pt-3 row">
                    <Col className="d-flex fs-9">
                        <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900">
                            {indexValueFirstPresentUser} to {indexValueLastPresentUser}
                            <span className="text-600"> items of </span>
                            {summaryPresentUser?.total}
                        </p>
                    </Col>
                    <Col xs="auto">
                        <CustomPagination
                            pageIndex={pageIndexPresentUser}
                            totalPage={summaryPresentUser?.total_page}
                            activePage={Number(summaryPresentUser?.page)}
                            setPageIndex={setPageIndex}
                        />
                    </Col>
                </Row>
                    :
                    <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
                        <span className="text-600"> No record found !!</span>
                    </p>
                }
            </PhoenixDocCard>

            <Suspense fallback={<div>Loading...</div>}>
                <AddExistingUserModal />
                <UpdateUserPermissionModal />

                <ConfirmationModal
                    show={toggleDeleteModal}
                    onHide={setShowConfirmation}
                    onConfirm={confirmDelete}
                    text={`Are you sure you want to delete User, "${updateUserPermission?.user?.first_name} ${updateUserPermission?.user?.last_name}" ?`}
                />
            </Suspense>
        </>

    )
}

export default ProjectPresentUser;