import { AxiosError } from 'axios';
import { dateAndTime, dbDateWithTimeStamp, formatDueDate } from 'common/timeStampToDate';
import Button from 'components/base/Button';
import { KanbanBoardItem, KanbanBoardTask } from 'data/kanban';
import { useAuth } from 'providers/AuthContext';
import { useKanbanContext } from 'providers/KanbanProvider';
import { useToast } from 'providers/ToastProvider';
import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import apiCall from 'services/api';
import { handleCutomError } from 'services/handleCutomError';;
import Unicon from 'components/base/Unicon';
import { UilPen } from '@iconscout/react-unicons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CardDateTime } from '../KanbanTaskDetailsModal';
import DatePicker2 from 'components/base/DatePicker2';

interface EditableEndDateProps {
    task: KanbanBoardTask;
    list: KanbanBoardItem;
    setCardDateTime: React.Dispatch<React.SetStateAction<CardDateTime>>;
    setCardDetails: React.Dispatch<React.SetStateAction<KanbanBoardTask | undefined>>;
    cardDateTime: CardDateTime;
    onHistoryUpdate?: () => void;
}

export interface Loading {
    endDateUpdate: boolean
}


const EditableEndDate: React.FC<EditableEndDateProps> = ({ task, list, cardDateTime, setCardDateTime, setCardDetails, onHistoryUpdate }) => {
    const { alias, id } = useParams();
    const { boardLists, kanbanDispatch } = useKanbanContext();

    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const { showErrorToast, showSuccessToast } = useToast();
    const navigation = useNavigate();
    const [validated, setValidated] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [taskData, setTaskData] = useState<KanbanBoardTask | undefined>(undefined);
    const [loading, setLoading] = useState<Loading>({
        endDateUpdate: false
    });
    const [originalEndDateTime, setOriginalEndDateTime] = useState<string>('');
    const [error, setError] = useState({
        end_datetime: '',
    });

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {

        if (task) {
            setTaskData({
                ...task,
                id: task.id
            });
            setOriginalEndDateTime(task.end_datetime);
        }
    }, [task]);

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus(); // Set focus to input field
        }
    }, [isEditing]);

    const handleEndDateTimeClick = () => {
        setIsEditing(true); //on Click Enable editing mode
    };



    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }) => {
        const { name, value } = e.target;

        setTaskData((prevData) => {
            if (prevData) {
                return {
                    ...prevData,
                    [name]: name === "end_datetime" ? dbDateWithTimeStamp(value) : value,
                };
            }
            return prevData;
        });
    };
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!taskData?.end_datetime || taskData.end_datetime.trim() === '') {
            setError((prevError) => ({
                ...prevError,
                end_datetime: 'End Date is required',
            }));
            return;
        } else {
            setError((prevError) => ({
                ...prevError,
                end_datetime: '',
            }));
        }

        if (taskData?.end_datetime === originalEndDateTime) {
            setIsEditing(false);
            return; // No change in EndDateTime, don't submit
        }
        try {
            setLoading((prev) => ({
                ...prev,
                endDateUpdate: true
            }));
            const formData = new FormData();
            formData.append('end_datetime', taskData?.end_datetime);
            const response = await apiCall({
                url: `project/${alias}/boards/${id}/card/${taskData?.id}/update`,
                method: 'POST',
                data: formData,
                headers: {
                    'x-access-token': userTkn,
                    workspace: workSpaceTkn
                }
            });
            if (response.status === 200) {
                showSuccessToast('Card updated successfully!');
                const updatedItem = response?.data?.data?.boardListCard;
                const { formattedDate, formattedTime } = dateAndTime(updatedItem.end_datetime)
                setCardDateTime({
                    cardDate: formattedDate,
                    cardTime: formattedTime
                })
                setCardDetails(updatedItem);
                const updatedBoardLists = boardLists.map((item) => {
                    if (Number(item.id) === Number(list.id)) {
                        // Update the board list by replacing the card within boardListCards
                        return {
                            ...item,
                            boardListCards: item.boardListCards.map(card =>
                                // If the card matches, replace it, otherwise keep it as is
                                card.id === updatedItem.id ? updatedItem : card
                            )
                        };
                    }
                    return item;
                });
                setOriginalEndDateTime(updatedItem.end_datetime);
                kanbanDispatch({ type: 'SET_DATA', payload: updatedBoardLists });
                if (onHistoryUpdate) onHistoryUpdate();
            } else {
                throw new Error('Failed to update card');
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An unexpected error occurred:', error);
                showErrorToast('An unexpected error occurred');
            }
        } finally {
            setLoading((prev) => ({
                ...prev,
                endDateUpdate: false
            }));
            setIsEditing(false);
        }
    }
    const handleCancel = () => {
        // Reset the description to the original state and exit edit mode
        setTaskData((prevData) => {
            if (prevData) {
                return {
                    ...prevData,
                    end_datetime: originalEndDateTime

                };
            }
            return prevData;
        });
        setIsEditing(false); // Close the editor without submitting changes
    };

    return (
        <>

            {isEditing ? (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className='d-flex gap-1 bg-white p-3 mb-2 rounded-1'>
                        <div className='w-50'>
                            <Form.Group className=" text-start">
                                <DatePicker2
                                    // value={formData.due_date ? new Date(formData.due_date).toLocaleDateString('en-GB') : ''}
                                    value={taskData?.end_datetime ? formatDueDate(new Date(taskData?.end_datetime)) : ''}
                                    onChange={(date: Date[]) => {
                                        handleInputChange({
                                            target: {
                                                name: 'end_datetime',
                                                value: date[0].toString()
                                            }
                                        });
                                    }}

                                    options={{
                                        enableTime: true,
                                        dateFormat: "d/m/Y h:i K",
                                        minDate: new Date().setHours(0, 0, 0, 0)
                                    }}
                                    isError={error?.end_datetime}
                                    id="end_datetime"
                                    name="end_datetime"
                                    render={(_, ref) => {
                                        const isInvalid = !!error.end_datetime;
                                        return (
                                            <Form.Floating>
                                                <Form.Control
                                                    type="text"
                                                    ref={ref}
                                                    placeholder="d/m/Y  h:i"
                                                    readOnly
                                                    isInvalid={isInvalid}

                                                />
                                                <label htmlFor="startDate" className="ps-6">
                                                    {`Due Date *`}
                                                </label>
                                                <Form.Control.Feedback type="invalid">
                                                    {error?.end_datetime}
                                                </Form.Control.Feedback>
                                            </Form.Floating>
                                        );
                                    }}
                                />

                            </Form.Group>
                        </div>
                        <div className='d-flex gap-2 justify-content-center align-items-center mt-2 w-50'>
                            <Button
                                startIcon={<FontAwesomeIcon icon={faTimes} />}
                                onClick={handleCancel}
                                className="p-0"
                                disabled={loading?.endDateUpdate}
                            >
                                cancel
                            </Button>
                            <Button
                                variant="outline-primary"
                                className=""
                                type='submit'
                                disabled={loading?.endDateUpdate}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </Form>

            ) : (
                <div className="d-flex gap-2 w-100 "

                    style={{ cursor: 'pointer', height: "50px" }}

                >
                    <div className='d-flex gap-1'>
                        {cardDateTime?.cardTime && <div className="mb-0 fs-8 fw-semibold">
                            <FontAwesomeIcon
                                icon={faClock}
                                className="me-1"
                            />
                            {cardDateTime?.cardTime}
                        </div>
                        }
                        <div className="mb-0 fs-8 fw-semibold">{cardDateTime?.cardDate}</div>


                    </div>

                    <div >
                        <Button
                            variant=""
                            className="btn-icon"
                            onClick={handleEndDateTimeClick}
                        >
                            <Unicon icon={UilPen} size={16} />
                        </Button>
                    </div>
                </div>
            )}

        </>
    );
};

export default EditableEndDate;
