import { Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import boardIcon from 'assets/img/kanban/board.png';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faCircleXmark,
  faPlus,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { useKanbanContext } from 'providers/KanbanProvider';
import PhoenixFloatingLabel from 'components/base/PhoenixFloatingLabel';
import usePhoenixForm from 'hooks/usePhoenixForm';
import { FormEvent, useEffect, useState } from 'react';
import { ADD_NEW_LIST, TOGGLE_ADD_LIST_MODAL, UPDATE_BOARD_LIST_DETAILS } from 'reducers/KanbanReducer';
import { getRandomNumber } from 'helpers/utils';
import { useParams } from 'react-router-dom';
import { useToast } from 'providers/ToastProvider';
import { useAuth } from 'providers/AuthContext';
import apiCall from 'services/api';
import { ListFormData, listInitialData } from 'data/kanban';


const KanbanAddListModal: React.FC = ({

}) => {
  const { alias, id } = useParams();
  const { isEditModeList, listUpdateData, boardLists, openAddListModal, kanbanDispatch } = useKanbanContext();
  const { showErrorToast, showSuccessToast } = useToast()
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (isEditModeList === true) {
      setFormData({
        id: listUpdateData?.id,
        title: listUpdateData?.title,
        description: listUpdateData?.description
      })
    }
    if (isEditModeList === false) {
      setFormData({
        id: listUpdateData?.id,
        title: listUpdateData?.title,
        description: listUpdateData?.description
      })
    }

  }, [listUpdateData])


  const { formData, setFormData, onChange } =
    usePhoenixForm<ListFormData>(listInitialData);

  const handleClose = () => {
    kanbanDispatch({
      type: TOGGLE_ADD_LIST_MODAL,
      payload: false
    });
    setError("");
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formData.title.trim()) {
      setError("List name can't be empty.");
      return;
    }
    setError("");
    try {
      setLoading(true);
      const formDataSubmit = new FormData();
      formDataSubmit.append('title', formData.title);
      const apiUrl = isEditModeList
        ? `project/${alias}/boards/${id}/lists/${formData?.id}/update`
        : `project/${alias}/boards/${id}/lists/create`;
      const response = await apiCall({
        url: apiUrl,
        method: 'POST',
        data: formDataSubmit,
        headers: {
          'x-access-token': userTkn,
          workspace: workSpaceTkn
        }
      });

      if (response.status === 200) {
        if (isEditModeList === true) {
          const updateItem = response?.data?.data?.boardList;

          kanbanDispatch({
            type: UPDATE_BOARD_LIST_DETAILS,
            payload: {
              id: updateItem.id,
              title: updateItem.title
            }
          });
          kanbanDispatch({
            type: TOGGLE_ADD_LIST_MODAL,
            payload: false
          });
        } else {
          showSuccessToast('List added successfully!');
          const addedItem = response?.data?.data?.boardList;
          kanbanDispatch({
            type: 'ADD_NEW_LIST',
            payload: { list: addedItem, columnNo: boardLists.length + 1 }
          });

          kanbanDispatch({
            type: TOGGLE_ADD_LIST_MODAL,
            payload: false
          });
          setFormData(listInitialData);
        }

      } else {
        showErrorToast('Failed to add list');
      }
    } catch (error) {
      console.error('Error adding list:', error);
      showErrorToast('Failed to add list');
    } finally {
      setLoading(false);
    }
  };


  return (
    <Modal show={openAddListModal} centered onHide={handleClose} backdrop="static">
      <Modal.Header className="p-4 d-flex gap-2 border-0">
        <h3 className="mb-0 text-body-emphasis fw-semibold flex-1">
          {isEditModeList ? 'Update List' : 'Add New List'}
        </h3>
        <Button className="p-0 ms-auto" onClick={handleClose}>
          <FontAwesomeIcon icon={faXmark} className="fs-7" />
        </Button>
      </Modal.Header>
      <Modal.Body className="p-4 pt-0">
        <form onSubmit={handleSubmit}>
          <Row className="g-3 mb-4">
            <Col xs={12}>
              <PhoenixFloatingLabel
                label="List Name"
                className="flex-1"
              >
                <Form.Control
                  type="text"
                  placeholder="List Name"
                  name="title"
                  value={formData.title}
                  onChange={onChange}
                  isInvalid={!!error}
                />
                <Form.Control.Feedback type="invalid">
                  {error}
                </Form.Control.Feedback>
              </PhoenixFloatingLabel>
            </Col>
          </Row>
          <div className="d-flex gap-3 flex-between-center">
            <Button
              variant="outline-primary"
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              type="submit"
              disabled={loading}
            >
            {isEditModeList ?  "Update List" : "Add List"}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default KanbanAddListModal;
