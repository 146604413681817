import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Icon,
  UilCube,
  UilDocumentLayoutRight,
  UilHome,
  UilAbacus,
  UilClipboardAlt,
  UilInvoice
} from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  path: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
  noDropDown?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'dashboard',
    path: '/',
    active: true,
    icon: UilHome,
    labelDisabled: true,
    megaMenu: false,
    noDropDown: true,
    pages: []
  },
  {
    label: 'project',
    horizontalNavLabel: 'project',
    path: '/project/list',
    active: true,
    noDropDown: true,
    icon: UilCube,
    labelDisabled: true,
    pages: [
      // {
      //   name: 'home',
      //   icon: 'pie-chart',
      //   active: true,
      //   hasNew: true,
      //   flat: true,
      //   pages: [
      //     {
      //       name: 'Project List',
      //       path: '/',
      //       pathName: 'default-dashboard',
      //       topNavIcon: 'list',
      //       active: true
      //     },
      //     {
      //       name: 'create-project',
      //       path: '/dashboard/create-project',
      //       pathName: 'create-project-dashbaord',
      //       topNavIcon: 'clipboard',
      //       active: true
      //     }
      //   ]
      // },
    ]
  },
  {
    label: 'metting',
    horizontalNavLabel: 'metting',
    path: '#',
    active: false,
    icon: UilAbacus,
    labelDisabled: true,
    noDropDown: true,
    pages: []
  },
  {
    label: 'note',
    horizontalNavLabel: 'note',
    path: '#',
    active: false,
    icon: UilClipboardAlt,
    labelDisabled: true,
    pages: [],
    megaMenu: false,
    noDropDown: true
  },
  {
    label: 'action',
    horizontalNavLabel: 'action',
    path: '/all-action-point/list',
    active: true,
    icon: UilInvoice,
    labelDisabled: true,
    pages: [],
    noDropDown: true
  },
  {
    label: 'Admin',
    horizontalNavLabel: 'Admin',
    path: '/admin/home',
    active: true,
    noDropDown: true,
    icon: UilDocumentLayoutRight,
    labelDisabled: true,
    pages: [
      // {
      //   name: 'Entity',
      //   path: '/admin/entity/list',
      //   pathName: 'admin-client-lsit',

      //   // topNavIcon: 'shopping-cart',
      //   active: true
      // },
      {
        name: 'Users',
        path: '/admin/users/list',
        pathName: 'admin-users-lsit',
        // topNavIcon: 'shopping-cart',
        active: true
      },
      {
        name: 'Projects',
        path: '/admin/project/list',
        pathName: 'admin-projects-lsit',
        // topNavIcon: 'shopping-cart',
        active: true
      },
      // {
      //   name: 'Meta',
      //   path: '/admin/meta/list',
      //   pathName: 'admin-meta-lsit',
      //   // topNavIcon: 'shopping-cart',
      //   active: true
      // },
      {
        name: 'Master',
        active: true,
        pages: [
          {
            name: 'Project Category',
            path: '/admin/master/project-category/list',
            pathName: 'admin-project-category',
            active: true
          },
          {
            name: 'Designation',
            path: '/admin/masters/designation/list',
            pathName: 'admin-designation',
            active: true
          },
          {
            name: 'Meeting Location Category',
            path: '/admin/masters/meetinglocationcategory/list',
            pathName: 'admin-meeting-location-category',
            active: true
          },
          {
            name: 'Meeting Location',
            path: '/admin/masters/meetinglocation/list',
            pathName: 'admin-meeting-location',
            active: true
          },
          {
            name: 'Meeting Category',
            path: '/admin/masters/meetingcategory/list',
            pathName: 'admin-meeting-category',
            active: true
          },
          {
            name: 'Phases',
            path: '/admin/masters/phases/list',
            pathName: 'admin-phases',
            active: true
          },
          {
            name: 'Priority',
            path: '/admin/masters/priority/list',
            pathName: 'admin-priority',
            active: true
          }
        ]
      }
    ]
  }
];
