// import {
//   faCalendarXmark,
//   faComment,
//   faListCheck
// } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Avatar from 'components/base/Avatar';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UilEditAlt } from '@iconscout/react-unicons';
import Avatar from 'components/base/Avatar';
import { KanbanBoard } from 'data/kanban';
import { useKanbanContext } from 'providers/KanbanProvider';
import { Button, Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { BOARD_MODAL_TYPE, BOARD_UPDATE_DATA, TOGGLE_ADD_BOARD_MODAL } from 'reducers/KanbanReducer';
import board1 from 'assets/img/kanban/Image1.jpg';
import board2 from 'assets/img/kanban/Image2.jpg';
import board3 from 'assets/img/kanban/Image3.jpg';
import board4 from 'assets/img/kanban/Image4.jpg';
import board5 from 'assets/img/kanban/Image5.jpg';
import board6 from 'assets/img/kanban/Image6.jpg';
import board7 from 'assets/img/kanban/Image7.jpg';
import board8 from 'assets/img/kanban/Image8.jpg';
import board9 from 'assets/img/kanban/Image9.jpg';
import board10 from 'assets/img/kanban/Image10.jpg';
import board11 from 'assets/img/kanban/Image11.jpg';
import board12 from 'assets/img/kanban/Image12.jpg';
import board13 from 'assets/img/kanban/Image13.jpg';
import board14 from 'assets/img/kanban/Image14.jpg';
import ToggleViewAvtar from 'components/common/ToggleViewAvtar';


const KanbanBoardCard = ({ board }: { board: KanbanBoard }) => {
  const { alias, workspace } = useParams();
  const { kanbanDispatch } = useKanbanContext();

  const getRandomBoardImage = () => {
    const images = [board1, board2, board3, board4, board5, board6, board7, board8, board9, board10, board11, board12, board13, board14];
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };

  const truncateText = (text: string, maxWords: number, maxChars: number) => {
    if (!text) return '';
    
    const words = text.split(' ');
    
    if (text.length > maxChars) {
      return text.slice(0, maxChars) + '...';
    } else if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
    
    return text;
  };
  return (
    <Card className="overflow-hidden bg-transparent h-100 position-relative">

      <div className="position-relative">
        {board?.boardbanner ? (
          <>
            <img
              src={board?.boardbanner}
              alt=""
              height={128}
              className="w-100 fit-cover"
            />
          </>
        ) : (
          <>
            <img
              src={getRandomBoardImage()}
              alt=""
              height={128}
              className="w-100 fit-cover"
            />
          </>

        )}

      </div>
      <Card.Body className="d-flex flex-column rounded-bottom-lg justify-content-between p-3">
        <div>
          <h3 className="text-body">{board.title}</h3>
          <p className="text-body-tertiary mb-1">{truncateText(board.description, 20, 150)}</p>
        </div>
        <div className='d-flex justify-content-between'>
          {board.users && board.users.length > 0 && (
            <Avatar.Group
              total={board.users.length}
              size="m"
              className="bottom-0  ms-1"
            >
              {board.users?.slice(0, 4).map(user => (
                <ToggleViewAvtar
                  tooltip={`${user?.first_name} ${user?.last_name}`}
                  key={user?.id}
                >
                  <Avatar size="m" variant="name" key={user?.id}>{user?.short_name}</Avatar>
                </ToggleViewAvtar>
              ))}
            </Avatar.Group>
          )
          }
          <Button
            className="btn-icon  rounded-1  p-0 ms-auto  z-3 me-2"
            variant="phoenix-primary"
            onClick={() => {
              kanbanDispatch({
                type: TOGGLE_ADD_BOARD_MODAL,
                payload: true
              });
              kanbanDispatch({
                type: BOARD_MODAL_TYPE,
                payload: true
              });
              kanbanDispatch({
                type: BOARD_UPDATE_DATA,
                payload: {
                  id: board?.id,
                  title: board?.title,
                  description: board?.description,
                  banner: board?.boardbanner || ''
                }
              });
            }}
          >
            <FontAwesomeIcon icon={faPencil} className="fs-8" />
          </Button>
        </div>
      </Card.Body>
      <Link
        to={`/${workspace}/project/${alias}/board/${board?.id}`}
        className="stretched-link"
      />
    </Card>
  );
};

export default KanbanBoardCard;
