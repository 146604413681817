import calculateSerialNumber from "common/calculateSerialNumber"
import regexPatterns from "common/regexPatterns"
import Button from "components/base/Button"
import PhoenixDocCard from "components/base/PhoenixDocCard"
import { KanbanBoardMembers } from "data/kanban"
import { useAuth } from "providers/AuthContext"
import { useProjectContext } from "providers/ProjectProvider"
import { useToast } from "providers/ToastProvider"
import { useEffect, useState } from "react"
import { } from "react-bootstrap"
import { Col, Row, Modal, Form, FloatingLabel } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { PRESENT_USER_SUMMARY, PROJECT_PAST_USER_PERMISSION_DATA, PROJECT_PRESENT_USER_PERMISSION_DATA, TOGGLE_PROJECT_ADD_USER_MODAL } from "reducers/ProjectReducer"
import apiCall from "services/api"
import { handleCutomError } from "services/handleCutomError"
import { UserPermission } from "types/project"


const AddExistingUserForm = () => {
    const { alias } = useParams();
    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const { showErrorToast, showSuccessToast } = useToast();
    const { projectAllUsers, projectDispatch } = useProjectContext();
    const [validated, setValidated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const navigation = useNavigate();
    const [users, setUsers] = useState<KanbanBoardMembers[]>([]);
    const [formData, setFormData] = useState({
        user_id: users.length > 0 ? users[0].id : "",
        is_project_admin: false,
        is_story_admin: false,
        user_password: false,
        is_meeting_admin: false,
        is_diary_admin: false,
        role_in_project: "",
        alias: "",
    });

    //for existing user errors
    const [errorExisting, setErrorExisting] = useState({
        user_id: "",
        is_project_admin: "",
        is_story_admin: "",
        user_password: "",
        is_meeting_admin: "",
        role_in_project: "",
        is_diary_admin: "",
        alias: "",
    });

    //for existing user
    useEffect(() => {
        loadUsers();
    }, [, alias]);

    //for existing user
    const loadUsers = async () => {
        try {

            const response = await apiCall({
                // url: `project/${projAlias}/users`,
                url: `admin/user/list?pagination=0&status=${10}`,   //using this admin api, because if loggedin user is admin
                method: 'GET',
                headers: {
                    'x-access-token': userTkn,
                    'workspace': workSpaceTkn
                },
            });

            let userData = response?.data?.users?.data;
            let remainingUsers = userData.filter((user: KanbanBoardMembers) => !projectAllUsers.includes(user.id))
            setUsers(remainingUsers);

        } catch (error: any) {
            if (error?.isAxiosError) {
                // Access the error message
                showErrorToast(error)
                handleCutomError(error, signOut, navigation);
            } else {
                // Handle other types of errors
                console.error('An error occurred:', error);
                showErrorToast(error)
            }
        } finally {
            setLoading(false);
        }
    };

    //for existing user
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        let errorMessage = "";

        if (name === "user_id") {
            if (!value.trim()) {
                errorMessage = "User is required.";
            }
        }

        if (name === "role_in_project") {
            if (!value.trim()) {
                errorMessage = "Role is required.";
            } if (!regexPatterns.titleRegex14.test(value)) {
                errorMessage = "Role should be 3 to 14 alphabetic characters.";
            }
        }
        setFormData({
            ...formData,
            [name]: value,
        });

        setErrorExisting({
            ...errorExisting,
            [name]: errorMessage,
        });
    };

    const handleSubmitExisting = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // Prevent default form submission behavior
        e.stopPropagation();

        // Add the following line to set the form validation state
        setValidated(true);

        if (           // Check if there are any errors before making the API call
            errorExisting.user_id ||
            errorExisting.role_in_project

        ) {
            setLoading(false);
            return;
        }
        if (!formData.role_in_project.trim() || !formData.user_id) {
            setErrorExisting({
                ...errorExisting,
                role_in_project: !formData.role_in_project.trim() ? "Role is required." : "",
                user_id: !formData.user_id ? "User is required." : "",

            });
            setLoading(false);
            return;
        }

        // Perform any necessary logic or API calls here
        const formDataSubmit = new FormData();
        formDataSubmit.append("user_id", String(formData?.user_id));
        formDataSubmit.append("is_project_admin", String(formData.is_project_admin ? 1 : 0));
        formDataSubmit.append("is_story_admin", String(formData.is_story_admin ? 1 : 0));
        formDataSubmit.append("is_meeting_admin", String(formData.is_meeting_admin ? 1 : 0));
        formDataSubmit.append("is_diary_admin", String(formData.is_diary_admin ? 1 : 0));
        formDataSubmit.append("role_in_project", String(formData.role_in_project));
        formDataSubmit.append("user_designation", "1");

        try {
            const response = await apiCall({
                url: `admin/project/${alias}/permissions-create`,
                method: 'POST',
                data: formDataSubmit,
                headers: {
                    'x-access-token': userTkn,
                    'workspace': workSpaceTkn
                },
            });
            if (response.status === 200) {
                if (response?.data?.errors) {
                    const errors = response?.data.errors;
                    // Dynamically set errors based on the response 
                    Object.keys(errors).forEach((key) => {
                        const errorMessages = errors[key];
                        const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                        // Set the error state for the corresponding field
                        setErrorExisting((prevData) => ({
                            ...prevData,
                            [key]: firstErrorMessage,
                        }));
                    });
                } else {
                    fetchExistingUsers();
                }
            } else {
                console.error("error message:");
            }
        } catch (error: any) {
            if (error?.isAxiosError) {
                // Access the error message
                handleCutomError(error, signOut, navigation);
            } else {
                // Handle other types of errors
                console.error('An error occurred:', error);
                // Display a generic error message to the user
                // toast.error('An unexpected error occurred', {
                //   position: "top-center"
                // });
            }
        } finally {
            setLoading(false); // Set loading to false after the API call is completed 
        }
    };

    const fetchExistingUsers = async () => {
        try {
            setLoading(true)

            const [response2] = await Promise.all([

                apiCall({
                    url: `admin/project/${alias}/permissions/list?expand=user&page=1`,
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                        'workspace': workSpaceTkn
                    },
                }),
            ])

            if (response2.status === 200) {
                let permissionData = response2?.data?.data?.permission
                const presentUsers = permissionData.filter((user: UserPermission) => user.status === 1);
                const pastUsers = permissionData.filter((user: UserPermission) => user.status !== 1);
                const updatedPresentUsers = presentUsers?.map((users: any, index: any) => ({
                    ...users,
                    serialNumber: calculateSerialNumber(1, index, response2?.data?.data?.summary?.pageSize) // Assuming 10 items per page
                }));

                const updatedPastUsers = pastUsers?.map((users: any, index: any) => ({
                    ...users,
                    serialNumber: calculateSerialNumber(1, index, response2?.data?.data?.summary?.pageSize) // Assuming 10 items per page
                }));


                projectDispatch({ type: PROJECT_PRESENT_USER_PERMISSION_DATA, payload: updatedPresentUsers });
                projectDispatch({ type: PROJECT_PAST_USER_PERMISSION_DATA, payload: updatedPastUsers });
                const summary = response2?.data?.data?.summary;

                projectDispatch({
                    type: PRESENT_USER_SUMMARY, payload: {
                        "total": summary.total,
                        "page": summary.page,
                        "pageSize": summary.pageSize,
                        "total_page": summary.total_page,
                    }
                });
                showSuccessToast("User Added sucessfully!");
                projectDispatch({
                    type: TOGGLE_PROJECT_ADD_USER_MODAL,
                    payload: false
                });
            } else {
                console.error("error message:");
            }
        } catch (error: any) {
            if (error?.isAxiosError) {
                handleCutomError(error, signOut, navigation);
            } else {
                console.error('An error occurred:', error);
                showErrorToast(error)
            }
        } finally {
            setLoading(false)
        }
    };


    return (
        <PhoenixDocCard className='pt-3  pb-4 mt-1 border_design'>

            <Form noValidate validated={validated} onSubmit={handleSubmitExisting}>
                <Modal.Body className="pt-4 pb-2 ">
                    <Row className="mb-3 d-flex gap-1">
                        <Col>
                            <Form.Group className=" text-start">
                                <FloatingLabel
                                    controlId="floatingSelectTask"
                                    label="User *"
                                >
                                    <Form.Select required value={formData.user_id} name='user_id'
                                        onChange={handleInputChange}
                                        isInvalid={!!errorExisting.user_id}
                                    >
                                        <option value={""}>Select</option>
                                        {users && users?.map((user, index: number) => {
                                            return (
                                                <option value={user.id} key={user.id}>{`${user.first_name} ${user.last_name}`}</option>
                                            )
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errorExisting?.user_id ? errorExisting?.user_id : ""}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3 d-flex gap-1">
                        <Col>
                            <FloatingLabel
                                controlId="floatingInputGrid"
                                label='ROLL WITH IN PROJECT *'
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Role"
                                    name="role_in_project"
                                    value={formData?.role_in_project}
                                    onChange={handleInputChange}
                                    isInvalid={!!errorExisting?.role_in_project}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errorExisting?.role_in_project}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-between flex-wrap">
                        <Form.Check type="checkbox" className="mb-0 ">
                            <Form.Check.Input
                                type="checkbox"
                                name="is_project_admin"
                                id="is_project_admin"
                                value={formData.is_project_admin.toString()}
                                onChange={(e) => {
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        is_project_admin: e.target.checked
                                    }))
                                }}
                            />
                            <Form.Check.Label htmlFor="projectadmin" className="mb-0">
                                Project Admin
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="checkbox" className="mb-0 ">
                            <Form.Check.Input
                                type="checkbox"
                                name="is_story_admin"
                                id="is_story_admin"
                                value={formData.is_story_admin.toString()}
                                onChange={(e) => {
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        is_story_admin: e.target.checked
                                    }))
                                }}
                            />
                            <Form.Check.Label htmlFor="storyadmin" className="mb-0">
                                Story Admin
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="checkbox" className="mb-0 ">
                            <Form.Check.Input
                                type="checkbox"
                                name="is_meeting_admin"
                                id="is_meeting_admin"
                                value={formData.is_meeting_admin.toString()}
                                onChange={(e) => {
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        is_meeting_admin: e.target.checked
                                    }))
                                }}
                            />
                            <Form.Check.Label htmlFor="meetingadmin" className="mb-0">
                                Meeting Admin
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="checkbox" className="mb-0">
                            <Form.Check.Input
                                type="checkbox"
                                name="is_diary_admin"
                                id="is_diary_admin"
                                value={formData.is_diary_admin.toString()}
                                onChange={(e) => {
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        is_diary_admin: e.target.checked
                                    }))
                                }}
                            />
                            <Form.Check.Label htmlFor="diaryadmin" className="mb-0">
                                Diary Admin
                            </Form.Check.Label>
                        </Form.Check>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center align-items-center px-4 pb-4 border-0 pt-3">
                    <Button
                        variant="outline-primary"
                        size="sm"
                        className="px-7 fs-9  my-0 btn"
                        type="submit"
                        disabled={loading}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>

        </PhoenixDocCard>
    )
}

export default AddExistingUserForm;