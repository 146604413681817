import { Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import boardIcon from 'assets/img/kanban/board.png';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faCircleXmark,
  faPlus,
  faWrench,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { useKanbanContext } from 'providers/KanbanProvider';
import PhoenixFloatingLabel from 'components/base/PhoenixFloatingLabel';
import usePhoenixForm from 'hooks/usePhoenixForm';
import { FormEvent, useEffect, useState } from 'react';
import { TOGGLE_ADD_BOARD_MODAL, UPDATE_BOARD_DETAILS } from 'reducers/KanbanReducer';
import { useAuth } from 'providers/AuthContext';
import apiCall from 'services/api';
import { handleCutomError } from 'services/handleCutomError';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { useToast } from 'providers/ToastProvider';
import ReactSelect from 'components/base/ReactSelect';
import { BoardFormData, boardInitialData, KanbanBoard } from 'data/kanban';
import Dropzone from 'components/base/Dropzone';
import { title } from 'process';


const KanbanAddBoardModal: React.FC = () => {
  const { alias, workspace } = useParams();
  const { showErrorToast, showSuccessToast } = useToast()
  const { openAddBoardModal, isEditMode, doardUpdateData, yourBoardData, kanbanDispatch } = useKanbanContext();
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const navigation = useNavigate() as NavigateFunction;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    title: '',
    description: '',
    banner: ''
  });
  const { formData, setFormData, onChange } =
  usePhoenixForm<BoardFormData>(boardInitialData);

  useEffect(() => {
    if (isEditMode === true) {
      setFormData({
        id: doardUpdateData?.id,
        title: doardUpdateData?.title,
        description: doardUpdateData?.description,
        banner: doardUpdateData?.banner
      })
    }
    if (isEditMode === false) {
      setFormData({
        id: doardUpdateData?.id,
        title: doardUpdateData?.title,
        description: doardUpdateData?.description,
        banner: doardUpdateData?.banner
      })
    }

  }, [doardUpdateData])



  const handleClose = () => {
    kanbanDispatch({
      type: TOGGLE_ADD_BOARD_MODAL,
      payload: false
    });
    setError({
      title: '',
      description: '',
      banner: ''
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formData.title.trim()) {
      setError((prev) => ({
        ...prev,
        title: "Board name can't be empty."
      }));
      return;
    }
    setError({
      title: '',
      description: '',
      banner: ''
    });
    try {
      setLoading(true);
      const formDataSubmit = new FormData();
      formDataSubmit.append('title', formData?.title);
      formDataSubmit.append('description', formData?.description);
      if (formData.banner instanceof File) {
        formDataSubmit.append('banner', formData.banner);
      }
      const apiUrl = isEditMode
        ? `project/${alias}/boards/${formData?.id}/update`
        : `project/${alias}/boards/create`;
      const response = await apiCall({
        url: apiUrl,
        method: 'POST',
        data: formDataSubmit,
        headers: {
          'x-access-token': userTkn,
          workspace: workSpaceTkn
        }
      });

      if (response.status === 200) {
        if (response?.data?.errors) {
          const errors = response?.data.errors;
          // Dynamically set errors based on the response
          Object.keys(errors).forEach((key) => {
            const errorMessages = errors[key];
            const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
            // Set the error state for the corresponding field
            setError((prevData) => ({
              ...prevData,
              [key]: firstErrorMessage,
            }));
          });
        } else {
          if (isEditMode === true) {
            const boardData = response?.data?.data?.board;
            showSuccessToast('Board added successfully!');
            const updatedBoardsData = yourBoardData.map((item) => {
              if (Number(item.id) === Number(boardData.id)) {
                return {
                  ...item, // Spread the existing board properties
                  ...boardData, // Overwrite with the updated board data
                };
              }
              return item; // Keep other boards unchanged
            });
            kanbanDispatch({ type: 'YOUR_BOARD_DATA_LIST', payload: updatedBoardsData });
            kanbanDispatch({
              type: TOGGLE_ADD_BOARD_MODAL,
              payload: false
            });
            kanbanDispatch({
              type: UPDATE_BOARD_DETAILS,
              payload: {
                id: boardData.id,
                title: boardData.title,
                description: boardData.description
              }
            });


          } else {
            const boardData = response?.data?.data?.board;
            const boardListData = boardData?.boardLists;
            const boardMembersData = boardData?.users;
            // setYourBoards(prevBoards => [...prevBoards, newItem]); 
            showSuccessToast('Board added successfully!');
            kanbanDispatch({
              type: TOGGLE_ADD_BOARD_MODAL,
              payload: false
            });
            kanbanDispatch({ type: 'SET_DATA', payload: boardListData });
            kanbanDispatch({ type: 'UPDATE_BOARD_USERS', payload: boardMembersData });
            kanbanDispatch({ type: 'BOARD_DATA', payload: boardData });
            navigation(`/${workspace}/project/${alias}/board/${boardData?.id}`);
          }
        }


      } else {
        showErrorToast('Failed to add Board');
      }
    } catch (error) {
      console.error('Error adding Board:', error);
      showErrorToast('Failed to add Board');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={openAddBoardModal} centered onHide={handleClose} backdrop="static">
      <Modal.Header className="p-4 d-flex gap-2 border-0">
        {/* <img src={boardIcon} height={24} width={18} /> */}
        <h3 className="mb-0 text-body-emphasis fw-semibold flex-1">
          {isEditMode ? 'Update Board' : 'Add New Board'}
        </h3>
        <Button className="p-0 ms-auto" onClick={handleClose}>
          <FontAwesomeIcon icon={faXmark} className="fs-7" />
        </Button>
      </Modal.Header>
      <Modal.Body className="p-4 pt-0">
        <form onSubmit={handleSubmit}>
          <Row className="g-3 mb-4">
            <Col xs={12}>
              <PhoenixFloatingLabel
                label="Board Name"
                className="flex-1"
              >
                <Form.Control
                  type="text"
                  placeholder="Board Name"
                  name="title"
                  value={formData.title}
                  onChange={onChange}
                  isInvalid={!!error?.title}
                />
                <Form.Control.Feedback type="invalid">
                  {error?.title}
                </Form.Control.Feedback>
              </PhoenixFloatingLabel>
            </Col>
            <Col xs={12}>
              <Form.Control
                as="textarea" // Use textarea for description
                rows={5}
                placeholder="Board Description"
                name="description"
                value={formData.description}
                onChange={onChange}
                disabled={loading}
                isInvalid={!!error?.description}
              />
                <Form.Control.Feedback type="invalid">
                  {error?.description}
                </Form.Control.Feedback>
            </Col>
            <Col xs={12}>
              <Dropzone
                multiple={false}
                accept={{ 'image/jpeg': [], 'image/png': [], 'image/jpg': [] }}
                onDrop={(acceptedFiles: File[]) => {
                  if (acceptedFiles.length > 0) {
                    const file = acceptedFiles[0];
                    if (
                      file.type === 'image/jpeg' ||
                      file.type === 'image/png' ||
                      file.type === 'image/jpg'
                    ) {
                      setFormData((prev) => ({
                        ...prev,
                        banner: file, // Update the `banner` in the formData
                      }));
                      setError((prev) => ({
                        ...prev,
                        banner: '', // Update the `banner` in the formData
                      }));
                    } else {
                      showErrorToast('Only JPEG, JPG, and PNG files are allowed.');
                    }
                  }
                }}
              />
              <div className='text-danger fs-9'>{error?.banner}</div>

            </Col>
          </Row>
          <div className="d-flex gap-3 flex-center">
            <Button
              variant="outline-primary"
              startIcon={isEditMode  ? <></> : <FontAwesomeIcon icon={faPlus} />}
              type="submit"
              disabled={loading}
            >
              {isEditMode ? 'Save' : 'Save'}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default KanbanAddBoardModal;
