import { ColorPaletteData, CreateNewLabel, selectColor } from "data/kanban";
import { useKanbanContext } from "providers/KanbanProvider";
import { useState } from "react";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";

const ColorPalette = () => {
    const [hoveredId, setHoveredId] = useState<number | null>(null);
    const { createNewLabelFormData, kanbanDispatch } = useKanbanContext();

    const handleChange = (item: ColorPaletteData) => {
        const newLabel: CreateNewLabel = {
            id: createNewLabelFormData?.id,
            colorPalette: item,
            title: createNewLabelFormData?.title || "",
        };
        kanbanDispatch({ type: 'CREATE_NEW_LABEL', payload: newLabel });
    }
    return (
        <div className='tags-option-container'>
            {selectColor?.map((item: ColorPaletteData, index: number) => (
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="overlay-trigger-example">
                            {item?.name}
                        </Tooltip>
                    }
                    container={document.body} 
                >
                    <div
                        key={index}
                        className='color-palete'
                        style={{
                            backgroundColor: hoveredId === item.id ? item.codeHoverd : item.code,
                            borderWidth: "2px",
                            borderStyle: 'solid',
                            borderColor: createNewLabelFormData?.colorPalette?.id === item?.id ? "#0c66e4" : "#ffffff"
                        }}
                        onMouseEnter={() => setHoveredId(item.id)}
                        onMouseLeave={() => setHoveredId(null)}
                        onClick={() => { handleChange(item) }}
                    >
                        {hoveredId === item.id && (
                            <div className='tooltip'>
                                {item.name}
                            </div>
                        )}
                    </div>
                </OverlayTrigger>
            ))}
        </div>
    )
}

export default ColorPalette;