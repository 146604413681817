import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { initialSummary, KanbanBoard, SummaryProps } from 'data/kanban';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import KanbanBoardSection from 'components/modules/kanban/KanbanBoardSection';
import { useAuth } from 'providers/AuthContext';
import apiCall from 'services/api';
import { handleCutomError } from 'services/handleCutomError';
import { toast } from 'react-toastify';
import { BOARD_MODAL_TYPE, BOARD_UPDATE_DATA, TOGGLE_ADD_BOARD_MODAL } from 'reducers/KanbanReducer';
import KanbanProvider, { useKanbanContext } from 'providers/KanbanProvider';
import KanbanAddBoardModal from 'components/modals/KanbanAddBoardModal';
import { AxiosError } from 'axios';
import PageBreadcrumb, { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import { getPageBreadCrumbs } from 'services/getPageBreadCrumbs';
import CustomPagination from 'components/sp-common/CustomPagination';



const Boards = () => {
  const { setContentClass } = useMainLayoutContext();

  useEffect(() => {
    setContentClass('kanban-content');

    return () => {
      setContentClass('');
    };
  }, []);

  return (
    <KanbanProvider>
      <BoardsContent />
    </KanbanProvider>
  );
};

const BoardsContent = () => {
  const { yourBoardData, kanbanDispatch } = useKanbanContext();
  const initialBreadcrumb: PageBreadcrumbItem[] = [];
  const [breadCrumb, setBreadCrumb] =
    useState<PageBreadcrumbItem[]>(initialBreadcrumb);
  const [pageName, setPageName] = useState('Trello');
  const { alias, workspace } = useParams();
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const navigation = useNavigate() as NavigateFunction; // Explicitly define the type
  const [, setLoading] = useState<boolean>(false);
  // const [yourBoards, setYourBoards] = useState<KanbanBoard[]>([]);
  const [summary, setSummary] = useState<SummaryProps>(initialSummary);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [indexValueFirst, setValueFirst] = useState<number | null>(1);
  const [indexValueLast, setValueLast] = useState<number | null>(1);

  useEffect(() => {
    const projectTitle = alias
    getPageBreadCrumbs({ pageName, workspace, setBreadCrumb, projectTitle });
  }, [workspace, pageName, setBreadCrumb]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true
        const response = await apiCall({
          url: `project/${alias}/boards?page=${pageIndex}`,  // updated
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            workspace: workSpaceTkn
          }
        });

        if (response?.status === 200) {
          // Calculate serial numbers for the projects on the current page
          const boardData = response?.data?.data?.boards;
          kanbanDispatch({ type: 'YOUR_BOARD_DATA_LIST', payload: boardData });
          // setYourBoards(boardData);
          const summary = response?.data?.data?.summary;
          setSummary({
            total: summary.total,
            page: summary.page,
            pageSize: summary.pageSize,
            total_page: summary.total_page,
          });
          setLoading(false);
        } else {
          console.error('error messag');
        }
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          handleCutomError(error, signOut, navigation);
        } else {
          console.error('An unexpected error occurred:', error);
          toast.error('An unexpected error occurred');
        }
      } finally {
        setLoading(false); // Set loading to false after the API call is completed
      }
    };
    fetchData();
  }, [pageIndex]);



  return (
    <div className="mb-9">
      <div className="border-bottom border-translucent px-4 px-lg-6 pb-5">
        <Row>
          <Col xs="auto">
            <PageBreadcrumb items={breadCrumb} navigation={navigation} />
          </Col>
        </Row>
        <Row className="justify-content-between gy-4">
          <Col xs="auto">
            <h2>
              Boards{' '}
              <span className="text-body-tertiary fw-semibold">
                ({yourBoardData?.length})
              </span>
            </h2>
          </Col>
          <Col xs="auto" className="d-flex flex-wrap gap-2">
            <Button
              variant="outline-primary"
              className="px-3"
              // as={Link}
              // to="/apps/kanban/create-board"
              onClick={() => {
                kanbanDispatch({
                  type: TOGGLE_ADD_BOARD_MODAL,
                  payload: true
                });
                kanbanDispatch({
                  type: BOARD_MODAL_TYPE,
                  payload: false
                });
                kanbanDispatch({
                  type: BOARD_UPDATE_DATA,
                  payload: {
                    id: 0,
                    title: '',
                    description: '',
                    banner: ''
                  }
                });
              }}
              startIcon={<FontAwesomeIcon icon={faPlus} className="me-2" />}
            >
              Create New Board
            </Button>
          </Col>
        </Row>
      </div>

      {Array.isArray(yourBoardData) && yourBoardData.length > 0 ? (
        <>
          <KanbanBoardSection
            title="Your Boards"
            description=""
            boards={yourBoardData}
          />
          {summary?.total !== 0 ? (
            <Row className="align-items-center pt-3 row">
              <Col className="d-flex fs-9">
            
              </Col>
              <Col xs="auto">
                <CustomPagination
                  pageIndex={pageIndex}
                  totalPage={summary?.total_page}
                  activePage={Number(summary?.page)}
                  setPageIndex={setPageIndex}
                />
              </Col>
            </Row>
          ) : (
            <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
              <span className="text-600"> No record found !!</span>
            </p>
          )}
        </>

      ) : (
        <div className='ms-5'>No Board available</div>
      )}

      <KanbanAddBoardModal />
    </div>
  );
};

export default Boards;
