import classNames from 'classnames';
import Button from 'components/base/Button';
import { PropsWithChildren } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ToggleViewAvtar = ({
  tooltip,
  children
}: PropsWithChildren<{
  tooltip: string;
}>) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip style={{ position: 'fixed' }}>{tooltip}</Tooltip>}
    >
      <div>
        {children}
      </div>
    </OverlayTrigger>
  );
};

export default ToggleViewAvtar;
