import { AxiosError } from 'axios';
import Button from 'components/base/Button';
import VersionType from 'components/custom/VersionType';
import { useToast } from 'providers/ToastProvider';
import { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import apiCall from 'services/api';
import logo from 'assets/img/icons/logo-sign.png';
import ReCAPTCHA from "react-google-recaptcha";


type FormData = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  are_you_a_company: string;
  team_size: string;
  organization_name: string;
  how_do_you_know_us: string;
  recaptcha: string | null;
};

const SignUpForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const { showSuccessToast, showErrorToast } = useToast()
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const recaptchaRef = useRef(null);
  const secretKey = process.env.REACT_APP_CAPTCH_SITE_KEY || '';
 
  const [formData, setFormData] = useState<FormData>({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    are_you_a_company: '',
    team_size: '',
    organization_name: '',
    how_do_you_know_us: '',
    recaptcha: ''
  });

  const [errors, setErrors] = useState<Partial<FormData>>({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    are_you_a_company: '',
    team_size: '',
    organization_name: '',
    how_do_you_know_us: '',
  });


  const fieldNames: Record<string, string> = {
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email Address',
    phone: 'Phone Number',
    are_you_a_company: 'Are You a Company?',
    team_size: 'Team Size',
    organization_name: 'Organization name',
    how_do_you_know_us: 'How Do You Know Us',
  };

  const validateField = (field: keyof FormData, value: string) => {
    let error = '';

    // Check if the field is required and if it's empty
    if (!value.trim() && (field === 'first_name' || field === 'last_name' || field === 'email' || field === 'phone' || field === 'are_you_a_company' || field === 'team_size')) {
      error = `${fieldNames[field]} is required`;
    } else {
      switch (field) {
        case 'first_name':
          if (!/^[A-Za-z][A-Za-z\s]*$/.test(value)) {
            error = 'First name must start with a character and contain no leading spaces.';
          }
          break;

        case 'last_name':
          if (!/^[A-Za-z\s]*$/.test(value)) {
            error = 'Last name can only contain letters and spaces.';
          }
          break;

        case 'email':
          if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            error = 'Enter a valid email address.';
          }
          break;

        case 'phone':
          if (value && !/^\+?\d{1,15}(-\d{1,15})?$/.test(value)) {
            error = 'Phone number can only contain numbers, plus sign (+), and hyphens (-).';
          }
          break;

        case 'team_size':
          if (value && (Number(value) <= 0 || isNaN(Number(value)))) {
            error = 'Team size must be a positive number.';
          }
          break;

        case 'how_do_you_know_us':
          if (value.length > 1000) {
            error = 'Response must not exceed 1000 characters.';
          }
          break;

        case 'organization_name':
          // Check if `organization_name` is mandatory based on `are_you_a_company`
          if (formData.are_you_a_company === '1' && !value.trim()) {
            error = 'Organization Name is required.';
          }
          break;

        default:
          break;
      }
    }

    setErrors((prevErrors: any) => ({ ...prevErrors, [field]: error }));

  };

  const handleRecaptchaChange = (token: string | null) => {
    setFormData(prevState => ({
      ...prevState,
      recaptcha: token
    }));
    setErrors(prevState => ({
      ...prevState,
      recaptcha: ''
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    // Clear the error for the current field while typing
    setErrors((prevErrors: any) => ({ ...prevErrors, [name]: '' }));
    const newValue = name === 'email' ? value.toLowerCase() : value;

    // Set the form data
    setFormData((prevData: any) => ({ ...prevData, [name]: newValue }));
  };
  const handleBlur = (field: keyof FormData) => {
    const value = formData[field] || '';
    // Only validate the field on blur if it's not empty, otherwise keep the error empty
    if (value.trim()) {
      validateField(field, value);
    } else {
      setErrors((prevErrors: any) => ({ ...prevErrors, [field]: '' }));
    }
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate all fields on submit
    (Object.keys(formData) as Array<keyof FormData>).forEach((field) => {
      const value = formData[field] || '';
      if(value){
        validateField(field, value);
      }
      
    });

    if (!formData.recaptcha) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        recaptcha: 'Please verify that you are not a robot.',
      }));
      return; 
    }


    // Check if there are any errors
    const hasErrors = Object.values(errors).some((error) => error);

    if (!hasErrors) {

      try {
        setLoading(true);
        const formDataSubmit = new FormData();

        formDataSubmit.append('first_name', formData?.first_name);
        formDataSubmit.append('last_name', formData?.last_name);
        formDataSubmit.append('email', formData?.email);
        formDataSubmit.append('phone', formData?.phone);
        formDataSubmit.append('are_you_a_company', formData?.are_you_a_company);
        formDataSubmit.append('team_size', formData?.team_size);
        if (formData?.are_you_a_company === '1') {
          formDataSubmit.append('organization_name', formData?.organization_name);
        }

        formDataSubmit.append('how_do_you_know_us', formData?.how_do_you_know_us);

        const response = await apiCall({
          url: `demo-application`,
          method: 'POST',
          data: formDataSubmit
        });
        if (response.status === 200) {

          if (response?.data?.errors) {
            const errors = response?.data.errors;
            Object.keys(errors).forEach((key) => {
              const errorMessages = errors[key];
              const firstErrorMessage = errorMessages[0];
              setErrors((prevData: any) => ({
                ...prevData,
                [key]: firstErrorMessage,
              }));
            });
          } else {
            const message = response?.data?.message
            setMessage(message)
            setFormData({
              first_name: '',
              last_name: '',
              email: '',
              phone: '',
              are_you_a_company: '',
              team_size: '',
              organization_name: '',
              how_do_you_know_us: '',
              recaptcha:''
            })
            setIsModalOpen(true);

          }

        } else {
          throw new Error('Failed to add card');
        }
      } catch (error: unknown) {
        if (error instanceof AxiosError) {

        } else {
          console.error('An unexpected error occurred:', error);
          showErrorToast('An unexpected error occurred');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="text-center mb-3">
        <img src={logo} alt="" width={160} className="rounded-2  mt-3" />
        <VersionType />
      </div>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Apply for organization account</h3>
        {/* <p className="text-body-tertiary">Create your account</p> */}
      </div>
      <Form onSubmit={handleSubmit}>
        <Row className="g-3">
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="first_name">First Name</Form.Label>
              <Form.Control
                name="first_name"
                type="text"
                placeholder="First Name"
                value={formData.first_name}
                onChange={handleChange}
                onBlur={() => handleBlur('first_name')}
                isInvalid={!!errors.first_name}
              />
              <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="last_name">Last Name</Form.Label>
              <Form.Control
                name="last_name"
                type="text"
                placeholder="Last Name"
                value={formData.last_name}
                onChange={handleChange}
                onBlur={() => handleBlur('last_name')}
                isInvalid={!!errors.last_name}
              />
              <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-3">
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="email">Email Address</Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder="name@example.com"
                value={formData.email}
                onChange={handleChange}
                onBlur={() => handleBlur('email')}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="phone">Phone Number</Form.Label>
              <Form.Control
                type="tel"
                id="phone"
                name="phone"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handleChange}
                onBlur={() => handleBlur('phone')}
                isInvalid={!!errors.phone}
              />
              <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="are_you_a_company">Are You a Company?</Form.Label>
              <Form.Select id="are_you_a_company" name='are_you_a_company' value={formData.are_you_a_company} onChange={handleChange} isInvalid={!!errors.are_you_a_company}>
                <option value="">Select</option>
                <option value="1">Yes</option>
                <option value="2">No</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">{errors.are_you_a_company}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="team_size">Team Size</Form.Label>
              <Form.Control
                id="team_size"
                type="number"
                name="team_size"
                placeholder="Team Size"
                value={formData.team_size}
                onChange={handleChange}
                onBlur={() => handleBlur('team_size')}
                isInvalid={!!errors.team_size}
                min={1}
              />
              <Form.Control.Feedback type="invalid">{errors.team_size}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col>
            <Form.Group>
              <Form.Label htmlFor="organization_name">Oragnization Name?</Form.Label>
              <Form.Control
                type="text"
                id="organization_name"
                name="organization_name"
                placeholder="organization name"
                value={formData.organization_name}
                onChange={handleChange}
                onBlur={() => handleBlur('organization_name')}
                isInvalid={!!errors.organization_name}
                maxLength={1000}
              />
              <Form.Control.Feedback type="invalid">{errors.organization_name}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col>
            <Form.Group>
              <Form.Label htmlFor="how_do_you_know_us">How do you know us?</Form.Label>
              <Form.Control
                as="textarea"
                id="how_do_you_know_us"
                name="how_do_you_know_us"
                placeholder="Write here"
                value={formData.how_do_you_know_us}
                onChange={handleChange}
                onBlur={() => handleBlur('how_do_you_know_us')}
                isInvalid={!!errors.how_do_you_know_us}
                maxLength={1000}
              />
              <Form.Control.Feedback type="invalid">{errors.how_do_you_know_us}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className=" mb-3">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={secretKey} // Replace with your actual site key
                onChange={handleRecaptchaChange}
              />
              {errors.recaptcha && (
                <div className="text-danger fs-9">{errors.recaptcha}</div>
              )}
            </div>
          </Col>
        </Row>
        <Button variant="outline-primary" className="w-100 mb-3" type="submit">
          Sign Up
        </Button>
        <div className="text-center">
          <Link to={`/sign-in`} className="fs-9 fw-bold">
            Sign in to an existing account
          </Link>
        </div>
      </Form>
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} backdrop="static" keyboard={false}>
        <Modal.Header className='border-0 d-flex justify-content-center'>
          <Modal.Title>Sign Up Success</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-success '>
          <div className='text-center'>
            {message}
          </div>
        </Modal.Body>
        <Modal.Footer className='border-0'>
          <Row className="w-100">
            <Col className="text-center">
              <Link to={`/sign-in`} className="fs-9 fw-bold">
                Go to Sign In
              </Link>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SignUpForm;
