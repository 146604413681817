import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PhoenixDroppable from 'components/base/PhoenixDroppable';
import KanbanAddListModal from 'components/modals/KanbanAddListModal';
import KanbanBoardOffcanvas from 'components/modules/kanban/KanbanBoardOffcanvas';
import KanbanHeader from 'components/modules/kanban/KanbanHeader';
import KanbanList from 'components/modules/kanban/KanbanList';
import KanbanProvider, { useKanbanContext } from 'providers/KanbanProvider';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import { useEffect, useState } from 'react';
import { DragDropContext, DropResult, Draggable } from 'react-beautiful-dnd';
import {
  LIST_MODAL_TYPE,
  LIST_UPDATE_DATA,
  TOGGLE_ADD_BOARD_MODAL,
  TOGGLE_ADD_LIST_MODAL,
  USER_PERMISSION_ON_BOARD
} from 'reducers/KanbanReducer';
import apiCall from 'services/api';
import { handleCutomError } from 'services/handleCutomError';
import { toast } from 'react-toastify';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'providers/AuthContext';
import { KanbanBoardItem, KanbanBoardMembers, KanbanBoard } from 'data/kanban';
import KanbanAddBoardModal from 'components/modals/KanbanAddBoardModal';
import { AxiosError } from 'axios';
import PageBreadcrumb, { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import { getPageBreadCrumbs } from 'services/getPageBreadCrumbs';
import board7 from 'assets/img/kanban/defaultbaorimage.jpg';

const Kanban = () => {
  const { setContentClass } = useMainLayoutContext();

  useEffect(() => {
    setContentClass('kanban-content');

    return () => {
      setContentClass('');
    };
  }, []);

  return (
    <KanbanProvider>
      <KanbanContent />
    </KanbanProvider>
  );
};

const KanbanContent = () => {
  const { boardLists, boardData, boardUsersList, filterValue, boardLoading, kanbanDispatch } = useKanbanContext();
  const { alias, id, workspace } = useParams();
  const { userTkn, workSpaceTkn, user, signOut } = useAuth();
  const navigation = useNavigate() as NavigateFunction; // Explicitly define the type
  const initialBreadcrumb: PageBreadcrumbItem[] = [];
  const [breadCrumb, setBreadCrumb] =
    useState<PageBreadcrumbItem[]>(initialBreadcrumb);
  const [pageName, setPageName] = useState('TrelloView');


  useEffect(() => {
    const userId = user?.id;
    if (!boardUsersList) {
      console.warn('boardUsersList is undefined');
      return;
    }
    if (userId) {
      const isManager = boardUsersList.some(
        (boardUser) => boardUser.id === userId && boardUser.is_manager === 1
      );
      kanbanDispatch({
        type: USER_PERMISSION_ON_BOARD,
        payload: isManager
      });

    } else {
      kanbanDispatch({
        type: USER_PERMISSION_ON_BOARD,
        payload: false
      });
    }

  }, [boardUsersList, user, filterValue])

  useEffect(() => {
    const projectTitle = alias
    getPageBreadCrumbs({ pageName, workspace, setBreadCrumb, projectTitle });
  }, [workspace, pageName, setBreadCrumb]);

  const handleDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId, type } = result;

    if (type === "LIST") {
      if (!destination) return;
      const reorderedLists = Array.from(boardLists);
      const [movedList] = reorderedLists.splice(source.index, 1);
      reorderedLists.splice(destination.index, 0, movedList);
      kanbanDispatch({ type: 'SET_DATA', payload: reorderedLists });
      const formData = new FormData();
      reorderedLists.forEach(item => {
        formData.append('sequenceIds[]', String(item.id)); // Add each ID as part of a list
      });

      //update sequence
      const response = await apiCall({
        url: `project/${alias}/boards/${id}/lists/sequence`,
        method: 'POST',
        data: formData,
        headers: {
          'x-access-token': userTkn,
          workspace: workSpaceTkn
        }
      });

      if (response.status !== 200) {
        throw new Error('Failed to update card position on backend');
      }
    }
    else {
      const updatedList = structuredClone(boardLists);

      const task = updatedList
        .find(list => Number(list.id) === Number(source.droppableId))
        ?.boardListCards.splice(source.index, 1)[0];

      if (task && destination) {
        updatedList
          .find(list => Number(list.id) === Number(destination.droppableId))
          ?.boardListCards.splice(destination.index, 0, task);
      }

      if (!destination) return; // If the drag is canceled or invalid

      kanbanDispatch({
        type: 'MOVE_ITEMS',
        payload: { source, destination }
      });

      const cardId = draggableId.split('-')[1];
      const sourceListId = source.droppableId;
      const destinationListId = destination.droppableId;

      //update card move
      if (sourceListId !== destinationListId) {
      
        try {
          const formData = new FormData();
          formData.append('list_id', destination.droppableId);

          // Synchronize with backend
          const response = await apiCall({
            url: `project/${alias}/boards/${id}/card/${cardId}/card-move`,
            method: 'POST',
            data: formData,
            headers: {
              'x-access-token': userTkn,
              workspace: workSpaceTkn
            }
          });

          if (response.status !== 200) {
            throw new Error('Failed to update card position on backend');
          }
        } catch (error) {
          console.error('Error during drag end:', error);
          toast.error('Failed to update card position');
          // Optionally revert UI changes on error
        }
        // return;
      }

      // Use destinationId as a string

      try {
        const destinationId: string = String(destination.droppableId);
        const destinationList = updatedList.find(
          list => Number(list.id) === Number(destinationId)
        );
        if (!destinationList) {
          throw new Error(`No list found for droppableId: ${destinationId}`);
        }
        if (destinationList.boardListCards.length < 2) {
          return;
        }

        const formData = new FormData();
        formData.append('list_id', destination.droppableId);
        destinationList.boardListCards.forEach(item => {
          formData.append('sequenceIds[]', item.id); // Add each ID as part of a list
        });

        //update sequence
        const response = await apiCall({
          url: `project/${alias}/boards/${id}/card/sequence`,
          method: 'POST',
          data: formData,
          headers: {
            'x-access-token': userTkn,
            workspace: workSpaceTkn
          }
        });

        if (response.status !== 200) {
          throw new Error('Failed to update card position on backend');
        }
      } catch (error) {
        console.error('Error during drag end:', error);
        toast.error('Failed to update card position');
        // Optionally revert UI changes on error
      } const reorderedLists = Array.from(boardLists);
      const [movedList] = reorderedLists.splice(source.index, 1);
      reorderedLists.splice(destination.index, 0, movedList);

      // kanbanDispatch({ type: 'SET_DATA', payload: reorderedLists });
    }
  };

  useEffect(() => {
    // kanbanDispatch({ type: 'BOARD_CARD_FILTER', payload: '1' });
    const fetchData = async () => {
      try {
        kanbanDispatch({ type: 'BOARD_LOADING', payload: true });

        const URL = Number(filterValue) === 1 ? `project/${alias}/boards/${id}/view` : `project/${alias}/boards/${id}/view?card_status=0`;

        const [boardResponse, cardPriorityResponse, cardCategoryResponse, boardLabelsResponse] = await Promise.all([
          apiCall({
            url: URL,
            method: 'GET',
            headers: {
              'x-access-token': userTkn,
              workspace: workSpaceTkn
            }
          }),
          apiCall({
            url: `project/${alias}/masters/priority`,  // updated
            method: 'GET',
            headers: {
              'x-access-token': userTkn,
              workspace: workSpaceTkn
            }
          }),
          apiCall({
            url: `project/${alias}/masters/category`,  // updated
            method: 'GET',
            headers: {
              'x-access-token': userTkn,
              workspace: workSpaceTkn
            }
          }),
          apiCall({
            url: `project/${alias}/boards/${id}/masters/label`,  // updated
            method: 'GET',
            headers: {
              'x-access-token': userTkn,
              workspace: workSpaceTkn
            }
          })
        ])

        if (boardResponse?.status === 200) {
          // Calculate serial numbers for the projects on the current page
          const boardData = boardResponse?.data?.data?.board;
          const boardListData = boardData?.boardLists;
          const boardMembersData = boardData?.users;
          // setData(boardListData);
          kanbanDispatch({ type: 'SET_DATA', payload: boardListData });
          kanbanDispatch({ type: 'UPDATE_BOARD_USERS', payload: boardMembersData });
          kanbanDispatch({ type: 'BOARD_DATA', payload: boardData })

          kanbanDispatch({ type: 'BOARD_LOADING', payload: false });
        } else {
          console.error('error messag');
        }
        if (cardPriorityResponse?.status === 200) {

          const priorityData = cardPriorityResponse?.data?.data?.ProjectsPriority;
          const options = priorityData.map((item: any) => ({
            value: item.id,
            label: item.title,
            color: item?.color
          }));
          kanbanDispatch({ type: 'CARD_PRIORITY', payload: options })
        }
        if (cardCategoryResponse?.status === 200) {

          const priorityData = cardCategoryResponse?.data?.data?.ProjectsCategory;
          const options = priorityData.map((item: any) => ({
            value: item.id,
            label: item.title,
            color: item?.color
          }));
          kanbanDispatch({ type: 'CARD_CATEGORY', payload: options })
        }
        if (boardLabelsResponse?.status === 200) {
          const labelsData = boardLabelsResponse?.data?.data?.boardMasterLabel;
          kanbanDispatch({ type: 'BOARD_LABELS_LIST', payload: labelsData })
        }
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          handleCutomError(error, signOut, navigation);
        } else {
          toast.error('An unexpected error occurred');
        }
      } finally {
        kanbanDispatch({ type: 'BOARD_LOADING', payload: false }); // Set loading to false after the API call is completed
      }
    };
    fetchData();
  }, [, filterValue]);

  return (
    <div>
      <div className="kanban-header">
        <PageBreadcrumb items={breadCrumb} navigation={navigation} />
      </div>

      <KanbanHeader />
      <DragDropContext onDragEnd={handleDragEnd}>
        <PhoenixDroppable droppableId="kanban-lists" direction="horizontal" type="LIST">
          {(provided) => (
            <div
              className="kanban-container scrollbar"
              style={{
                backgroundImage: `url(${boardData?.boardbanner || board7})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {boardLists?.map((list, index) => (
                <Draggable key={`${list.id}`} draggableId={`${list.id}`} index={index}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <PhoenixDroppable key={list.id} droppableId={list.id.toString()} type="CARD">
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.droppableProps}>
                            <KanbanList list={list} />
                            {provided.placeholder}
                          </div>
                        )}
                      </PhoenixDroppable>
                    </div>
                  )}
                </Draggable>
              ))}
              <div className="kanban-column kanban-add-list scrollbar position-relative   d-flex flex-column h-100 flex-center bg-body-hover">
                <Button
                  className="stretched-link btn-icon btn-icon bg-body-secondary rounded-circle mb-1"
                  onClick={() => kanbanDispatch({ type: TOGGLE_ADD_LIST_MODAL, payload: true })}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <h5>Add another list</h5>
              </div>
              {provided.placeholder}
            </div>
          )}
        </PhoenixDroppable>
      </DragDropContext>
      <KanbanBoardOffcanvas />
      <KanbanAddListModal />
      <KanbanAddBoardModal />
    </div>
  );
};
export default Kanban;
