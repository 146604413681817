import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "common/Constants";
import PageBreadcrumb, { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { lazy, Suspense, useEffect, useState, useRef } from "react";
import { Link, NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { projectsTablecolumns } from "components/tables/customTables/ProjectsTablesList";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import { ChangeEvent } from "react";
import { Button, Col, FloatingLabel, Form,  Row, Spinner, Table } from "react-bootstrap";
import { useAppContext } from "providers/AppProvider";
import { NavPositionVariant, NavTopShapeVariant } from "config";
import useSettingsMountEffect from "hooks/useSettingsMountEffect";
import { useAuth } from "providers/AuthContext";
import Avatar from "components/base/Avatar";
import Unicon from 'components/base/Unicon';
import { UilPen, UilRefresh,  UilToggleOff, UilToggleOn, UilUser } from '@iconscout/react-unicons';
import apiCall from "services/api";
import calculateSerialNumber from "common/calculateSerialNumber";
import CustomPagination from "components/sp-common/CustomPagination";
import { useMaster } from "providers/MasterContext";
import { geDesignationNameById } from "utils/getNames";
const AddUserModal = lazy(() => import("./AddUserModal"));
const UpdateUserModal = lazy(() => import("./UpdateUserModal"));
const UpdatePassWordModal = lazy(() => import("./UpdatePassWordModal"));
import { toast, ToastContainer } from 'react-toastify';
import SearchInput from "components/sp-common/SearchInput";
import ConfirmationModal from "common/ConfirmModal";
import { getAdminPageBreadCrumbs } from "services/getAdminPageBreadCrumbs";
import { handleCutomError } from "services/handleCutomError";


type Users = {
  id: number;
  first_name: string | null;
  last_name: string | null;
  short_name: string | null;
  color_code: string;
  email: string;
  phone: string;
  designation_id: number;
  is_super_admin: number;
  status: number;
  status_name: string;
  serialNumber: number;
};


type ReactSelectOption = {
  value: number;
  label: string;
};

// Define the type for the page filters state
type PageFiltersState = {
  status: string;
  titleSearch: string;
};


const AdminUsersList = () => {
  const initialBreadcrumb: PageBreadcrumbItem[] = [];
  const [pageName, setPageName] = useState("AdminUserList")
  const [breadCrumb, setBreadCrumb] = useState<PageBreadcrumbItem[]>(initialBreadcrumb);
  const { workspace } = useParams();
  const { userTkn, workSpaceTkn, signOut } = useAuth();
  const { designation } = useMaster();
  const navigation = useNavigate() as NavigateFunction; // Explicitly define the type
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const [data, setData] = useState<Users[] | null>(null);
  const [summary, setSummary] = useState<any | null>({
    "total": 0,
    "page": 1,
    "pageSize": 0,
    "total_page": 1,
  });
  const [pageIndex, setPageIndex] = useState<number | null>(1);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const tableRef = useRef<HTMLTableElement | null>(null);
  const [indexValueFirst, setValueFirst] = useState<number | null>(1);
  const [indexValueLast, setValueLast] = useState<number | null>(1);
  //for filters
  const [addStatus, setAddStatus] = useState<boolean>(false);
  const [pageFilters, setPageFilters] = useState<PageFiltersState>({
    status: "10",
    titleSearch: ""
  })
  const [userUpdateData, setUserUpdateData] = useState({
    id: 0,
    first_name: "",
    last_name: "",
    short_name: "",
    email: "",
    designation_id: 0,
    status: 0,
    user_password: "",
  });
  const [userUpdatePassword, setUserUpdatePassword] = useState({
    id: 0,
  });

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmedItemAlias, setConfirmedItemAlias] = useState<number | null>(null);

  const {
    config: { theme, navbarPosition },
    setConfig,
  } = useAppContext();

  //set top navigation
  useEffect(() => {
    const value = "horizontal";
    const value2 = "slim";
    setConfig({
      navbarPosition: value as NavPositionVariant,
      navbarTopShape: value2 as NavTopShapeVariant,
    });
  }, []);

  //update Breadcrumb
  useEffect(() => {
    getAdminPageBreadCrumbs({ pageName, workspace, setBreadCrumb });
  }, [workspace, pageName, setBreadCrumb]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await apiCall({
          url: `admin/user/list?page=${pageIndex}&status=${pageFilters?.status}&fullname=${pageFilters.titleSearch}`,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });

        if (response.status === 200) {
          const updatedUsers = response?.data?.users?.data.map((users: any, index: any) => ({
            ...users,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, response?.data?.users?.summary?.pageSize) // Assuming 10 items per page
          }));
          setAddStatus(false)
          setData(updatedUsers);
          let summary = response?.data?.users?.summary;
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false); // Set loading to false after the API call is completed
      }
    };
    fetchData();
  }, [userTkn, pageIndex, addStatus, pageFilters.status]);




  useSettingsMountEffect({
    showSettingPanelButton: false,
  });

  const table = useAdvanceTable({
    data: data || [],
    columns: projectsTablecolumns,
    pageSize: 2,
    pagination: true,
    sortable: true,
  });

  const searchInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPageFilters((prevData) => ({
      ...prevData,
      titleSearch: e.target.value
    }))
  };

  const handleSearchInputReq = (value: string) => {
    // You can use the titleSearch state value to filter your data
    const fetchData = async () => {
      let searchUrl = ""
      if (value === "search") {
        searchUrl = `admin/user?page=${pageIndex}&status=${pageFilters?.status}&fullname=${pageFilters.titleSearch}`
      } else {
        searchUrl = `admin/user?page=${pageIndex}&status=${pageFilters?.status}&fullname=`
      }

      try {
        setLoading(true);
        const response = await apiCall({
          url: searchUrl,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });

        if (response?.status === 200) {
          const updatedUsers = response?.data?.users?.data.map((users: any, index: any) => ({
            ...users,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, response?.data?.users?.summary?.pageSize)
          }));
          setData(updatedUsers);
          let summary = response?.data?.users?.summary;
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  };

  const handleOnBlur = async (value: any) => {
    if (value !== "") {
      let searchUrl = ""

      searchUrl = `admin/user?page=${pageIndex}&status=${pageFilters?.status}&fullname=${pageFilters.titleSearch}`;

      try {
        setLoading(true);
        const response = await apiCall({
          url: searchUrl,
          method: 'GET',
          headers: {
            'x-access-token': userTkn,
            'workspace': workSpaceTkn
          },
        });

        if (response?.status === 200) {
          const updatedUsers = response?.data?.users?.data?.map((users: any, index: any) => ({
            ...users,
            serialNumber: calculateSerialNumber(pageIndex || 1, index, response?.data?.users?.summary?.pageSize) // Assuming 10 items per page
          }));
          setAddStatus(false)
          setData(updatedUsers);
          let summary = response?.data?.users?.summary;
          setSummary({
            "total": summary.total,
            "page": summary.page,
            "pageSize": summary.pageSize,
            "total_page": summary.total_page,
          });
        } else {
          console.error("error message:");
        }
      } catch (error: any) {
        if (error?.isAxiosError) {
          // Access the error message
          handleCutomError(error, signOut, navigation);
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          // Display a generic error message to the user
          toast.error('An unexpected error occurred', {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }
    }
  }

  const ChangeStatus = async (id: number) => {
    try {
      setLoading(true);
      const response = await apiCall({
        url: `admin/user/${id}/changestatus`,
        method: 'POST',
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });

      if (response?.status === 200) {
        // Ensure that newData is always a valid array
        const newData: Users[] =
          data?.map((item) => {
            if (item.id === response?.data?.user?.id) {
              item.status = response?.data?.user?.status;
            }
            return item;
          }) || [];

        setData(newData);
      } else {
        console.error("error message:");
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        toast.error('An unexpected error occurred', {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false); // Set loading to false after the API call is completed
    }
  };

  useEffect(() => {
    // Now 'tableRef.current' contains the reference to the table element
    const table = tableRef.current;

    if (table) {
      const firstRow = table?.rows[1];
      const lastRowIndex = table?.rows.length - 1;
      const lastRow = table?.rows[lastRowIndex];
      if (firstRow) {
        const firstCell = firstRow.cells[0];
        if (firstCell) {
          const cellValue = firstCell.textContent || firstCell.innerText;
          let value = Number(cellValue)
          setValueFirst(value)
        }
      }

      if (lastRow) {
        const firstCell = lastRow.cells[0];
        if (firstCell) {
          const cellValue = firstCell.textContent || firstCell.innerText;
          let value = Number(cellValue)
          setValueLast(value)
        }
      }
    }
  }, [data]);

  const getStatusIcon = (status: number, id: number) => {
    if (status === 10) return <Unicon icon={UilToggleOn} size={21} color="" fill="#8FC644" onClick={() => { setConfirmedItemAlias(id); setShowConfirmation(true) }} />
    else if (status === 9) return <Unicon icon={UilToggleOff} size={21} color="" fill="#6E7891" onClick={() => { setConfirmedItemAlias(id); setShowConfirmation(true) }} />
    else return <div></div>
  }

  const confirmStatusChange = async () => {
    // Call ChangeStatus function
    if (confirmedItemAlias) {
      await ChangeStatus(confirmedItemAlias);
      setShowConfirmation(false);
    }

  };

  const handlePageFilters = (value: string) => {
    setPageFilters((prevData) => ({
      ...prevData,
      titleSearch: "",
    }));
  }

  //toggle super admin
  const userActions = async (item: any) => {
    try {
      setLoading(true);
      const response = await apiCall({
        url: `admin/user/${item?.id}/toggle-super-admin`,
        method: 'POST',
        headers: {
          'x-access-token': userTkn,
          'workspace': workSpaceTkn
        },
      });
      
      if (response?.status === 200) {
        // Ensure that newData is always a valid array
        let updatedUser = response?.data?.user;
        const newData = data?.map((user: Users) => {
          if (user.id === updatedUser.id) {
            return { ...user, is_super_admin: updatedUser.is_super_admin };
          }
          return user;
        });
        if(newData){
          setData(newData);
        }
      } else {
        console.error("error message:");
      }
    } catch (error: any) {
      if (error?.isAxiosError) {
        // Access the error message
        handleCutomError(error, signOut, navigation);
      } else {
        // Handle other types of errors
        console.error('An error occurred:', error);
        // Display a generic error message to the user
        toast.error('An unexpected error occurred', {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false); // Set loading to false after the API call is completed
    }
  }

  return (
    <div>
      <PageBreadcrumb items={breadCrumb} navigation={navigation} />
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <h2 className="mb-0">
            <span className="me-3 fs-6 fw-bold">Users</span>{" "}
          </h2>
          <Button
            variant="outline-primary"
            className="btn px-5 fs-8 fw-bold"
            onClick={() => setOpenAddUserModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {Constants.new_user}
          </Button>
        </div>
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <Col xs={12} sm="auto">
            <div className="d-flex flex-wrap gap-3 ">
              <SearchInput
                pageFilters={pageFilters}
                handleSearchInputChange={searchInputOnChange}
                handleSearchInputBlur={handleSearchInputReq}
                handlePageFilters={handlePageFilters}
                handleOnBlur={handleOnBlur}
                title="Title"
              />
              <FloatingLabel controlId="lead-woner" label="Status">
                <Form.Select
                  value={pageFilters.status}
                  onChange={(e) => {
                    setPageFilters((prevData) => ({
                      ...prevData,
                      status: e.target.value
                    }))
                  }}
                >
                  <option value="10">Active</option>
                  <option value="9">Inactive</option>
                </Form.Select>
              </FloatingLabel>
            </div>
          </Col>

        </div>
        <div className="d-md-block d-none">

          <PhoenixDocCard className="p-4 mt-3">
            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="light" />{" "}
              </div>
            ) :
              (
                <>
                  <Table
                    className="phoenix-table fs-9 project-table border rounded"
                    size="sm"
                    striped
                    hover
                    responsive
                    ref={tableRef}
                  >
                    <thead>
                      <tr id={"a1"}>
                        <th style={{ width: "2%", maxWidth: "20px", padding: "16px 0px" }}>#</th>
                        <th
                          className="px-1 fs-9 fw-bolder"
                          style={{ width: "45%", minWidth: "200px" }}
                        >
                          {Constants.full_name}
                        </th>
                        <th
                          className="px-1 fs-9 fw-bolder"
                          style={{ width: "45%", minWidth: "200px" }}
                        >
                          {"Super Admin"}
                        </th>
                        <th
                          className="px-1 fs-9 fw-bolder"
                          style={{ width: "35%", minWidth: "200px" }}
                        >
                          Designation
                        </th>
                        <th
                          className="px-1 fs-9 fw-bolder"
                          style={{ width: "15%", minWidth: "200px" }}
                        >
                          {Constants.status}
                        </th>
                        <th
                          className="px-1 fs-9 fw-bolder"
                          style={{ width: "15%", minWidth: "200px" }}
                        >
                          {Constants.action}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.map((item: Users, index: number) => {
                          return (
                            <tr key={item?.id || index}>
                              <td className="fs-9 fw-semibold">{item?.serialNumber}</td>
                              <td >
                                <Link
                                  to={{
                                    pathname: `/${workspace}/admin/users/${item?.id}/view`,
                                  }}
                                  state={{ user: item }}
                                  className="text-decoration-none fw-semi-bold fs-8"
                                >
                                  <div className="d-flex  align-items-center">
                                    <div className="avatar_width">
                                      <Avatar
                                        size="s"
                                        variant="name"
                                        className="me-1"
                                      >
                                        {item?.short_name?.slice(0, 2)}
                                      </Avatar>
                                    </div>

                                    {`${item?.first_name} ${item?.last_name}`}
                                  </div>
                                </Link>
                              </td>
                              <td>
                                {item?.is_super_admin === 0 ? "" : "Yes"}
                              </td>
                              <td>
                                {geDesignationNameById(Number(item?.designation_id), designation)}
                              </td>

                              <td className="toggle_border">
                                {getStatusIcon(item?.status, item?.id)}
                              </td>
                              <td>
                                <div className="d-flex gap-1">
                                  {/* <Button
                                    variant="phoenix-primary"
                                    className="btn-icon rounded-1 bg-transparent"

                                    onClick={() => {
                                      setUserUpdatePassword({
                                        id: item?.id,
                                      });
                                      setOpenPasswordModal(true)
                                    }}
                                  >
                                    <Unicon icon={UilSitemap} size={18} />
                                  </Button> */}
                                  <Button
                                    variant="phoenix-primary"
                                    className="btn-icon rounded-1 bg-transparent"
                                    onClick={() => {
                                      setUserUpdateData({
                                        id: item?.id,
                                        first_name: item?.first_name || "",
                                        last_name: item?.last_name || "",
                                        short_name: item?.short_name || "",
                                        email: item?.email || "",
                                        designation_id: item?.designation_id,
                                        status: item?.status,
                                        user_password: "", // You might want to set a default value for user_password
                                      });
                                      setOpenUpdateModal(true); // Optionally open the update modal
                                    }}
                                  >
                                    <Unicon icon={UilPen} size={20} />
                                  </Button>
                                  <Button
                                    variant="phoenix-primary"
                                    className="btn-icon rounded-1"
                                    onClick={() => { userActions(item) }}
                                  >
                                    <Unicon icon={item?.is_super_admin === 1 ? UilRefresh : UilUser} size={20} id="ongoing" />
                                    {/* <Tooltip anchorSelect="#ongoing" clickable>{item?.is_super_admin === 1 ? "Remove Super Admin" : "Make Super Admin"}</Tooltip> */}
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  {summary?.total !== 0 ? <Row className="align-items-center pt-3 row">
                    <Col className="d-flex fs-9">
                      <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900">
                        {indexValueFirst} to {indexValueLast}
                        <span className="text-600"> items of </span>
                        {summary?.total}
                      </p>
                    </Col>
                    <Col xs="auto">
                      <CustomPagination
                        pageIndex={pageIndex}
                        totalPage={summary?.total_page}
                        activePage={Number(summary?.page)}
                        setPageIndex={setPageIndex} 
                      />
                    </Col>
                  </Row>
                    :
                    <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900 mt-2">
                      <span className="text-600"> No record found !!</span>
                    </p>
                  }
                </>
              )}
          </PhoenixDocCard>



        </div>

      </AdvanceTableProvider>
      <Suspense fallback={<div>Loading...</div>}>
        {openAddUserModal && (<AddUserModal
          show={openAddUserModal}
          handleClose={() => setOpenAddUserModal(false)}
          setAddStatus={setAddStatus}
          toast={toast}
          navigation={navigation}
        />
        )}

        {openUpdateModal && (<UpdateUserModal
          show={openUpdateModal}
          handleClose={() => setOpenUpdateModal(false)}
          userUpdateData={userUpdateData}
          setAddStatus={setAddStatus}
          toast={toast}
          navigation={navigation}
        />)}

        {
          openPasswordModal && (
            <UpdatePassWordModal
              show={openPasswordModal}
              handleClose={() => setOpenPasswordModal(false)}
              userUpdatePassword={userUpdatePassword}
              setAddStatus={setAddStatus}
              toast={toast}
              navigation={navigation}
            />
          )
        }
        {showConfirmation && (<ConfirmationModal
          show={showConfirmation}
          onHide={() => setShowConfirmation(false)}
          onConfirm={confirmStatusChange}
          text="Are you sure you want to change the status?"
        />
        )}
      </Suspense>
      <ToastContainer />
    </div>
  );
};

export default AdminUsersList;
