import { KanbanBoardMembers } from "data/kanban";

export interface UserPermission {
    serialNumber?: number;
    id: number;
    user_id: number;
    project_id: number;
    designation_id: number;
    role_in_project: string;
    last_activity_datetime: number;
    is_user_watching: number;
    is_project_admin: number;
    is_story_admin: number;
    is_meeting_admin: number;
    is_diary_admin: number;
    status: number;
    user?: KanbanBoardMembers
}

export interface Project {
    id: number;
    title: string;
    short_name: string;
    alias: string;
    description: string;
    start_date: string; // Format: YYYY-MM-DD
    end_date: string;   // Format: YYYY-MM-DD
    editable: number;
    category: number;
    phase: number;
    color: string | null;
    status: number;
    projectlogo: string;
    projectbanner: string;
    userpermission: UserPermission;
    entity_id?: number;
}

export interface ProjectPriorityType {
    id: number;
    title: string;
    color: string;
    serialNumber?: number;
}
export interface ProjectCategoryType {
    id: number;
    title: string;
    color: string;
    serialNumber?: number;
}


export const initialProjectState: Project = {
    id: 0,
    title: '',
    short_name: '',
    alias: '',
    description: '',
    start_date: '',
    end_date: '',
    editable: 0,
    category: 0,
    phase: 0,
    color: null,
    status: 0,
    projectlogo: '',
    projectbanner: '',
    userpermission: {
        id: 0,
        user_id: 0,
        project_id: 0,
        designation_id: 0,
        role_in_project: '',
        last_activity_datetime: 0,
        is_user_watching: 0,
        is_project_admin: 0,
        is_story_admin: 0,
        is_meeting_admin: 0,
        is_diary_admin: 0,
        status: 0,
    },
};

export const initialProjectUser: UserPermission = {
    id: 0,
    user_id: 0,
    project_id: 1,
    designation_id: 0,
    role_in_project: "0",
    last_activity_datetime: 0,
    is_user_watching: 0,
    is_project_admin: 0,
    is_story_admin: 0,
    is_meeting_admin: 0,
    is_diary_admin: 0,
    status: 1,
    user: {
      id: 0,
      short_name: "",
      color_code: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: null,
      designation_id: 0,
      is_super_admin: 0,
      status: 0,
      status_name: "",
    },
  };

  export const initialPriorityData: ProjectPriorityType = {
    id: 0,
    title: '',
    color: '',
    serialNumber: 1
}

export const initialCategoryData: ProjectCategoryType = {
    id: 0,
    title: '',
    color: '',
    serialNumber: 1
}
