import calculateSerialNumber from "common/calculateSerialNumber";
import Button from "components/base/Button";
import { useAuth } from "providers/AuthContext";
import { useProjectContext } from "providers/ProjectProvider";
import { useToast } from "providers/ToastProvider";
import { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom";
import { PRIORITY_SUMMARY, PROJECT_PRIORITY_DATA, TOGGLE_ADD_PRIORITY_MODAL } from "reducers/ProjectReducer";
import apiCall from "services/api";
import { handleCutomError } from "services/handleCutomError";

interface FormDataPrioity {
    id?: number,
    title: string;
    color: string;
}

interface ErrorsPrioity {
    title: string;
    color: string;
}
const initialDataPriority: FormDataPrioity = {
    id: 0,
    title: '',
    color: ''
}
const initialErrors: ErrorsPrioity = {
    title: '',
    color: ''
}

const AddCategoryForm = () => {
    const { showSuccessToast, showErrorToast } = useToast();
    const navigation = useNavigate();
    const { userTkn, workSpaceTkn, signOut } = useAuth();
    const { alias } = useParams();
    const { isEditModePriority, updatePriorityData, projectDispatch } = useProjectContext();
    const [priorityData, setPriorityData] = useState<FormDataPrioity>(initialDataPriority);
    const [errors, setErrors] = useState<ErrorsPrioity>(initialErrors);
    const [loading, setLoading] = useState(false)
    const [validated, setValidated] = useState<boolean>(false);

    useEffect(() => {
        if (isEditModePriority) {
            setPriorityData((prev) => ({
                ...prev,
                id: updatePriorityData?.id,
                title: updatePriorityData?.title,
                color: updatePriorityData?.color
            }))
        }
    }, [isEditModePriority, updatePriorityData])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let errorMessage = "";

        setPriorityData(prevData => ({
            ...prevData,
            [name]: value,
        }));


        validateField(name, value);
    }
    const validateField = (name: string, value: string) => {
        let newErrors: ErrorsPrioity = { ...errors };

        switch (name) {
            case 'title':
                if (!value) {
                    newErrors.title = "Title is required";
                } else if (value.length > 30) {
                    newErrors.title = "Title must be less than 30 characters";
                } else if (/^[^a-zA-Z]/.test(value)) {
                    newErrors.title = "Title cannot start with a number or special character";
                } else {
                    newErrors.title = ""; // No error
                }
                break;
            case 'color':
                if (!value) {
                    newErrors.color = "Color is required";
                } else {
                    newErrors.color = ""; // No error
                }
                break;
            default:
                break;
        }

        setErrors(newErrors);
    };
    const validateForm = () => {
        let isValid = true;
        const newErrors: ErrorsPrioity = { title: "", color: "" };

        // Title validation
        if (!priorityData.title) {
            newErrors.title = "Title is required";
            isValid = false;
        } else if (priorityData.title.length > 30) {
            newErrors.title = "Title must be less than 30 characters";
            isValid = false;
        } else if (/^[^a-zA-Z]/.test(priorityData.title)) {
            newErrors.title = "Title cannot start with a number or special character";
            isValid = false;
        }

        // Color validation
        if (!priorityData.color) {
            newErrors.color = "Color is required";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
        if (!validateForm()) return;
        try {
            setLoading(true);
            const formDataSubmit = new FormData();
            formDataSubmit.append('title', priorityData?.title);
            formDataSubmit.append('color', priorityData?.color);

            const apiUrl = isEditModePriority ? `project/${alias}/masters/priority/${priorityData?.id}/update`
                : `project/${alias}/masters/priority/create`;

            const response = await apiCall({
                url: apiUrl,
                method: 'POST',
                data: formDataSubmit,
                headers: {
                    'x-access-token': userTkn,
                    workspace: workSpaceTkn
                }
            });

            if (response.status === 200) {
                if (response?.data?.errors) {
                    const errors = response?.data.errors;
                    // Dynamically set errors based on the response
                    Object.keys(errors).forEach((key) => {
                        const errorMessages = errors[key];
                        const firstErrorMessage = errorMessages[0]; // Assuming you want to display only the first error message
                        // Set the error state for the corresponding field
                        setErrors((prevData) => ({
                            ...prevData,
                            [key]: firstErrorMessage,
                        }));
                    });
                } else {
                    if (isEditModePriority) {
                        fetchPriorityData();
                    } else {
                        fetchPriorityData();
                    }
                }

            } else {
                showErrorToast('Failed to add Board');
            }
        } catch (error) {
            console.error('Error adding Board:', error);
            showErrorToast('Failed to add Board');
        } finally {
            setLoading(false);
        }
    }

    const fetchPriorityData = async () => {
        try {
            setLoading(true)

            const [response] = await Promise.all([

                apiCall({
                    url: `project/${alias}/masters/priority`,
                    method: 'GET',
                    headers: {
                        'x-access-token': userTkn,
                        'workspace': workSpaceTkn
                    },
                }),
            ])

            if (response.status === 200) {
                const priorityData = response?.data?.data?.ProjectsPriority;
                const updatedData = priorityData?.map((users: any, index: any) => ({
                    ...users,
                    serialNumber: calculateSerialNumber(1, index, response?.data?.data?.summary?.pageSize)
                }));

                projectDispatch({ type: PROJECT_PRIORITY_DATA, payload: updatedData });
                const summary = response?.data?.data?.summary;
                projectDispatch({
                    type: PRIORITY_SUMMARY, payload: {
                        "total": summary.total,
                        "page": summary.page,
                        "pageSize": summary.pageSize,
                        "total_page": summary.total_page,
                    }
                });

                projectDispatch({
                    type: TOGGLE_ADD_PRIORITY_MODAL,
                    payload: false
                });

            } else {
                console.error("error message:");
            }
        } catch (error: any) {
            if (error?.isAxiosError) {
                // Access the error message
                handleCutomError(error, signOut, navigation);
            } else {
                // Handle other types of errors
                showErrorToast('An error occurred:', error);

            }
        } finally {
            setLoading(false)
        }
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
                <Col md={12} className="mb-3">
                    <Form.Group className="text-start">
                        <FloatingLabel
                            controlId="floatingInputGrid"
                            label={`Title`}
                        >
                            <Form.Control

                                type="text"
                                placeholder="Title"
                                name="title"
                                value={priorityData.title}
                                onChange={handleInputChange}
                                isInvalid={!!errors.title}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.title}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group className="text-start">
                        <Form.Control
                            type="color"
                            placeholder="Color"
                            name="color"
                            defaultValue={"#ffffff"}
                            value={priorityData.color}
                            onChange={handleInputChange}
                            isInvalid={!!errors.color}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.color}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <div className="d-flex justify-content-center align-items-center px-4 pb-4 border-0 pt-3">
                        <Button
                            variant="outline-primary"
                            size="sm"
                            className="px-7 fs-9  my-0 btn"
                            type="submit"
                            disabled={loading}
                        >
                            Save
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    )
}

export default AddCategoryForm;